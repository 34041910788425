.tabs-events {
	
	.ant-tabs-bar {
		margin: unset;
		background-color: var(--light-black);
		border-bottom: 2px solid rgba(255, 255, 255, 0.1);
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;

		.ant-tabs-nav-scroll {
			display: flex;
			flex-direction: row;
			align-items: center;
			//justify-content: center;

			.ant-tabs-tab {
				font-size: 16px;
				font-weight: bold;
				opacity: 0.7;
				color: var(--white);
				text-transform: uppercase;
			}
			.ant-tabs-nav{
				margin: auto;
			}

			.ant-tabs-tab-active {
				opacity: unset;
			}

			.ant-tabs-ink-bar {
				background-color: #044da1;
			}
		}
	}

}