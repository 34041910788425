.ant-input-affix-wrapper .ant-input:not(:last-child){
	padding-right:7rem
}
.search-box {
	.ant-input{
	background-color: var(--dark-blue);
	color: var(--white);
	font-size: 12px;
	padding-left: 40px;
	border: none;
	border-radius: 20px;
	height: 30px !important;
	}
	@media only screen and (min-width: 600px){
		.ant-input{
			padding-left: 20px !important;
		}
		.ant-input-affix-wrapper{
			.ant-input-suffix{
				position: absolute;
				left: 90% !important;
			}
		}
		
	}

	.ant-input-suffix svg{
		width: 19px;
		height: 19.7px;
		opacity: 0.5;
		color: var(--white-to-gray)
	}

	.ant-input-affix-wrapper .ant-input-suffix {
		right: 0;
		left: 10px;
		width: 20px;
    }
    
   .ant-spin-nested-loading  div .ant-spin-spinning .ant-spin-dot {
        left: 80% 
    } 
	
}

.search-popover{

   .ant-popover-title{
	   font-weight: bold;
	   height: 43px;
	   align-items: center;
	   display: flex;
   }
   .ant-popover-inner-content{
	   padding: 12px;
	   }
}

.search-list{
	max-height: 600px;
	height: auto;
	width: 230px;	
	cursor: pointer;
	overflow-y: scroll;	
	line-height: normal;
			h3{
			font-weight: bold;
			line-height: 1;
		}	
	}
.search-list::-webkit-scrollbar-track{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
	}
.search-list::-webkit-scrollbar-thumb{
		background-color:var(--blue);
	}
.search-list::-webkit-scrollbar{
	width: 6px;
	background-color: #F5F5F5;
	}
    
    .search-box-top-menu{
        .ant-input{
        background-color: transparent;
        border: none;
        color: var(--white);
        font-size: 14px;
        font-weight: bold;
        width: 20px;
        }
    
        .ant-input-suffix svg{
            width: 19px;
            height: 19.7px;
            opacity: 0.5;
            color: var(--white)
        }
        
    }
    .search-popover-hidden{
            position: absolute;
            z-index:  -1;
        }


/***************** Secondary Search bar ***************/ 

.sec-search-bar {
	background-color: var(--light-blue-to-off-white);
	border: 5px solid transparent;
	border-radius: 5px;
	width: 100%;
	margin: 0.6rem 0;
	padding: 0.25rem 1.5rem 0.25rem 0.2rem;
}
.sec-serach-continer {
	position: relative;
}
.sec-search-icon {
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(-50%, -50%);
}