/*********************** Ant Layout ************************/
.ant-layout {
  background-color: transparent !important;

  .ant-layout-sider {
    .ant-layout-sider-children {
      width: 100%;
    }
  }
}

/*********************** Carousel *****************************/

.ant-carousel {
  padding: 15px 10px;
}

/*********************** Button Styles ************************/
.ant-btn {
  //padding: unset !important;
  margin: unset !important;
  flex: 1 1;
  width: 100%;
  border: none;
  border-radius: 50px;
  height: 50px;
  //background-color: #044da1;
  font-size: 16px;
}

/*********************** Ant Table ************************/
.table {
  .ant-table-content {
    width: 100%;
  }
  .ant-table-fixed {
    background: var(--white);
  }
  .ant-table-expanded-row {
    min-height: 116px;
  }
  .ant-table-row {
    height: 53px !important;
  }

  .ant-table-header {
    background: var(--white);
  }
  .ant-table-thead {
    //background-color: transparent;

    tr > th {
      opacity: 0.5;
      font-size: 12px;
      font-weight: bold;
      text-transform: uppercase;
      background-color: transparent;
      border-bottom: 2px solid var(--light-black);
    }
  }

  .ant-table-tbody {
    background: var(--white);
    tr > td {
      //background-color: transparent !important;
      border-bottom: 2px solid #182e49;
    }

    .ant-table-row-expand-icon {
      background-color: transparent;
      color:var(--black-to-white) !important;
    }

    tr.ant-table-expanded-row {
      background: var(--white);
    }
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
    background-color: initial;
  }
}

/*********************** Ant Pagination ************************/
.ant-pagination {
  li {
    border: solid 0px;
    height: 40px;
    line-height: 40px;
    background-color: #172a40;
    padding: 0 10px 0 10px;

    a {
      font-size: 14px;
      font-weight: bold;
      opacity: 0.5;
      color: var(--white) !important;
      background-color: #172a40 !important;
      border: unset !important;
    }
  }
}

/*********************** Ant Range Picker ************************/
.range-picker * {
  color:var(--white);
}

/*********************** Ant Select ************************/
.select-dark * {
  color:var(--white);
}

/*********************** Ant Dropdown ************************/
.ant-dropdown.dark * {
  color:var(--white);
}

/*********************** Back to top Styles ************************/
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
}

.back-top {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #044da1;
  color: var(--white);
  text-align: center;
  font-size: 20px;

  //background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAoCAYAAACWwljjAAAABGdBT…piZ2PyYO1EVykesGfZEESsCkweyR8MUW+V8uJ1gkYipmpdP1pm2aJVPEGzAAAAAElFTkSuQmCC) 100%/100% no-repeat;
}

/*********************** Tooltip Styles ************************/
.ant-tooltip.error {
  background-color: unset;

  .ant-tooltip-inner {
    background-color: red;
  }

  .ant-tooltip-arrow {
    border-top-color: red;
    border-bottom-color: red;
  }
}

.ant-tooltip.error.ant-tooltip-placement-bottomRight {
  margin-top: 22px;
}

/*********************** Drawer Mobile ************************/
.ant-drawer.mobile {

  background-color: none !important;
  
  .ant-drawer-mask{
    // width: auto !important;
    opacity: 0;
  }
  .menu-banner,.ant-drawer-body,.sub-menu,.ant-menu-submenu-title{
    background-color: var(--dark-blue-to-white) !important;
  }
  .menu-banner {
    text-transform: uppercase;
    margin: 0 !important;
    color :#7E879B;
    padding-left:10px;
    font-size: 18px;
    font-weight: 500;

  }

  .ant-drawer-content-wrapper {
    // padding-bottom: 70px !important;
    // max-width: 350px !important;
    width: 75vw !important;
    background-color: var(--dark-blue-to-white) !important;

    max-width: 75vw !important;
    // padding: 10px;

    .ant-drawer-content {
      background-color: var(--light-black);

      .ant-drawer-wrapper-body {


        background-color: var(--dark-blue-to-white) !important;

        .ant-drawer-body {
          padding: unset;

          .ant-menu {
            margin: unset;
          }
        }
      }
    }
  }
}

.ant-drawer.mobile.mobile-bet-slip {
  .ant-drawer-content-wrapper {
    .ant-drawer-content {
      // background-color: var(--white);

      .ant-drawer-wrapper-body {
        // padding-top: 10px;
        // padding-left: 10px;
        // padding-right: 10px;

        .ant-drawer-body {
          .ant-menu {
            margin: unset;
          }
        }
      }
    }
  }
}

/*********************** Collaps Mobile ************************/
.ant-collapse.collaps {
  background: unset;
  border: unset;

  .ant-collapse-item {
    border: unset;
    margin-bottom: 1px;

    i {
      left: 10px !important;
    }

    .ant-collapse-header {
      padding-left: 30px;
      display: flex;
      align-items: center;
      color: var(--white);
      font-size: 12px;
      font-weight: bold;
      background-color: #182e49;
      height: 30px;
      line-height: unset;
    }

    .ant-collapse-content {
      background: unset;
      border: unset;

      .ant-collapse-content-box {
        padding: 0px;

        .row:last-child {
          border-bottom: unset;
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  /*********************** Popover Mobile ************************/
  .ant-popover {
    margin-right: 10px;

    .ant-popover-title {
      font-size: 16px;
      font-weight: bold;
      color: #0e2842;
    }

    .ant-form-item-with-help {
      margin-bottom: unset;
    }

    .ant-popover-inner-content {
      padding: 20px;

      .login {
        max-width: 260px;

        .search-box {
          display: none;
        }

        .ant-row {
          width: 100%;

          div {
            font-size: 14px;
            font-weight: bold;
            color: #0e2842;
            line-height: unset;
            margin-bottom: 5px;
          }

          .ant-form-item-control-wrapper {
            width: 100%;

            input {
              border: solid 1px #a0acb9;
              background-color: unset;
            }

            .ant-form-explain {
              display: none;
            }
          }
        }
      }
    }
  }
}

.tabs {
  margin-top: 17px;
  width: 100%;
}
.ant-tabs-ink-bar {
  height: 3px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  bottom: 0px;

}
.blueBorder {
  .ant-tabs-ink-bar {
    background-color: #2c8af7;
  }
}
.orangeBorder {
  .ant-tabs-ink-bar {
    background-color: #f4a932;
  }
}
.greenBorder {
  .ant-tabs-ink-bar {
    background-color: #2ea867;
  }
}
.redBorder {
  .ant-tabs-ink-bar {
    background-color: #d95458;
  }
}
.greyBlueBorder {
  .ant-tabs-ink-bar {
    background-color: #5b88bd;
  }
}
.purpleBorder {
  .ant-tabs-ink-bar {
    background-color: #9457cf;
  }
}

.ant-tabs-bar {
  border-bottom: 4px solid #333e4d !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ant-tabs-nav-scroll {
  display: flex;

}

.tab {
  height: 35px;
}

.ant-menu,
.ant-menu-submenu-title {
  background-color: transparent !important;
}

.ant-menu-submenu-title {
  font-size: 16px;
  font-family: "Poppins";
  opacity: 1 !important;
  font-weight: lighter;
  transition: all 200ms;
  background-color: transparent !important;
  border: 1px solid transparent;
  border-radius: 5px;
}

.ant-menu-submenu-title:hover {
  color: var(--white) !important;
  background-color: var(--light-black) !important;
  cursor: pointer;   
}