@import '_variables.scss';

* {
  font-family: "Poppins";
  line-height: unset;
}

body {
  // @extend [data-theme="dark"],[data-theme="light"];
  // @extend [data-theme="dark"];
  background-color: var(--black-to-gray);
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
  // scrollbar-width: none;
  // -ms-overflow-style: none;
}

.avoid-clicks {
  :not(.slots-game) :not(.frame-video) {
    pointer-events: none;
  }

  .slots-game .frame-video {
    pointer-events: all !important;
  }
}

// body::-webkit-scrollbar {
//   display: none;
// }

body {
  margin: 0; /* This will stop the margin, setting it to 0 */
}

.ant-layout-header{
  background: none;
}
div,
a {
  color: var(--white);
}

button,
span {
  // old style
  // color: var(--white) !important;

  // new one
  color: var(--white)
}

.app {
  display: flex;
  flex-direction: column;
  background-color: var(--light-black);
}

.upper {
  text-transform: uppercase;
}
.headeline {
  text-transform: uppercase;
}

/*********************** Margin Styles ************************/
.mtop-10 {
  margin-top: 10px;
}

.mtop-20 {
  margin-top: 20px;
}

.mtop-30 {
  margin-top: 30px;
}

.mtop-40 {
  margin-top: 40px;
}

@media only screen and (max-width: 768px) {
  .mtop-10,
  .mtop-20,
  .mtop-30,
  .mtop-40 {
    //margin-top: 0px;
  }
}

.gt {
  transform: rotate(-180deg);
}

/*********************** Link Styles ************************/
.link {
  cursor: pointer;
}

.light-on {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  -moz-transition-property: all;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: linear;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: linear;
}

.light-on:hover {
  color: #0e7eff;
}

/*********************** Action colors Styles ************************/
.success {
  background-color: #2C8AF7;
  color: white !important;
}

.message-no-status {
  background-color: #2C8AF7;
}

.success:hover,
.success:active,
.success:focus {
  background-color: #2C8AF7;
}

.info {
  background-color: #044da1;
}

.info:hover,
.info:active,
.info:focus {
  background-color: #044da1;
}

.warning {
  background-color: #eda72b;
}

.warning:hover,
.warning:active,
.warning:focus {
  background-color: #eda72b;
}

.error {
  background-color: #e82f2f;
}

.error:hover,
.error:active,
.error:focus {
  background-color: #e82f2f;
}

.clear {
  background-color: var(--dark-blue-to-white);
  border: solid 1px var(--white) !important;

  span {
    color: #182438 !important;
  }
}

.clear:hover,
.clear:active,
.clear:focus {
  background-color: #2C8AF7;
}

.ant-btn[disabled] {
  background-color: #2C8AF7;
  border: unset !important;

  span {
    color: var(--white) !important;
  }
}

.ant-btn[disabled]:hover {
  background-color: #2C8AF7 !important;
}

/*********************** Select Styles ************************/
.selectlist {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 28px;

  .ant-select-selection {
    background-color: transparent;
    border: none;
    opacity: 0.5;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center !important;
  }

  .ant-select-selection-selected-value {
    text-align: center;
    width: 100%;
  }
}

/*********************** Alert Styles ************************/
.alert-icon {
  display: flex;
  width: 18px;
  height: 18px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.alert {
  display: flex;
  flex-direction: row;
  align-items: center;

  .icon {
    .inner {
      border-radius: 10px;
      width: 16px;
      height: 16px;
      margin-right: 6px;
      color: var(--white);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      font-size: 13px;
    }

    .info {
    }
  }

  .message {
    font-size: 14px;
    color: var(--white);
  }
}

/*********************** Loading Styles ************************/
.loading {
  display: flex;
  flex-direction: row;
  align-items: center;

  .spiner {
  }

  .text {
    margin-left: 10px;
  }
}

.confirmation-form {
  display: flex;
  flex-direction: column;
}

.confirmation-input {
  height: 100px;
  font-size: 2rem;
  font-weight: bold;
  padding: 0 100px;
}

/*********************** StickeyBox Styles ************************/
.stickey-sider {
  height: 2000px;
}

/*********************** Input Styles ************************/
.ant-input-number,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  width: 100%;
  height: 40px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/*********************** Search box ************************/
.search {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  height: 40px;
  background-color: #044da1;
}

/*********************** Amount Up/Down ************************/
.amount-up-down {
  height: 32px;
  display: flex;
  flex-direction: row;
  border: solid 1px #dee2e8;
  border-radius: 4px;

  .up,
  .down {
    color: var(--light-black);
    background-color: var(--bet-slip-counter);
    width: 30px;
    border: var(--amount-counter-border);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;

  }
  .up{
    border-right: 1px solid rgb(211, 210, 210);

  }
  .down{
    border-left: 1px solid rgb(211, 210, 210);

  }

  .amount {
    .input {
      font-size: 13px;
      font-weight: bold;
      text-align: center;
      // color: var(--light-black);
      border: unset;
      height: 30px !important;
      width: 75px !important;
      background-color: var(--white-to-off-white);
    }
  }
}

/*********************** No activity modal ************************/
.noActivity {
  .text {
    color: var(--black-to-white);
    font-size: 20px;
    margin-bottom: 30px;
  }
}

@keyframes shine {
  0% {
    opacity: 1;
  }
  35% {
    opacity: 0.6;
  }
  40% {
    opacity: 0.7;
  }
  45% {
    opacity: 0.8;
  }
  46% {
    opacity: 1;
  }
}

.glint {
  animation: ant-skeleton-loading 2s ease-out infinite,
    shine 2s ease-out infinite;
  animation-name: ant-skeleton-loading shine;
  background: linear-gradient(
    60deg,
    transparent 25%,
    rgba(253, 253, 253, 0.38) 37%,
    transparent 43%
  );
  background-size: 400%;
}

.glint:hover {
  animation: ant-skeleton-loading 2s ease-out infinite,
    shine 2s ease-out infinite;
  animation-name: ant-skeleton-loading shine;
  background: linear-gradient(
    60deg,
    transparent 25%,
    rgba(253, 253, 253, 0.38) 37%,
    transparent 43%
  );
  background-size: 400%;
}

/************** Buttons **************/
.pri-btn {
  background-color:var(--white-to-blue);
  color: var(--black-to-white) !important;
  & {
    span {
      color: var(--black-to-white) !important;
    }
  }
  &:hover {
    background-color: var(--white);
    span {
      color: var(--black-to-white) !important;
    }
  }
}

.sec-btn {
  background-color: transparent;
  border: 1px solidvar(--white) !important;
  &:hover {
    span {
      color: var(--black-to-white) !important;
    }
  }
}


.ant-modal-wrap{
	top: 10vh !important;
}
.ant-modal-close-x{
	// background-color:var(--dark-blue) !important;
}

@media only screen and (max-width: 1100px){
  .ant-modal-wrap{
    top: 2vh !important;
    // height: 100vh;
    bottom: 5vh;

  }
}
