.sport {

	.virtual-video {
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;

		.frame-video {
			overflow-x: hidden;
			width: 100%;
		}

		.skeleton {
			height: 300px;
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;

			.loading {
				.ant-spin-dot {
					font-size: 40px !important;
					width: 40px;
					height: 40px;
				}

				.text {
					font-size: 25px;
				}
			}
		}

	}

	/*********************** VFL ************************/
	.virtual-11[min-width~="752px"] {

		.frame-video {
			max-width: 760px;
			height: 520px;
		}
	}

	.virtual-11[max-width~="752px"] {
		.frame-video {
			display: none !important;
		}
	}

	.virtual-11-mobile[min-width~="685px"] {

		.frame-video {
			max-width: 685px;
			height: 410px;
		}
	}

	.virtual-11-mobile[max-width~="685px"] {
		.frame-video {
			display: none !important;
		}
	}

	/*********************** VFLM ************************/
	.virtual-71[min-width~="1140px"] {

		.frame-video {
			max-width: 1140px;
			height: 610px;
		}
	}

	.virtual-71[max-width~="1140px"] {
		.frame-video {
			max-width: 604px;
			height: 575px;
		}
	}
	
	

	.virtual-71-mobile[min-width~="320px"] {

		.frame-video {
			max-width: 540px;
			height: 520px;
		}
	}

	.virtual-71-mobile[max-width~="320px"] {
		.frame-video {
			display: none !important;
		}
	}

	/*********************** VBL ************************/
	.virtual-82[min-width~="765px"] {

		.frame-video {
			max-width: 765px;
			height: 670px;
		}
	}

	.virtual-82[max-width~="762px"] {
		.frame-video {
			display: none !important;
		}
	}

	.virtual-82-mobile[min-width~="688px"] {

		.frame-video {
			max-width: 688px;
			height: 604px;
		}
	}

	.virtual-82-mobile[max-width~="688px"] {
		.frame-video {
			display: none !important;
		}
	}

	/*********************** VDR ************************/
	.virtual-33[min-width~="762px"] {

		.frame-video {
			max-width: 762px;
			height: 356px;
		}
	}

	.virtual-33[max-width~="762px"] {
		.frame-video {
			display: none !important;
		}
	}

	.virtual-33-mobile[min-width~="688px"] {

		.frame-video {
			max-width: 688px;
			height: 325px;
		}
	}

	.virtual-33-mobile[max-width~="688px"] {
		.frame-video {
			display: none !important;
		}
	}

	/*********************** VHR ************************/
	.virtual-22[min-width~="762px"] {

		.frame-video {
			max-width: 762px;
			height: 356px;
		}
	}

	.virtual-22[max-width~="762px"] {
		.frame-video {
			display: none !important;
		}
	}

	.virtual-22-mobile[min-width~="688px"] {

		.frame-video {
			max-width: 688px;
			height: 325px;
		}
	}

	.virtual-22-mobile[max-width~="688px"] {
		.frame-video {
			display: none !important;
		}
	}

	/*********************** EPL ************************/
	.virtual-91[min-width~="1140px"] {

		.frame-video {
			max-width: 1140px;
			height: 610px;
		}
	}

	.virtual-91[max-width~="1140px"] {
		.frame-video {
			max-width: 604px;
			height: 575px;
		}
	}

	.virtual-91-mobile[min-width~="320px"] {

		.frame-video {
			max-width: 540px;
			height: 520px;
		}
	}

	.virtual-91-mobile[max-width~="320px"] {
		.frame-video {
			display: none !important;
		}
	}

}
@media only screen and (max-width: 500px){
	.sport .virtual-video .frame-video{
		height: 105vw !important;
	}
	
	
}