.ant-modal.register {

	width: 900px !important;

	@media only screen and (max-width: 1024px) {
		width: 100%;

		.content {
			padding: 10px;
			.prefix-input{
				width: 50px;
			}
			.seperator-input{
				width: 55px;
			}
			.number-input{
				width: 125px
			}
		}
	}

	.ant-modal-body {
		padding: 20px 0px 40px 0px;
		.ant-btn-primary{
			background: #044da1 ;
		}
	}

	//Steps
	.ant-steps {
		padding: 15px 20px 15px 20px;

		.ant-steps-item {

			display: flex;
			flex-direction: row;
			align-items: center;

			.ant-steps-item-icon {
				min-width: 20px;
				max-width: 20px;
				height: 20px;
				border: unset;
				background-color: #d0d9e4;

				.ant-steps-icon {
					color: transparent !important;
					width: 20px;
				}
			}

			.ant-steps-item-content {

				.ant-steps-item-title {
					color: #182438;
					font-weight: bold;
					line-height: unset;
					white-space: normal;
				}

				.ant-steps-item-title:after {
					height: unset;
				}

				.ant-steps-item-description {
					font-size: 12px;
					font-weight: bold;
				}
			}

		}

		.ant-steps-item-process {
			.ant-steps-item-title {}
		}

		.ant-steps-item-wait {

			.ant-steps-item-title,
			.ant-steps-item-description {
				opacity: 0.5;
			}
		}

		.ant-steps-item-finish {

			.ant-steps-item-icon {
				background-color: #2C8AF7;
			}

			.ant-steps-item-content {
				.ant-steps-item-title {
					color: #2C8AF7;
				}

				.ant-steps-item-description {
					color: #2C8AF7;
				}
			}
		}
	}

	.step {
		display: flex;
		flex-direction: column;
		justify-content: center;

		.strip {
			width: 100%;
			height: 110px;
			background-color: #f0f4f9;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;

			.content {
				.text {
					text-align: center;
					max-width: 590px;
					color: #182438;
					font-size: 16px;
					font-weight: bold;
				}
			}
		}

		>.content {
			display: flex;
			justify-content: center;

			.form {
				max-width: 375px;

				input {
					border: solid 1px var(--light-black);
				}

				.ant-checkbox-wrapper {
					line-height: normal;
				}

				.ant-form-item-children {
					label {
						span {
							color: #182438 !important;
							font-size: 12px;
						}
					}
				}

				.ant-select {}

				.ant-form-item {
					.ant-form-item-label {

						label {
							color: #182438;
						}
					}
				}

				.ant-row {
					margin-bottom: 5px;
				}

				.terms {
					height: 80px;

					a {
						color: #182438;
						text-decoration: underline;
					}
				}

				.ant-select-selection {
					border: solid 1px var(--light-black);

					.ant-select-selection__rendered {
						margin-top: 4px;
					}

					.ant-select-selection-selected-value {
						color: #182438;
					}
				}

				#Step1_day {
					width: 20% !important;
				}

				#Step1_month {
					width: 50% !important;
				}

				#Step1_year {
					width: 30% !important;
				}

				.next {
					margin-top: 20px !important;
				}

				.btn_none_bg {
					margin-top: 20px !important;
					span{
						color:var(--black-to-white) !important;
						font-size: 16px !important;
						font-weight: bold;
						cursor: pointer;
					}
				}

			}
		}

	}

	.ant-modal-close {
		margin-top: -10px;
		margin-right: -10px;

		i {
			color: var(--light-black);
		}
	}

}

.ant-message-notice-content {
	background:var(--black-to-white);
	font-size: 20px;
}