.footer {
	.footer-image-container{
		opacity: 1 !important;
		padding-right: 10px;
	}
	.disclamer {
		padding-top: 10px;
		display: flex;
		flex-direction: row;
		align-items: center;

		.plus {
			border: solid 3px var(--white);
			font-weight: bold;
			border-radius: 30px;
			padding: 8px;
			margin-right: 10px;
			text-align: center;
		}

		.text {}
	}
}