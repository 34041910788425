#login-and-regisration-container{
    min-height: 85vh;
    .ant-btn{
        span{

            color: white !important;
        }
    }
    .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
        display: none;
    }
    .forget-password-parent-container{
        #OTP-vertification-parent-container{
            .ant-form .ant-form-item{
                width: 100% !important;
            }
        }

    }


    .ant-col-xs-24{
        float: none;
        width: 100%;
    }
    
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
        max-width: none;
        color: var(--forget-password-headline);
        position: relative;
        top: 70px;
        right: 70%;
        font-size: 17px;
        display: block !important;
        white-space: nowrap;
    }
    .forget-password-steps{
            display: flex;
            width:25%;
            padding-top:10px;
            margin:auto;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
        color: white !important;
    }
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon{
        display: inline !important;
    }
    .ant-steps-item-finish .ant-steps-item-icon{
        background-color: #2C8AF7;
        color: white !important;
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
        background-color:  var(--forget-password-steps);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
        background-color:  var(--forget-password-steps);
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon{
        color: #979CAA !important;
        display: inline !important;
    }
    .ant-steps-item-wait .ant-steps-item-icon{
        background-color:  var(--forget-password-steps);
        border: transparent;
    }
    .ant-steps-item-title::after{
        left: -7px;
        top: 25px;
        width: 160px;
        height: 3px;
    }
    .ant-steps-item-icon{
        
        height: 50px;
        width: 50px;
        font-size: 20px;
        line-height: 47px;
        
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item{
        margin-right: 0;
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
        max-width: none;
    }

    // .ant-steps-item-container{
    //     padding-left: 50px;
    // }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        color: var(--forget-password-headline);
    }
    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description{
        color: var(--forget-password-headline);
        position: relative;
        top: 70px;
        right: 70%;
        font-size: 17px;
        display: block !important;
        white-space: nowrap;

    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title{
        color: var(--forget-password-headline);
    }
    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description{
        color: #979CAA;
        position: relative;
        top: 70px;
        right: 75%;
        font-size: 17px;
        padding-bottom: 70px;
        display: block !important;
    }
    .form .ant-input {
        color: var(--login-registartion-inputs-font-color);
        background-color: var(--login-registartion-inputs);
        border: none;
    }
    .login-forget-password-container{
        // display: none;
        text-align: center;
        padding-top: 15px;
        span{
            border-bottom: 1px solid var(--forget-password-headline);
            color: var(--forget-password-headline) !important;
        }

    }
    .ant-form label::after {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
    .show-password-icon{
        position: absolute;
        right: 2%;
        // left: 100%;
        // right:0vw;
        top: 27%;
    
    }
    .login-form-container{
        background-color: var(--login-regitration-header);
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 5px;
        padding: 50px 110px;
        margin-top: 100px;
        .form-inputs-container{
            width: 40%;
        }
        .login-form-headline{
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 30px;
            color: var(--forget-password-headline) !important;

        }
        .ant-input{
            background-color: var(--login-registartion-inputs) !important;
            border: none;
            color: var(--login-registartion-inputs-font-color);
            // width: 25vw !important;
        }
        .ant-form-inline .ant-form-item > .ant-form-item-label{
            display: block;
        }
        .ant-form-item-label{
            text-align: start;
        }
        .ant-form label {
            font-size: 13px;
        }
        .ant-form-item-label > label{
            color: var(--white);
        }

        .login-password-container{
            position: relative;
        }
        
        .login-form-items-container{
            display: flex;
            justify-content: space-between;
        }
        .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper{
            width: 100%;
        }
        .login-form-button-container{
            display: flex;
            justify-content: center;
            padding-top: 120px;
            .ant-form-item{
                width: auto !important;
            }
        }
        .ant-btn{
            width: 20vw;
            background-color: #2C8AF7;
        }


        
   
    }
    .forget-password-headline{
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 30px;
        color: var(--forget-password-headline) !important;
    }
    .steps-content{
        background-color: var(--login-regitration-header);
        padding: 50px 100px;
        margin-top: 50px;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        border-radius: 5px;
        .OTP-fields{
            display: flex;
            justify-content: center;
            white-space: nowrap;
            .confirmation-input{
                margin-right: 5px;
                height: 92px !important;
                width:76px !important;
                padding: 0 !important;
                font-size: 15px;
                text-align: center;
                font-weight: 400;
                border: none;
                background-color: var(--login-registartion-inputs);
                color: var(--login-registartion-inputs-font-color);
            }
        }
        .forget-password-confirmation-input-label-container{
            display: flex;
            justify-content: space-between;
            .ant-form-item{
                width: 38% !important;
            }
            label{
                font-weight: 400;
            }
            .ant-form-item-required::before{
                display: none;
            }

        }
        .forget-password-input-label-container{
            display: flex;
            justify-content: space-between;
            .ant-form-item-required::before{
                display: none;
            }
            .ant-form-item{
                width: 40%;
            }
            label{
                font-weight: 400;
            }
        }
        .step1-parent-container{

            .ant-input{
                width: 100%;                
            }
        }
        .step1-button-container , .step2-button-container{
            margin-top: 100px;
            display: flex;
            justify-content: end;
            .ant-btn{
               
                width: 150px;
                flex: none
            }
        }

    }
    .ant-steps{
        margin-top: 20px;
        .anticon svg{
            font-size: 20px;
            color: white;
            content: "1";
        }
        .ant-steps-item {
            overflow: visible;
        }
    }
//     .ant-steps-label-horizontal div{
//         div:last-of-type{
//         display: none;
//     } 
// }
    // .forget-password-steps:last-child{
    //     display: none;
    //     div:last-child{
    //         display: none !important;
    //     }
    // } 
}
#login-and-registration-header{
    .top-menu{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 6vw 0 6vw;
        background-color:var(--login-regitration-header);
        img{
            cursor: pointer;
            
        }
    }
    .header-text{
        font-size: 18px ;
        div{
            display: inline-block;
            border-bottom: 2px solid var(--forget-password-headline);
            margin-left: 5px;
            cursor: pointer;
            color: var(--forget-password-headline) !important;
        }
        span{
            opacity: 0.5;
        }
    }
    .back-to-login{
        font-size: 18px;
        color: var(--forget-password-headline) !important;
        font-weight: 600;
        cursor: pointer;
        img{
            transform: rotate(90deg);
        }
    }
}




#register-user-container{
    .ant-btn{
        span{

            color: white !important;
        }
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
        color: white !important;
    }
    .ant-select-selection--single{
        background-color: var(--login-registartion-inputs);
        border: none;
    }
    form :not(.ant-input-group-wrapper) > .ant-input-group{
        display: flex;
        
    }
    .phone-number-input-group{
        .ant-input{
            width: 100% !important;
            text-align: start;
        }
        .ant-select-arrow svg{
            color: white;
        }
    }


    .ant-select-selection-selected-value{
        margin-top: 5px;
    }
    
    .register-buttons-container{
    display: flex;
    justify-content: end;
    .ant-btn{
        width: 150px;
        flex: none;
    }
    }
    .register-headline{
        font-size: 20px;
        font-weight: 600;
        text-align: center;
        padding-bottom: 30px;
        color: var(--forget-password-headline) !important;

    }
    .register-form-container{
        background-color: var(--login-regitration-header);
        // .ant-select{
        //     width: 100%;
        // }
        .ant-select-disabled .ant-select-selection,.ant-select-selection, .ant-input-group-addon{
            background-color: var(--login-registartion-inputs);
            border: none;
        }
        .register-form-sections{
            display: flex;
            justify-content: space-between;
        }
        .ant-form-item{
            display: inline-block;
            width: 40%;
        }
        .validation-meter{
            padding: 20px;
            margin-bottom: 10px;
            background-color: var(--login-regitartion-validiation-meter);
            .validation-meter-headline{
                opacity: 0.44
            }
            div{
                padding: 3px 0;
            }
        }
        #register-check-box{
            label::after{
                display: none;
            }
        }
        .ant-form-item-required::before{
            display: none;
        }
        .form-inputs-container{
            width: 40%;
        }
        .login-form-headline{
            text-align: center;
            font-size: 20px;
            font-weight: 600;
            padding-bottom: 30px;
            color: var(--forget-password-headline) !important;

        }
        .ant-input{
            background-color: var(--login-registartion-inputs) !important;
            border: none;
            color: var(--login-registartion-inputs-font-color);
            // width: 25vw !important;
        }
        .ant-form-inline .ant-form-item > .ant-form-item-label{
            display: block;
        }
        .ant-form-item-label{
            text-align: start;
        }
        .ant-form label {
            font-size: 13px;
        }
        .ant-form-item-label > label{
            color: var(--white);
        }

        .login-password-container{
            position: relative;
        }
        
        .login-form-items-container{
            display: flex;
            justify-content: space-between;
        }
        .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper{
            width: 100%;
        }
        .login-form-button-container{
            display: flex;
            justify-content: center;
            padding-top: 120px;
        }
        .ant-btn{
            width: 20vw;
            background-color: #2C8AF7;
        }


        
   
    }
    .form .ant-input {
        color: var(--login-registartion-inputs-font-color);
        background-color: var(--login-registartion-inputs);
        border: none;
    }
    .steps-content{
        margin-top: 10px;
    }
 .strip{
    position: relative;
    text-transform: none;
    display: flex !important;
    .register-close-icon{
        position: absolute;
        right: 10px;
        top: 10px;
    }
    background-color: var(--login-regitartion-strip);
    padding: 80px 0;
    margin-top: 50px;
    text-align: center;
    div{
        color: var(--forget-password-headline) !important;;
    }
    font-size: 17px;
    .content{
        padding: 0 20% ;
    }
 }   
}

@media only screen and (max-width: 1400px){
    #login-and-regisration-container .forget-password-steps{
        width: 35%;
    }
}

@media only screen and (max-width: 1200px){
    #login-and-regisration-container .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description{
        display: none !important;
    }
    #login-and-regisration-container .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description{
        display: none !important;
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
        display: none !important;
    }
    #login-and-regisration-container .forget-password-steps{

    }
    #login-and-regisration-container .forget-password-steps{
        margin: 0;
        margin-top: 30px;

    }
    #login-and-regisration-container{
        .ant-steps-item-container{
            width: 41vw;
            overflow: hidden;
        }
    }
    #login-and-regisration-container .ant-steps-item-title::after{
        width: 1000px;   
    }
}


@media only screen and (max-width: 1100px){
    .phone-number-item .country-select .ant-select-selection {
        height: 47px !important;
    }
    #login-and-registration-header{
        .top-menu{
            .logo{
                img{
                    height: 70px !important;
                    width: 150px !important;
                }
            }

        }
        .header-text div{
            font-size: 12px;
        }
        }
        #login-and-registration-header .header-text span {
            display: none;
        }
        
    }
@media only screen and (max-width: 1000px){
    #login-and-regisration-container{
        padding: 0 6vw 0 6vw;
    }
    #login-and-regisration-container .steps-content .forget-password-input-label-container {
        width: 100%;
    }
    #login-and-registration-header .back-to-login {
        font-size: 12px;
    }
    

}
@media only screen and (max-width: 900px){
    #login-and-regisration-container .steps-content .OTP-fields .confirmation-input{
        height: 52px !important;
        width:44px !important;
    }
}
@media only screen and (max-width: 700px){
    .step1-parent-container{
        .forget-password-input-label-container{
            display: block !important;
            .ant-form-item{
                width: 100% !important;
            }
           
        }
    }

    #register-user-container .register-form-container .register-form-sections{
        display: block;
    }
    #register-user-container .register-form-container .ant-form-item{
        width: 100%;
    }
    #register-user-container .strip{
        text-align: start;
        padding: 80px 20px ;
        font-size: 13px;
    }
    #register-user-container .register-headline {
        font-size: 17px;
    }
    #register-user-container .ant-checkbox-wrapper {
        font-size: 11px !important;
    }
    #register-user-container .register-buttons-container{
        justify-content: center !important;
    }
    #login-and-regisration-container .login-form-container .login-form-items-container{
        display: block;
    }
    #login-and-regisration-container .steps-content .step1-button-container, #login-and-regisration-container .steps-content .step2-button-container {
        margin-top: 30px;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
        .ant-btn{
            width: 40%;
        }
    }
    #login-and-regisration-container .login-form-container .form-inputs-container{
        width: 100%;
        padding-bottom: 20px;
    }

    #login-and-regisration-container .login-form-container{
        margin-top: 50px;
    }
    #login-and-regisration-container .login-form-container .login-form-button-container{
        padding-top: 30px;
    }
    #login-and-regisration-container .login-form-container{
        padding: 30px 20px
    }
    #login-and-regisration-container .steps-content{
        padding: 30px 20px
    }
    #login-and-regisration-container .login-form-container .ant-btn{
        width: 40vw;
    }
    #login-and-regisration-container .steps-content .step1-button-container{
        justify-content: center;
    }
    #login-and-regisration-container .steps-content .step1-button-container .ant-btn{
        width: 40%;
    }
    #login-and-regisration-container .steps-content .step1-button-container{
        margin-top: 40px;
        margin-bottom: 20px;
    }
    #login-and-regisration-container{
        .ant-steps-item-container{
            width: 40vw;
        }
    }
}
@media only  screen and (max-width: 550px){
    #login-and-regisration-container{
        .ant-steps-item-container{
            width: 39vw;
        }
    }
}
@media only screen and (max-width: 500px){
    #login-and-regisration-container .steps-content .forget-password-confirmation-input-label-container{
        display: block;
    }
    #login-and-regisration-container .steps-content .forget-password-confirmation-input-label-container .ant-form-item{
        width: 100% !important;
    }
    #login-and-regisration-container .steps-content .OTP-fields .confirmation-input{
        font-size: 18px;
    }
    #login-and-regisration-container .login-form-container .login-form-headline{
        font-size: 17px;
    }
    #login-and-regisration-container  .ant-input{
        font-size: 15px;
    }
    #login-and-regisration-container .login-form-container .ant-btn{
        font-size: 13px;
    }
    #login-and-regisration-container .login-forget-password-container span {
        font-size: 13px;
    }
    #login-and-regisration-container .ant-steps-item-title::after{
        display: block !important;  
    }
    #login-and-regisration-container .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon{
        margin-right: 0 !important;
    }
    // #login-and-regisration-container .forget-password-parent-container{
    //     overflow: hidden;
    // }
    #login-and-regisration-container .forget-password-headline {
        font-size: 17px;
    }
    #login-and-regisration-container .form .ant-form-item .ant-form-item-label label{
        font-size: 13px;
    }
    #login-and-regisration-container .ant-btn{
        font-size: 13px;
    }
}
@media only  screen and (max-width: 450px){
    #login-and-regisration-container{
        .ant-steps-item-container{
            width: 37vw;
        }
    }

}
@media only  screen and (max-width: 400px){
    #login-and-registration-header .back-to-login{
        font-size: 9px;
    }
    #login-and-regisration-container .steps-content .OTP-fields .confirmation-input{
        height: 42px !important;
        width:34px !important;
    }
}
@media only  screen and (max-width: 330px){
    #login-and-regisration-container{
        .ant-steps-item-container{
            width: 36vw;
        }
    }
    #login-and-regisration-container .steps-content .OTP-fields .confirmation-input{
        height: 37px !important;
        width:29px !important;
    }
}


// #login-and-regisration-container .steps-content.OTP-fields.confirmation-input