.document {

	.sider-left,
	.sider-right {
		display: none !important;
	}

	.layout-inner {
		display: flex;
		justify-content: center;

		.content {
			// max-width: 1024px;
			font-size: 17px;
			
			.email_validate{
				display: flex;
				flex-direction: row-reverse;
				align-items: baseline;
			color:var(--white)
			}
			.container {
				border-radius: 4px;
				// background-color: var(--light-black);
				padding: 20px 0 20px 0;

				h3 {
					display: none;
				}

				h5 {
					color: var(--white);
					font-size: 18px;
					// font-weight: bold;
					font-size: 18px !important;
					opacity: 1 !important;

					u{
						text-decoration: none;
					}
				}

				p {			
					opacity: 0.8;				
					
					strong {
						u {
							color: var(--white);
							font-size: 17px;
							font-weight: bold;
							opacity:1 !important
						}
					}
				}
				p:nth-of-type(2){
					font-size: 18px !important;
					opacity:1 !important;
					font-weight:400;
				}
				
			}
		}
	}
}

@media only screen and (max-width: 600px){
	.document{
		position: relative;
		bottom: 50px;
		.layout-inner{
			.content {
				.container{
					padding: 20px 6vw 0 6vw;
					
				}
			}
		}
	}
	
}