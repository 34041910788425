.screen {
  flex: 1 1;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // Categoris sport menu

  #sport-categories-menu{
    .ant-tabs-tab-next-icon {
      left: -10% !important;
      
    }
    .ant-tabs-nav {
      // position: static !important;
      // width: auto;
    }
    .ant-tabs-bar {
      border: none !important;
      // border-bottom-left-radius: 0px;
      // border-bottom-right-radius: 0px;
      }
      
      .ant-tabs-nav-scroll {
        display: flex;
        border-bottom: 3px solid rgba(174, 180, 191, 0.25) !important;
        border-bottom-left-radius: 5px !important;
        border-bottom-right-radius: 5px !important;
      }
    padding-bottom: 5px;
    .ant-tabs-tab-prev-icon-target,.ant-tabs-tab-next-icon-target {
      font-size: 18px !important;
      opacity: 0.9;
  }
    .ant-tabs-nav-wrap{
      margin: 0 !important;
    }
    .ant-tabs-nav{
      .ant-tabs-tab{
        margin: 0 0px 0 0  !important;
        padding-right: 4%;
      }
    }
  }
  /*********************** Header Styles ************************/
  .sticky-header {
    z-index: 2;
  }
  .ant-tabs-tab-prev.ant-tabs-tab-arrow-show, .ant-tabs-tab-next.ant-tabs-tab-arrow-show{
    z-index: 0 !important;
  }
  .header {
    width: 100%;
    flex: 1 1;
    line-height: unset;
    padding: unset;
    height: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: sticky;
    top: 0;

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 1;
      width: 100%;

      .connection {
        border-radius: 50px;
        width: 10px;
        height: 10px;
        margin-left: 20px;
      }
      @media only screen and (max-width:700px){
        .connection{
          margin-left: 10px;
        }
        .mode-switcher{
          padding: 0.4rem;
        }
        #mode-switcher-text{
          display: none;
        }
      }

      .top {
        padding: 0 6vw 0 6vw;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: var(--light-black);
        

        .size {
          max-width: 1760px;
          //min-width: 1250px;
          width: 100%;
          flex: 1 1;
          display: flex;
          flex-direction: row;
          //justify-content: flex-end;
          align-items: center;
          height: 50px;

          .link {
            margin-left: 16px;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer;
          }

          .time {
            opacity: var(--high-to-low-opacity);
            font-size: 14px;
            margin-left: 10px;
            color: gray !important;
          }
        }
      }

      .top-menu {
        width: 100%;
        min-height: 50px;
        align-items: center;
        padding: 0 6vw 0 6vw;
        background-color: var(--dark-blue);

        .size {
          max-width: 1760px;
          width: 100%;
          flex: 1 1;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        .title-wrapper {
          text-transform: uppercase;
          //transform: translateY(10%);
          display: flex;
          background-color: var(--header-menu-back-ground-color);
          width: 34vw;
          height:40px;
          padding: 0.7rem 3rem;
          border-radius: 100px;
          justify-content: space-between;
          .title {
            color: var(--header-menu-items);
            font-weight: bolder;
            font-size: 1.1vw;
            &:hover {
              color:var(--white) !important;
            }
          }
          .selected {
            color:var(--header-menu-selected-item);
          }
        }
        .title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;

          img {
            filter: brightness(0) invert(1);
          }
        }

        .selected {
          color: #0e7eff;

          img {
            filter: blue;
          }
        }

        .title:hover {
          cursor: pointer;
          color: #0e7eff;
        }

        .title img {
          margin-right: 5px;
        }

        .logo {
          cursor: pointer;
          margin-right: 3%;
        }
      }

      .login {
        .account-details {
          height: auto;
          min-height: 45px;
          margin-right: 20px !important;
          // width: 88px;
          border-radius: 25px;
          background-color:var(--white-to-blue);
          border: unset;
          font-size: 16px;
          // font-weight: bold;
          span {
            display: block; 
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            color:var(--black-to-white) !important;
          }
        }
      }

      .logedin {
        .top-nav-account {
          display: flex;
          align-items: center;
        }
      }

      .login {
        display: flex;
        //flex-direction: column;
        //align-items: flex-end;
        justify-content: flex-end;
        margin-top: 5px;

        input[type="text"],
        input[type="password"] {
          height: 40px;
          width: 200px;
          font-size: 14px;
        }

        .ant-form-inline .ant-form-item {
          margin: unset;
          margin-left: 15px;
        }

        .ant-form-explain {
          display: none;
        }
        .balance {
          margin-right: 20px;
          font-size: 18px;
          font-weight: bold;
          .default-currency .ant-select-selection {
            height: 40px;
            display: flex;
            align-items: center;
            background: #044da1;
            border: none;
          }
        }

        .balance-bounce {
          animation-name: bounce;
          animation-duration: 1s;
          animation-iteration-count: 1;
        }

        @keyframes bounce {
          0%,
          20%,
          50%,
          80%,
          100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-8px);
          }
          60% {
            transform: translateY(-5px);
          }
        }

        .buttons {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          padding-bottom:5px;

          button {
            min-width: 100px;
            font-weight:bold;
            font-family: 'Oswald', sans-serif !important;
            border:1px solid var(--white-to-gray);
            font-size: 14px;
            height: 40px;
          }

          button.clear {
            background-color: transparent;
            border: solid 1px var(--white) !important;

            span {
              color: var(--white) !important;
            }
          }
        }
      }

      .logedin {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;

        .deposit {
          margin-right: 20px;
        }

        .balance {
          margin-right: 20px;
          font-size: 18px;
          font-weight: bold;
          .default-currency .ant-select-selection {
            height: 40px;
            display: flex;
            align-items: center;
            background: #044da1;
            border: none;
          }
        }

        .details {
          cursor: pointer;

          .drop {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .icon {
              height: 36px;
              width: 36px;
              background-color: #044da1;
              border: solid 1px #006be7;
              border-radius: 30px;
              margin-right: 10px;
              font-size: 12px;
              font-weight: bold;

              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              .first-letter {
                text-transform: uppercase;
                width: 9px;
                visibility: hidden;
              }

              .first-letter:first-letter {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  /*********************** Banner Styles ************************/
  .banner {
    line-height: unset;
    padding: unset;
    height: unset;
    margin-top: 4px;

    .container {
      .content {
        height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .size {
          position: absolute;
          justify-content: center;
          align-items: center;
          text-align: center;
        }

        .text {
          font-size: 40px;
          font-weight: 900;
          text-shadow: 0 3px 0 #5b5b5b;
        }

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /*********************** Body Styles ************************/
  .body {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% calc(100vh - 340px);
    padding-bottom: 30px;

    .size {
      //max-width: 1760px;
      //min-height: 800px !important;
      margin-bottom: 30px;

      .content {
        width: 100%;
        .container {
          .title {
            color: #182438 ;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 40px;
          }

          .mobile-title {
            font-size: 20px;
            font-weight: bold;
            background-color: var(--dark-blue);
            text-align: center;
            padding: 20px  0 20px 0;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .bg-00 {
      //background-image: url('../../resources/backgrounds/generic.webp');
      background-color: var(--light-black);
    }

    .bg-22 {
      //background-image: url('../../resources/backgrounds/horse-racing.webp');
      background-color: var(--light-black);
    }

    .bg-51 {
      //	background-image: url('../../resources/backgrounds/soccer.webp');
      background-color: var(--light-black);
    }

    .bg-11 {
      //	background-image: url('../../resources/backgrounds/soccer.webp');
      background-color: var(--light-black);
    }

    .bg-61 {
      //	background-image: url('../../resources/backgrounds/soccer.webp');
      background-color: var(--light-black);
    }

    .bg-616 {
      //background-image: url('../../resources/backgrounds/rugby.webp');
      background-color: var(--light-black);
    }

    .bg-71 {
      //	background-image: url('../../resources/backgrounds/soccer.webp');
      background-color: var(--light-black);
    }

    .bg-62 {
      //background-image: url('../../resources/backgrounds/basketball.webp');
      background-color: var(--light-black);
    }

    .bg-82 {
      //	background-image: url('../../resources/backgrounds/basketball.webp');
      background-color: var(--light-black);
    }

    .bg-63 {
      //background-image: url('../../resources/backgrounds/baseball.webp');
      background-color: var(--light-black);
    }

    .bg-64 {
      //background-image: url('../../resources/backgrounds/ice-hockey.webp');
      background-color: var(--light-black);
    }

    .bg-65 {
      //	background-image: url('../../resources/backgrounds/tennis.webp');
      background-color: var(--light-black);
    }

    .bg-66 {
      //	background-image: url('../../resources/backgrounds/handball.webp');
      background-color: var(--light-black);
    }

    .bg-612 {
      //	background-image: url('../../resources/backgrounds/rugby.webp');
      background-color: var(--light-black);
    }

    .bg-6117 {
      ////background-image: url('../../resources/backgrounds/mma.webp');
      background-color: var(--light-black);
    }

    .bg-621 {
      //	background-image: url('../../resources/backgrounds/cricket.webp');
      background-color: var(--light-black);
    }
  }

  /*********************** Sider ************************/
  .sider-left {
    background-color: var(--light-black);
    border-radius: 4px;
    margin-right: 20px;
  }

  .sider-right {
    background-color: transparent;
    border-radius: 4px;
    margin-left: 20px;
  }

  /*********************** Title Strip ************************/
  .strip {
    height: 71px;
    margin-top:10px;
    // border-radius: 4px;
    margin-bottom: 20px;
    background-color: var(--light-black);
    object-fit: contain;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: var(--dark-blue);

    .title {
      font-size: 22px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 20px;

      .live {
        margin-top: 3px;
        margin-left: 15px;
        color: #e01c23;
        border: solid 1px #e01c23;
        padding: 0 10px 0 10px;
        font-size: 18px;
      }

      img {
        margin-right: 15px;
        filter: brightness(0) invert(1);
        width: 36px;
        height: 36px;
      }
    }

    .images {
      display: block;
      overflow: auto;
      margin-left: auto;
      display: flex;
      overflow: hidden;
    }

    .pic {
      //float: right;
      //border: unset;
      //height: 70px;
      margin-bottom: 1px;
      border-radius: 4px;
    }

    .mask {
      margin-left: -1px;
      position: absolute;
      border: unset;
      align-self: flex-end;
    }

    @media only screen and (max-width: 1024px) {
      display: none;
      .form {
        display: none !important
      }
    }
  }

  /*********************** Footer Styles ************************/
  .footer {
    width: 100%;
    background-color: var(--light-black);
    display: flex;
    flex-direction: column;
    align-items: center;


    .container {
      padding: 20px 0 20px 0;
      width: 100%;
      padding: 0 6vw 0 6vw;

      .content {
        .size {
          width: 100%;
          flex: 1 1;
          display: flex;
        }

        .child {
          font-size: 16px;
          
        }

        .child:nth-child(1) {
          flex: 0.05;
        }

        .child:nth-child(2) {
          margin-right: 20px;
          flex: 0.2;
          line-height: 2.2;

        }

        .child:nth-child(3) {
          flex: 1;

        }
        .footer-description , .disclamer .text{
          opacity:0.7;
        }
        .footer-description{
          margin-top: 2.35rem;
        }
      }
    }
  }
}

.currency-message-popover {
  position: fixed;
  .ant-popover-message-title {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-popover-buttons {
    .ant-btn {
      font-size: 14px;
      border-radius: 4px;
      height: 24px;
      width: auto;
      font-weight: unset;
      margin-left: 8px !important;
    }
    button:first-child {
      border: 1px solid #d9d9d9 !important;
      span {
        color: rgba(0, 0, 0, 0.65) !important;
      }
    }
  }
}

.logged-in-popover {
  .ant-popover-content .ant-popover-inner .ant-popover-title {
    font-weight: bold;
  }
}
.forgot,
.register {
  line-height: normal;
  // font-weight: bold;
  font-size: 15px;
  cursor: pointer;
  margin-top: 5px;
  color:var(--white) !important;
}

.login-confirm-popover {
  .ant-popover-message-title {
    color: rgba(0, 0, 0, 0.65);
  }
  .ant-popover-buttons {
    .ant-btn {
      font-size: 14px;
      border-radius: 4px;
      height: 24px;
      width: auto;
      font-weight: unset;
      margin-left: 8px !important;
    }
    button:first-child {
      border: 1px solid #d9d9d9 !important;
      span {
        color: rgba(0, 0, 0, 0.65) !important;
      }
    }
  }
}

.balance {
  margin-right: 5px;
  font-size: 14px;
  .default-currency .ant-select-selection {
    height: 40px;
    display: flex;
    align-items: center;
    background: #044da1;
    border: none;
  }
  a {
    color:black;
    height: 30px;
    display: flex;
    align-items: center;
    border: none;
  }
  button {
    border-radius: 2rem;
    font-weight: unset;
    border: 1px solid lightgray;
    margin: 10px 0 !important;
    border-color: lightgray !important;
    justify-content: center;
    align-items: center;
  }
  .btn-selected-balance {
    background-color: rgba(10, 187, 135, 0.1);
    border-color: rgba(10, 187, 135, 0.1) !important;
    a {
      color: #0abb87;
    }
  }

  .eye-icon {
    color: grey !important;
    cursor: pointer;
    font-size: 17px;
    margin-bottom: 0.5em;
  }
  .eye-icon:hover {
    color: #0e7eff !important;
  }
  // .btn-selected-balance:hover{
  //   background-color: #0abb87;
  //   a{
  //       color: var(--white);
  //   }
  // }
}

.ant-popover-inner-content {
  .details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-top: 1px solid #e8e8e8;

    button {
      padding: 5px;
      margin: 5px 0 !important;
      width: 180px;
    }
  }
  .details a:first-child {
    padding-top: 10px !important;
  }
}

@-moz-document url-prefix() {
  .screen .header .container .logedin .details .drop .icon .first-letter {
    overflow: hidden;
    visibility: visible;
  }
}

.general-modal.ant-modal {
  width: max-content !important;
}

.mode-switcher {
  background-color: var(--switcher-button);
  padding: 0.2rem 1rem;
  border-radius: 20px;
  color:var(--white-to-gray) !important;
  &:hover {
    background-color: var(--buttons-hover);
    cursor: pointer;
  }
  img {
    margin: 0 0.5rem;
    
  }
  #light-mode-text{
    color: gray !important;
  }
  span{
    color: var(--white-to-gray) !important;
    opacity: var(--high-to-low-opacity);
  }

}