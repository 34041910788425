
#transactions-container{
	width: 100%;
	// .ant-table-thead > tr > th{
	// 	text-align: start !important;
	// }
	// .ant-table-tbody td {
	// 	text-align: start;
	// }

	.ant-table-tbody > tr > td{
		padding: 0;
		padding-top: 10px;
		// padding-right: 16px;
		// padding-bottom: 10px;
	}
	.form-container{
		width: 100%;
		padding-top: 60px;
	}
}


.voucher-details-expanded{

	display: flex;
    justify-content: space-between;
    align-items: flex-start;
	background-color: var(--light-black) !important;
    width: 100%;
	padding: 20px;
	.voucher-content-details{
		display: flex;
    flex-direction: column;
    align-items: flex-start;
	
	.issued-date {
		color: var(--white);
		font-size: 13px;
		margin-bottom: 10px;
		span:nth-of-type(1){
			margin-inline-end: 10px;
		}
	}
	.expiry-date  {
		color: var(--white);
		font-size: 13px;
		margin-bottom: 10px;
		span:nth-of-type(1){
			margin-inline-end: 5px;
		}
	}
	
	.voucher-number  {
		color: var(--white);
		font-size: 13px;
		margin-bottom: 10px;
		span:nth-of-type(1){
			margin-inline-end: 5px;
		}
		span:nth-of-type(2){
			font-weight:bold
		}
	}
	
	.voucher-status {
		color: var(--white);
		font-size: 13px;
		margin-bottom: 10px;
		span:nth-of-type(1){
			margin-inline-end: 5px;
		}
		span:nth-of-type(2){
			color: #FF9D00 !important;
			font-weight:bold
		}
	}
	
	}
	.qr-code-top-border{
		display: flex;
		justify-content: center;
		align-items: center;	
		width: 135px;
		height: 135px;
		background: linear-gradient(to right,var(--white) 2px, transparent 2px) 0 0, 
		linear-gradient(to right, var(--white) 2px, transparent 2px) 0 100%, 
		linear-gradient(to left, var(--white) 2px, transparent 2px) 100% 0, 
		linear-gradient(to left, var(--white) 2px, transparent 2px) 100% 100%, 
		linear-gradient(to bottom, var(--white) 2px, transparent 2px) 0 0, 
		linear-gradient(to bottom, var(--white) 2px, transparent 2px) 100% 0, 
		linear-gradient(to top, var(--white) 2px, transparent 2px) 0 100%, 
		linear-gradient(to top, var(--white) 2px, transparent 2px) 100% 100%;
		background-repeat: no-repeat;
		background-size: 20px 20px;
		
	}
}

.account{
    #my-account-transaction-mobile-table{
		thead{
			display: none !important;
		}
		display:none
	}
}

@media only screen and (max-width:900px){
	.account .layout-inner .content .container .bets .table .ant-table-tbody td{
		text-align: start;
	}
	.account .layout-inner .content .container{
		.deposit-mtn , .withdrawals , .deposit{
			width: 73vw;
		}
	} 
	
	.account{    
		.layout-inner .content .container .table{
			.mobile-table-row-data{
			margin-left: 8vw;
			}
			width: 90vw;
			padding: 0;
		}
		#my-account-transaction-mobile-table{
			display:block;
			.ant-table-tbody > tr > td{
				
					border: none;
					background-image: linear-gradient(to right, #202D46 33%, rgba(255,255,255,0) 0%);
					background-position: bottom;
					background-size: 32px 3px;
					background-repeat: repeat-x;
					text-align: start;
			}}
		
		#my-account-desktop-table{
			display:none
		}
	}

}

@media only screen and (max-width:1000px){
	.voucher-details-expanded{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background-color: var(--light-black) !important;
		width: 95%;
	}
	.voucher-content-details{
		margin-bottom: 33px;
	}
	.qr-code-top-border{
		margin-bottom: 26px;
	}
}

@media only screen and (max-width:900px){
	.voucher-details-expanded{
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 5px;
		// width:90%;
	}
	.voucher-content-details{
		margin-bottom: 33px;
	}
	.qr-code-top-border{
		margin-bottom: 26px;
	}

}
@media only screen and (max-width:500px){
	#transactions-container .form-container{
		padding-top: 20px;
	}

}
@media only screen and (max-width:320px){
	.account .voucher .ant-table-tbody > tr > td {
		font-size: 7px !important;
	}

}

