.sport{
	#mobile-top-bets-details-container{
		display: none;
	}
	.mobile-bets-filter-container{
		display: none;

	}
	#mobile-bets-filter-controller{
		// width:1400px;
		// overflow-y: hidden;
		// overflow-x: scroll;
		//var(--white)-space: nowrap;
		// // overflow: auto;
		// border: 4px solid red;
		// background-color:var(--white);
		// // -webkit-overflow-scrolling: touch;
		// position: relative;
		
	}
	.mobile-bet-filter-options{
		display:inline-block;
		text-align: center;
		border-radius: 25px;
		margin-left: 5px;
		background-color: var(--dark-blue);
		padding: 5px 10px;
		opacity: 0.5;
		cursor: pointer;
	}
	
	@media only screen and (max-width: 600px){
		#top-bets-details-container{
			display: none;
		}
		#mobile-top-bets-details-container{
			display: block !important;
			.top-bets-header-details{
				text-align: center;
				opacity: 0.5;
				// display: inline-block;
			}
			.top-bets-match-details{
				padding: 20px 20px 5px 20px;
				box-sizing:border-box;
				.top-bets-match-teams{
					display:flex;
					justify-content:space-between;
					padding-bottom: 10px;
				}
			}
		}
		.mobile-bet-filter-options{
			font-size: 14px;
		}
		.mobile-bets-filter-container{
			display: block;
		}
		.bets-filter-container{
			display: none;
		}
		.buttons-ods{
			button{
				width: 12vw;
			}
		}
		.row{
			.col{
				.event{
					.details{
						.teams{
							.team{
								font-size: 14px !important;
							}
						}
					}
				}
			}
		}
	
}
@media only screen and (max-width:500px){
	.mobile-bet-filter-options{
		font-size: 11px;
	}
}
@media only screen and (max-width:400px){
	.mobile-bet-filter-options{
		font-size: 8px;
	}
}
@media only screen and (max-width:300px){
	.mobile-bet-filter-options{
		font-size: 5px;
	}
}


	.events{
		.teams-position{
			display: inline;
			border-radius: 15%;
			background-color: var(--dark-blue-to-dark-gray);
			padding: 0 5px;
			color:var(--black-to-white);
			height: 20px;
			margin-right: 5px;
			margin-bottom: 5px;
			min-width:20px ;
			text-align: center;
		}
		.mobile-bet-rows-container{
			display: none;
			
		}
		@media only screen and (max-width: 600px){
			.bet-type-0{
				width:45vw !important;
				max-width: 45vw !important;
				min-width:45vw !important;
			}
			.row{
				.col{
					// display: block;
				}
			}
			#bet-rows-container{
				display: none;
			}

			.mobile-bet-rows-container{
				display: block;
				padding: 5px 15px;
				.bet-rows-parent{
					border-bottom: 1px solid #2F384B;
					padding-top:5px;
					padding-bottom:5px
				}
				
				

			}
			.event-row-parent-container:last-of-type{
				.mobile-bet-rows-container{
					.bet-rows-parent{
						border: none;
					}
				}
			}
		}
		
	}
}
.sport {

	/*-----------------  events styles -----------------*/
	.events {
		border-radius: 4px;
		background-color: var(--light-black);
	}

	/*----------------- Sport styles -----------------*/
	// .bg {
	// 	position: absolute;
	// 	height: 400px;
	// 	width: 100%;
	// 	left: 0;
	// 	right: 0;
	// 	z-index: -50;
	// 	margin-top: -10px;
	// 	background-color: transparent;
	// 	background-image: url('/resources/images/soccer_bg.png');
	// 	background-repeat: no-repeat;
	// 	background-size: 100% 100%;
	// 	//background-image: linear-gradient(to bottom, rgba(66, 194, 72, 0.3), rgba(4, 16, 30, 0));
	// }

	/*----------------- Sport Top Bets styles -----------------*/
	.top-bets {
		margin-bottom: 30px;

		.top {
			display: flex;
			flex-direction: row;
			align-items: center;
			margin-bottom: 17px;


			.title {
				height: 24px;
				font-size: 20px;
				font-weight: 300;
				margin-right: 15px;
				margin-left:10px;
			}

			.container {
				display: flex;
				flex-direction: row;

				.bt {
					width: 35px;
					height: 35px;
					border-radius: 4px;
					background-color: #32425c;
					display: flex;
					justify-content: center;
					align-items: center;
					font-size: 25px;
					cursor: pointer;
				}

				.bt:last-child {
					margin-left: 10px;
					transform: rotate(-180deg);
				}

			}
		}

		.ant-carousel {
			margin-bottom: 20px;

			.card {
				//width: 100%;
				display: flex !important;
				flex-direction: row;

				.event {
					cursor: pointer;
					flex: 1 1;
					border-radius: 4px;

					.container {
						width: 100%;

						.bet-line-num{
							opacity: 0.8;
							font-size: 18px;
							text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
							font-size: 20px;	

						}

						@media only screen and (max-width: 1500px) {
							.bet-line-num{
								opacity: 0.8;
								font-size: 18px;
								text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
								font-size: 15px;	
	
							}
						}

						.buttons-ods {
							
							button {

								.container {
									justify-content: space-between;
									

									.name {
										opacity: 0.5;
										text-align: left;
									}

									.lock {
										margin-right: 10px;
									}
								}
							}
						}
					}

					.row-1{
						display: flex;
						flex-direction: row;
						align-items: center;
						margin-bottom: 12px;
						justify-content: space-between;
					}

					.row-2,
					.row-3 {
						display: flex;
						flex-direction: row;
						justify-content: center;

					
					}

					.row-2 {
						white-space: nowrap;
						justify-content: space-around;
						font-size: 18px;

						.team {
							text-align: center;
							text-overflow: ellipsis;
							overflow: hidden;
							white-space: nowrap;
							text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
							color:var(--white);		
						}

						.vs{
							width: 30%;
							display: flex;
							justify-content: center;
						}
					}

					

					.league {
						opacity: 0.8;
						font-size: 18px;
						text-overflow: ellipsis;
						overflow: hidden;
						max-width: max-content;
						white-space: nowrap;
						text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);

					}
					.date {
						//width: 70px;
						text-align: right;
						opacity: 0.8;
						font-size: 18px;
						text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);

					}

					@media only screen and (max-width: 1500px) {
	

						
						.row-2 {
							margin-bottom: 12px;
							white-space: nowrap;
							justify-content: space-around;
							text-align: center;
	
							.team {
								text-overflow: ellipsis;
								overflow: hidden;
								white-space: nowrap;
								text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
								color:var(--white);
							}
	
							.vs{
								width: 30%;
								display: flex;
								justify-content: center;
							}
						}
	
						
	
						.league {
							opacity: 0.8;
							font-size: 15px;
							text-overflow: ellipsis;
							overflow: hidden;
							max-width: max-content;
							white-space: nowrap;
							text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
							width: 250px;
						}
						.date {
							//width: 70px;
							text-align: right;
							opacity: 0.8;
							font-size: 15px;
							text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
	
						}
					}
			
				}

				.event:not(:last-child) {
					margin-right: 16px;
				}

				.event:nth-child(1) {
					background-color: var(--strong-blue);
					background-position: 0px 0px;
					background-size: 100% 100%;
					background-repeat: no-repeat;
				}

				.event:nth-child(2) {
					background-image: url('../../resources/top-bets/studiom1/2.webp');
					background-position: 0px 0px;
					background-size: 100% 100%;
					background-repeat: no-repeat;
				}

				.event:nth-child(3) {
					background-image: url('../../resources/top-bets/studiom1/1.webp');
					background-position: 0px 0px;
					background-size: 100% 100%;
					background-repeat: no-repeat;
				}

			}
		}

	}


	/*----------------- Sport rows header styles -----------------*/
	.header {
		display: flex;
		flex-direction: row;
		padding-top: 20px;
		padding-bottom: 8px;
		padding-left: 20px;
		padding-right: 20px;
		border-bottom: solid 2px #182e49;

		.col {
			display: flex;
			flex-direction: row;
			align-items: center;


			.bet-types {
				min-width: 150px;
				display: flex;
				align-items: center;
				justify-content: center;

				.selectlist {
					width: 100%;
					max-width: 150px;
				}

				.odds {
					height: 40px;
					flex: 1 1;
					// display: flex;
					flex-direction: column;
					justify-content: space-between;
					width: 8.8vw;


					div {
						display: flex;
						flex-direction: row;
						width: 8.8vw;
						div{
							width: 8.8vw;
							margin-right: 1.5vw;
						}

						// width: 3.8vw;

												// width: 8.8vw;
												// margin-right: 1vw;
						//justify-content: space-evenly;
					}

					.odds-desc {
						display: flex;
						flex-direction: row;
						justify-content: space-around;
						font-size: 12px;
						font-weight: bold;
						text-transform: uppercase;
					}
				}

				.title {
					display: flex;
					align-items: center;
					justify-content: center;
					align-self: center;
					width: 100%;

					opacity: 0.6;
					font-size: 12px;
					font-weight: lighter;
					text-align: center;
					font-weight: 400;
					color: var(--white);
				}

				.select {
					height: 28px;
					display: flex;
					align-items: center;
					justify-content: center;
					align-self: center;
					width: 100%;
				}


			}
		}
	}

	/*----------------- Sport Skelaton -----------------*/
	.skeleton {
		display: flex;
		flex-direction: column;

		.row {
			height: 110px;
			margin-bottom: 10px;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.col {
				opacity: 0.5;
				float: left;
				height: 100%;
				flex: 1 1;
				background-color: var(--light-black);
				border-radius: 4px;
			}

			.col:not(:last-child) {
				margin-right: 10px;
			}

			.loader {
				position: absolute;
				float: left;
				height: 65px;
				width: calc(100% - 20px);
				flex: 1 1;
				animation: ant-skeleton-loading 2s ease infinite;
				animation-name: ant-skeleton-loading;
				animation-duration: 2s;
				animation-timing-function: ease;
				animation-delay: 0s;
				animation-iteration-count: infinite;
				animation-direction: normal;
				animation-fill-mode: none;
				animation-play-state: running;
				background: linear-gradient(90deg, transparent 25%, #32425c 37%, transparent 43%);
				background-size: 400% 300%;
			}
		}
	}

	/*----------------- Sport rows styles -----------------*/
	.row {
		overflow: visible;
		cursor: pointer;

		.disabled {
			background-color: var(--white);
			opacity: 0.3;
			width: 100%;
			height: 100px;
			float: left;
			margin-top: -95px;
		}

		.notice * {
			color: #182438;

			a {
				text-decoration: underline;
			}
		}

		.row-0,
		.row-1,
		.row-2 {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			padding-top: 5px;
		}

		.row-2 {
			margin-top: 5px;
		}

		.disabled {
			display: flex;
			flex-direction: row;
			pointer-events: none;
			opacity: 0.6;
		}

		.bet-num {
			opacity: 0.5;
			font-size: 15px;
		}

		.date {
			white-space: nowrap;
			font-size: 14px;
			opacity: 0.5;
		}

		.timer {
			opacity: 0.5;
			font-size: 12px;
			font-weight: bold;
			margin-left: 10px;
		}

		.league {
			font-size: 12px;
			font-weight: bold;
			margin-left: 10px;
			max-width: calc(100vw - 170px);
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			.text {
				opacity: 0.5;
			}
		}

		.season {
			font-size: 12px;
			font-weight: bold;
			margin-right: 10px;
		}

		.live {
			font-size: 12px;
			font-weight: bold;
			color: #e01c23;
			margin-right: 10px;
			text-transform: uppercase;
		}


		.col {
			display: flex;
			flex-direction: row;
			align-items: center;

			.event {
				width: 100%;
				display: flex;
				flex: 1 1;

				.details {
					width: 100%;
					padding-right:20px;

					.teams {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						

						.team {
							font-size: 16px;
							color:var(--white);

							width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
					}
				}

					.score  {
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						.event-score{
							color: #0e7eff !important;
						}
						.game-scores{
							position: relative;
						display: flex;
						letter-spacing: 10px;
						align-items: center;
						font-size: 16px;
						margin-left: -5px;
						.game-result{
							color:var(--white) !important;
						
						
						}
						.match::after{
							content: " ";
							position: absolute;
							top: 0.2em;
							background:var(--white);
							width: 1px;
							height: 40px;
						}
						.match-lost{
							color: var(--white) !important;
							opacity: 0.7;
						}
						.match-won{
							color: #044da1 !important;
							opacity: 0.8;
						}
						.game-current{
							color: #0e7eff !important;
							letter-spacing: 0px;
							margin: 0 10px;
						}
						.current-turn{
								width: 6px;
								height: 6px;
								background-color: #ebff13;
								border-radius: 50%;
						}
					}

				}
			}

			.stats {
				filter: brightness(0) invert(5);
			}

			.plus {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				background-color: transparent;

				.icon {
					font-size: 14px;
					font-weight: bold;
				}
			}

			.bet-types {
				//width: 190px;
				flex: 1 1;
				display: flex;
				align-items: center;
				flex-direction: row;
				justify-content: center;

				.container {
					width: 100%;
					display: flex;
					align-items: center;
					justify-content: center;

					i {
						font-size: 1.4rem;
						opacity: 0.5;
					}
				}
			}
		}
	}

	.buttons.cols-1 {
		button {
			width: 100%;
		}
	}

	.buttons.cols-2 {
		button {
			width: 50%;
		}
	}

	.buttons.cols-3 {
		button {
			width: 33.3%;
		}
	}

	.buttons.cols-4 {
		button {
			width: 25%;
		}
	}

	.buttons-ods:first-child {
		button:first-child {
			border-top-left-radius: 6px;
		}

		button:last-child {
			border-top-right-radius: 6px;
		}
	}

	.buttons-ods:last-child {
		button:first-child {
			border-bottom-left-radius: 6px;
		}

		button:last-child {
			border-bottom-right-radius: 6px;
		}
	}

	.buttons-ods {
		flex: 1 1;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		margin-bottom: 3px !important;

		button {
			opacity: var(--sport-odd-buttons-opacity);
			border-radius: 0px;
			border-top: solid 2px var(--sport-event-buttons-background-color);
			border-bottom: solid 2px var(--sport-event-buttons-background-color);
			background-color: var(--sport-event-buttons-background-color);
			font-size: 14px;
			
		}

		// button:first-child {
		// 	border-top-left-radius: 6px;
		// 	border-bottom-left-radius: 6px;
		// }

		// button:last-child {
		// 	border-top-right-radius: 6px;
		// 	border-bottom-right-radius: 6px;
		// }

		button:hover {
			border-top: solid 2px #044da1;
			border-bottom: solid 2px #044da1;
			background-color: #044da1;
		}

		@media (hover: none) {
			button:hover {
				padding: unset;
				border-radius: 0px;
				border-top: solid 2px var(--light-black);
				border-bottom: solid 2px var(--light-black);
				background-color: var(--light-black);
				font-size: 14px;
				font-weight: bold;
			}
		}

		button.selected {
			opacity : 1; 
			border-top: solid 2px var(--dark-blue-to-dark-gray);
			border-bottom: solid 2px var(--dark-blue-to-dark-gray);
			background-color: var(--dark-blue-to-dark-gray);
			div{
				div{
					color: white !important;
				}
			}
		}

		button:not(:last-child) {
			margin-right: 3px !important;
		}

		.up {
			border-top: solid 2px #2C8AF7;
			animation: blink-border-top normal 0.5s 4 ease-in-out forwards;
		}

		.down {
			border-bottom: solid 2px #e82f2f;
			animation: blink-border-bottom normal 0.5s 4 ease-in-out forwards;
		}
	}

	@keyframes blink-border-top {
		0% {
			border-top: solid 2px transparent;
		}

		50% {
			border-top: solid 2px #2C8AF7;
		}

		100% {
			border-top: solid 2px transparent;
		}
	}

	@keyframes blink-border-bottom {
		0% {
			border-bottom: solid 2px transparent;
		}

		50% {
			border-bottom: solid 2px #e82f2f;
		}

		100% {
			border-bottom: solid 2px transparent;
		}
	}

	.upcoming {

		.title {
			font-weight: bold;
			font-size: 18px;
			opacity: 0.8;
			margin: 20px 0 10px 10px;
			text-transform: uppercase;
		}
	}

	/*----------------- Sport menu styles -----------------*/
	.search {
		border-top-right-radius: 4px;
		border-top-left-radius: 4px;
		height: 40px;
		background-color: var(--blue);
	}


	/*----------------- Today styles -----------------*/
	.today {
		display: flex;
		flex-direction: column;
	}

}
