.contact {

	// padding:  0 6vw 0 6vw;
	.document-pages-header{
		display: none;
	}
	@media only screen and (max-width: 1024px ){
		.document-pages-header{
			display: block !important;
			font-size: 20px;
			font-weight: bold;
			background-color: var(--dark-blue);
			text-align: center;
			padding: 20px 0 20px 0;
			text-transform: uppercase;
			// padding: 0 6vw 0 6vw;
			margin-bottom: 20px;
		
		}
		
	}
	
	
	.form{
		.ant-select-selection--single , .ant-input{
			background-color: var(--form-input-background-color);
			border: transparent;
			color: #808897
		}

		.ant-form-item .ant-form-item-label label {
			font-weight: 400 !important;
		}
		// .ant-form-item-required::before{
		// 	display: none;
		// }
		.ant-form-item-required::before{
			display: none;
		}
		.ant-form-item-required::after{
			display: inline-block;
			margin-left: 4px;
			color: #f5222d;
			font-size: 14px;
			font-family: SimSun, sans-serif;
			line-height: 1;
			content: '*';
		}
	}
	

	.sider-left,
	.sider-right {
		display: none !important;
	}

	.layout-inner {
		display: flex;
		justify-content: center;

		.content {
			max-width: 1024px !important;

			.container {
				display: flex;
				flex-direction: row;

				@media only screen and (max-width: 1024px) {
					flex-direction: column;

					.left {
						margin-bottom: 30px;
					}
				}

				.left {
					flex: 1;
					margin-right: 30px;
					width: 100%;
				}

				.right {
					flex: 1;
					width: 100%;
				}

				.right .google-map {
					height: 300px;
					width: 100%;
				}

				.ant-layout-sider {
					border-radius: 4px;
					background-color: transparent;
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					padding: 28px;
				}

				.ant-layout-sider-children {
					flex: 1 1;
					display: flex;
					flex-direction: column;
					align-items: center;
					background-color:var(--dark-blue) ;
					border-radius: 5px;
					padding :30px 20px 30px 20px;
				}

				.title {
					font-size: 20px !important;
					font-weight: 400 !important;
					color: var(--white) !important;
					text-align: center;
					flex: 1;
					width: 285px;
					margin-bottom: 30px !important;
				}

				.message {
					margin-top: 10px;
					color: var(--white)
				}

				.message textarea {
					height: 155px !important;
					max-height: 155px !important;
				}

				.captcha {
					margin-top: 20px;
					margin-bottom: 30px;
					overflow: visible;
					clear: both;
					width: 100% !important;
				}
				.text .fu-title {
					font-size: 20px;
					font-weight: 600;
					opacity: 1 !important;
				}
				.text:not(div){
					width: 100%;
					font-size: 16px;
					opacity: 0.5 !important;
				}

				
			}
			.upload{
				background: #044da1; 
					
			}
				.anticon-paper-clip{
				display: none;
				}
				
						
			.captcha {
				>div {
					>div {
						width: 100% !important;

						iframe {
							width: 100% !important;

							* {
								width: 100% !important;
								border: solid 1px red;
							}

						}
					}
				}
			}

		}
	}
}

#rc-imageselect {
	transform: scale(0.1);
	-webkit-transform: scale(0.1);
	transform-origin: 0 0;
	-webkit-transform-origin: 0 0;
}

.g-recaptcha {
	-moz-transform: scale(1.1);
	-ms-transform: scale(1.1);
	-o-transform: scale(1.1);
	-moz-transform-origin: 0;
	-ms-transform-origin: 0;
	-o-transform-origin: 0;
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
	-webkit-transform-origin: 0 0;
	transform-origin: 0;
	filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.1, M12=0, M21=0, M22=1.1, SizingMethod='auto expand');
}