.slot-machines {
  /*-----------------  events styles -----------------*/
 .scroll-slot-categories{
  span{
     color:var(--white) !important;
   }
 }
  .back{
    font-weight: bold;
    margin: 0 0 10px 0 !important;
  }
  .items {
    border-radius: 4px;
    padding-bottom: 10px;

    .gutter-example .ant-row > div {
      background: transparent;
      border: 0;
    }
    .gutter-box {
      // background: #00a0e9;
      margin: 20px 0;
      cursor: pointer;
    }
    .gutter-box:hover {
      opacity: 0.9;
      filter: alpha(opacity=90); /* For IE8 and earlier */
      box-shadow: 10px 10px 5pxvar(--black-to-white);
    }

    .slot-image {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .slots-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  .game-symbols{
  display: flex;
  width: 100%;
  justify-content: space-between;
  .symbol{
    padding: 20px 20px 0 0; 
       width: 100%;
    img{
      width: 100%;
      max-width: 170px;
    max-height: 170px;
  
    }
    .symbol-text{
    text-align: left;
    font-size: 16px;
     
       font-weight: bold;
       p{

        margin: 0 0px 1px 25% !important;
        span:first-child{
color: gold !important;
        }
       }
    }
  }

  .symbol:last-child{
    padding: 20px 0 0 0;
  }

}

    .frame-video {
      width: 100%;
    }

    .skeleton {
      height: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      .loading {
        .ant-spin-dot {
          font-size: 40px !important;
          width: 40px;
          height: 40px;
        }

        .text {
          font-size: 25px;
        }
      }
    }
  }
  .slots-video {
    .frame-video {
      height: 60vh;
      }
  }
}


.modal-not-finish-text{
  font-size: 30px;
}

