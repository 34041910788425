.today-bets {


	.header {
		.sort {
			.title {
				cursor: pointer;

				i svg {
					height: 13px;
				}
			}

			

			.sort-by {
				display: flex;
				align-items: center;

				.text {
					opacity: 0.5;
					font-size: 14px;
					font-weight: bold;
					line-height: normal;
					padding-bottom: 4px;
				}

				.selectlist {
					width: 100px;
					font-size: 14px;
					font-weight: bold;

					.ant-select-selection {
						opacity: unset;
						text-transform: unset;
					}
				}
			}
		}
	}

}