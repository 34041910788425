.screen {
// mobile footer style
@media only screen and (max-width:1380px){

  .row-of-bet-details-buttons{
    justify-content: center;
  }

}
@media only screen and (max-width:600px){

    .footer{
      .container{
        .content{
          .child{
            font-size: 14px !important;
          }
        }
      }
    }
}
@media only screen and (max-width: 600px){
  .header{
    .container{
      .login{
        .account-details{
          width: 45vw;
          margin-right: 0px !important;
          #my-account-username{
            font-size: 2.2vw !important;
          }
          #my-account-balance{
            font-size: 3vw !important;
          }
          span{
            display:inline-block
          }
          .account-details-headline{
            display: flex;
            justify-content: space-around;
            align-items: center;
          }
        }
      }
    }
  }
}

// 
  //1 Col
 
  @media only screen {
    width: 100%;

    .header {
      @media only screen and (max-width:500px){
        .container{
          .top-menu{
            .title{
              font-size: 3.2vw !important;                  // font-weight: 700;
            }
          }
        }
        .mobile{
          .login{
            .buttons{
              button{
                width: 18vw !important;
                // padding: 5px;
                height: 8vw;
                span{
                  display: flex;
                  justify-content:center ;
                  align-self:center ;
                  font-size: 0.7rem !important;
                }
                
              }
            }
          }
        }   

      
      }
      @media only screen and (max-width:360px){
        #winners-logo{
          width: 100px !important;
        }  
        .mobile{
          .login{
            .buttons{
              button{
                width: 22vw !important;
                height: 10vw;
                span{
                  font-size: 0.6rem !important;

                }
          
                
              }
            }
          }
        }  

      
      }
      .mobile {
        // background-image: linear-gradient(
        //   to bottom,
        //   var(--white),
        //   darken(#deedff, 5%)
        // ) !important;
        // NEW STYLE FOR HEADER
        .mobile-header-top{
          display: flex;
          justify-content: space-between;
        }
        .mobile-header-title-wrapper{
          display: flex;
          justify-content: space-between;
          text-transform: uppercase;
          background-color: var(--light-black);
          padding: 1rem 2rem;
          border-radius: 100px;
          color:var(--white) !important;
        }
        #menu-icon-container{
          padding: 10px 14px 10px 14px;
          border-radius: 20px;
          background-color: var(--dark-blue);
          margin-top: 10px;
          .menu{

            filter: brightness(0.75) ;       }

          }
          
        @media only screen and (max-width:600px){
          .search-box-mobile-view{
            width: 30px;
          }
        }
        @media only screen and (max-width:400px){
          #time-mobile-view{
            font-size: 8px;
          }
        }

        .search-box-mobile-view-icon{
          width: 130px;
        }
        
        // END OF NEW STYLE
        .search-box-top-menu:focus {
          outline: none !important;
          border-color:var(--white);
        }

        .search-box-top-menu .ant-input-suffix svg {
          color: #2e3c4f !important;
        }
        .search-box-top-menu {
          width: 200px;

          .ant-input {
            border: none;
            color: #172a40;
            font-size: 14px;
            font-weight: bold;
            width: 20px;
          }
        }
        .top {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          padding: 15px;
          height: 70px !important;

          .menu {
            width: 50px;
            margin-top: 5px;
            cursor: pointer;

            .icon {
              font-size: 25px;
              opacity: 0.5;
              color: #044da1;
            }
          }

          .logo {
            margin: 0 auto 0 auto;

            img {
              height: 40px;
            }
          }

          .empty {
            width: 50px;

            div {
              margin-left: auto;
            }
          }
        }

        .top-menu {
          // width: 100%;
          // padding: 15px 10px 15px 10px;
          // height: unset;
          // background: unset;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          // border-bottom: 1px solid var(--white);
          // background: var(--white);
          .scroll::-webkit-scrollbar {
            display: none;
          }
          .scroll {
            overflow-y: hidden;
          }
          .light-on {
            color: #3E5167 !important;
          }
          .light-on:hover {
            color: var(--white) !important;
          }
          .title {
            img {
              filter: invert(70%) sepia(7%) saturate(3072%) hue-rotate(178deg)
                brightness(92%) contrast(90%);
            }
          }

          .selected {
            color: var(--white) !important;

            img {
              filter: invert(17%) sepia(90%) saturate(2356%) hue-rotate(206deg)
                brightness(96%) contrast(96%);
            }
          }

          .title:hover {
            cursor: pointer;
            color: #0e7eff;
          }

          .title img {
            margin-right: 5px;
          }

          .logo {
            cursor: pointer;
            margin-right: 3%;
          }
          .gt {
            opacity: 0.5;
          }

          .scroll {
            overflow-y: hidden;
            overflow-x: auto;
            //width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 10px;

            .title {
              white-space: nowrap;
              font-size: 14px;

              img {
                height: 18px;
              }
            }
          }
        }

        .bet-slip-icon {
          height: unset !important;
          background-color: var(--light-black);

          .ant-affix {
            left: calc((100vw - 50px) / 2) !important;
            //top:  // calc((100vh - 50px) / 2) !important;
            top: unset !important;
            bottom: 50px;
            width: 50px !important;
            height: 50px;
            cursor: pointer;
            background-color: #2C8AF7;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 30px;
            box-shadow: 5px 5px 5px #00000070;

            i svg {
              color: #182438;
              width: 25px;
              height: 25px;
            }

            .badge {
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 100%;
              margin-top: -20px;
              margin-left: 15px;
              position: absolute;
              width: 25px !important;
              height: 25px;
              background-color: #E01B22  !important;
              color: var(--white);
            }
          }
        }
      }
    }

    .banner {
      margin-bottom: 1px;

      .mobile {
        .content {
          height: 80px;

          .text {
            font-size: 20px;
          }

          img {
          }
        }
      }

      .size {
      }
    }
    

    .body {
      padding-bottom: unset;

      .size {
        width: 100%;
        min-height: unset;
        padding: 0 6vw 0 6vw;

        .sider-left,
        .sider-right {
          display: none;
        }

        .content {
          padding: 0px 10px 0px 10px;
          overflow: hidden;
        }
      }
    }
    

    .footer {
      padding: unset;

      .mobile {
        .content {
          .size {
            min-width: unset;
            flex-direction: column;
            align-items: unset;
          }
        }
      }

      .size {
        min-width: unset !important;
      }
    }
  }

  @media only screen and (min-width: 950px) {
    .header {
      .top {
        .menu {
          display: none;
        }
      }
    }

    .body {
      padding-bottom: unset;

      .size {
        min-height: unset;

        .sider-left {
          display: unset;
          margin-right: 0px;
          margin-left: 10px;
        }

        .sport-types {
          display: none !important;
        }

        .content {
        }
      }
    }
  }

  @media only screen and (min-width: 320px) {
    .header {
      .mobile {
        .top-menu {
          .scroll::-webkit-scrollbar {
            display: none;
          }
          .scroll {
            overflow-y: hidden;
            .mobile-menu-tabs {
              .ant-tabs-bar {
                margin: 0;
                border-bottom: none;
              }
              .ant-tabs-ink-bar {
                display: none !important;
              }
              .ant-tabs-nav .ant-tabs-tab {
                margin: 0;
                div {
                  font-size: 12px;
                }
                img {
                  width: 12px;
                }
              }
              span {
                color: #044da1 !important;
              }
            }
            .title {
              margin-right: calc(100% / 20);
              margin-left: 1.5%;
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 425px) {
    .header {
      .mobile {
        .top .logo {
          margin: 0 0 0 35%;
        }
        .top-menu {
          .scroll::-webkit-scrollbar {
            display: none;
          }
          .scroll {
            overflow-y: hidden;
            .mobile-menu-tabs {
              .ant-tabs-bar {
                margin: 0;
              }
              .ant-tabs-ink-bar {
                display: none !important;
              }
              .ant-tabs-nav .ant-tabs-tab {
                margin: 0;
                div {
                  font-size: 12px;
                }
                img {
                  width: 12px;
                }
              }
              span {
                color: #044da1 !important;
              }
            }

            //  overflow-x: hidden !important;
            .title {
              margin-right: calc(100% / 20);
            }
          }
        }
      }
    }
  }

  @media only screen and (min-width: 680px) {
    .header {
      .mobile {
        .top-menu {
          .gt {
            display: none;
          }

          // justify-content: center;

          .scroll {
            //display: none;
            width: 100%;
            justify-content: center;

            .title {
              margin-right: calc(100% / 20);
            }
          }
        }
      }
    }
  }

  
  @media only screen and (min-width: 1000px) {

    .header,
    .banner,
    .footer {
      .mobile {
        display: none;
      }

      .desktop {
        display: unset !important;
      }
    }

    .body {
      .size {
        .sider-right {
          display: unset;
          margin-right: 10px;
          margin-left: 0px;

          flex: 0 0 230px !important;
          max-width: 230px !important;
          min-width: 230px !important;
          width: 230px !important;
          top:1.5rem;

        }



        .mobile-title {
          display: none;
        }
      }
    }

    .logedin {
      .search-box {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 1110px) {
    .header,
    .banner,
    .footer {
      .container .login .buttons {
        padding: 0;
        button {
          margin: 10 !important;
          min-width: 0;
        }
      }

      .top-menu {
        .size .logo {
          margin-right: 0;
        }
      }
    }
  }


  @media only screen and (max-width: 1000px) {

    .header {
      .container{
        .top-menu{
          .title{
            font-size: 2.5vw;
            // font-weight: 700;
          }
        }
      }
    }
    .header,
    .banner,
    .footer {
      .mobile {
        display: unset;

        .top-menu {
          min-height: unset !important;
          padding-bottom: 10px;
        }

        .search-box {
          width: 100%;
          padding: 0;
          margin-top: 10px;

          input {
            width: 100% !important;
          }
        }
        .ant-input-affix-wrapper .ant-input:not(:last-child){
          padding-right: 0;
        }
        // .search-box{
        //   margin-right: 50px;
        // }
        .search-box-mobile-view{
            margin-right: 20px;

        }


        .logedin {
          display: flex;
          margin-left: unset;
          flex-direction: column-reverse;
          align-items: unset;
        }

        .top-nav-account {
          align-items: center;
          justify-content: flex-start;
          display: flex;
        }
        .login {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          box-sizing: content-box !important;
          .buttons {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-evenly;

            button {
              max-width: 120px;
              // background-color: #054da1;
              width: 5rem;
              padding: 5px;
              
              span {
                font-size: 0.8rem;
              }
            }

            button.clear {
              background-color: transparent;
              border: solid 1px #044da1 !important;

              span {
                color: #044da1 !important;
              }
            }
          }
        }
      }

      .desktop {
        display: none !important;
      }
    }

    .footer {
      .child:nth-child(3) {
        margin-top: 20px;
      }
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1249px) {
    .header {
      .logo {
        //margin-right: unset !important;
      }

      .logedin {
        .search-box {
          display: none;
        }
      }

      .login {
        //display: none !important;

        input[type="text"],
        input[type="password"] {
          width: 80px !important;
        }

        .ant-form-inline .ant-form-item {
          margin-left: 5px !important;
          margin-right: unset !important;
          width: 80px;
        }

        .submit {
          width: 60px !important;
        }

        button {
          //width: 60px !important;
        }
      }
    }
  }

  @media only screen and (min-width: 1300px) {
    .body {
      .size {
        .sider-right {
          flex: 0 0 295px !important;
          max-width: 295px !important;
          min-width: 295px !important;
          width: 295px !important;
          top: 0rem;
        }
      }
    }

    .header {
      .logedin {
        .search-box {
          display: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .account{
    .ant-menu-inline .ant-menu-item{
      font-size: 12px;
    }
  }
  body .account .layout-inner .content .container .deposit-main .deposit-title {
    font-size: 13px !important;
  }
  .screen {
    .header .mobile {
      .empty {
        width: 0 !important;
      }
      .top .logo {
        margin: 0 0 0 35%;
      }
      .login {
        button {
          // width: 90px !important;
          // padding: 0 !important;
        }
      }
    }
  }
  .ant-modal.register {
    .ant-steps {
      display: flex;
      margin: 0;

      .ant-steps-item {
        min-height: 20px;
        align-items: initial !important;

        .ant-steps-item-title {
          display: none;
        }

        .ant-steps-item-icon {
          // margin-right: 5;
        }
      }
    }

    .ant-steps > div > div > div {
      font-size: 12px;
      padding: 0;
    }

    .step .strip .content .text {
      font-size: 14px !important;
    }
  }

  .size {
    .content {
      padding: 0 !important;
    }
  }

  .account {
    .strip {
      display: inline !important;

      .title {
        font-size: 20px;
        padding-bottom: 10px;
        margin-left: 0;
      }
    }

    .ant-calendar-range-picker-separator {
      color: var(--light-black) !important;
      padding-top: 5px;
    }

    // .ant-input,
    // .ant-select {
    //   // max-width: 180px !important;
    //   max-height: 30px;
    //   font-size: 11px;
    //   padding: 0 5px;
    // }
    .ant-input {
      max-height: 30px;
      // font-size: 11px;
      // padding: 0 5px;
    }

    .ant-table-tbody {
      .ant-table-row {
        height: 35px !important;
      }
    }

    .layout-inner .content .container {
      // width: 350px;
      // min-height: 600px !important;

      .shrink-table-expanded-row .ant-table-content {
        //width: 100% !important;
      }

      .ant-pagination li {
        height: 20px !important;
        line-height: 16px !important;
        padding: 0 !important;

        a {
          font-size: 9px !important;
        }

        .table {
          max-width: 300px !important;
        }
      }
    }

    .bets {
      .table,
      .ant-table-expanded-row {
        // height: 130px;
      }

      .ant-table-fixed-right {
        z-index: 1;
      }

      .ant-table-expand-icon-th,
      .ant-table-row-expand-icon-cell {
        min-width: 20px !important;
        max-width: 20px !important;
        padding: 0 !important;

        .ant-table-row-expand-icon {
          width: 13px !important;
          height: 13px !important;
          line-height: 10px !important;
        }
      }

      .status-type {
        width: 50px !important;
        text-align: center;
      }

      .ant-calendar-picker {
        padding-bottom: 5px;
        margin-left: 0px !important;
      }

      .ant-select {
        width: 180px;
        padding: 0;

        .ant-select-selection {
          height: 28px !important;
          margin-top: 0 !important;

          .ant-select-selection__rendered {
            margin-top: 0 !important;
            line-height: 25px !important;
          }
        }
      }
    }

    .bets,
    .voucher {
      .form-container .select {
        margin-right: 0 !important;
        padding: 0;
        max-height: 30px;
        border: none !important;
        .ant-select-selection {
          max-height: 30px;
          border: 1px solidvar(--black-to-white) !important;
        }
      }
      .filters-container {
        min-width: 350px !important;
        .title {
          max-width: 40px !important;
          font-size: 10px !important;
        }
      }
      .form-container {
        min-width: 300px !important;

        .select-dark {
          width: 150px !important;
          max-height: 30px;
        }
      }

      .ant-table-thead > tr > th {
        font-size: 10px !important;
        padding: 0px 5px !important;
        height: 31px;
      }

      .ant-table-tbody > tr > td {
        padding: 0px 5px !important;
        font-size: 8px !important;
        max-height: 20px !important;

        div {
          // color:var(--black-to-white);
        }
      }
    }
    .deposit-mtn {
      .mtn-container .ant-select,
      .ant-input,
      .ant-input-number,
      .ant-input-number-input {
        // padding: 0 !important;
        // max-width: 250px !important;
        // border: 1px solidvar(--black-to-white);
        max-height: 40px;
        color:var(--black-to-white);
      }
    }
    .deposit,
    .withdrawals {
      // width: 300px !important;
      text-align: center;

      .ant-form-item-label {
        text-align: center;
      }
    }

    .personal {
      width: 300px !important;

      .ant-btn > .anticon + span {
        display: none !important;
      }

      .success-check {
        font-size: 8px !important;
        width: 20px;
        background: #2C8AF7 !important;
      }

      .text {
        color:var(--black-to-white);
        font-size: 10px;
      }

      .ant-form-item {
        display: flex;
        justify-content: space-between;
      }

      .ant-form-item-label {
        width: 80px;
      }

      .ant-form-item-control-wrapper {
        width: 180px;
      }

      .ant-input-group {
        width: 180px;
      }

      .ant-form-item-label > label {
        font-size: 11px !important;
      }

      .ant-btn {
        height: 28px !important;
        font-size: 10px !important;
        margin-left: 0px;
        padding: 0 !important;
      }

      .ant-select {
        width: 180px;
        padding: 0;

        .ant-select-selection {
          height: 28px !important;
          margin-top: 0 !important;

          .ant-select-selection__rendered {
            margin-top: 0 !important;
            line-height: 25px !important;
          }
        }
      }
    }
  }

  .add-phone-modal {
    .ant-modal-content .ant-modal-body {
      padding: 10px;

      .prefix-input {
        width: 50px;
      }

      .seperator-input {
        width: 55px;
      }

      .number-input {
        width: 125px;
      }
    }

    .ant-btn {
      width: 100% !important;
    }
  }
}

@media only screen and (max-width: 950px) {
  body {
    #side-menu{
      padding: 20px 2px 20px 2px;
    }
    .sec-serach-continer {
      /* position: relative; */
      display: flex;
      justify-content: center;
    }
    .sec-search-bar {
      width: 98%;
    }
    .sec-search-icon {
      display:none
    }
    .events{
      .row{
        .anticon{
          svg{
            filter:brightness(100);
          }
        }
      }
    }
    // background-color: var(--white) !important;
    .main-image{
      margin: 0 !important
    }
    .ant-drawer.mobile .ant-drawer-content-wrapper .ant-drawer-content {
      // background-color: var(--white);
    }
    .slot-machines {
      min-height: 800px;
      .slots-game {
        .game-symbols {
          display: none;
        }
      }
    }
    .voucher-modal {
      .voucher-details {
        .details {
          //padding-bottom: 25px;
          display: block !important;
        }
      }
    }
    .account .layout-inner .content .container .deposit-main {
      
      .deposit-icon {
        // margin: 20px 0 0 0;
        height: 100%;
        width: 30%;
      }
      .deposit-title {
        // color:var(--black-to-white);
        font-size: 15px;
        margin-left: 5%;
      }
      .menu {
        display: block;
        margin-left: 20px;
      }
    }
  }

  .app {
    display: flex;
    flex-direction: column;
    background-color: var(--light-black);
  }

  .slot-machines .items {
    .gutter-box {
      margin: 8px 0 !important;
    }
    .gutter-box:hover {
      box-shadow: none;
    }
  }

  .ant-popover-inner-content .login,
  .account,
  .details,
  .logedin {
    button {
      background: #054da1 !important;
      background-color: #054da1 !important;
      color:var(--white);
      span {
        color: var(--white) !important;
      }
    }
  }

  .register {
    .ant-modal-body .ant-btn-primary {
      background: #054da1 !important;
    }
  }
  .back {
    span {
      color:var(--white) !important;
    }
    color:var(--white) !important;
  }
  // .popup-login-mobile {
  //     color:var(--white) !important;
  //       button span div{
  //          color:var(--white) !important;
  //      }
  // }
  button,
  span {
    color:var(--white) !important;
  }

  .sport-types {
    .swipe {
      background-color: var(--white) !important;
      .sport-type {
        cursor: pointer;
        .selected .icon {
          filter: brightness(0) invert(1) !important;
        }
        .box {
          opacity: 0.7;
          .icon {
            margin-bottom: 5px;
            img {
              filter: invert(45%) sepia(20%) saturate(392%) hue-rotate(166deg)
                brightness(86%) contrast(91%) !important;
              width: 25px;
              height: 25px;
            }
          }

          .title {
            color: gray !important;
          }
        }

        .box:hover {
          color: #40a9ff;
        }

        .box.selected {
          background-color: #060a4a !important;
          font-weight: 500;
          opacity: 1;
          .title {
            color: var(--white) !important;
          }
        }
      }
    }
  }
  .search-popover {
    .light-on {
      color: #0e2842 !important;
    }
    .ant-popover-inner .ant-popover-title {
      color: #0e2842 !important;
    }
  }
  /********** Ant Menu **********/
  .ant-menu-item {
    // background: darken(#054da1, 30%) !important;
  }

  .ant-menu {
    background: var(--white) !important;
    border: none !important;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    border: unset;

    .ant-menu-submenu {
      .title {
        .icon {
          img {
            filter: brightness(0) invert(1);
            width: 16px;
            height: 16px;
            margin-right: 10px;
          }
        }
      }

      .ant-menu-submenu-title {
        opacity: 1;
        // border-bottom: solid 1px #344362 !important;
        margin: unset !important;
        padding: unset !important;
        background-color: darken(#054da1, 5%) !important;
        padding-left: 10px !important;

        .nav-country {
          color: #e9f3ff !important;
        }
      }
    }

    .ant-menu-submenu.ant-menu-submenu-open {
      .ant-menu-submenu-title {
        color: var(--white);
      }

      .ant-menu-sub {
        .ant-menu-item {
          opacity: 0.7;
          border-bottom: solid 1px var(--light-black) !important;
        }
      }

      ul {
        background-color: #054da1 !important;
      }

      .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px var(--white);
        background-color: var(--white);
      }

      .ant-menu-sub.ant-menu-inline::-webkit-scrollbar {
        width: 0px;
        background-color: var(--white);
      }

      .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: #054da1;
        border: solid 0px var(--white);
      }
    }
  }

  .sub-menu-mobile {
    //  display: flex !important;
    .ant-menu-submenu-title {
      min-height: 40px;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .text {
          line-height: 20px;
        }
        .anticon {
          height: 15px;
        }
      }
    }
    background-color: #033570 !important;
    //   margin: 20px 0px 0px 5px;
    display: flex;
    .ant-menu {
      margin: 0;
    }
    .ant-menu-item {
      border-bottom: solid 1px #033570 !important;
      // margin: unset !important;
      background: #044188 !important;
      padding: 0 10px !important;
      font-size: 9px;

      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px;
        .text {
          line-height: 20px;
        }
      }
    }
    .ant-menu-submenu::after,
    .ant-menu-submenu::before {
      content: none;
    }
  }

  .flat-item {
    opacity: 0.7;
    background-color: #172a40;
  }

  /*----------------- Virtual Menu -----------------*/
  .virtual-menu {
    .ant-menu-submenu {
      background-color: #172a40 !important;
      margin-bottom: 1px !important;
    }

    .ant-menu-item-selected {
      background-color: #054da1 !important;
    }
  }

  /*----------------- My Account Menu -----------------*/
  .account-menu {
    .ant-menu-item:not(:last-child) {
      // border-bottom: solid 1px #054da1 !important;
    }

    .ant-menu-item-selected {
      background-color: #054da1 !important;
    }
  }

  .account-menu-mobile {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    margin: 0;
    min-height: 40px;
    .ant-menu-submenu .ant-menu-submenu-title {
      padding-left: 0 !important;
    }
    .buttons-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 5px 0;
      width: 100%;
      div {
        opacity: 0.7;
        line-height: 15px;
      }
    }
    .sub-menu-mobile {
      .ul .ant-menu-item {
        border-bottom: solid 1px #033570 !important;
        margin: unset !important;
        background: #044188 !important;
        padding: 0 10px !important;
      }
    }
    li {
      width: inherit;
      opacity: 1;
      border-bottom: solid 1px #033570 !important;
      margin: unset !important;
      background-color: #044188 !important;
      font-size: 9px;
      .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px;
        .text {
          line-height: 20px;
        }
        .anticon {
          margin: 0;
        }
      }
    }
  }

  .bet-slip {
    // .ant-drawer.mobile{
    //   .ant-drawer-content-wrapper{
    //     padding: 0 !important;
    //   }
    // }
    .success span {
      color: var(--white) !important;
    }
    //Top bar
    .ant-tabs-top-bar {
      // background-color: #054da1 !important;

      .ant-tabs-tab {
        opacity: 0.5;
        color: var(--white) !important;
      }

      .ant-tabs-tab-active {
        opacity: unset;
      }

      .ant-tabs-ink-bar {
        // width: 115px !important;
        background-color: var(--white);
      }
    }

    .bet-code {
      border-bottom: solid 1px var(--light-black);
      background-color: var(--white);

      .text,
      .code,
      .close {
        color: #182438;
      }
    }

    .tabs-betslip {
      //Top bar
      .ant-tabs-top-bar {
        background-color: var(--white);
        border-bottom: solid 1px #182438;

        .ant-tabs-tab {
          color: var(--light-black);
          opacity: 0.5;
        }

        .ant-tabs-ink-bar {
          background-color: #0e7eff;
        }
      }
    }

    .load-code {
      .top {
        background-color: #054da1 !important;
      }

      .content {
        .fields {
          .button {
            flex: 0.5 1;

            .load-betcode {
              span {
                color: var(--white) !important;
              }
            }
          }
        }
      }
    }

    .content {
      // background-color: var(--white) !important;

      .events {
        background-color: unset;
        color: #182438 !important;

        .event {
          // border-bottom: solid 1px var(--light-black);
        }

        .error-message {
          .ant-btn::before {
            background: none !important;
            transition: none !important;
          }

          .clear-bet-interval {
            color:var(--white);
          }
        }

        .row-amount {
          .currency {
            color: #182438;
          }
        }

        .row-total-possible {
          div {
            color:var(--black-to-white);
          }
        }

        .row {
          padding: unset;
          color: #182438;

          .icon {
            img {
              filter: var(--white);
              width: 18px;
              height: 18px;
            }
          }

          .text {
            color: #182438;
          }

          .teams {
            color: #182438;

            .live {
              color: var(--white);
            }
          }

          .team {
            color: #182438;
          }

          .odd-name {
            color: #182438;
          }

          .odd-value {
            color: #182438;
          }

          .close {
            color: #182438;
          }

          .possible {
            color: #182438;
          }
        }

        .placebet {
          .row {
            .placebet-btn {
              span {
                color: var(--white) !important;
              }

              .ant-btn::before {
                background: none !important;
                transition: none !important;
              }

              .clear-bet-interval {
                color:var(--white);
              }
            }
          }

          .stake {
            .text {
              color: #182438;
            }

            .total {
              color: #182438;
            }
          }

          .total-odds {
            color: #182438;
          }

          .total-odds-value {
            color: #182438;
          }

          .total-possible {
            color: #182438;
          }

          .total-possible-value {
            color: #182438;
          }

          .terms {
            color: #182438;

            a {
              color: #182438;
            }
          }

          .amount {
            .currency {
              color: #182438;
            }
          }
        }
      }
    }

    .notice {
      background-color: var(--white)8dc;
    }
  }
  .drawer-details {
    max-width: 230px;
  }
  .mobile-voucher-details {
    .ant-tabs-nav-container {
      border: none;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      width: 100%;
      font-size: 11px;
      background-color: #044da1;
      div {
        color:var(--white) !important;
        font-weight: bold;
        display: flex;
        justify-content: center;
      }

    }
    div {
      color:var(--black-to-white);
    }
    .details {
      display: block;
    }
    .title {
      display: flex;
      justify-content: space-between;
      // width: 100%;
      padding: 0 5px;
    }
    .value {
      padding: 0 5px;
    }
    .qr-code {
      display: flex;
      justify-content: center;
      padding: 20px 0;
    }
  }
  .mobile-table-details {
    .ant-tabs-bar {
      margin: 0;
    }
    .ant-tabs-nav-container {
      border: none;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      width: 100%;
      font-size: 11px;
      background-color: #044da1;
      div {
        color:var(--white) !important;
        font-weight: bold;
        display: flex;
        justify-content: center;
      }
    }
    div {
      color:var(--black-to-white);
      // font-size: 12px;
    }
    .tab-table {
    }

    .bet-lines {
      .row {
        padding: 5px 10px 0 10px;
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        .possible-winnings {
          font-size: 14px;
          color:var(--black-to-white);
        }
        .winnings-odds {
          font-size: 12px;
          color:var(--black-to-white);
        }
        .text {
          font-size: 10px;
        }
      }
      .row:last-child {
        justify-content: center;
      }
      .status {
        width: 230px;
        min-height: 25px;
        border-radius: 5px;
        margin: 5px 0;
        font-size: 12px;
        text-align: center;
        padding: 3px;
      }
    }

    .events {
      border-radius: unset;
      background-color: unset;
      padding-bottom: unset;
      color:var(--white) !important;
      .event {
        padding: 5px 10px 5px 10px;
        border-bottom: solid 1px var(--light-black);
        display: flex;
        flex-direction: column;
        align-items: start;
        div {
          padding: 1px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .row {
          display: flex;
          justify-content: start;
          flex-direction: row;
          width: 100%;
          align-items: center;
          height: 15px;
          .status {
            width: 14px;
            border-radius: 50%;
            height: 14px;
            padding: 0px;
          }

          .icon {
            width: 13px;
          }
          .cell {
            //  min-width: 40px
          }
          .cell:first-child {
            width: 15px;
            min-width: 20px;
          }
          .cell:nth-child(2) {
            width: 170px;
          }
        }

        .header {
          font-weight: bold;
          font-size: 10px;
        }
        .text {
          font-size: 9px;
        }
      }
    }
  }
  .settings-popover {
    .ant-popover-title {
      color: #252525 !important;
    }

    .betslip-settings-container {
      .setting {
        div {
          color:var(--black-to-white);
        }
      }

      .currency-setting {
        .default-currency {
          width: 87.2px;
        }

        font-weight: bold;
        color: #252525 !important;

        svg {
          font-weight: bold;
          color: #252525 !important;
        }
      }

      .default-bet-amount {
        width: 87.2px !important;
        height: 30px !important;
        border-radius: 4px;
        border: solid 1px #dee2e8;
        margin: 0 0 0 10px;
        font-weight: bold;
        font-size: 16px;
        color: #252525;
      }

      .ant-switch-checked {
        background-color: #0b995c !important;
      }

      .betslip-switch {
        width: 60px;
        height: 30px;
        border-radius: 15px;
      }

      .ant-switch::after {
        width: 20px;
        height: 20px;
        top: 4px;
        margin: 0 0 0 5px;
      }

      .ant-switch-checked::after {
        width: 20px;
        height: 20px;
        top: 4px;
        left: 48px;
      }
    }

    .tabs-top > {
      //Top bar
      .ant-tabs-top-bar {
        //border: solid 1px red;

        .ant-tabs-tab,
        .ant-tabs-ink-bar {
          margin: 0;
          width: 80px !important;
        }

        .ant-tabs-tab:last-child {
          margin-left: 30px;
        }
      }
    }

    .content {
      .events {
        .event {
          padding: 10px;
        }

        .placebet {
          padding: 10px;
        }
      }
    }
  }

  .mobile-bet-slip {
    .bet-slip {
      display: flex;

      .content .events .placebet {
        .amount-up-down .input {
          width: 50px !important;
        }

        .total-possible-value {
          font-size: 14px;
        }
      }

      .tabs-top > {
        //Top bar
        .ant-tabs-top-bar {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }

      .load-code {
        .top {
          border: none;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }

  .top-bets {
    padding: 0 3.2vw 0 3.2vw;
    .ant-carousel .card .event:nth-child(1) {
      background-color: #054da1;
      background-image: none !important;
    }
    .top {
      .title {
        // color:var(--black-to-white) !important;
        
      }
      .container {
        .bt {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          background-color: #32425c;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 25px;
          cursor: pointer;
        }
      }
    }
  }
  .fixture .layout-inner .content .container {
    background-color: transparent !important;
    .left,
    .right {
      .title {
        color: #182e49 !important;
      }
    }
    .list .box {
      background-color: #054da1 !important;
    }
  }
  .screen .body .size .content {
    padding: 0 !important;
  }
  .tabs-events .ant-tabs-bar,
  .ant-collapse-header {
    background-color: #060a4a !important;
  }
  .mtop-10 {
    margin-top: -1px;
  }
  .sport {
    .buttons-ods button.selected {
      border-top: solid 2px gray !important;
      border-bottom: solid 2px gray !important;
      background-color: gray !important;
    }
    .content.ant-layout-content > div {
      color: var(--black-to-white) !important;
    }
    .buttons-ods button {
      opacity: 0.5;
      border-radius: 0px;
      border-top: solid 2px var(--light-black);
      border-bottom: solid 2px var(--light-black);
      background-color: var(--light-black);
      font-size: 14px;
      .container .value,
      .name {
        color:var(--white);
      }
    }
    .buttons-ods button.selected {
      opacity: 1;
      border-top: solid 2px var(--dark-blue-to-dark-gray) !important;
      border-bottom: solid 2px var(--dark-blue-to-dark-gray) !important;
      background-color: var(--dark-blue-to-dark-gray) !important;
    }
    .upcoming .title {
      color: #054da1 !important;
    }
    span {
      color: var(--white) !important;
    }
    .events {
      background-color: var(--light-black) !important;
      .header div {
        color:var(--white) !important;
      }
    }
    .row {
      // border-bottom: solid 2px #e9f3ff !important;
      .col {
        .bet-types i {
          color: #182e49 !important;
        }
        .stats {
          // filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(42deg)
          //   brightness(97%) contrast(103%) !important;
        }
        .plus .icon {
          color:var(--white) !important;
        }
        .event .details .teams {
          // color: #182438 !important;
          .team {
            color:var(--white) !important;
          }
        }
      }
      .bet-num,
      .date,
      .league .text,
      .timer,
      .league {
        color:var(--white) !important;
      }
    }

    .dd {
      .event .bg-1 {
        background-image: none !important;
      }
      .event .container div,
      .tabs-events .ant-tabs-bar {
        background-color: #054da1;
      }
      .groups {
        background-color: var(--single-match-pages-background);
        .group .title {
          // color: #182e49;
        }
      }
      .tabs-events .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-ink-bar {
        background-color: var(--white);
      }
    }
  }

  .document .layout-inner .content .container {
    // background-color: #054da1 !important;
    p span {
      color: var(--white) !important;
    }
  }
  .screen .footer {
    background-color: var(--light-black);
  }
  .screen .banner {
    margin-bottom: 0px;
  }
}
.ant-tabs-nav-container{
  margin-bottom: -3px;
}


@media only screen and (max-width: 800px){
  .ant-tabs-nav-container{
    img {
      height: auto;
      width: 100%;
    }
  }
}


@media only screen and (max-width: 1000px){

  .screen{

    header{
          position: sticky;

    }
    .body {
      .main-image{
        padding: 0 6vw 0 6vw;
      }
      

      // Unsolved
      #bits-filter{
        width: auto;
        padding: 0 6vw 0 6vw;
      }
      // END OF Unsolved

      #bits-filter-rows{
        padding: 0 6vw 0 6vw;

      }

      .size {
        padding: 0;
      }
    }
  }
  
}