.fixture {

	.sider-left,
	.sider-right {
		display: none !important;
	}

	.layout-inner {
		display: flex;
		justify-content: center;

		.content {
			max-width: 1024px;

			.container {
				border-radius: 4px;
				background-color: var(--light-black);
				padding: 20px;
				display: flex;

				.left,
				.right {
					width: 100%;
					>.title {
						font-size: 20px !important;
						font-weight: bold;
						margin-bottom: 15px !important;
						color: var(--white);
					}
				}

				.right {
					margin-left: 50px;
				}

				.list {

					.col1 {
						margin-right: 15px;
					}

					.box {
						cursor: pointer;
						background-color: #044da1;
						width: 100%;
						max-width: 100%;
						min-height: 60px;
						padding: 0 12px 0 12px;
						margin-bottom: 10px;
						border-radius: 4px;
						display: flex;
						flex-direction: row;
						align-items: center;

						.icon {
							font-size: 35px;
							line-height: 0;
						}

						.title {
							font-size: 16px !important;
							font-weight: bold;
							color: var(--white) !important;
							margin-bottom: unset;
						}

						.text {
							opacity: 0.6;
							font-size: 14px;
							font-weight: bold;
						}
					}
				}

			}
		}

		@media only screen and (max-width: 650px) {
			.content {

				.container {
					flex-direction: column;
					align-items: center;
					justify-items: center;

					.right {
						margin-top: 30px;
						margin-left: unset;
					}

					.list {
						.box {
							//max-width: 300px;
						}
					}
				}
			}
		}
	}
}