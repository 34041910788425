.form {
	width: 100%;
	.validation-meter{
		div{
			color:black;
		}
		.alert{
			.icon .inner{
				display: flex;
			align-items: center;
			}
			
			.success{
				background-color: lightgreen;
			}
			svg{
				width: 12px;
			}
		}
	}
	.ant-input {
		color: var(--white);
	}

	.ant-select-arrow i svg {
		color: var(--white) !important;
	}

	.ant-input-disabled {
		background-color: lightgray !important;
	}

	.ant-select-disabled .ant-select-selection  {
		background-color: lightgray;
	}

	.ant-form-item {
	//	margin-bottom: 10px;

		.ant-form-item-label {
			text-align: unset;

			label {
				color: var(--white);
				font-size: 14px;
				font-weight: bold;
			}
		}

		.ant-input-group-addon {
			padding: unset;
			border: unset;

			div {
				color: red;
			}

			.ant-btn {
				border-top-left-radius: unset;
				border-bottom-left-radius: unset;
			}
		}

		.ant-form-item-control-wrapper {
			.ant-select {

				.ant-select-selection--single {
					height: 40px;

					.ant-select-selection__rendered {
						margin-top: 4px;

						.ant-select-selection-selected-value {
							color: #182438;
						}
					}
				}
			}
		}

		.ant-select-arrow .ant-select-arrow-icon svg {
			color: #c1c1c1;
		}
	}

	.ant-form-item-with-help {
		margin-bottom: 10px !important;
	}

	.captcha {
		border: solid 0px red;
	}
}

.form-white {
	.ant-form-item {
		.ant-form-item-label label {
			color: #182438;
		}
	}
}

.phone-number-item{
	
	.ant-form-item-control{
	line-height: 0;	
	}
	
	.seperator-input{
			width: 55px;
			pointer-events: 'none';
			background-color: var(--white);
			font-size: 40px;
			border-left:  0 !important;
			border-right: 0 !important;
			text-align: center;
			cursor: auto;

	}
	.prefix-input{
		border-right: 0 !important;
		width: 70px;
	  text-align: center;
	 
	}
	.number-input{
		
		 width: 140px;
	     border-left: 0 !important;
		 text-align: center;
		 background-color: white !important
	}

	.country-select{
		width: 110px !important;
		.ant-select-selection{
			height: 40px !important;
		}
	}
	.ant-input{
		-webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255)!important;
	}
	
}

.phone-number-view{
	.ant-input-disabled{
		background-color: lightgray;
		//	color: gray;
		//background-color: var(--white) !important;
	}

	.ant-input-group-wrapper .ant-input-group-addon{
		padding: 0 15px;
	}
}
