.bet-slip {
  .teams{
    .bet-slip-teams-container{
      flex-wrap: wrap;
    }
  }


 
  .load-code-mobile-view{
    display: none;
  }

.default-currency{
  top: 0.1rem;
  left:13rem;
  z-index: 1;
}
.ant-select-selection{
  background-color: transparent;
  border: none;
}
@media only screen and (max-width:1300px){
  .default-currency{
    left:9rem
  }
}
@media only screen and (max-width: 1000px){

  .default-currency{
    left:60vw !important;

  }


}
@media only screen and (max-width: 600px){

  .default-currency{
    left:53vw !important;

  }


}
@media only screen and (max-width: 360px){

  .default-currency{
    left:48vw !important;

  }


}

.ant-tabs-ink-bar {
  .ant-tabs-ink-bar-animated {
    width: 0px;
}
}

.sport-images{
  background-color: var(--betslip-sport-images);
  padding: 0 15px 0 15px;
}
// .tabs-top > {
//   .ant-tabs-top-bar{
//     .ant-tabs-tab-active{
//       .ant-tabs-ink-bar{
//         height: 0px;
//       }
//     }

//   }

// }
// .tabs-top {
//   .ant-tabs-top-bar {
//     .ant-tabs-tab-active {
//       display: none; 
//     }
//   }
// }
.ant-tabs-ink-bar{
height: 2px;
}
// .ant-tabs-bar{
//   border-bottom: 0px;
// }

.ant-tabs-nav{
  .ant-tabs-tab{
    margin: 0px;
    width:5.9rem
  }
}
@media only screen and (max-width:1300px){
  .ant-tabs-nav{
    .ant-tabs-tab{
      width:4.5rem
    }
  }
}
@media only screen and (max-width:600px){
  .load-code-mobile-view{
    padding: 10px 15px !important;

  }
    .content{
      .events{
        .placebet{
          padding: 10px 15px !important;

        }
        .event{
          padding: 10px 15px !important;
        }
      }

    }
   
  .load-code{
    display: none;
  }
  .load-code-mobile-view{
    display: block;
    margin-top: 20px;
    #input-code-mobile-view{
      width: 43vw;
      display:inline-block
    }
    #submit-code-mobile-view{
      width: 21vw;
      display:inline-block
    }
    // {{width:"37vw" , display:"inline-block"}}
    // {{width:"19vw", display:"inline-block" }}
    .ant-btn{
      height: 40px;
    }
    fieldset{
      padding: 20px 10px;
      border:2px solid #354361;
      border-radius: 5px;
    }
    legend{
      font-size: 14px;
      width: auto;
      margin-left: 5px;
    }
    
  }
.tabs-betslip {
  .ant-tabs-top-bar {
    .ant-tabs-tab{
      width: 25vw; 
    }
  }
}
}
@media only screen and (max-width:450px){
  .load-code-mobile-view{
    #input-code-mobile-view{
      width: 39vw;
      display:inline-block
    }
    #submit-code-mobile-view{
      width: 22vw;
      display:inline-block
    }
    // {{width:"37vw" , display:"inline-block"}}
    // {{width:"19vw", display:"inline-block" }}
    
  }

}
@media only screen and (max-width:380px){
  .load-code-mobile-view{
    #input-code-mobile-view{
      width: 36vw;
      display:inline-block
    }
    #submit-code-mobile-view{
      width: 19vw;
      display:inline-block
    }
    // {{width:"37vw" , display:"inline-block"}}
    // {{width:"19vw", display:"inline-block" }}
    
  }

}
@media only screen and (max-width:360px){
  .load-code-mobile-view{
    #input-code-mobile-view{
      width: 34vw;
      display:inline-block
    }
    #submit-code-mobile-view{
      width: 21vw;
      display:inline-block
    }
    // {{width:"37vw" , display:"inline-block"}}
    // {{width:"19vw", display:"inline-block" }}
    
  }

}
// the following styling of the bit slip header is an alternative of the tab  header itself, you can find a class in this page called '.ant-tabs-tab-active',the display: none property is responsible of hidding the origin header, to switch back to the original styling comment .bit-slip-header styling and delete the div in betslip component, and comment the display: none inside '.ant-tabs-tab-active'
.bit-slip-header{
  padding:20px 0px 15px 0px;
  margin-bottom: 0px !important;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  width:115px;
  color:var(--white);
  position: absolute;
  z-index: 1;

  
}
#default-currency-container{
  position: absolute;
  // .ant-select-arrow .ant-select-arrow-icon svg {
  //   color:var(--white);
  //   /* left: 40px; */
  //   position: relative;
  //   left: 8px;
  //   bottom: 2px;
  //   width: 20px;
  //   height: 14px;
  // }
  .ant-select-arrow .ant-select-arrow-icon svg {
    display: none;
  }
}
.ant-select-selection--single {
  position: absolute !important;

}

.events .bet-slip-row-container .event:not(:last-child){
  
  border-bottom: solid 1px #3A4864;

}
#split-bitslip{
  border-bottom: solid 1px #3A4864;
  opacity: 0.25;
}
.ant-tabs-bar{
  border-bottom: none !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

// hide borders of navs
// .tabs-top  .ant-tabs-nav-wrap .ant-tabs-ink-bar{
//   height: 0px;
// }

// .ant-tabs-ink-bar{
//   height: 0px;
// }
// .tabs-top  .ant-tabs-nav-scroll{
//   display: none;
// }
// .betslip-header{
//   display: none
// }
}

// @media only screen and (min-width: 1300px) {
//   .ant-select-selection{
//     left:0rem;
//   }
//   }
.bet-slip {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  border-radius: 4px;
  // margin-left: 10px;

  .tabs-top > {
    .ant-tabs-bar {
      margin: unset;
    }

    //Top bar
    .ant-tabs-top-bar {
      border: none;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      height: 60px;
      width: 100%;
      background-color: var(--blue);

      .ant-tabs-tab {
        padding: 20px 0px 15px 0px;
        margin-bottom: 0px !important;
        width: 115px;
        text-align: center;
        font-size: 16px;
        opacity: 0.5;
        color: var(--white);
        text-transform: uppercase;
      }

      .ant-tabs-tab-active {
        display: none;
        opacity: unset; 
      }

      .ant-tabs-ink-bar {
        background-color: var(--white);
      }

      .ant-tabs-nav-scroll {
        display: flex;
        flex-direction: row;
        
      }
//       .ant-tabs-nav-scroll .ant-tabs-nav::after{
// border: 10px solid;   
//    }

      // .ant-tabs-nav-scroll {
      //   display: none
        
      // }
    }
  }

  .delete-all {
    color:var(--white-to-red);
    padding: 10px 15px 0 15px;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer;
  }

  .bet-code {
    border-bottom: solid 1px var(--light-black);
    background-color: var(--white);
    padding: 10px;
    text-align: center;

    .text,
    .code,
    .close {
      color: #182438;
    }

    .text {
      font-size: 14px;
    }

    .code {
      font-size: 22px;
      font-weight: bold;
    }

    .close {
      position: absolute;
      right: 0;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      text-align: right;
      cursor: pointer;
    }
  }

  .tabs-betslip {
    .ant-tabs-bar {
      margin: unset;
    }

    //Top bar
    .ant-tabs-top-bar {
      border: none;
      height: 40px;
      border-bottom: solid 1px var(--light-black);

      .ant-tabs-tab {
        padding: 12px 0 7px 0;
        margin-bottom: 0px !important;
        text-align: center;
        font-size: 14px;
        color:var(--white);
        opacity: 0.5;
      }

      .ant-tabs-tab-active {
        opacity: unset;
      }

      .ant-tabs-ink-bar {
        background-color:var(--white);
      }

      .ant-tabs-nav-scroll {
        display: flex;
        flex-direction: row;
        background-color: var(--dark-blue-to-white);
        color:var(--white);
      }
    }
  }

  .load-code {
    margin-top: 30px;

    .top {
      border: none;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      height: 30px;
      // background-color: var(--blue);
      font-size: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
    }

    .content {
      padding: 15px !important;
      background-color: var(--dark-blue-to-white);
      border-radius: 5px !important;

      .fields {
        display: flex;
        align-items: center;

        .code {
          flex: 1 1;
          input {
            height: 35px !important;
            font-size: 12px;
          }
        }

        .button {
          margin-left: 15px;
          flex: 0.5 1;
          button {
            height: 35px;
          }
        }
      }

      .notice {
        margin-top: 20px;
      }
    }
  }

  .multiple {
    border: solid 1px red;
  }

  .content {
    padding: unset !important;
    width: 100% !important;
    background-color: var(--dark-blue-to-white);

    .events {
      font-size: 13px;
      border-radius: unset;
      background-color: unset;
      padding-bottom: unset;
      color:var(--white) !important;
      .bonus-btn-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        height: 25px;
        .bonus-btn {
          width: 100px;
          font-size: 13px;
          margin: 0 5px !important;
        }
      }
      .event {
        padding: 10px 15px 10px 15px;
      }

      .error-message {
        padding: 20px 20px 20px 20px !important;
        .success-bet {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          div {
            padding: 10px;
          }
          .ant-btn::before {
            background: none !important;
            transition: none !important;
          }
          .anticon-loading {
            margin-left: -17px !important;
            font-size: 22px;
            padding-right: 15px;
          }
          .clear-bets-btn {
            width: 140px;
            height: 40px;
            border-radius: 5px;
            background-color: #0b995c;
            display: flex;
            align-items: center;
          }
          .clear-bet-interval {
            color:var(--white);
            font-size: 12px;
            left: 10px;
            position: absolute;
          }
        }
      }

      .message,
      .error-message {
        font-weight: bold;

        .success {
          background-color: unset;
          justify-content: center;
          font-size: 20px;
        }
      }
      .row-amount {
        border: none;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .currency {
          color: #182438;
          font-size: 14px;
          font-weight: bold;
          padding: 5px;
        }
      }
      .row-total-possible {
        display: flex;
        justify-content: flex-end;
        div {
          padding: 5px;
        }
      }
      // .bin-icon{
      //   filter: var(--convert-brightness);
      // }

      .row {
        padding: unset;
        color:var(--white);
        font-size: 14px;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .icon {
          min-width: 25px;
          margin-right: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          img {
            filter: var(--black-to-white);
            width: 18px;
            height: 18px;
          }
        }

        .sport-icon {
          width: 23px;
        }

        .text {
          width: 100%;
          display: flex;
          color:var(--white);
          font-size: 12px;
          text-align: center;
          font-weight: 300;
        }

        .odds {
          font-size: 14px;
        }

        .teams {
          color:var(--white);
          margin-right: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 13px;

          .live {
            background-color: #e82f2f;
            color: var(--white);
            font-size: 10px;
            font-weight: bold;
            padding: 0px 1px 0px 1px;
          }

        }

        .team {
          color:var(--white);
          font-weight: bold;
          font-family: "Roboto Black";
        }

        .odd-name {
          color:var(--white);
          //font-weight: bold;
        }

        .odd-value {
          color:var(--white);
          font-weight: bold;
          margin-left: auto;
          font-family: "Roboto Black";
        }

        .odd-value.up {
          border-top: solid 2px #2C8AF7;
          animation: blink-border-top-betslip normal 0.5s 4 ease-in-out forwards;
        }

        .odd-value.down {
          border-bottom: solid 2px #e82f2f;
          animation: blink-border-bottom-betslip normal 0.5s 4 ease-in-out
            forwards;
        }

        @keyframes blink-border-top-betslip {
          0% {
            border-top: solid 2px #2C8AF7;
          }

          50% {
            border-top: solid 2px transparent;
          }

          100% {
            border-top: solid 2px #2C8AF7;
          }
        }

        @keyframes blink-border-bottom-betslip {
          0% {
            border-bottom: solid 2px #e82f2f;
          }

          50% {
            border-bottom: solid 2px transparent;
          }

          100% {
            border-bottom: solid 2px #e82f2f;
          }
        }

        .close {
          color:var(--white);
          font-weight: bold;
          cursor: pointer;

          i svg {
            width: 20px;
            height: 20px;
          }
        }

        .possible {
          margin-left: auto;
          color:var(--white);
          font-weight: bold;
        }

        .buttons {
          width: 100%;
          flex-direction: column;

          button:not(:last-child) {
            margin-bottom: 10px !important;
          }
        }
      }

      .row:not(:last-child) {
        margin-bottom: 1px;
      }

      .row:last-child {
        //margin-top: 10px;
      }

      .placebet {
        padding: 20px 15px 5px 15px;

        .row {
          margin: 0px 0px 5px 0px !important;
          .placebet-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            justify-content: center;
            div {
              padding: 10px;
            }
            .ant-btn::before {
              background: none !important;
              transition: none !important;
            }
            .anticon-loading {
              font-size: 30px;
              position: absolute;
              left: 55px;
              top: 5px;
            }
            .clear-bets-btn {
              width: 140px;
              height: 40px;
              border-radius: 5px;
              display: flex;
              align-items: center;
            }
            .clear-bet-interval {
              color:var(--white);
              font-size: 16px;
              left: 40px;
              position: absolute;
            }
          }
        }

        .stake {
          // margin-bottom: 10px;
          display: flex;
          align-self: center;
          

          .text {
            color:var(--white);
          }

          .total {
            color:var(--white);
          }
        }

        .total-odds {
          color:var(--white);
        }

        .total-odds-value {
          color:var(--white);
        }

        .total-possible {
          color:var(--white);
          display: flex;
          align-self: center;
        }

        .total-possible-value {
          color:var(--white);
          font-family: "Roboto Black";
          font-weight: bold;
          font-size: 20px;
          text-align: end;
        }

        .terms {
          margin-top: 7px;
          margin-bottom: 12px;
          font-size: 12px;
          color:var(--white);

          a {
            color:var(--white);
            font-weight: bold;
            text-decoration: underline;
          }
        }

        .amount {
          display: flex;
          align-content: center;
          justify-content: center;
          .anticon svg {
            display: inline-block;
            width: 8px;
            filter: brightness(0);
            opacity: var(--high-to-very-low-opacity);
        }

          .currency {
            margin-left: 10px;
            display: flex;
            align-items: center;
            color:var(--white);
            font-weight: bold;
          }
        }
      }
    }
  }

  .notice {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%;
    // background-color: var(--white);

    .alert:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  .images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.betslip-settings {
  ant-tabs-bar {
    margin-top: 1rem !important;
  }
  i {
    position: absolute;
    z-index: 1;
    right: 5px;
    top: 20px;
    font-size: 25px;
  }
}

.settings-popover {
  width: 230px;
  .ant-popover-content {
    position: sticky;
    top: 210px;
  }
  .ant-popover-arrow {
    right: 8px !important;
  }
  .ant-popover-title {
    font-weight: bold;
    height: 43px;
    align-items: center;
    display: flex;
    color: #252525;
  }
  .betslip-settings-container {
    .setting {
      div {
        font-size: 14px;
        color:var(--black-to-white);
      }
      display: flex;
      justify-content: space-between;
      padding: 10px 0px;
    }
    .currency-setting {
      .default-currency {
        width: 87.2px;
      }
      font-weight: bold;
      color: #252525;
      svg {
        font-weight: bold;
        color: #252525;
      }
    }
    .default-bet-amount {
      width: 87.2px !important;
      height: 30px !important;
      border-radius: 4px;
      border: solid 1px #dee2e8;
      margin: 0 0 0 10px;
      font-weight: bold;
      font-size: 16px;
      color: #252525;
    }

    .ant-switch-checked {
      background-color: #0b995c !important;
    }

    .betslip-switch {
      width: 60px;
      height: 30px;
      border-radius: 15px;
    }
    .ant-switch::after {
      width: 20px;
      height: 20px;
      top: 4px;
      margin: 0 0 0 5px;
    }
    .ant-switch-checked::after {
      width: 20px;
      height: 20px;
      top: 4px;
      left: 48px;
    }
  }
}

@media only screen and (max-width: 1300px) {
  .bet-slip {

    .ant-tabs-nav-wrap{
      margin-bottom: 0px;
    }
    .tabs-top > {
      //Top bar
      .ant-tabs-top-bar {
        //border: solid 1px red;

        .ant-tabs-tab,
        .ant-tabs-ink-bar {
          margin: 0;
          width: 80px !important;
        }

        .ant-tabs-tab:last-child {
          margin-left: 30px;
        }
      }
    }

    .content {
      .events {
        .event {
          padding: 10px;
        }

        .placebet {
          padding: 10px;
        }
      }
    }
  }

  //Drawer
  .mobile-bet-slip {
    .ant-drawer-close {
      left: -25px;
      top: -15px;
      background: #044da1;
      width: 26px;
      border-radius: 50%;
      height: 26px;
      svg {
        margin: 0 0 14px 0;
      }
    }
    .bet-slip {
      .content .events .placebet {
        .amount-up-down .input {
          width: 50px !important;
        }
        .total-possible-value {
          font-size: 20px;

        }
      }
      .tabs-top > {
        //Top bar
        .ant-tabs-top-bar {
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }

      .load-code {
        .top {
          border: none;
          border-top-right-radius: 0;
          border-top-left-radius: 0;
        }
      }
    }
  }
}

