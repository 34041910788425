#bits-filter-button-container{
    display: flex;
    flex-direction: row;
    align-self: center;
    min-width:12.9vw;
    padding-right: 1.5vw;
}


.bets-filter-buttons{

    background-color: var(--blue-to-gray);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3em;
    width: 5em;
    font-weight: 600;
    border:transparent;
    margin-left: 10px;
}

.bets-filter-buttons:active{
    background-color: #2C8AF7;
    color: var(--white);
}

.bets-filter-container{
    display: flex;
    flex-wrap: wrap;
}

@media only screen and (max-width: 1790px){
    .bets-filter-container{
        justify-content: center;

    }
    

}

