.sport-types::-webkit-scrollbar {
	display: none;
}

.sport-types {

	.swipe {
		width: 100%;

		display: flex;
		flex-direction: row;
		align-items: center;
		overflow-x: auto;
		background-color: #172a40;

		// .sport-types-container {
		// 	justify-content: center;
		// 	align-items: center;
		// 	display: flex;
		// 	flex-direction: row;
		// 	-moz-user-select: none;
		// 	-webkit-user-select: none;
		// 	-ms-user-select: none;
		// 	user-select: none;
		// 	-o-user-select: none
		// }

		.sport-type {
			cursor: pointer;

			.box {
				padding: 12px 20px 10px 15px;
				height: 100%;
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				align-content: space-between;
				opacity: 0.7;

				.icon {
					margin-bottom: 5px;

					img {
						filter: brightness(0) invert(1);
						width: 25px;
						height: 25px;
					}
				}

				.title {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 14px;
					font-weight: bold;
				}
			}

			.box:hover {
				color: #40a9ff;
			}

			.box.selected {
				background-color: #243a53;
				font-weight: 500;
				opacity: 1;
			}
		}

		.sport-type:first-child {
			/* margin-left: 0; */
		}

		.sport-type:last-child .divider {
			/* margin-right: 0; */
			border: none;
		}

		.paddle {
			width: 1em;
			height: auto;
			cursor: pointer;
			margin-left: 1rem !important;
			margin-right: 1rem !important;
			border: none !important;
			background-color: transparent !important;
			border-radius: 0 !important;
			opacity: 1;
		}

		.left-paddle {
			left: 0;
		}

		.right-paddle {
			right: 0;
		}
	}
}