@media only screen and (max-width: 1900px) {
    /*********************** event rows width 1750px ************************/
    .row-of-bet-details-buttons{
        flex-wrap: wrap;
        flex-direction: row ;
        height: 100%;
    }

  }
@media only screen and (max-width: 1480px) {
    /*********************** event rows width 1750px ************************/
    .row-of-bet-details-buttons{
        flex-wrap: wrap;
        flex-direction: row ;
        height: 100%;
    }

  }
