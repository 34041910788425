// Dark theme


[data-theme="dark"] {
    --dark-blue:#14213C;
    --light-black:#0B1828 ;
    --blue: #45577C;
    --white:#FFFFFF;
    --white-to-blue: #FFFFFF;
    --white-to-gray:#fff;
    --strong-blue:#263B69;
    --black-to-white:black;
    --light-blue-to-off-white:#334260;
    --dark-blue-to-white: #233355;
    --dark-blue-to-dark-gray: #647598;
    --sport-odd-buttons-opacity:0.5;
    --convert-brightness: brightness(100);
    --black-to-gray: #04101E;
    --blue-to-gray:#14284C;
    --high-to-low-opacity:1;
    --high-to-very-low-opacity:1;
    --buttons-hover:#293c5c;
    --header-menu-back-ground-color: #0B1828;
    --header-menu-selected-item: white;
    --header-menu-items:#566B84;
    --betslip-sport-images: #14213C;
    --white-to-red: white;
    --switcher-button: #14213C;
    --sport-event-buttons-background-color: #0B1828;
    --form-input-background-color:#3B475F;
    --personal-details-form-input-font-color:#FFFFFF;
    --bet-slip-counter: #dee2e8;
    --amount-counter-border: none;
    --white-to-off-white: white;
    --virtual-menu-background-color: #172a40;
    --login-regitration-header: #14213C;
    --forget-password-steps: #233355;
    --forget-password-headline: #FFFFFF;
    --login-registartion-inputs: #3B475F;
    --login-registartion-inputs-font-color: white;
    --login-regitartion-strip: #233355;
    --login-regitartion-validiation-meter: #0B1828;
    --single-match-pages-background: #14213C;
    
}

[data-theme="light"] {
    --dark-blue:#FFFFFF;
    --light-black:#F3F4F7;
    --blue:#D9DCE5;
    --white:black;
    --white-to-blue: #525C6E;
    --white-to-gray:gray;
    --strong-blue:#D9DCE5;
    --black-to-white:white;
    --light-blue-to-off-white:#F5F5F9;
    --dark-blue-to-white: #FFFFFF;
    --dark-blue-to-dark-gray: #525C6E;
    --sport-odd-buttons-opacity:1;
    --convert-brightness: brightness(0);
    --black-to-gray: #F3F4F7;
    --blue-to-gray:#D9DCE5;
    --high-to-low-opacity:0.7;
    --high-to-very-low-opacity:0.3;
    --buttons-hover:#F3F4F7;
    --header-menu-back-ground-color: #D9DCE5;
    --header-menu-selected-item: #525C6E;
    --header-menu-items:#B3B9C3;
    --betslip-sport-images: #F5F5F9;
    --white-to-red: #ED4D53;
    --switcher-button: #F3F4F7;
    --sport-event-buttons-background-color: #D9DCE5;
    --form-input-background-color:#F4F5F7;
    --personal-details-form-input-font-color:#979CAA;
    --bet-slip-counter: #F5F5F9;
    --amount-counter-border: #dee2e8;
    --white-to-off-white:#F5F5F9;
    --virtual-menu-background-color: #D9DCE5;
    --login-regitration-header: #FFFFFF;
    --forget-password-steps: #FFFFFF;
    --forget-password-headline: #003169;
    --login-registartion-inputs: #F4F5F7;
    --login-registartion-inputs-font-color: #979CAA;
    --login-regitartion-strip: #E1EBF7;
    --login-regitartion-validiation-meter: #F4F5F7;
    --single-match-pages-background: #FFFFFF;

    
}

//  
// 