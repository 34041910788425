 .center {
	display: block;
	margin-left: auto;
	margin-right: auto;
  }
  @media only screen and (max-width: 1550px ){
	.sport {
		.top-bets{
			.ant-carousel{
				.card{
					.event{
						.row-2{
							.team{
								font-size: 16px !important;
							}
						}
					}
				}
			}
		}
	}     
  }
  @media only screen and (max-width: 1400px ){
	.sport {
		.top-bets{
			.ant-carousel{
				.card{
					.event{
						.row-2{
							.team{
								font-size: 14px !important;
							}
						}
					}
				}
			}
		}
	}     
  }
  @media only screen and (max-width:600px){
	.sport{
		.events{
			.bet-type-1,
 			.bet-type-2,
 			.bet-type-3,
 			.bet-type-4 {
 				width: 17vw !important;
 				// margin-left: 10px;
 			}
		}
		.header{
			// padding-right: 3.5vw !important;
			.col{
	

				.bet-types{

					.odds{
						width: 17vw !important;
						div {
							width: 17vw !important;
							div{
								width: 17vw !important;
							}

						}
					}
				}
			}
		}
		// .bets-filter-container{
		// 	justify-content: start;
						

		// }
		.bets-filter-container:last-child div{
width:82vw		}
		// .bets-filter-buttons:not(:first-child){
		// 	margin-left: 0 !important;
		// }
	  }
  }
//   @media only screen and (max-width:400px){
	
// 		.bets-filter-container:last-child div{
// width:100vw !important		}
	
//   }
  
//   @media only screen and (max-width:1550px){
// 	.sport{
// 		.events{
// 			.header {
// 			   padding-right: 5vw !important;
// 			}
			
// 		}
// 	  }
//   }
//   @media only screen and (max-width:1400px){
// 	.sport{
// 		.events{
// 			.header {
// 				padding-right: 7vw !important;
// 			 }
// 			 .bet-type-1{
// 				margin-right: 1vw;
// 			 }

// 		}
// 	  }
//   }
//   @media only screen and (max-width:1150px){
// 	.sport{
// 		.events{
// 			.bet-type-1,
//  			.bet-type-2,
//  			.bet-type-3,
//  			.bet-type-4 {
//  				width: 2vw !important;
// 				 margin-right: 7vw;
//  				// margin-left: 10px;
//  			}
// 			//  .bet-type-1{
// 			// 	margin-right: 1vw;
// 			//  }
// 		}
// 	  }
//   }

 .sport {

 	//1 Col
 	@media all {

 		.top-bets {

 			margin-bottom: unset;

 			.top {
 				display: flex;
 				justify-content: space-between;
 			}

 			.carousel-1,
 			.carousel-2,
 			.carousel-3 {
 				display: none;
 			}

 			.ant-carousel {
 				.card {
 					.event:not(:last-child) {
 						margin-right: 10px;
 					}

 					.event {
 						.container {
 							.buttons-ods {
 								button {
 									.container {
 										padding: 7px;
 									}
 								}
 							}
 						}

 						.row-2 {
 							.team {
 								//max-width: 100px;
								 
								 font-size: 20px;
 							}
 						}
 					}
 				}
 			}
 		}

 		.top-bets[min-width~="300px"] {
 			.carousel-1 {
 				display: block;
 			}
 		}

 		.top-bets[min-width~="500px"] {

 			.carousel-1,
 			.carousel-3 {
 				display: none;
 			}

 			.carousel-2 {
 				display: block;
 			}
 		}

 		.top-bets[min-width~="890px"] {

 			.carousel-1,
 			.carousel-2 {
 				display: none;
 			}

 			.carousel-3 {
 				display: block;
 			}
 		}

 		.events {
 			.header {
 				padding: 0.6vw;
				padding-right: 2.6vw;
 			}

 			.row {
				 background-color: var(--dark-blue);
 			}

 			.bet-type-0 {
				max-width: 220px;
 				min-width: 220px;
 				margin-right: auto;
 				flex-basis: auto;

 				.details {
 					width: 100%;
					.country{
						display: flex;
						align-items: center
					}
 					.title {
 						font-size: 18px;
 						
 						color:var(--white);
 						max-width: 100%;
 						white-space: nowrap;
 						overflow: hidden;
 						display: inline-block;
 						text-overflow: ellipsis;
 					}
 				}
 			}

 			.bet-type-1,
 			.bet-type-2,
 			.bet-type-3,
 			.bet-type-4 {
 				width: 8.8vw;
 				// margin-left: 10px;
 			}

 			.bet-type-5,
 			.bet-type-6 {
 				display: flex;

 				// .stats {
 				// 	width: 25px;
 				// 	max-width: 25px;
 				// 	margin-left: 7px !important;
 				// }

 				.plus {
					 padding: 3px;
					height: auto;
 					width: auto;
 					max-width: 35px;
 				}
 			}

 			// .bet-type-2,
 			// .bet-type-3,
 			// .bet-type-4,
 			// .bet-type-5,
 			// .bet-type-6 {
 			// 	display: none;
 			// }

 			//Odd buttons
 			.bet-types {
 				width: 7.8vw;

 				.container {
 					.buttons-ods {
 						display: flex;

 						button {}
 					}
 				}
 			}
 		}

 		//425px
 		.events[min-width~="403px"] {
 			.bet-types {
 				min-width: 7.8vw;
 			}

 			.bet-type-5,
 			.bet-type-6 {
 				display: flex;
			 }
 		}

 		//550px
 		.events[min-width~="530px"] {
 			.bet-type-2 {
 				display: flex;
 			}
 		}

 		//710px
 		.events[min-width~="690px"] {
 			.bet-type-3 {
 				display: flex;
 			}
 		}

 		//870px
 		.events[min-width~="850px"] {
 			.bet-type-4 {
 				display: flex;
 			}
 		}
 	}

 	//1 Cols, Stats, Plus
 	@media only screen and (min-width: 425px) {
 		.top-bets {

 			.ant-carousel {
 				.card {
 					.event {
 						width: 45%;

 						.row-2 {
 							.team {
								 //max-width: 70px;
								 
 							}
 						}
 					}
 				}
 			}
		 }		 
 	}

 	@media only screen and (max-width: 1024px) {
 		.events {
 			.bet-types {
			
 				width: 7.8vw;

 				.container {

 					.buttons-ods {

 						display: flex;

 						button {
 							min-height: 50px;
 						}
 					}
 				}
 			}
		 }
		 .event{
			.score{
				.game-result{
				   letter-spacing: 0px;
				   padding: 0 5px;
				}
			}
		}
 	}
 }