#my-bets-container{
  div , span{
    color: white;
  }

  overflow-y: scroll;
  max-height: 60vh;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: fixed;
  bottom: 0;
  width: 295px;
  z-index: 3;
  background-color: #044DA1;

  right: 6.5vw;
    .my-bets-rows-arrow{
      display: flex;
      justify-content: end;
      position: relative;
      bottom: 30px;
        
    }
    .my-bets-headline{
        display: flex;
        justify-content: space-between;
        background-color: #044DA1;
        padding: 10px;
    }
    .fixed-my-bets-headline{
      display: flex;
      position: fixed;
      justify-content: space-between;
      background-color: #044DA1;
      padding: 10px;
      width: 285px;
      z-index: 3;

    }
    .list-group-container{
        margin-bottom: 10px;
        margin-top: 50px;
    }
    
    .my-bets-tap-pane{
     width:50%
    }
    .ant-tabs-bar{
        
        width: 100%;
    }
    // .ant-tabs-nav-scroll{
    //     display: flex;
    //     justify-content: space-evenly;
    // }
    .ant-tabs-nav-wrap{
        display: flex;
        justify-content: space-around;
    }
    .ant-tabs-nav {
        .ant-tabs-tab{
            margin: 0;
        }
    }
    .my-bets-single-row{
        text-align: center;
        background-color: #45577C;

    }
    .my-bets-rows-container{
        padding: 10px;
        cursor: pointer;
        border-bottom: 1px solid #FAF9F6;
    }
    .lower-opacity-container{
      opacity : 0.5
    }

    .active, .my-bets-rows-container:hover {
  background-color: #45577C;
    }
  .my-bets-details-container{
    padding: 10px;
    display: none;
    overflow: hidden;
  }

      .my-bets-rows-container{
        h3 {
            overflow: hidden;
            text-align: center;
            color:#f8f8f8;

          }
        h3:before,
      h3:after {
        background-color: #346FB3;
        content: "";
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 50%;
      }
      
      h3:before {
        right: 1em;
        margin-left: -50%;
      }
      
      h3:after {
        left: 1em;
        margin-right: -50%;
      }
      .my-bets-row-1{
          display: flex;
      }
      .my-bets-formId-container{
          font-size: 14px;
          width: 10rem;
          align-self: center;
          text-align: center;
      }
      }

      .my-bets-header{
        text-transform: uppercase;
        font-size: 16px;
      }
      .my-bets-tabs{
        border-top: 1px solid #6C6F71;
      }


      // Nav style 
      a {
        text-decoration: none;
        color: inherit;
    }
        nav {
            max-width: 800px;
            margin: auto;
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid rgb(12, 12, 12);
        }
    
            nav a {
                flex-grow: 1;
                text-align: center;
                padding: 1em;
                position: relative;
            }
    
            // animmation
            nav a::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right:0;
                height: 2px;
                transform: scaleX(0);
                background: #D0DAE6;
                transition: 0.7s transform cubic-bezier(0.06, 0.9, 0.28, 1);
            }
    
            nav a:hover::after{
                transform: scaleX(1)
            }
          // End of Nav style 
          .lower-opacity-container{
            opacity : 0.5
          }
          .my-bets-container-header-arrows{
            cursor: pointer;
          }
          #my-bets-total-odds-container{
            display: flex;
            justify-content: space-between;
            padding: 25px 10px 25px 10px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            background-color: #233355;
          }
          .bolded-element{
            font-weight: bold;
          }


      
}
@media only screen and (max-width:1280px){
 
    #my-bets-container .my-bets-rows-container .my-bets-row-1{
        display: flex;
        width: auto;
        flex-wrap: wrap;
        justify-content: center;
    }
    #my-bets-container .my-bets-rows-arrow{
    }
  }
@media only screen and (max-width:1400px){
  #my-bets-container , .fixed-my-bets-headline{
    right: 6.65vw;
  }
  }
@media only screen and (max-width:1300px){
  #my-bets-container , .fixed-my-bets-headline{
    width: 230px !important;
    right: 6.78vw;
  }
  }
@media only screen and (max-width:1000px){
  #my-bets-container{
    display: none;
  }
  }

  ::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}