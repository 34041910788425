.personal-form {
	width: 100%;
	display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
	padding: 30px;
	.ant-form-item .ant-form-item-label label{
		font-size: 13px !important;
	}
	.ant-input-lg {
		font-size: 15px !important;
	}
	.ant-input{
		font-size: 15px;
	}
	.ant-select{
		font-size: 15px;
	}
	.ant-form-item-control{
		line-height: 0;
	}
	.ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered{
		margin-top: 0 !important;
	}
    .form-container{
        width: 100%;
    }
    .ant-form{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
	.validation-meter{
		div{
			color: black;
		}
		.alert{
			.icon .inner{
				display: flex;
			align-items: center;
			}
			
			.success{
				background-color: lightgreen;
			}
			svg{
				width: 12px;
			}
		}
	}
	.ant-input {
		color: #FFFFFF;
		background-color: #3B475F;
		height: 47px;
        border: none;
        height: 47px !important;
	}

	.ant-select-arrow i svg {
		color: #000 !important;
	}

	.ant-input-disabled {
		color: rgba(255, 255, 255, 0.44);
		background-color: #3B475F !important;
	}
.ant-select-selection{
    border: none;
}
.ant-select-disabled .ant-select-selection  {
	background-color: #3B475F;
}

.ant-form-item {
	
	display: flex;
	flex-direction: column;
	width: 40%;
	margin-inline-start: 5%;
	
	.ant-form-item-label {
		text-align: unset;
		width: 100%;
		label {
			color: #fff !important;
			font-size: 14px;
			font-weight: bold;
		}

		.ant-form-item-required::before {
			
			content: '';
		}
		.ant-form-item-required::after {
			display: inline-block;
			margin-right: 4px;
			color: #f5222d;
			font-size: 14px;
			font-family: SimSun, sans-serif;
			line-height: 1;
			content: '*';
		}
	}
	

	.ant-input-group-addon {
		padding: unset;
		border: unset;

		div {
			color: red;
		}

		.ant-btn {
			border-top-left-radius: unset;
			border-bottom-left-radius: unset;
		}
	}

	.ant-form-item-control-wrapper {
		width: 85%;
		.ant-select {

			.ant-select-selection--single {
				height: 47px;
				background-color: #3B475F;
				.ant-select-selection__rendered {
					margin-top: 4px;
					height: 100%;
					.ant-select-selection__placeholder{
						color: rgba(255, 255, 255, 0.44);
					}
					.ant-select-selection-selected-value {
						color: rgb(255, 255, 255);
						height: 100%;
						display: flex !important;
						opacity: 1;
						justify-content: center;
						align-items: center;
						flex-direction: column;
						span{
							color: rgba(255, 255, 255, 0.44);
						}
					}
				}
			}
		}
	}

	.ant-select-arrow .ant-select-arrow-icon svg {
		color: #c1c1c1;
	}
}

	.ant-form-item-with-help {
		margin-bottom: 10px !important;
	}

	.captcha {
		border: solid 0px red;
	}

	.phone-number-item{
	
		.ant-form-item-control{
		line-height: 0;	
		}
		
		.seperator-input{
				width: 55px;
				pointer-events: 'none';
				background-color: #fff;
				font-size: 40px;
				border-left:  0 !important;
				border-right: 0 !important;
				text-align: center;
				cursor: auto;
	
		}
		.prefix-input{
			border-right: 0 !important;
			width: 70px;
		  text-align: center;
		 
		}
		.number-input{
			
			 width: 140px;
			 border-left: 0 !important;
			 text-align: center;
			 background-color: #fff !important
		}
	
		.country-select{
			width: 110px !important;
			.ant-select-selection{
				height: 47px !important;
				
			}
		}
		.ant-input{
			-webkit-box-shadow: 0 0 0 0 rgb(255, 255, 255)!important;
		}
		
	}
	
	.phone-number-view{
		.ant-input-disabled{
			color: rgba(255, 255, 255, 0.44);
			//	color: gray;
			//background-color: #fff !important;
		}
	
		.ant-input-group-wrapper .ant-input-group-addon{
			padding: 0 15px;
			background-color: #3B475F;
			border: none !important;
		}
	}
	
}

.ant-select-dropdown{
	background-color: #3B475F !important;
	align-items: flex-start;
.ant-select-dropdown-menu-item{
	display: flex;
	color:rgb(255, 255, 255);
}
.ant-select-dropdown-menu-item-selected{
	color:rgba(0, 0, 0, 0.44) ;
	div{
		div:nth-child(2){
			color: #000;
		}
	}
}

}
.form-white {
	.ant-form-item {
		.ant-form-item-label label {
			color: #182438;
		}
	}
}

@media only screen and (max-width:1100px){
	.ant-form{
		flex-direction: column;
		.ant-form-item{
			width: 100%;
		}
		.ant-input {
			color: #FFFFFF;
			background-color: #3B475F;
			height: 47px;
			border: none;
			height: 47px !important;
		}
	}
	.ant-form-item-control-wrapper {
		width: 100%;
	}
	.phone-number-view{
		.ant-input-disabled{
			color: rgba(255, 255, 255, 0.44);
			//	color: gray;
			//background-color: #fff !important;
		}
	
		.ant-input-group-wrapper .ant-input-group-addon{
			padding: 0 5px;
		
		}
	}
}

@media only screen and (max-width:1000px){

	.ant-form{
		flex-direction: column;
		.ant-form-item{
			width: 100%;
		}
		.ant-input {
			color: #FFFFFF;
			background-color: #3B475F;
			height: 47px;
			border: none;
			height: 47px !important;
		}
	}
	.ant-form-item-control-wrapper {
		width: 100%;
	}
	.ant-input-group-wrapper .ant-input-group-addon{
		padding: 0 5px !important;
	div{
		margin-inline-end: 10px;
	}
	}
}
	
@media only screen and (max-width:900px){
.ant-form{
		flex-direction: column;
		.ant-form-item{
			width: 100%;
		}
		.ant-input {
			color: #FFFFFF;
			background-color: #3B475F;
			height: 47px;
			border: none;
			height: 47px !important;
		}
	}
	.ant-form-item-control-wrapper {
		width: 100%;
	}
	.ant-input-group-wrapper .ant-input-group-addon{
		padding: 0 5px;
		div{
			margin-inline-end: 10px;
		}
	}
}

@media only screen and (max-width:480px){
	.ant-form{
		flex-direction: column;
		.ant-form-item{
			width: 100%;
		}
		.ant-input {
			color: #FFFFFF;
			background-color: #3B475F;
			height: 47px !important;
			border: none;
			max-height: 47px !important;
			.ant-select{
				max-height: 47px; 
			font-size: 11px;
			 padding: 0;
		
			}
		}
	}
	.ant-form-item-control-wrapper {
		width: 100%;
	}
	.ant-input-group-wrapper .ant-input-group-addon{
		padding: 0 5px;
		div{
			margin-inline-end: 10px;
		}
	
	}
	
}

@media only screen and (max-width:380px){
	.ant-form{
		flex-direction: column;
		.ant-form-item{
			max-width: 100%;
		}
		.ant-input {
			color: #FFFFFF;
			background-color: #3B475F;
			height: 47px;
			border: none;
			height: 47px !important;
		}
	}
	.ant-form-item-control-wrapper {
		width: 100%;
	}
	.ant-input-group-wrapper .ant-input-group-addon{
		padding: 0 5px;
		div{
			margin-inline-end: 10px;
		}
	}
}
@media only screen and (max-width:300px){
	.ant-form{
		flex-direction: column;
		.ant-form-item{
			width: 100%;
		}
		.ant-input {
			color: #FFFFFF;
			background-color: #3B475F;
			height: 47px;
			border: none;
			height: 47px !important;
		}
	}
	.ant-form-item-control-wrapper {
		width: 100%;
	}
	.ant-input-group-wrapper .ant-input-group-addon{
		padding: 0 5px;
		div{
			margin-inline-end: 10px;
		}
	}
	
}
