#bits-filter-rows{
    margin-top: 40px;
}
#bet-rows-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0px 25px 0 25px;

}
.event-row-button-reset{
    opacity: 50%;
    border-radius: 0px;
    border-top: solid 2px var(--light-black);
    border-bottom: solid 2px var(--light-black);
    background-color: var(--light-black);
    font-size: 14px;
}
// .colomn-in-row-events:last-child{
//     width: 100%;
//     display: flex;
//     align-items: center;
//     justify-content: center;
// }
.event-row-parent-container{
}
.row-of-bet-details-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.event-row-parent-container:last-child #bet-rows-container .events-row-container {
    border:none
}
.bet-info{
    display: flex;
}
.buttons-inside-event-row{
    display: flex;
}
.bet-rows-icon-container{
    background-color: var(--light-black);
    margin-right:3px;
    padding: 9px 4px 9px 4px;
    border-radius: 5px;
    font-weight: bolder;
}
.events-row-container{
    padding: 20px 0 20px 0;
    border-bottom: solid 2px #182e49;
}
