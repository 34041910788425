.modal-container {
  display: "flex";
  flex-direction: "column";

  .modal-buttons {
    text-align: center;

    .modal-button {
      max-width: max-content;
      margin-left: 10px !important;
    }
  }
}
