
.bit-slip-rows{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
#first-bet-slip-row{
    padding-bottom: 15px;
}

.bet-slip-buttons{
    flex: 1 1;
    width: 100%;
    border: none;
    border-radius: 50px;
    height: 50px;
    font-size: 13px;
    font-weight: bold;
    margin-top: 10px;
}
