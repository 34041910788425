
.account {
	.sider-right{
		margin-right: 0px !important;
	}
	.content .container .title{
		font-size: 18px !important;
	}

	.ant-empty-description{
		color: var(--white)
	}
	#my-account-mobile-table{
		thead{
			display: none !important;
		}
		display:none
	}

	.my-account-headline-menu-items{
		display: inline-block;
		margin-left: 10px;
		padding:10px;
		background-color: var(--dark-blue);
		margin-bottom: 10px;
		border-radius: 20px;
	}
	#my-account-headline-menu{
		display:none
	}
	@media only screen and (max-width:1700px){
		#my-account-left-sider-container{
			display: none;
		}
		#my-account-headline-menu{
			display: block ;
			background-color: transparent;
			.account-menu .ant-menu-item-selected {
				background-color: #2C8AF7!important;
				border-radius: 5px;
			}
			.ant-layout-sider .ant-layout-sider-children{
				background-color: transparent !important;
			}
			.ant-menu{
				display: inline-block;
			}
			.account-menu .ant-menu-item {
				display: inline-block;
				width: auto;
				border: none;
				background-color: var(--dark-blue);
				border-radius: 5px;
				border: none;
				padding: 0 10px !important;
				margin-bottom: 10px !important;
			}
			.account-menu .ant-menu-item:not(:first-child) {
				margin-left: 10px !important;
			}
			.ant-menu .ant-menu-item .title{
				display: inline-block;
			}
			#my-account-left-menu-container{
				padding: 0;
			}
		}
		.my-account-headeline{
			display: none;
		}
	}
	#my-account-left-menu-container{
		padding: 20px 10px 80px 10px;
	}
	.account-menu .ant-menu-item:not(:last-child) {
		padding: 5px 0 !important
	}

	.account-menu .ant-menu-item-selected{
		background-color: var(--light-black) !important;
		border-radius: 5px;
	}
	.ant-menu {
		font-weight: 400 !important;
		.ant-menu-item{
			.title{
				opacity: 1;
			}
		} 
	}
	.bets{
		.ant-tabs-bar{
			border-bottom :2px solid var(--light-black) !important;
		}
		.ant-tabs-nav-scroll{
			padding-top: 5px;
			
			.ant-tabs-ink-bar {
				background-color: var(--white);
			}
			.ant-tabs-nav{
				.ant-tabs-tab{
					opacity: 0.6;
					width: 27.5vw;
					text-align: center;
					font-size: 16px;
					color: var(--white) !important;
				}
				.ant-tabs-tab-active{
					opacity: 1 !important;
				}
			}
		}
	}
	.my-account-headeline{
		padding: 15px 0 0 0;
		font-size: 18px;
	}
	.ant-layout .ant-layout-sider .ant-layout-sider-children{
		background-color: var(--dark-blue);
		height:auto;
		border-radius: 5px;
	}


	.my-account-menu-Icons{
		padding-right: 10px;
	}
	.alert{
		color: var(--light-black);
	}
	
	
	.ant-select {
		// border: 1px solid black !important;
		border-radius: 4px;
		.ant-select-selection{
			margin-top: 0px !important;
		}
	}
	.ant-calendar-range-picker-input{
		width: 40%;
	}
	.ant-input-group-addon{
		background-color:#f5f5f5;
		border: 1px solid black !important;
		border-right: none !important;
	}

	border: black;
	.layout-inner {
		display: flex;
		justify-content: center;
		
		.content {
			width: 100%;
			// max-width: 1224px !important;
			display: flex;
			justify-content: space-between;

			.content-right {
				margin-left: 10px;
			}

			.container {
				min-width: 57.47vw;
				min-height: 415px;
				// padding: 20px;
				border-radius: 4px;
				background-color: var(--dark-blue);
				display: flex;
				// justify-content: center;

				.bets,
				.voucher {

					display: flex;
					justify-content: center;
					.match-text{
						text-overflow: ellipsis;
						white-space: nowrap;
						max-width: 120px;
						overflow: hidden;
						span{
							color: black !important;
						}
					}
					.ant-table-fixed-right{
						z-index: 1;
					}

					.message-alert{
						margin: 30px 10px;
						span{
							color: var(--black-to-white) !important;
						}
					}
					.table .ant-table-tbody{
						// background: #fff !important;
						td {
						color: black;
						text-align: center;
						}
					}
					.ant-table-expanded-row > td {
							color:  var(--white) !important;
							padding-top: 0px !important;
						}
					
				    
					.ant-table-thead > tr > th{
						font-weight: 400 !important;
						background-color: var(--dark-blue);
						opacity: 1 !important;
						border-bottom: 2px solid #616A7C !important;
						text-align: center;
						font-size: 14px;

						div{
							color: black ;
					   }
					}
				    .ant-table-tbody > tr > td {
						background-color: var(--dark-blue) !important;
						color: var(--white) !important;
						// text-align: center;
						div{
							//  color: black;
						}
						 
					}
										
					.ant-pagination {
						.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
							min-width: 62px !important;
						}

						li {
							border: solid 0px;
							height: 40px;
							line-height: 40px;
							color: var(--black-to-white) !important;
							background-color: transparent;
							padding: 0 10px 0 10px;
							border: 1px solid #7A8291;
					
						span, a {
								font-size: 14px;
								font-weight: bold;
								opacity: 1;
								// color: var(--black-to-white) !important;
								background-color: transparent !important;
								border: unset !important;

							}
						a {
							filter: var(--convert-brightness);
						}
						}
					}
					width: 100%;

					.form-container {

						.filters-container{
							display: flex;
    						align-items: center;
						}
					  .title{
						  color: black;
						  max-width: 50px;
						  font-size: 13px;
						  margin: 0px
					  }
						min-width: 750px;
						.range-picker {
							margin-left: 10px;
							margin-right: 20px;
						}
						.select {
							margin-left: 10px;
							margin-right: 20px;
							div, span{
								color:black !important;
							}
							
						}
						.select-dark {
							width: 170px;
						}
		

					}
				}
				.mtn-container {
					max-width: 400px !important;
				}
				.deposit-main {

					margin-top:7vh;
					margin-left:1.5vw;
					.deposit-title{
						font-size: 18px;
						color: var(--white);
						font-weight: 300;
					}
					.deposit-input	{
    					border: 1px solid black;
					}
					.menu {
						// justify-content: space-between;
						display: flex;
						flex-flow: wrap;
					}
					.menu > a:last-of-type{
						margin-left: 15px;
					}
					.deposit-icon {
						margin: 20px 0% 0 0;
						height: 140px;
						width: 145px;
						border-radius: 5px;
					}

				}
				.deposit,.deposit-mtn,
				.withdrawals {
					.ant-input-number-input{

						height: 38px;
					}
					.ant-select-selection__rendered{
						line-height: 38px;
					}
					.withdraw-button-container{
						width: 70% !important;
						margin: auto !important;
						
					}
					.ant-btn{
						background-color: #2C8AF7 !important;
					}
					.ant-input{
						// border: 1px solid black !important;
						background-color: var(--form-input-background-color);
						border: none;
						color: var(--white);
						// padding: 25px;
					
					}
					margin: auto;
					width: 500px;
					padding: 30px 0;
					// .title {
					// 	text-align: left;
					// }
					.title {
						color: var(--white);
						text-align: center;
						font-weight: 500;
					}
					.form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value{
						color: var(--white);
					}
					.ant-select-selection{
						background-color: var(--form-input-background-color);
						border: none;
					}
					.form .ant-input-disabled {
						background-color: var(--form-input-background-color) !important;
					}
					.form .ant-input{
						color: var(--white);
						background-color: var(--form-input-background-color) ;
						border: none !important;
					}
					.ant-form-item-required::before{
						display: none;
					}
					.text {
						text-align: center;
						font-size: 16px;
					}
					.ant-input-number-input {
						// color:black;
						color: var(--white);
						
					}
					.ant-input-number {
						background-color: var(--form-input-background-color);
						border: none;
					}
					.ant-input-group>[class*='col-'] {
						width: 25%;

						input {
							text-align: center;
						}
					}

					.balance {
						// color:var(--light-black);
						font-size: 14px;
						// font-weight: bold;
						margin-bottom: 60px;
						text-align: center;
						span{
							font-weight:bold;
						}
					}
					.withdraw-confirmation-statement{
						text-align: center;
						span{
							color:#26A405 !important;
							font-size: 17px;
						}
						padding-bottom: 15px;
						
					}
					.withdraw-another-amount-button{
						margin-top: 60px;
					}
					.qr-code {
						justify-content: center !important
					}
					.voucher {
						font-size:17px;
						margin-top: 10px;
						// color:var(--light-black);
						display: flex;
						justify-content: center;

						.number {
							// color: black;
							font-weight: 600;
							//margin-left: 20px;
						}
						

						// .voucher-container {
						// 	display: flex;
						// 	flex-direction: column;
						// 	padding: 10px 5px;
						// 	text-align: center;
						// 	width:100%;
						// 	.alert {
						// 		justify-content: center;
						// 		.message {
						// 		font-size: 25px;
						// 		}
						// 	}

				
						// 	.voucher-details {
						// 		text-align: center;
						// 		//padding: 20px;
						// 		.details {
						// 			padding-bottom: 25px;
						// 			display: block !important;
						// 			font-size: 14px;
						// 		 //  display: inherit;
						// 		   flex-wrap: wrap;
						// 		   width: 100%;
						// 		h3 {
						// 			margin: 0 10px
						// 		}
						// 		 div {
						// 			 width: 50%;
						// 			 display: flex;
						// 			 align-items: center;
						// 			 align-content: center;
						// 		 }
							
						// 		.line {
						// 			width: 100%;

						// 		// 	.text {
						// 		// 		margin-top: 0.5em;
						// 		// 	}
						// 		}
						// 	}
						// 		.ant-modal-body {
						// 			padding: 20px;
						// 			width: 100%;
						// 			height: 100%;
						// 		}
						// 		.qr-code {
						// 			padding-top: 20px;
						// 		}
						// 		.table-wrapper {
						// 		  height: 100%;
						// 		}
						// 		div {
						// 		  color: black;
						// 		  text-align: center;
						// 		}
							
						// 	  }

							
						// }
					}
				}
				  
				.personal {
					width: 600px;
					.ant-btn{
						height: 38px;
					}						
				}	
				.success-check{
					background: #2C8AF7 !important;
				}			
				.change-password{
					width: 100%;
					display: flex;
					justify-content: center;
					.alert{
						background-color: var(--dark-blue) !important;
						.message{
							color: var(--white);
						}
					}

					.form-container{
						padding: 30px 0 30px 0 ;
						.show-password-icon{
							position: absolute;
							right: 3%;
							top: 10px;
							// filter:brightness(50)
							
						}
						.ant-input{
							background-color: var(--form-input-background-color) !important;
							color: var(--white) !important;
							border: none;
							padding: 20px;
							font-size: 15px;
							
						}
						.info{
							background-color: #169ef4 !important;
						}
						.change-password-buttons-container{
							width: 30% !important;
							margin: auto !important;
						}
						// max-width: 250px;
						// width: 250px;
						// text-align: center;
						width: 90%;
						.ant-form-item-required::before{
							display:none
						}
						.ant-form-item-required::after{
							display: inline-block;
							margin-right: 4px;
							color: #f5222d;
							font-size: 14px;
							font-family: SimSun, sans-serif;
							line-height: 1;
							content: '*';
						}
						.forget-password-labels-containers{
							width: 48%;
							display: inline-block;
						}
						.forget-password-second-section{
							display: flex;
							justify-content: space-between;
						}
						
					}
					.validation-meter{
						width: 300px;
						margin: auto;
						div{
							// color: black;
						}
						.alert{
							margin: 0;
							padding: 0;
							.icon .inner{
								display: flex;
							align-items: center;
							}
							
							.success{
								background-color: lightgreen;
							}
							svg{
								width: 10px;
							}
						}
					}
				}
					.buttons {
					margin-top: 30px;

					button:last-child {
						margin-top: 20px !important;
					}
				}

				.alert {
					border-radius: 4px;
					padding: 10px;
					margin-top: 20px;
					background-color: var(--light-black);
				}

				.table {

					margin-top: 30px;
					// width: 55vw;
					padding: 20px;

					.status-type{
						background: lightgray;
						width: 100px;
					}
				}
			
				.shrink-table-expanded-row {
					.ant-table-content {
						// width: calc(100% - 100px);
					}
				}


				.ant-form-item-label label {
					color: var(--white);
					font-weight: 500;
				}

				.ant-table-placeholder {
					background-color: transparent;
					border: unset;
				}
			}			
		}	
	}
}

.voucher-modal {
	width: 70% !important;
	height: 60% !important;
	top: 2% !important;
	padding: 0 !important;
	.ant-modal-content {
	  width: 100% !important;
	  height: 100% !important;
	  .ant-modal-close {
		// background: red !important;
	  }
	}
  
	.ant-modal-body {

		
	  padding: 20px;
	  width: 100%;
	  height: 100%;
  
	  .message {
		color: black !important;
	  }
  
	  .voucher-details {
		text-align: center;
		padding: 20px;
		.ant-modal-body {
			padding: 20px;
			width: 100%;
			height: 100%;
		}
		.qr-code {
			padding-top: 20px;
		}
		.table-wrapper {
		  height: 100%;
		}
		div {
		  color: black;
		  text-align: center;
		}
	
	  }
// 	   .ant-table-v1{
// 		  .ant-table-tbody > tr > td, span{
//   color: black !important;
// 		  }
// 		}
	  .voucher-details {
		height: 100%;
		h3 {
		  font-weight: bold !important;
		  padding: 10px;
		  margin: 0;
		}
  
		.details {
		  	 padding-bottom: 25px;
		 	  display: flex;
			//  display: inherit;
		 	 flex-wrap: wrap;
		 	 width: 100%;
			div {
				width: 50%;
				display: flex;
				align-items: center;
				align-content: center;
			}
		}
	  }
	}
	 .voucher-container{
		display: flex;
		flex-direction: column;
		padding: 10px 5px;
		text-align: center;
		.alert {
			justify-content: center;
			.message {
			font-size: 25px;
			}
		}
	}
}

.bet-details-modal {
	width: 76% !important;
    height: 80% !important;
    top: 3% !important;
	right: 1%;
    padding: 0 !important;
	.ant-modal-content {
		background-color: var(--dark-blue);
	  width: 100% !important;
	  height: 100% !important;
	  .ant-modal-close {
		// background: red !important;
	  }
	}
  
	.ant-modal-body {
		.ant-table-thead > tr > th, .ant-table-tbody > tr > td {
			color: var(--white) !important;
			// text-align: center;
		}
		.ant-table-tbody > tr > td{
			border-bottom: 2px solid #26334B !important;
			text-align: center;
		}
		.ant-table-thead > tr > th{
			border-bottom: 2px solid #26334B !important;

		}
		// .ant-table-tbody > tr > td:hover,.ant-table-tbody > tr:hover{
		// 	background: none !important;
		// }
		.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
			background: unset !important;
			
		}


		.modal-description-container{
			display: flex;
			justify-content: space-between;
			font-size: 16px;
			.modal-desciption-divs{
				padding: 10px;
			}
		}
		.modal-left-description , .modal-right-description{
			display: inline-block;
		}
	  padding: 0;
	  width: 100%;
	  height: 100%;
  
	  .btn-wrapper {
		display: flex;
		width: 100%;
		justify-content: center;
		padding: 20px;
		.ant-btn {
		  margin-right: 20px !important;
		  flex: none;
		  width: 150px;
		}
		.btn-cancel {
		  background: grey;
		}
	  }
	  .circle {
		width: 32px !important;
		padding: 0 !important;
		font-size: 16px !important;
		border-radius: 50% !important;
		height: 32px !important;
		margin: 0 20px !important;
	  }
	  .kiosk-menu {
		position: absolute;
		padding: 20px;
		.btn-open-checkin,
		.btn-open-chashin {
		  margin-top: 15px !important;
		  display: block;
		  width: 40px;
		}
	  }
	  .kiosk-info {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		align-items: center;
		padding-bottom: 60px;
		img {
		  max-width: 50%;
		  max-height: 75%;
		}
		.ant-input-lg {
		  font-size: 14px;
		}
	  }
  
	  .btn-kiosk {
		width: auto;
		flex: unset;
		margin-right: 10px;
		margin-bottom: 10px;
	  }
  
	  .title-kiosk {
		margin-top: 30px;
	  }
	  .message {
		color: black !important;
	  }
  
	  .bet-details {
		.ant-pagination li {
		  background-color: #4ba5e6;
		  a {
			background-color: #4ba5e6 !important;
		  }
		}
  
		.ant-pagination .ant-pagination-item-active li {
		  background-color: #4ba5e682;
		  a {
			background-color: #4ba5e682 !important;
		  }
		}
		// text-align: center;
		padding: 20px;
		.table-wrapper {
		  height: 100%;
		}
		div {
		  color: black;
		//   text-align: center;
		}
		th {
		  font-weight: bold;
		  background: #4ba5e6;
		}
		.ant-table-row {
		  background: aliceblue;
		  text-align: center;
		}
	  }
	   .ant-table-v1{
		  .ant-table-tbody > tr > td, span{
//   color: black !important;
		  }
		}
	  .bet-details {
		height: 100%;
		h3 {
			color: var(--white);
			font-weight: 300;
		//   font-weight: bold;
		font-size: 14px;
		  padding: 10px;
		  margin: 0;
		}
  
   
		.ant-table-thead > tr > th,
		.ant-table-tbody > tr > td {
		  padding: 5px;
		  word-break: break-all;
		}
  
		.details {
		  display: flex;
		  flex-wrap: wrap;
		  width: 100%;
		  div {
			color: var(--white);
			width: 50%;
			display: flex;
			align-items: center;
			align-content: center;
		  }
		}
	  }
	
	}
	 .bet-details-container{
		#modal-details-table-mobile-view{
			display: none;
		}
		.ant-table-thead > tr > th {
			background-color: transparent;
			text-align: center;
		}
		h1{
			color: var(--white);
			text-transform: uppercase;
			font-size: 18px;
			text-align: center;
			padding: 10px 0;
		}
	  display: flex;
	  flex-direction: column;
	  padding: 50px 20px;
	  background-color: var(--dark-blue);
	//   text-align: center;
	  .alert {
		justify-content: center;
		.message {
		  font-size: 25px;
		}
	  }
	  .status{
		// color: black;
		// font-weight: bold;
		font-size: 20px;
		display: flex;
		flex-direction: column;
		text-align: center;
		padding: 10px;
	  }
	  .details-table {
		padding: 10px 0;
		.ant-pagination li {
			background-color: transparent;
			border: 1px solid var(--white);
		//   background-color: #4ba5e6;
		  a {
			opacity: 1 ;
			// background-color: #4ba5e6 !important;
			background-color: transparent;
			height:0px;
		  }
		}
		.ant-table table { font-size: 13px; }
	  }
  
	  .form-title {
		font-weight: bold;
		font-size: 1rem;
		margin-bottom: 1rem;
		margin-top: 1rem;
		color: black;
		text-align: center;
	  }
  
	  .form-details {
		margin-bottom: 1rem;
	  }
  
	  .form-message {
		margin-bottom: 1.5rem;
		margin-top: 0.5rem;
	  }
  
	  .table-winning-row {
		background-color: rgb(144, 238, 144);
		color: black;
	  }
  
	  .table-losing-row {
		background-color: rgb(240, 128, 128);
		color: black;
	  }
  
	  .table-row-refund {
		color: red;
	  }
  
	  .table-no-result-row {
		color: black;
	  }
  
	  .winnings-form-text-value {
		color: #169ef4;
	  }
  
	  .winnings-form-text {
		color: black;
	  }
	}
  
	.column-header {
	  div {
		// color: black !important;
		// font-weight: bold;
		span {
		//   color: black !important;
		  font-size: 14px;
		  text-transform: uppercase;
		  font-weight: 500;
		//   text-align: center;
		}
	  }
	}
  }
.add-second-phone{
	display: flex;
	flex-direction: column;
	text-align: center;
			*{			
		color: black;
		
			}	
				.ant-btn{
					width: 375px;
						}
					}
@media only screen and (max-width:1100px){

	.bet-details-modal { 
		.bet-details-container{
			.status{
				font-size: 13px;
				padding: 15px;
			}
		} 
		 .ant-modal-body{
			.ant-table-tbody > tr > td{
				border-bottom: none !important;
				text-align: start;
			}
			.mobile-modal-row-details{
				padding:10px 0 ;
				border: none;
				background-image: linear-gradient(to right, #202D46 33%, rgba(255,255,255,0) 0%);
				background-position: bottom;
				background-size: 30px 3px;
				background-repeat: repeat-x;
			}
			padding: 0;
			.bet-details{
				padding: 0;
			}
			
			.bet-details-container{
				#modal-details-table-desktop-view{
					display: none;
				}
				#modal-details-table-mobile-view{
					display: block;
					thead{
						display: none;
					}
				}
				.modal-left-description , .modal-right-description{
					display: block;
					div{
						span{
		
							font-size: 13px;
						}
					}
				}
				.ant-table-pagination.ant-pagination{
					float: none;
					display: flex;
					justify-content: center;
				}

			}
			.modal-description-container{
				display: block;
				.modal-desciption-divs{
					padding: 0;
				}
			}
		 }
		}
	

}
@media only screen and (max-width:1000px){
	#my-account-body-container{
		padding: 0 6vw 0 6vw !important;
	}
	.account{
		.ant-table-pagination.ant-pagination{
			float: none !important;
			display: flex !important;
			justify-content: center !important;
		}
		.bets{
			.ant-tabs-nav-scroll{

				.ant-tabs-nav{
					.ant-tabs-tab{
						width: 38vw;
					}
				}
			}
		}
		
	}
}
		
@media only screen and (max-width:1000px){
.account {
	#my-account-headline-menu{
		#my-account-left-menu-container {
			height:90px;
			overflow:hidden;
			.ant-menu{
				font-weight: 400 !important;
				height: 90px;
				width: 86vw;
				overflow: scroll;
				-webkit-overflow-scrolling: touch;
				white-space: nowrap;
				overflow-y: hidden;
				scroll-behavior: smooth;
			}
	}
	}
	
	
}


}
@media only screen and (max-width:900px){
	.account .layout-inner .content .container .bets .table .ant-table-tbody td{
		text-align: start;
	}
	.account .layout-inner .content .container{
		.deposit-mtn , .withdrawals , .deposit{
			width: 73vw;
		}
	} 
	
	.account{    
		.layout-inner .content .container .table{
			.mobile-table-row-data{
			margin-left: 8vw;
			}
			width: 90vw;
			padding: 0;
		}
		#my-account-mobile-table{
			display:block;
			.ant-table-tbody > tr > td{
					border: none;
					background-image: linear-gradient(to right, #202D46 33%, rgba(255,255,255,0) 0%);
					background-position: bottom;
					background-size: 32px 3px;
					background-repeat: repeat-x;
			}
		}
		
		#my-account-desktop-table{
			display:none
		}
	}

}
@media only screen and (max-width: 500px){
	.account .layout-inner .content .container .table{
		margin-top: 0;
	}
}
@media only screen and (max-width:480px){

	.account .layout-inner .content .container .change-password .validation-meter{
		width: auto;
	}

	.account .layout-inner .content .container .change-password .form-container{
		.change-password-buttons-container{
			width: 75% !important;
			
		}
		.forget-password-labels-containers{
			width: 100%;
			display: block;
		}
		.forget-password-second-section{
			display: block;
			// justify-content: space-between;
		}
	}
	
	.account .layout-inner .content .container{
		min-height: 43vh;
		.mtn-container{
			padding: 15px;
			.ant-btn{
				font-size: 12px;
				font-weight: bold;
			}
		}
		.deposit-mtn {
			.title{
				font-size: 16px;
			}
		}
		.withdrawals {
			.withdraw-confirmation-statement{
				text-align: start;
				span{
					font-size: 15px;
				}
			}
			.voucher {
				span{
					font-size: 12px;
				}
				.number {
					font-size: 12px;
				}	
			}
			.voucher-container{
				// width: 3vw;
				// .voucher-details{
				// 	svg{
				// 		width: 100px;
				// 		height: 100px;
				// 	}
				// }
			}
			.ant-btn{
				font-size: 12px;
				font-weight: bold;

			}
			.balance{
				font-size: 12px;
				
			}
		}
	}
	
}
@media only screen and (max-width:380px){
	.account .layout-inner .content .container{
		.withdrawals{
			.withdraw-confirmation-statement{
				text-align: start;
				span{
					font-size: 12px;
				}
			}
		}

		.approve-for-withdraw-container{
			.ant-btn{
				font-size: 10px;

			}
		}
	}
	
}
@media only screen and (max-width:300px){
	.account .layout-inner .content .container{
		.approve-for-withdraw-container{
			.ant-btn{
				font-size: 8px;

			}
		}
	}
	
}
