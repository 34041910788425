@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);
[data-theme="dark"] {
  --dark-blue:#14213C;
  --light-black:#0B1828 ;
  --blue: #45577C;
  --white:#FFFFFF;
  --white-to-blue: #FFFFFF;
  --white-to-gray:#fff;
  --strong-blue:#263B69;
  --black-to-white:black;
  --light-blue-to-off-white:#334260;
  --dark-blue-to-white: #233355;
  --dark-blue-to-dark-gray: #647598;
  --sport-odd-buttons-opacity:0.5;
  --convert-brightness: brightness(100);
  --black-to-gray: #04101E;
  --blue-to-gray:#14284C;
  --high-to-low-opacity:1;
  --high-to-very-low-opacity:1;
  --buttons-hover:#293c5c;
  --header-menu-back-ground-color: #0B1828;
  --header-menu-selected-item: white;
  --header-menu-items:#566B84;
  --betslip-sport-images: #14213C;
  --white-to-red: white;
  --switcher-button: #14213C;
  --sport-event-buttons-background-color: #0B1828;
  --form-input-background-color:#3B475F;
  --personal-details-form-input-font-color:#FFFFFF;
  --bet-slip-counter: #dee2e8;
  --amount-counter-border: none;
  --white-to-off-white: white;
  --virtual-menu-background-color: #172a40;
  --login-regitration-header: #14213C;
  --forget-password-steps: #233355;
  --forget-password-headline: #FFFFFF;
  --login-registartion-inputs: #3B475F;
  --login-registartion-inputs-font-color: white;
  --login-regitartion-strip: #233355;
  --login-regitartion-validiation-meter: #0B1828;
  --single-match-pages-background: #14213C; }

[data-theme="light"] {
  --dark-blue:#FFFFFF;
  --light-black:#F3F4F7;
  --blue:#D9DCE5;
  --white:black;
  --white-to-blue: #525C6E;
  --white-to-gray:gray;
  --strong-blue:#D9DCE5;
  --black-to-white:white;
  --light-blue-to-off-white:#F5F5F9;
  --dark-blue-to-white: #FFFFFF;
  --dark-blue-to-dark-gray: #525C6E;
  --sport-odd-buttons-opacity:1;
  --convert-brightness: brightness(0);
  --black-to-gray: #F3F4F7;
  --blue-to-gray:#D9DCE5;
  --high-to-low-opacity:0.7;
  --high-to-very-low-opacity:0.3;
  --buttons-hover:#F3F4F7;
  --header-menu-back-ground-color: #D9DCE5;
  --header-menu-selected-item: #525C6E;
  --header-menu-items:#B3B9C3;
  --betslip-sport-images: #F5F5F9;
  --white-to-red: #ED4D53;
  --switcher-button: #F3F4F7;
  --sport-event-buttons-background-color: #D9DCE5;
  --form-input-background-color:#F4F5F7;
  --personal-details-form-input-font-color:#979CAA;
  --bet-slip-counter: #F5F5F9;
  --amount-counter-border: #dee2e8;
  --white-to-off-white:#F5F5F9;
  --virtual-menu-background-color: #D9DCE5;
  --login-regitration-header: #FFFFFF;
  --forget-password-steps: #FFFFFF;
  --forget-password-headline: #003169;
  --login-registartion-inputs: #F4F5F7;
  --login-registartion-inputs-font-color: #979CAA;
  --login-regitartion-strip: #E1EBF7;
  --login-regitartion-validiation-meter: #F4F5F7;
  --single-match-pages-background: #FFFFFF; }

/* cyrillic-ext */
@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxFIzIFKw.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxMIzIFKw.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxEIzIFKw.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxLIzIFKw.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxHIzIFKw.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxGIzIFKw.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Roboto Thin";
  font-style: normal;
  font-weight: 100;
  src: local("Roboto Thin"), local("Roboto-Thin"), url(https://fonts.gstatic.com/s/roboto/v19/KFOkCnqEu92Fr1MmgVxIIzI.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Roboto Light";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmSU5fBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu5mxKOzY.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu7mxKOzY.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4WxKOzY.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu7WxKOzY.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu7GxKOzY.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(https://fonts.gstatic.com/s/roboto/v19/KFOmCnqEu92Fr1Mu4mxK.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Roboto Medium";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmEU9fBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Roboto Bold";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"), local("Roboto-Bold"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmWUlfBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

/* cyrillic-ext */
@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfCRc4EsA.woff2) format("woff2");
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F; }

/* cyrillic */
@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfABc4EsA.woff2) format("woff2");
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116; }

/* greek-ext */
@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfCBc4EsA.woff2) format("woff2");
  unicode-range: U+1F00-1FFF; }

/* greek */
@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfBxc4EsA.woff2) format("woff2");
  unicode-range: U+0370-03FF; }

/* vietnamese */
@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfCxc4EsA.woff2) format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB; }

/* latin-ext */
@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfChc4EsA.woff2) format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF; }

/* latin */
@font-face {
  font-family: "Roboto Black";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"), local("Roboto-Black"), url(https://fonts.gstatic.com/s/roboto/v19/KFOlCnqEu92Fr1MmYUtfBBc4.woff2) format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD; }

[data-theme="dark"] {
  --dark-blue:#14213C;
  --light-black:#0B1828 ;
  --blue: #45577C;
  --white:#FFFFFF;
  --white-to-blue: #FFFFFF;
  --white-to-gray:#fff;
  --strong-blue:#263B69;
  --black-to-white:black;
  --light-blue-to-off-white:#334260;
  --dark-blue-to-white: #233355;
  --dark-blue-to-dark-gray: #647598;
  --sport-odd-buttons-opacity:0.5;
  --convert-brightness: brightness(100);
  --black-to-gray: #04101E;
  --blue-to-gray:#14284C;
  --high-to-low-opacity:1;
  --high-to-very-low-opacity:1;
  --buttons-hover:#293c5c;
  --header-menu-back-ground-color: #0B1828;
  --header-menu-selected-item: white;
  --header-menu-items:#566B84;
  --betslip-sport-images: #14213C;
  --white-to-red: white;
  --switcher-button: #14213C;
  --sport-event-buttons-background-color: #0B1828;
  --form-input-background-color:#3B475F;
  --personal-details-form-input-font-color:#FFFFFF;
  --bet-slip-counter: #dee2e8;
  --amount-counter-border: none;
  --white-to-off-white: white;
  --virtual-menu-background-color: #172a40;
  --login-regitration-header: #14213C;
  --forget-password-steps: #233355;
  --forget-password-headline: #FFFFFF;
  --login-registartion-inputs: #3B475F;
  --login-registartion-inputs-font-color: white;
  --login-regitartion-strip: #233355;
  --login-regitartion-validiation-meter: #0B1828;
  --single-match-pages-background: #14213C; }

[data-theme="light"] {
  --dark-blue:#FFFFFF;
  --light-black:#F3F4F7;
  --blue:#D9DCE5;
  --white:black;
  --white-to-blue: #525C6E;
  --white-to-gray:gray;
  --strong-blue:#D9DCE5;
  --black-to-white:white;
  --light-blue-to-off-white:#F5F5F9;
  --dark-blue-to-white: #FFFFFF;
  --dark-blue-to-dark-gray: #525C6E;
  --sport-odd-buttons-opacity:1;
  --convert-brightness: brightness(0);
  --black-to-gray: #F3F4F7;
  --blue-to-gray:#D9DCE5;
  --high-to-low-opacity:0.7;
  --high-to-very-low-opacity:0.3;
  --buttons-hover:#F3F4F7;
  --header-menu-back-ground-color: #D9DCE5;
  --header-menu-selected-item: #525C6E;
  --header-menu-items:#B3B9C3;
  --betslip-sport-images: #F5F5F9;
  --white-to-red: #ED4D53;
  --switcher-button: #F3F4F7;
  --sport-event-buttons-background-color: #D9DCE5;
  --form-input-background-color:#F4F5F7;
  --personal-details-form-input-font-color:#979CAA;
  --bet-slip-counter: #F5F5F9;
  --amount-counter-border: #dee2e8;
  --white-to-off-white:#F5F5F9;
  --virtual-menu-background-color: #D9DCE5;
  --login-regitration-header: #FFFFFF;
  --forget-password-steps: #FFFFFF;
  --forget-password-headline: #003169;
  --login-registartion-inputs: #F4F5F7;
  --login-registartion-inputs-font-color: #979CAA;
  --login-regitartion-strip: #E1EBF7;
  --login-regitartion-validiation-meter: #F4F5F7;
  --single-match-pages-background: #FFFFFF; }

* {
  font-family: "Poppins";
  line-height: unset; }

body {
  background-color: var(--black-to-gray);
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  /* Konqueror HTML */
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome and Opera */ }

.avoid-clicks :not(.slots-game) :not(.frame-video) {
  pointer-events: none; }

.avoid-clicks .slots-game .frame-video {
  pointer-events: all !important; }

body {
  margin: 0;
  /* This will stop the margin, setting it to 0 */ }

.ant-layout-header {
  background: none; }

div,
a {
  color: var(--white); }

button,
span {
  color: var(--white); }

.app {
  display: flex;
  flex-direction: column;
  background-color: var(--light-black); }

.upper {
  text-transform: uppercase; }

.headeline {
  text-transform: uppercase; }

/*********************** Margin Styles ************************/
.mtop-10 {
  margin-top: 10px; }

.mtop-20 {
  margin-top: 20px; }

.mtop-30 {
  margin-top: 30px; }

.mtop-40 {
  margin-top: 40px; }

.gt {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }

/*********************** Link Styles ************************/
.link {
  cursor: pointer; }

.light-on {
  transition-property: all;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  -moz-transition-property: all;
  -moz-transition-duration: 0.3s;
  -moz-transition-timing-function: linear;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.3s;
  -webkit-transition-timing-function: linear; }

.light-on:hover {
  color: #0e7eff; }

/*********************** Action colors Styles ************************/
.success {
  background-color: #2C8AF7;
  color: white !important; }

.message-no-status {
  background-color: #2C8AF7; }

.success:hover,
.success:active,
.success:focus {
  background-color: #2C8AF7; }

.info {
  background-color: #044da1; }

.info:hover,
.info:active,
.info:focus {
  background-color: #044da1; }

.warning {
  background-color: #eda72b; }

.warning:hover,
.warning:active,
.warning:focus {
  background-color: #eda72b; }

.error {
  background-color: #e82f2f; }

.error:hover,
.error:active,
.error:focus {
  background-color: #e82f2f; }

.clear {
  background-color: var(--dark-blue-to-white);
  border: solid 1px var(--white) !important; }
  .clear span {
    color: #182438 !important; }

.clear:hover,
.clear:active,
.clear:focus {
  background-color: #2C8AF7; }

.ant-btn[disabled] {
  background-color: #2C8AF7;
  border: unset !important; }
  .ant-btn[disabled] span {
    color: var(--white) !important; }

.ant-btn[disabled]:hover {
  background-color: #2C8AF7 !important; }

/*********************** Select Styles ************************/
.selectlist {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  height: 28px; }
  .selectlist .ant-select-selection {
    background-color: transparent;
    border: none;
    opacity: 0.5;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center !important; }
  .selectlist .ant-select-selection-selected-value {
    text-align: center;
    width: 100%; }

/*********************** Alert Styles ************************/
.alert-icon {
  display: flex;
  width: 18px;
  height: 18px;
  font-size: 13px;
  font-weight: bold;
  border-radius: 20px;
  text-align: center;
  justify-content: center;
  align-items: center; }

.alert {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .alert .icon .inner {
    border-radius: 10px;
    width: 16px;
    height: 16px;
    margin-right: 6px;
    color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 13px; }
  .alert .message {
    font-size: 14px;
    color: var(--white); }

/*********************** Loading Styles ************************/
.loading {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .loading .text {
    margin-left: 10px; }

.confirmation-form {
  display: flex;
  flex-direction: column; }

.confirmation-input {
  height: 100px;
  font-size: 2rem;
  font-weight: bold;
  padding: 0 100px; }

/*********************** StickeyBox Styles ************************/
.stickey-sider {
  height: 2000px; }

/*********************** Input Styles ************************/
.ant-input-number,
input[type="text"],
input[type="email"],
input[type="number"],
input[type="password"] {
  width: 100%;
  height: 40px !important; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type="number"] {
  -moz-appearance: textfield; }

/*********************** Search box ************************/
.search {
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  height: 40px;
  background-color: #044da1; }

/*********************** Amount Up/Down ************************/
.amount-up-down {
  height: 32px;
  display: flex;
  flex-direction: row;
  border: solid 1px #dee2e8;
  border-radius: 4px; }
  .amount-up-down .up,
  .amount-up-down .down {
    color: var(--light-black);
    background-color: var(--bet-slip-counter);
    width: 30px;
    border: var(--amount-counter-border);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .amount-up-down .up {
    border-right: 1px solid #d3d2d2; }
  .amount-up-down .down {
    border-left: 1px solid #d3d2d2; }
  .amount-up-down .amount .input {
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    border: unset;
    height: 30px !important;
    width: 75px !important;
    background-color: var(--white-to-off-white); }

/*********************** No activity modal ************************/
.noActivity .text {
  color: var(--black-to-white);
  font-size: 20px;
  margin-bottom: 30px; }

@-webkit-keyframes shine {
  0% {
    opacity: 1; }
  35% {
    opacity: 0.6; }
  40% {
    opacity: 0.7; }
  45% {
    opacity: 0.8; }
  46% {
    opacity: 1; } }

@keyframes shine {
  0% {
    opacity: 1; }
  35% {
    opacity: 0.6; }
  40% {
    opacity: 0.7; }
  45% {
    opacity: 0.8; }
  46% {
    opacity: 1; } }

.glint {
  -webkit-animation: ant-skeleton-loading 2s ease-out infinite, shine 2s ease-out infinite;
          animation: ant-skeleton-loading 2s ease-out infinite, shine 2s ease-out infinite;
  -webkit-animation-name: ant-skeleton-loading shine;
          animation-name: ant-skeleton-loading shine;
  background: linear-gradient(60deg, transparent 25%, rgba(253, 253, 253, 0.38) 37%, transparent 43%);
  background-size: 400%; }

.glint:hover {
  -webkit-animation: ant-skeleton-loading 2s ease-out infinite, shine 2s ease-out infinite;
          animation: ant-skeleton-loading 2s ease-out infinite, shine 2s ease-out infinite;
  -webkit-animation-name: ant-skeleton-loading shine;
          animation-name: ant-skeleton-loading shine;
  background: linear-gradient(60deg, transparent 25%, rgba(253, 253, 253, 0.38) 37%, transparent 43%);
  background-size: 400%; }

/************** Buttons **************/
.pri-btn {
  background-color: var(--white-to-blue);
  color: var(--black-to-white) !important; }
  .pri-btn span {
    color: var(--black-to-white) !important; }
  .pri-btn:hover {
    background-color: var(--white); }
    .pri-btn:hover span {
      color: var(--black-to-white) !important; }

.sec-btn {
  background-color: transparent;
  border: 1px solidvar(--white) !important; }
  .sec-btn:hover span {
    color: var(--black-to-white) !important; }

.ant-modal-wrap {
  top: 10vh !important; }

@media only screen and (max-width: 1100px) {
  .ant-modal-wrap {
    top: 2vh !important;
    bottom: 5vh; } }

.footer .footer-image-container {
  opacity: 1 !important;
  padding-right: 10px; }

.footer .disclamer {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center; }
  .footer .disclamer .plus {
    border: solid 3px var(--white);
    font-weight: bold;
    border-radius: 30px;
    padding: 8px;
    margin-right: 10px;
    text-align: center; }

.dd .event .row-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  background-color: var(--light-black);
  padding: 10px;
  margin-bottom: 10px; }
  .dd .event .row-1 .col-1 {
    flex: 1 1; }
  .dd .event .row-1 .col-2 {
    display: flex;
    flex-direction: row; }
  .dd .event .row-1 .league {
    opacity: 0.7;
    font-size: 14px;
    font-weight: bold; }
  .dd .event .row-1 .date {
    text-align: right;
    opacity: 0.7;
    font-size: 14px;
    font-weight: bold;
    align-self: flex-end;
    display: flex;
    flex-direction: row;
    justify-items: flex-end;
    align-items: flex-end; }
    .dd .event .row-1 .date .timer,
    .dd .event .row-1 .date .event-id {
      margin-left: 20px; }
  @media only screen and (max-width: 768px) {
    .dd .event .row-1 {
      flex-direction: column;
      padding: 10px 20px 10px 20px;
      margin-left: -10px;
      margin-right: -10px;
      margin-bottom: 2px; }
      .dd .event .row-1 .league {
        margin-bottom: 5px; } }

.dd .event .row-2 {
  overflow: auto;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  background-size: 100% 100%;
  height: 125px;
  border-radius: 4px; }
  .dd .event .row-2 .back {
    padding-left: 15px;
    float: left;
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: bold;
    background-image: url(/static/media/lt.273abc23.webp);
    background-repeat: no-repeat;
    background-position: 0 3px;
    cursor: pointer; }
  .dd .event .row-2 .teams {
    margin: auto auto auto auto;
    width: 100%; }
    .dd .event .row-2 .teams .names,
    .dd .event .row-2 .teams .score {
      text-shadow: 0 0 50px var(--black-to-white);
      font-weight: bold;
      font-size: 40px; }
    .dd .event .row-2 .teams .names {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center; }
    .dd .event .row-2 .teams .score {
      text-align: center; }
  @media only screen and (max-width: 768px) {
    .dd .event .row-2 {
      height: 70px; }
      .dd .event .row-2 .teams .names,
      .dd .event .row-2 .teams .score {
        font-size: 20px; } }

.dd .event .bg-1 {
  background-image: url(/static/media/drill1.2ee7d490.webp); }
  @media only screen and (max-width: 768px) {
    .dd .event .bg-1 {
      margin-left: -10px;
      margin-right: -10px;
      border-radius: unset;
      background-image: unset;
      background-color: var(--light-black); } }

.dd .groups {
  padding: 10px;
  border-radius: 4px;
  background-color: var(--single-match-pages-background); }
  .dd .groups .group {
    display: flex;
    flex-direction: column; }
    .dd .groups .group .title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 5px;
      margin-top: 10px;
      text-transform: capitalize;
      color: #7aa5c8; }
    .dd .groups .group .group-title {
      font-weight: bold;
      font-size: 14px;
      opacity: 0.8;
      margin-bottom: 5px;
      margin-top: 10px;
      text-transform: capitalize; }
    .dd .groups .group .buttons-ods.match .name {
      opacity: unset !important; }
    .dd .groups .group .buttons-ods {
      margin-bottom: 7px; }
      .dd .groups .group .buttons-ods button {
        height: 100%;
        min-height: 40px;
        flex: 1 1; }
        .dd .groups .group .buttons-ods button .container {
          flex: 1 1;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center; }
          .dd .groups .group .buttons-ods button .container .name {
            width: 100%;
            padding: 5px 10px 5px 10px;
            text-align: left;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; }
          .dd .groups .group .buttons-ods button .container .value {
            padding: 5px 10px 5px 10px;
            text-align: center; }
          .dd .groups .group .buttons-ods button .container .lock {
            margin-right: 10px; }
      .dd .groups .group .buttons-ods .empty {
        flex: 1 1; }
    @media only screen and (max-width: 768px) {
      .dd .groups .group .buttons-ods {
        flex-direction: row; }
        .dd .groups .group .buttons-ods button .container {
          flex: 1 1;
          display: flex;
          flex-direction: column !important;
          justify-content: space-between;
          align-items: center; }
          .dd .groups .group .buttons-ods button .container .name {
            width: 100%;
            padding: 5px;
            text-align: center; } }

.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat; }

.flag-icon {
  background-size: contain;
  background-position: 50% 12%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em; }

.flag-icon:before {
  content: "\A0"; }

.flag-icon.flag-icon-squared {
  width: 1em; }

.flag-icon-ad {
  background-image: url(/static/media/ad.8474edbd.webp); }

.flag-icon-ad.flag-icon-squared {
  background-image: url(/static/media/ad.ad21a73b.webp); }

.flag-icon-ae {
  background-image: url(/static/media/ae.797b8c55.webp); }

.flag-icon-ae.flag-icon-squared {
  background-image: url(/static/media/ae.b3fd53bc.webp); }

.flag-icon-af {
  background-image: url(/static/media/af.acd17708.webp); }

.flag-icon-af.flag-icon-squared {
  background-image: url(/static/media/af.40c8f8b0.webp); }

.flag-icon-ag {
  background-image: url(/static/media/ag.ec2849eb.webp); }

.flag-icon-ag.flag-icon-squared {
  background-image: url(/static/media/ag.416e7a76.webp); }

.flag-icon-ai {
  background-image: url(/static/media/ai.bab0cee3.webp); }

.flag-icon-ai.flag-icon-squared {
  background-image: url(/static/media/ai.afe9a74c.webp); }

.flag-icon-al {
  background-image: url(/static/media/al.ae01671e.webp); }

.flag-icon-al.flag-icon-squared {
  background-image: url(/static/media/al.4bba9c93.webp); }

.flag-icon-am {
  background-image: url(/static/media/am.29ab035b.webp); }

.flag-icon-am.flag-icon-squared {
  background-image: url(/static/media/am.7d81fd0d.webp); }

.flag-icon-ao {
  background-image: url(/static/media/ao.6b1d38f6.webp); }

.flag-icon-ao.flag-icon-squared {
  background-image: url(/static/media/ao.ec9f301c.webp); }

.flag-icon-aq {
  background-image: url(/static/media/aq.47765b73.webp); }

.flag-icon-aq.flag-icon-squared {
  background-image: url(/static/media/aq.955b50f7.webp); }

.flag-icon-ar {
  background-image: url(/static/media/ar.e3d0904a.webp); }

.flag-icon-ar.flag-icon-squared {
  background-image: url(/static/media/ar.b9671d47.webp); }

.flag-icon-as {
  background-image: url(/static/media/as.e8e7cc49.webp); }

.flag-icon-as.flag-icon-squared {
  background-image: url(/static/media/as.a1a0d594.webp); }

.flag-icon-at {
  background-image: url(/static/media/at.b1c65c5b.webp); }

.flag-icon-at.flag-icon-squared {
  background-image: url(/static/media/at.cdb16d8a.webp); }

.flag-icon-au {
  background-image: url(/static/media/au.578bc258.webp); }

.flag-icon-au.flag-icon-squared {
  background-image: url(/static/media/au.99b1f642.webp); }

.flag-icon-aw {
  background-image: url(/static/media/aw.30f6f69e.webp); }

.flag-icon-aw.flag-icon-squared {
  background-image: url(/static/media/aw.a50dd3eb.webp); }

.flag-icon-ax {
  background-image: url(/static/media/ax.88f156b1.webp); }

.flag-icon-ax.flag-icon-squared {
  background-image: url(/static/media/ax.16f6c0f5.webp); }

.flag-icon-az {
  background-image: url(/static/media/az.5cd441f0.webp); }

.flag-icon-az.flag-icon-squared {
  background-image: url(/static/media/az.ad044f05.webp); }

.flag-icon-ba {
  background-image: url(/static/media/ba.bb6d72b7.webp); }

.flag-icon-ba.flag-icon-squared {
  background-image: url(/static/media/ba.6e861681.webp); }

.flag-icon-bb {
  background-image: url(/static/media/bb.ea653c2a.webp); }

.flag-icon-bb.flag-icon-squared {
  background-image: url(/static/media/bb.6f8cddf9.webp); }

.flag-icon-bd {
  background-image: url(/static/media/bd.715bd848.webp); }

.flag-icon-bd.flag-icon-squared {
  background-image: url(/static/media/bd.503aa8c3.webp); }

.flag-icon-be {
  background-image: url(/static/media/be.1573649b.webp); }

.flag-icon-be.flag-icon-squared {
  background-image: url(/static/media/be.ebfe594d.webp); }

.flag-icon-bf {
  background-image: url(/static/media/bf.037d6806.webp); }

.flag-icon-bf.flag-icon-squared {
  background-image: url(/static/media/bf.0b4c192f.webp); }

.flag-icon-bg {
  background-image: url(/static/media/bg.18ddaaa8.webp); }

.flag-icon-bg.flag-icon-squared {
  background-image: url(/static/media/bg.1c3d5638.webp); }

.flag-icon-bh {
  background-image: url(/static/media/bh.763ee610.webp); }

.flag-icon-bh.flag-icon-squared {
  background-image: url(/static/media/bh.e536fe57.webp); }

.flag-icon-bi {
  background-image: url(/static/media/bi.bc8f2468.webp); }

.flag-icon-bi.flag-icon-squared {
  background-image: url(/static/media/bi.fcd9e45e.webp); }

.flag-icon-bj {
  background-image: url(/static/media/bj.3f20c12e.webp); }

.flag-icon-bj.flag-icon-squared {
  background-image: url(/static/media/bj.50c76f58.webp); }

.flag-icon-bl {
  background-image: url(/static/media/bl.6eb47c3c.webp); }

.flag-icon-bl.flag-icon-squared {
  background-image: url(/static/media/bl.2b05b4fe.webp); }

.flag-icon-bm {
  background-image: url(/static/media/bm.a81c1ba6.webp); }

.flag-icon-bm.flag-icon-squared {
  background-image: url(/static/media/bm.6da9f39f.webp); }

.flag-icon-bn {
  background-image: url(/static/media/bn.dda1da98.webp); }

.flag-icon-bn.flag-icon-squared {
  background-image: url(/static/media/bn.50bc94f4.webp); }

.flag-icon-bo {
  background-image: url(/static/media/bo.5cc65515.webp); }

.flag-icon-bo.flag-icon-squared {
  background-image: url(/static/media/bo.0678361d.webp); }

.flag-icon-bq {
  background-image: url(/static/media/bq.6bdb6c63.webp); }

.flag-icon-bq.flag-icon-squared {
  background-image: url(/static/media/bq.f5005b71.webp); }

.flag-icon-br {
  background-image: url(/static/media/br.3b8a4a18.webp); }

.flag-icon-br.flag-icon-squared {
  background-image: url(/static/media/br.4c827bdc.webp); }

.flag-icon-bs {
  background-image: url(/static/media/bs.a36a8e7f.webp); }

.flag-icon-bs.flag-icon-squared {
  background-image: url(/static/media/bs.5cfe7e22.webp); }

.flag-icon-bt {
  background-image: url(/static/media/bt.596a83ac.webp); }

.flag-icon-bt.flag-icon-squared {
  background-image: url(/static/media/bt.1c9c1c43.webp); }

.flag-icon-bv {
  background-image: url(/static/media/bv.100ed58f.webp); }

.flag-icon-bv.flag-icon-squared {
  background-image: url(/static/media/bv.5cffc750.webp); }

.flag-icon-bw {
  background-image: url(/static/media/bw.6671f318.webp); }

.flag-icon-bw.flag-icon-squared {
  background-image: url(/static/media/bw.6e5fb466.webp); }

.flag-icon-by {
  background-image: url(/static/media/by.3c89d55b.webp); }

.flag-icon-by.flag-icon-squared {
  background-image: url(/static/media/by.aaf8567a.webp); }

.flag-icon-bz {
  background-image: url(/static/media/bz.0b4b9716.webp); }

.flag-icon-bz.flag-icon-squared {
  background-image: url(/static/media/bz.9c061853.webp); }

.flag-icon-ca {
  background-image: url(/static/media/ca.6f7bb239.webp); }

.flag-icon-ca.flag-icon-squared {
  background-image: url(/static/media/ca.ec64799e.webp); }

.flag-icon-cc {
  background-image: url(/static/media/cc.e1d5c635.webp); }

.flag-icon-cc.flag-icon-squared {
  background-image: url(/static/media/cc.e4bd5559.webp); }

.flag-icon-cd {
  background-image: url(/static/media/cd.968fd4d0.webp); }

.flag-icon-cd.flag-icon-squared {
  background-image: url(/static/media/cd.e3bb7c7b.webp); }

.flag-icon-cf {
  background-image: url(/static/media/cf.4a7f7ecc.webp); }

.flag-icon-cf.flag-icon-squared {
  background-image: url(/static/media/cf.81e4eb2b.webp); }

.flag-icon-cg {
  background-image: url(/static/media/cg.ed7038ee.webp); }

.flag-icon-cg.flag-icon-squared {
  background-image: url(/static/media/cg.bbf39516.webp); }

.flag-icon-ch {
  background-image: url(/static/media/ch.7cd1dd06.webp); }

.flag-icon-ch.flag-icon-squared {
  background-image: url(/static/media/ch.001ad8b7.webp); }

.flag-icon-ci {
  background-image: url(/static/media/ci.af02eac8.webp); }

.flag-icon-ci.flag-icon-squared {
  background-image: url(/static/media/ci.cc4ac343.webp); }

.flag-icon-ck {
  background-image: url(/static/media/ck.c06cdd50.webp); }

.flag-icon-ck.flag-icon-squared {
  background-image: url(/static/media/ck.387df5d2.webp); }

.flag-icon-cl {
  background-image: url(/static/media/cl.21a8aa23.webp); }

.flag-icon-cl.flag-icon-squared {
  background-image: url(/static/media/cl.ce91c21b.webp); }

.flag-icon-cm {
  background-image: url(/static/media/cm.f2183d30.webp); }

.flag-icon-cm.flag-icon-squared {
  background-image: url(/static/media/cm.dd963f34.webp); }

.flag-icon-cn {
  background-image: url(/static/media/cn.db8885a6.webp); }

.flag-icon-cn.flag-icon-squared {
  background-image: url(/static/media/cn.b2f89a8f.webp); }

.flag-icon-co {
  background-image: url(/static/media/co.111dbef0.webp); }

.flag-icon-co.flag-icon-squared {
  background-image: url(/static/media/co.1c3583e1.webp); }

.flag-icon-cr {
  background-image: url(/static/media/cr.3e2f485d.webp); }

.flag-icon-cr.flag-icon-squared {
  background-image: url(/static/media/cr.9720f4f7.webp); }

.flag-icon-cu {
  background-image: url(/static/media/cu.7faea625.webp); }

.flag-icon-cu.flag-icon-squared {
  background-image: url(/static/media/cu.20835570.webp); }

.flag-icon-cv {
  background-image: url(/static/media/cv.a577f6ee.webp); }

.flag-icon-cv.flag-icon-squared {
  background-image: url(/static/media/cv.d2de2547.webp); }

.flag-icon-cw {
  background-image: url(/static/media/cw.4393eff0.webp); }

.flag-icon-cw.flag-icon-squared {
  background-image: url(/static/media/cw.c1fbefa5.webp); }

.flag-icon-cx {
  background-image: url(/static/media/cx.af580948.webp); }

.flag-icon-cx.flag-icon-squared {
  background-image: url(/static/media/cx.17d36101.webp); }

.flag-icon-cy {
  background-image: url(/static/media/cy.1ebbc346.webp); }

.flag-icon-cy.flag-icon-squared {
  background-image: url(/static/media/cy.5cd05b8b.webp); }

.flag-icon-cz {
  background-image: url(/static/media/cz.ab42de66.webp); }

.flag-icon-cz.flag-icon-squared {
  background-image: url(/static/media/cz.70189085.webp); }

.flag-icon-de {
  background-image: url(/static/media/de.4e8ed53a.webp); }

.flag-icon-de.flag-icon-squared {
  background-image: url(/static/media/de.030d1166.webp); }

.flag-icon-dj {
  background-image: url(/static/media/dj.1ec6ce20.webp); }

.flag-icon-dj.flag-icon-squared {
  background-image: url(/static/media/dj.2f1783d2.webp); }

.flag-icon-dk {
  background-image: url(/static/media/dk.8703c84d.webp); }

.flag-icon-dk.flag-icon-squared {
  background-image: url(/static/media/dk.0172c15b.webp); }

.flag-icon-dm {
  background-image: url(/static/media/dm.247f78d9.webp); }

.flag-icon-dm.flag-icon-squared {
  background-image: url(/static/media/dm.1a3097b4.webp); }

.flag-icon-do {
  background-image: url(/static/media/do.3c455234.webp); }

.flag-icon-do.flag-icon-squared {
  background-image: url(/static/media/do.6f0abc1a.webp); }

.flag-icon-dz {
  background-image: url(/static/media/dz.85aca57d.webp); }

.flag-icon-dz.flag-icon-squared {
  background-image: url(/static/media/dz.b399ca86.webp); }

.flag-icon-ec {
  background-image: url(/static/media/ec.b6a76935.webp); }

.flag-icon-ec.flag-icon-squared {
  background-image: url(/static/media/ec.8fc3672a.webp); }

.flag-icon-ee {
  background-image: url(/static/media/ee.ee7d8b06.webp); }

.flag-icon-ee.flag-icon-squared {
  background-image: url(/static/media/ee.48fd749c.webp); }

.flag-icon-eg {
  background-image: url(/static/media/eg.c5bf963a.webp); }

.flag-icon-eg.flag-icon-squared {
  background-image: url(/static/media/eg.05c92360.webp); }

.flag-icon-eh {
  background-image: url(/static/media/eh.21ff79c4.webp); }

.flag-icon-eh.flag-icon-squared {
  background-image: url(/static/media/eh.08b2a86a.webp); }

.flag-icon-er {
  background-image: url(/static/media/er.eecd50c8.webp); }

.flag-icon-er.flag-icon-squared {
  background-image: url(/static/media/er.07327643.webp); }

.flag-icon-es {
  background-image: url(/static/media/es.55653e20.webp); }

.flag-icon-es.flag-icon-squared {
  background-image: url(/static/media/es.5ba3a95d.webp); }

.flag-icon-et {
  background-image: url(/static/media/et.99db8a18.webp); }

.flag-icon-et.flag-icon-squared {
  background-image: url(/static/media/et.87b3d043.webp); }

.flag-icon-fi {
  background-image: url(/static/media/fi.347b3e2f.webp); }

.flag-icon-fi.flag-icon-squared {
  background-image: url(/static/media/fi.b82e43e1.webp); }

.flag-icon-fj {
  background-image: url(/static/media/fj.9ecee5a5.webp); }

.flag-icon-fj.flag-icon-squared {
  background-image: url(/static/media/fj.f9b2202b.webp); }

.flag-icon-fk {
  background-image: url(/static/media/fk.2a22b93e.webp); }

.flag-icon-fk.flag-icon-squared {
  background-image: url(/static/media/fk.76898666.webp); }

.flag-icon-fm {
  background-image: url(/static/media/fm.16f85e7e.webp); }

.flag-icon-fm.flag-icon-squared {
  background-image: url(/static/media/fm.7b73d43a.webp); }

.flag-icon-fo {
  background-image: url(/static/media/fo.1c4f2cb2.webp); }

.flag-icon-fo.flag-icon-squared {
  background-image: url(/static/media/fo.e8778e37.webp); }

.flag-icon-fr {
  background-image: url(/static/media/fr.6eb47c3c.webp); }

.flag-icon-fr.flag-icon-squared {
  background-image: url(/static/media/fr.2b05b4fe.webp); }

.flag-icon-ga {
  background-image: url(/static/media/ga.6749391f.webp); }

.flag-icon-ga.flag-icon-squared {
  background-image: url(/static/media/ga.d43bb832.webp); }

.flag-icon-gb {
  background-image: url(/static/media/gb.6d3c29ab.webp); }

.flag-icon-gb.flag-icon-squared {
  background-image: url(/static/media/gb.fb925f9f.webp); }

.flag-icon-gd {
  background-image: url(/static/media/gd.1a752c4f.webp); }

.flag-icon-gd.flag-icon-squared {
  background-image: url(/static/media/gd.3229e263.webp); }

.flag-icon-ge {
  background-image: url(/static/media/ge.c92d00e5.webp); }

.flag-icon-ge.flag-icon-squared {
  background-image: url(/static/media/ge.cd0399b4.webp); }

.flag-icon-gf {
  background-image: url(/static/media/gf.9b0204c9.webp); }

.flag-icon-gf.flag-icon-squared {
  background-image: url(/static/media/gf.0eb720c3.webp); }

.flag-icon-gg {
  background-image: url(/static/media/gg.51803a81.webp); }

.flag-icon-gg.flag-icon-squared {
  background-image: url(/static/media/gg.c873316b.webp); }

.flag-icon-gh {
  background-image: url(/static/media/gh.23718205.webp); }

.flag-icon-gh.flag-icon-squared {
  background-image: url(/static/media/gh.364c3e9b.webp); }

.flag-icon-gi {
  background-image: url(/static/media/gi.7fe304bd.webp); }

.flag-icon-gi.flag-icon-squared {
  background-image: url(/static/media/gi.9a1389e0.webp); }

.flag-icon-gl {
  background-image: url(/static/media/gl.c1236265.webp); }

.flag-icon-gl.flag-icon-squared {
  background-image: url(/static/media/gl.b6fccb71.webp); }

.flag-icon-gm {
  background-image: url(/static/media/gm.bd33f145.webp); }

.flag-icon-gm.flag-icon-squared {
  background-image: url(/static/media/gm.324dcb2e.webp); }

.flag-icon-gn {
  background-image: url(/static/media/gn.fb034940.webp); }

.flag-icon-gn.flag-icon-squared {
  background-image: url(/static/media/gn.0ce5f322.webp); }

.flag-icon-gp {
  background-image: url(/static/media/gp.6eb47c3c.webp); }

.flag-icon-gp.flag-icon-squared {
  background-image: url(/static/media/gp.2b05b4fe.webp); }

.flag-icon-gq {
  background-image: url(/static/media/gq.99d2a6f1.webp); }

.flag-icon-gq.flag-icon-squared {
  background-image: url(/static/media/gq.997ff576.webp); }

.flag-icon-gr {
  background-image: url(/static/media/gr.ad59c3e5.webp); }

.flag-icon-gr.flag-icon-squared {
  background-image: url(/static/media/gr.fbaa833a.webp); }

.flag-icon-gs {
  background-image: url(/static/media/gs.63d015ea.webp); }

.flag-icon-gs.flag-icon-squared {
  background-image: url(/static/media/gs.297c6c0d.webp); }

.flag-icon-gt {
  background-image: url(/static/media/gt.8f8bd0ee.webp); }

.flag-icon-gt.flag-icon-squared {
  background-image: url(/static/media/gt.f6f45bf0.webp); }

.flag-icon-gu {
  background-image: url(/static/media/gu.5be31ac3.webp); }

.flag-icon-gu.flag-icon-squared {
  background-image: url(/static/media/gu.d7ca82de.webp); }

.flag-icon-gw {
  background-image: url(/static/media/gw.382b0bf9.webp); }

.flag-icon-gw.flag-icon-squared {
  background-image: url(/static/media/gw.a9b15bd1.webp); }

.flag-icon-gy {
  background-image: url(/static/media/gy.985cfd94.webp); }

.flag-icon-gy.flag-icon-squared {
  background-image: url(/static/media/gy.7e481cff.webp); }

.flag-icon-hk {
  background-image: url(/static/media/hk.25ce8805.webp); }

.flag-icon-hk.flag-icon-squared {
  background-image: url(/static/media/hk.a42b26b2.webp); }

.flag-icon-hm {
  background-image: url(/static/media/hm.b543285f.webp); }

.flag-icon-hm.flag-icon-squared {
  background-image: url(/static/media/hm.5bef1bec.webp); }

.flag-icon-hn {
  background-image: url(/static/media/hn.85f6b996.webp); }

.flag-icon-hn.flag-icon-squared {
  background-image: url(/static/media/hn.cb42d406.webp); }

.flag-icon-hr {
  background-image: url(/static/media/hr.76a0a402.webp); }

.flag-icon-hr.flag-icon-squared {
  background-image: url(/static/media/hr.b36e292c.webp); }

.flag-icon-ht {
  background-image: url(/static/media/ht.1c7a931e.webp); }

.flag-icon-ht.flag-icon-squared {
  background-image: url(/static/media/ht.cbd253ac.webp); }

.flag-icon-hu {
  background-image: url(/static/media/hu.0c6f3c9d.webp); }

.flag-icon-hu.flag-icon-squared {
  background-image: url(/static/media/hu.7564e8ac.webp); }

.flag-icon-id {
  background-image: url(/static/media/id.a89fd8c3.webp); }

.flag-icon-id.flag-icon-squared {
  background-image: url(/static/media/id.7d1d2642.webp); }

.flag-icon-ie {
  background-image: url(/static/media/ie.e4a56caa.webp); }

.flag-icon-ie.flag-icon-squared {
  background-image: url(/static/media/ie.65fc7c31.webp); }

.flag-icon-il {
  background-image: url(/static/media/il.df379608.webp); }

.flag-icon-il.flag-icon-squared {
  background-image: url(/static/media/il.b84875c1.webp); }

.flag-icon-im {
  background-image: url(/static/media/im.43d569d0.webp); }

.flag-icon-im.flag-icon-squared {
  background-image: url(/static/media/im.300129dc.webp); }

.flag-icon-in {
  background-image: url(/static/media/in.addb19c6.webp); }

.flag-icon-in.flag-icon-squared {
  background-image: url(/static/media/in.8c1f0f83.webp); }

.flag-icon-io {
  background-image: url(/static/media/io.47af53bc.webp); }

.flag-icon-io.flag-icon-squared {
  background-image: url(/static/media/io.ff4ab8bb.webp); }

.flag-icon-iq {
  background-image: url(/static/media/iq.5eed97bf.webp); }

.flag-icon-iq.flag-icon-squared {
  background-image: url(/static/media/iq.f185e210.webp); }

.flag-icon-ir {
  background-image: url(/static/media/ir.f6abfa8c.webp); }

.flag-icon-ir.flag-icon-squared {
  background-image: url(/static/media/ir.63bba3fc.webp); }

.flag-icon-is {
  background-image: url(/static/media/is.2a5365e6.webp); }

.flag-icon-is.flag-icon-squared {
  background-image: url(/static/media/is.e33a15d5.webp); }

.flag-icon-it {
  background-image: url(/static/media/it.0220e5fa.webp); }

.flag-icon-it.flag-icon-squared {
  background-image: url(/static/media/it.99aceea8.webp); }

.flag-icon-je {
  background-image: url(/static/media/je.de056f8e.webp); }

.flag-icon-je.flag-icon-squared {
  background-image: url(/static/media/je.5e7f5099.webp); }

.flag-icon-jm {
  background-image: url(/static/media/jm.77800d34.webp); }

.flag-icon-jm.flag-icon-squared {
  background-image: url(/static/media/jm.5c7194bf.webp); }

.flag-icon-jo {
  background-image: url(/static/media/jo.f698d398.webp); }

.flag-icon-jo.flag-icon-squared {
  background-image: url(/static/media/jo.7c977297.webp); }

.flag-icon-jp {
  background-image: url(/static/media/jp.cfc717c2.webp); }

.flag-icon-jp.flag-icon-squared {
  background-image: url(/static/media/jp.a9f27dc4.webp); }

.flag-icon-ke {
  background-image: url(/static/media/ke.c91dc2e3.webp); }

.flag-icon-ke.flag-icon-squared {
  background-image: url(/static/media/ke.64ea93d8.webp); }

.flag-icon-kg {
  background-image: url(/static/media/kg.3357db12.webp); }

.flag-icon-kg.flag-icon-squared {
  background-image: url(/static/media/kg.a3188904.webp); }

.flag-icon-kh {
  background-image: url(/static/media/kh.a98b850a.webp); }

.flag-icon-kh.flag-icon-squared {
  background-image: url(/static/media/kh.1350ce8f.webp); }

.flag-icon-ki {
  background-image: url(/static/media/ki.b8c08c30.webp); }

.flag-icon-ki.flag-icon-squared {
  background-image: url(/static/media/ki.cb5fddac.webp); }

.flag-icon-km {
  background-image: url(/static/media/km.de904f4b.webp); }

.flag-icon-km.flag-icon-squared {
  background-image: url(/static/media/km.35c177bd.webp); }

.flag-icon-kn {
  background-image: url(/static/media/kn.87061952.webp); }

.flag-icon-kn.flag-icon-squared {
  background-image: url(/static/media/kn.89fc6236.webp); }

.flag-icon-kp {
  background-image: url(/static/media/kp.a19c1fca.webp); }

.flag-icon-kp.flag-icon-squared {
  background-image: url(/static/media/kp.cd8e97bc.webp); }

.flag-icon-kr {
  background-image: url(/static/media/kr.f1afbff9.webp); }

.flag-icon-kr.flag-icon-squared {
  background-image: url(/static/media/kr.d8edf318.webp); }

.flag-icon-kw {
  background-image: url(/static/media/kw.18b26179.webp); }

.flag-icon-kw.flag-icon-squared {
  background-image: url(/static/media/kw.a9eb72b7.webp); }

.flag-icon-ky {
  background-image: url(/static/media/ky.d74d51a3.webp); }

.flag-icon-ky.flag-icon-squared {
  background-image: url(/static/media/ky.bfbc7ed6.webp); }

.flag-icon-kz {
  background-image: url(/static/media/kz.5084e3f1.webp); }

.flag-icon-kz.flag-icon-squared {
  background-image: url(/static/media/kz.79ef1560.webp); }

.flag-icon-la {
  background-image: url(/static/media/la.d200353a.webp); }

.flag-icon-la.flag-icon-squared {
  background-image: url(/static/media/la.9201bd6f.webp); }

.flag-icon-lb {
  background-image: url(/static/media/lb.608dd049.webp); }

.flag-icon-lb.flag-icon-squared {
  background-image: url(/static/media/lb.1945fe87.webp); }

.flag-icon-lc {
  background-image: url(/static/media/lc.70abfe37.webp); }

.flag-icon-lc.flag-icon-squared {
  background-image: url(/static/media/lc.8bfabfe3.webp); }

.flag-icon-li {
  background-image: url(/static/media/li.e3fd6bc2.webp); }

.flag-icon-li.flag-icon-squared {
  background-image: url(/static/media/li.f9f111bd.webp); }

.flag-icon-lk {
  background-image: url(/static/media/lk.f2888da3.webp); }

.flag-icon-lk.flag-icon-squared {
  background-image: url(/static/media/lk.118d998a.webp); }

.flag-icon-lr {
  background-image: url(/static/media/lr.f1037959.webp); }

.flag-icon-lr.flag-icon-squared {
  background-image: url(/static/media/lr.94efe279.webp); }

.flag-icon-ls {
  background-image: url(/static/media/ls.f01d40a6.webp); }

.flag-icon-ls.flag-icon-squared {
  background-image: url(/static/media/ls.dc78393a.webp); }

.flag-icon-lt {
  background-image: url(/static/media/lt.3cf4eeff.webp); }

.flag-icon-lt.flag-icon-squared {
  background-image: url(/static/media/lt.b20b5cf9.webp); }

.flag-icon-lu {
  background-image: url(/static/media/lu.fb7cabf8.webp); }

.flag-icon-lu.flag-icon-squared {
  background-image: url(/static/media/lu.8a6d7794.webp); }

.flag-icon-lv {
  background-image: url(/static/media/lv.51f2daeb.webp); }

.flag-icon-lv.flag-icon-squared {
  background-image: url(/static/media/lv.8e8dd772.webp); }

.flag-icon-ly {
  background-image: url(/static/media/ly.a467f980.webp); }

.flag-icon-ly.flag-icon-squared {
  background-image: url(/static/media/ly.9545eaf1.webp); }

.flag-icon-ma {
  background-image: url(/static/media/ma.5f9da079.webp); }

.flag-icon-ma.flag-icon-squared {
  background-image: url(/static/media/ma.b90a237b.webp); }

.flag-icon-mc {
  background-image: url(/static/media/mc.8f58014b.webp); }

.flag-icon-mc.flag-icon-squared {
  background-image: url(/static/media/mc.d061fae8.webp); }

.flag-icon-md {
  background-image: url(/static/media/md.90beab0b.webp); }

.flag-icon-md.flag-icon-squared {
  background-image: url(/static/media/md.aa083ed5.webp); }

.flag-icon-me {
  background-image: url(/static/media/me.dcd6274d.webp); }

.flag-icon-me.flag-icon-squared {
  background-image: url(/static/media/me.40c2020b.webp); }

.flag-icon-mf {
  background-image: url(/static/media/mf.6eb47c3c.webp); }

.flag-icon-mf.flag-icon-squared {
  background-image: url(/static/media/mf.2b05b4fe.webp); }

.flag-icon-mg {
  background-image: url(/static/media/mg.3993aeff.webp); }

.flag-icon-mg.flag-icon-squared {
  background-image: url(/static/media/mg.98b9c832.webp); }

.flag-icon-mh {
  background-image: url(/static/media/mh.0a0eeeab.webp); }

.flag-icon-mh.flag-icon-squared {
  background-image: url(/static/media/mh.187d7d03.webp); }

.flag-icon-mk {
  background-image: url(/static/media/mk.55c34ecb.webp); }

.flag-icon-mk.flag-icon-squared {
  background-image: url(/static/media/mk.5dc4fdd1.webp); }

.flag-icon-ml {
  background-image: url(/static/media/ml.7a2a692c.webp); }

.flag-icon-ml.flag-icon-squared {
  background-image: url(/static/media/ml.e22c81a6.webp); }

.flag-icon-mm {
  background-image: url(/static/media/mm.eb304d49.webp); }

.flag-icon-mm.flag-icon-squared {
  background-image: url(/static/media/mm.b2f4afd6.webp); }

.flag-icon-mn {
  background-image: url(/static/media/mn.a979483c.webp); }

.flag-icon-mn.flag-icon-squared {
  background-image: url(/static/media/mn.bd34979c.webp); }

.flag-icon-mo {
  background-image: url(/static/media/mo.18362f3d.webp); }

.flag-icon-mo.flag-icon-squared {
  background-image: url(/static/media/mo.64f458dd.webp); }

.flag-icon-mp {
  background-image: url(/static/media/mp.3c592959.webp); }

.flag-icon-mp.flag-icon-squared {
  background-image: url(/static/media/mp.88af4931.webp); }

.flag-icon-mq {
  background-image: url(/static/media/mq.c7fb1951.webp); }

.flag-icon-mq.flag-icon-squared {
  background-image: url(/static/media/mq.2b05b4fe.webp); }

.flag-icon-mr {
  background-image: url(/static/media/mr.123562f7.webp); }

.flag-icon-mr.flag-icon-squared {
  background-image: url(/static/media/mr.f1bcbcc6.webp); }

.flag-icon-ms {
  background-image: url(/static/media/ms.db0b4c30.webp); }

.flag-icon-ms.flag-icon-squared {
  background-image: url(/static/media/ms.8a6b8154.webp); }

.flag-icon-mt {
  background-image: url(/static/media/mt.550e6ff2.webp); }

.flag-icon-mt.flag-icon-squared {
  background-image: url(/static/media/mt.ddd78b69.webp); }

.flag-icon-mu {
  background-image: url(/static/media/mu.eba8b89d.webp); }

.flag-icon-mu.flag-icon-squared {
  background-image: url(/static/media/mu.688ee34c.webp); }

.flag-icon-mv {
  background-image: url(/static/media/mv.ba7fc681.webp); }

.flag-icon-mv.flag-icon-squared {
  background-image: url(/static/media/mv.c2c62145.webp); }

.flag-icon-mw {
  background-image: url(/static/media/mw.db8c6330.webp); }

.flag-icon-mw.flag-icon-squared {
  background-image: url(/static/media/mw.922b9c0f.webp); }

.flag-icon-mx {
  background-image: url(/static/media/mx.bbf621ec.webp); }

.flag-icon-mx.flag-icon-squared {
  background-image: url(/static/media/mx.1de9c557.webp); }

.flag-icon-my {
  background-image: url(/static/media/my.9441ca1c.webp); }

.flag-icon-my.flag-icon-squared {
  background-image: url(/static/media/my.a800efe5.webp); }

.flag-icon-mz {
  background-image: url(/static/media/mz.41fef865.webp); }

.flag-icon-mz.flag-icon-squared {
  background-image: url(/static/media/mz.28aebbec.webp); }

.flag-icon-na {
  background-image: url(/static/media/na.b1f66c6d.webp); }

.flag-icon-na.flag-icon-squared {
  background-image: url(/static/media/na.beddb728.webp); }

.flag-icon-nc {
  background-image: url(/static/media/nc.6eb47c3c.webp); }

.flag-icon-nc.flag-icon-squared {
  background-image: url(/static/media/nc.2b05b4fe.webp); }

.flag-icon-ne {
  background-image: url(/static/media/ne.5313add7.webp); }

.flag-icon-ne.flag-icon-squared {
  background-image: url(/static/media/ne.12853ea5.webp); }

.flag-icon-nf {
  background-image: url(/static/media/nf.6bf0494f.webp); }

.flag-icon-nf.flag-icon-squared {
  background-image: url(/static/media/nf.e5184678.webp); }

.flag-icon-ng {
  background-image: url(/static/media/ng.07a686e2.webp); }

.flag-icon-ng.flag-icon-squared {
  background-image: url(/static/media/ng.566c55b1.webp); }

.flag-icon-ni {
  background-image: url(/static/media/ni.36b7b9fb.webp); }

.flag-icon-ni.flag-icon-squared {
  background-image: url(/static/media/ni.64a41652.webp); }

.flag-icon-nl {
  background-image: url(/static/media/nl.2f7b292b.webp); }

.flag-icon-nl.flag-icon-squared {
  background-image: url(/static/media/nl.6eab2b6c.webp); }

.flag-icon-no {
  background-image: url(/static/media/no.325285ce.webp); }

.flag-icon-no.flag-icon-squared {
  background-image: url(/static/media/no.95b14a36.webp); }

.flag-icon-np {
  background-image: url(/static/media/np.b442aa4e.webp); }

.flag-icon-np.flag-icon-squared {
  background-image: url(/static/media/np.64d5614d.webp); }

.flag-icon-nr {
  background-image: url(/static/media/nr.da0dfb1b.webp); }

.flag-icon-nr.flag-icon-squared {
  background-image: url(/static/media/nr.b200f441.webp); }

.flag-icon-nu {
  background-image: url(/static/media/nu.c30ac62a.webp); }

.flag-icon-nu.flag-icon-squared {
  background-image: url(/static/media/nu.ea68b12b.webp); }

.flag-icon-nz {
  background-image: url(/static/media/nz.de853049.webp); }

.flag-icon-nz.flag-icon-squared {
  background-image: url(/static/media/nz.dbf543a7.webp); }

.flag-icon-om {
  background-image: url(/static/media/om.ced03b48.webp); }

.flag-icon-om.flag-icon-squared {
  background-image: url(/static/media/om.d4a6f201.webp); }

.flag-icon-pa {
  background-image: url(/static/media/pa.6e86d7a0.webp); }

.flag-icon-pa.flag-icon-squared {
  background-image: url(/static/media/pa.784b6fd2.webp); }

.flag-icon-pe {
  background-image: url(/static/media/pe.3d9b4c81.webp); }

.flag-icon-pe.flag-icon-squared {
  background-image: url(/static/media/pe.f805fa29.webp); }

.flag-icon-pf {
  background-image: url(/static/media/pf.2715d8ad.webp); }

.flag-icon-pf.flag-icon-squared {
  background-image: url(/static/media/pf.14d4f9ae.webp); }

.flag-icon-pg {
  background-image: url(/static/media/pg.ba07d9aa.webp); }

.flag-icon-pg.flag-icon-squared {
  background-image: url(/static/media/pg.f0b25b74.webp); }

.flag-icon-ph {
  background-image: url(/static/media/ph.2dd95eec.webp); }

.flag-icon-ph.flag-icon-squared {
  background-image: url(/static/media/ph.c7bfeaf4.webp); }

.flag-icon-pk {
  background-image: url(/static/media/pk.b29b43dc.webp); }

.flag-icon-pk.flag-icon-squared {
  background-image: url(/static/media/pk.27e1ede6.webp); }

.flag-icon-pl {
  background-image: url(/static/media/pl.ba75d449.webp); }

.flag-icon-pl.flag-icon-squared {
  background-image: url(/static/media/pl.5e5a53d7.webp); }

.flag-icon-pm {
  background-image: url(/static/media/pm.6eb47c3c.webp); }

.flag-icon-pm.flag-icon-squared {
  background-image: url(/static/media/pm.2b05b4fe.webp); }

.flag-icon-pn {
  background-image: url(/static/media/pn.da70a850.webp); }

.flag-icon-pn.flag-icon-squared {
  background-image: url(/static/media/pn.743974ea.webp); }

.flag-icon-pr {
  background-image: url(/static/media/pr.2eb2f2d4.webp); }

.flag-icon-pr.flag-icon-squared {
  background-image: url(/static/media/pr.0847f4b1.webp); }

.flag-icon-ps {
  background-image: url(/static/media/ps.0ea2093d.webp); }

.flag-icon-ps.flag-icon-squared {
  background-image: url(/static/media/ps.130ada91.webp); }

.flag-icon-pt {
  background-image: url(/static/media/pt.d5c0336d.webp); }

.flag-icon-pt.flag-icon-squared {
  background-image: url(/static/media/pt.508268ff.webp); }

.flag-icon-pw {
  background-image: url(/static/media/pw.99a21cc1.webp); }

.flag-icon-pw.flag-icon-squared {
  background-image: url(/static/media/pw.224d510f.webp); }

.flag-icon-py {
  background-image: url(/static/media/py.780f184a.webp); }

.flag-icon-py.flag-icon-squared {
  background-image: url(/static/media/py.0884ed60.webp); }

.flag-icon-qa {
  background-image: url(/static/media/qa.b61d0013.webp); }

.flag-icon-qa.flag-icon-squared {
  background-image: url(/static/media/qa.885df84d.webp); }

.flag-icon-re {
  background-image: url(/static/media/re.6eb47c3c.webp); }

.flag-icon-re.flag-icon-squared {
  background-image: url(/static/media/re.2b05b4fe.webp); }

.flag-icon-ro {
  background-image: url(/static/media/ro.fa69e621.webp); }

.flag-icon-ro.flag-icon-squared {
  background-image: url(/static/media/ro.2874c231.webp); }

.flag-icon-rs {
  background-image: url(/static/media/rs.089c325c.webp); }

.flag-icon-rs.flag-icon-squared {
  background-image: url(/static/media/rs.7709858d.webp); }

.flag-icon-ru {
  background-image: url(/static/media/ru.cd3eb368.webp); }

.flag-icon-ru.flag-icon-squared {
  background-image: url(/static/media/ru.96b4640d.webp); }

.flag-icon-rw {
  background-image: url(/static/media/rw.d178c033.webp); }

.flag-icon-rw.flag-icon-squared {
  background-image: url(/static/media/rw.ebc0cfa0.webp); }

.flag-icon-sa {
  background-image: url(/static/media/sa.9b19f2a0.webp); }

.flag-icon-sa.flag-icon-squared {
  background-image: url(/static/media/sa.b8f94381.webp); }

.flag-icon-sb {
  background-image: url(/static/media/sb.5678df1f.webp); }

.flag-icon-sb.flag-icon-squared {
  background-image: url(/static/media/sb.53fae8f3.webp); }

.flag-icon-sc {
  background-image: url(/static/media/sc.896ffc9b.webp); }

.flag-icon-sc.flag-icon-squared {
  background-image: url(/static/media/sc.75f5f821.webp); }

.flag-icon-sd {
  background-image: url(/static/media/sd.191839ca.webp); }

.flag-icon-sd.flag-icon-squared {
  background-image: url(/static/media/sd.e615b768.webp); }

.flag-icon-se {
  background-image: url(/static/media/se.ad236f26.webp); }

.flag-icon-se.flag-icon-squared {
  background-image: url(/static/media/se.043d1079.webp); }

.flag-icon-sg {
  background-image: url(/static/media/sg.0c1b92e0.webp); }

.flag-icon-sg.flag-icon-squared {
  background-image: url(/static/media/sg.68ec1eab.webp); }

.flag-icon-sh {
  background-image: url(/static/media/sh.24d778df.webp); }

.flag-icon-sh.flag-icon-squared {
  background-image: url(/static/media/sh.d1d5344d.webp); }

.flag-icon-si {
  background-image: url(/static/media/si.0046faf9.webp); }

.flag-icon-si.flag-icon-squared {
  background-image: url(/static/media/si.1ca9a7d9.webp); }

.flag-icon-sj {
  background-image: url(/static/media/sj.6c006272.webp); }

.flag-icon-sj.flag-icon-squared {
  background-image: url(/static/media/sj.76841f24.webp); }

.flag-icon-sk {
  background-image: url(/static/media/sk.e8177196.webp); }

.flag-icon-sk.flag-icon-squared {
  background-image: url(/static/media/sk.2c160b72.webp); }

.flag-icon-sl {
  background-image: url(/static/media/sl.3c53b9a7.webp); }

.flag-icon-sl.flag-icon-squared {
  background-image: url(/static/media/sl.eb3107d6.webp); }

.flag-icon-sm {
  background-image: url(/static/media/sm.85bf2ad0.webp); }

.flag-icon-sm.flag-icon-squared {
  background-image: url(/static/media/sm.6dd456e6.webp); }

.flag-icon-sn {
  background-image: url(/static/media/sn.a4250ec9.webp); }

.flag-icon-sn.flag-icon-squared {
  background-image: url(/static/media/sn.a7a2f46b.webp); }

.flag-icon-so {
  background-image: url(/static/media/so.6c69869d.webp); }

.flag-icon-so.flag-icon-squared {
  background-image: url(/static/media/so.262f035f.webp); }

.flag-icon-sr {
  background-image: url(/static/media/sr.ab754813.webp); }

.flag-icon-sr.flag-icon-squared {
  background-image: url(/static/media/sr.f2b36025.webp); }

.flag-icon-ss {
  background-image: url(/static/media/ss.73830661.webp); }

.flag-icon-ss.flag-icon-squared {
  background-image: url(/static/media/ss.8c526a2c.webp); }

.flag-icon-st {
  background-image: url(/static/media/st.f128693b.webp); }

.flag-icon-st.flag-icon-squared {
  background-image: url(/static/media/st.ef7341d7.webp); }

.flag-icon-sv {
  background-image: url(/static/media/sv.9c7ae511.webp); }

.flag-icon-sv.flag-icon-squared {
  background-image: url(/static/media/sv.937ac893.webp); }

.flag-icon-sx {
  background-image: url(/static/media/sx.0fe41df2.webp); }

.flag-icon-sx.flag-icon-squared {
  background-image: url(/static/media/sx.e6535b85.webp); }

.flag-icon-sy {
  background-image: url(/static/media/sy.c541ef8b.webp); }

.flag-icon-sy.flag-icon-squared {
  background-image: url(/static/media/sy.8bd128f7.webp); }

.flag-icon-sz {
  background-image: url(/static/media/sz.134923b0.webp); }

.flag-icon-sz.flag-icon-squared {
  background-image: url(/static/media/sz.75198b6c.webp); }

.flag-icon-tc {
  background-image: url(/static/media/tc.7da62516.webp); }

.flag-icon-tc.flag-icon-squared {
  background-image: url(/static/media/tc.e43132ba.webp); }

.flag-icon-td {
  background-image: url(/static/media/td.6a586bb2.webp); }

.flag-icon-td.flag-icon-squared {
  background-image: url(/static/media/td.cfdb7777.webp); }

.flag-icon-tf {
  background-image: url(/static/media/tf.b5ade249.webp); }

.flag-icon-tf.flag-icon-squared {
  background-image: url(/static/media/tf.1a23dd3d.webp); }

.flag-icon-tg {
  background-image: url(/static/media/tg.24e36cf7.webp); }

.flag-icon-tg.flag-icon-squared {
  background-image: url(/static/media/tg.4a1951ba.webp); }

.flag-icon-th {
  background-image: url(/static/media/th.2fb00d79.webp); }

.flag-icon-th.flag-icon-squared {
  background-image: url(/static/media/th.f4443e83.webp); }

.flag-icon-tj {
  background-image: url(/static/media/tj.e8f2f847.webp); }

.flag-icon-tj.flag-icon-squared {
  background-image: url(/static/media/tj.019ddfe9.webp); }

.flag-icon-tk {
  background-image: url(/static/media/tk.5bea383d.webp); }

.flag-icon-tk.flag-icon-squared {
  background-image: url(/static/media/tk.88270353.webp); }

.flag-icon-tl {
  background-image: url(/static/media/tl.74645308.webp); }

.flag-icon-tl.flag-icon-squared {
  background-image: url(/static/media/tl.5a57fd3b.webp); }

.flag-icon-tm {
  background-image: url(/static/media/tm.e1f153b0.webp); }

.flag-icon-tm.flag-icon-squared {
  background-image: url(/static/media/tm.8bcb651f.webp); }

.flag-icon-tn {
  background-image: url(/static/media/tn.8fee5e42.webp); }

.flag-icon-tn.flag-icon-squared {
  background-image: url(/static/media/tn.978b8fd2.webp); }

.flag-icon-to {
  background-image: url(/static/media/to.ab51f488.webp); }

.flag-icon-to.flag-icon-squared {
  background-image: url(/static/media/to.ea73d814.webp); }

.flag-icon-tr {
  background-image: url(/static/media/tr.5735afca.webp); }

.flag-icon-tr.flag-icon-squared {
  background-image: url(/static/media/tr.adbcee4d.webp); }

.flag-icon-tt {
  background-image: url(/static/media/tt.9c5a69d0.webp); }

.flag-icon-tt.flag-icon-squared {
  background-image: url(/static/media/tt.70f8a960.webp); }

.flag-icon-tv {
  background-image: url(/static/media/tv.4c3142a5.webp); }

.flag-icon-tv.flag-icon-squared {
  background-image: url(/static/media/tv.09a86995.webp); }

.flag-icon-tw {
  background-image: url(/static/media/tw.4d0bb249.webp); }

.flag-icon-tw.flag-icon-squared {
  background-image: url(/static/media/tw.30ff97e7.webp); }

.flag-icon-tz {
  background-image: url(/static/media/tz.007f9a9c.webp); }

.flag-icon-tz.flag-icon-squared {
  background-image: url(/static/media/tz.9546fc6e.webp); }

.flag-icon-ua {
  background-image: url(/static/media/ua.17882ad8.webp); }

.flag-icon-ua.flag-icon-squared {
  background-image: url(/static/media/ua.2e9e00f4.webp); }

.flag-icon-ug {
  background-image: url(/static/media/ug.603ad208.webp); }

.flag-icon-ug.flag-icon-squared {
  background-image: url(/static/media/ug.39bf1678.webp); }

.flag-icon-um {
  background-image: url(/static/media/um.dc013500.webp); }

.flag-icon-um.flag-icon-squared {
  background-image: url(/static/media/um.c68008a3.webp); }

.flag-icon-us {
  background-image: url(/static/media/us.dc013500.webp); }

.flag-icon-us.flag-icon-squared {
  background-image: url(/static/media/us.c68008a3.webp); }

.flag-icon-uy {
  background-image: url(/static/media/uy.3e607fd6.webp); }

.flag-icon-uy.flag-icon-squared {
  background-image: url(/static/media/uy.d17d8fe1.webp); }

.flag-icon-uz {
  background-image: url(/static/media/uz.b6dec4f6.webp); }

.flag-icon-uz.flag-icon-squared {
  background-image: url(/static/media/uz.f966e221.webp); }

.flag-icon-va {
  background-image: url(/static/media/va.adc15837.webp); }

.flag-icon-va.flag-icon-squared {
  background-image: url(/static/media/va.36ba9919.webp); }

.flag-icon-vc {
  background-image: url(/static/media/vc.5b7394aa.webp); }

.flag-icon-vc.flag-icon-squared {
  background-image: url(/static/media/vc.17b76bbf.webp); }

.flag-icon-ve {
  background-image: url(/static/media/ve.31ff3f4e.webp); }

.flag-icon-ve.flag-icon-squared {
  background-image: url(/static/media/ve.bfb82629.webp); }

.flag-icon-vg {
  background-image: url(/static/media/vg.d29617ff.webp); }

.flag-icon-vg.flag-icon-squared {
  background-image: url(/static/media/vg.0ce2e4d7.webp); }

.flag-icon-vi {
  background-image: url(/static/media/vi.0be016f5.webp); }

.flag-icon-vi.flag-icon-squared {
  background-image: url(/static/media/vi.7f64f20d.webp); }

.flag-icon-vn {
  background-image: url(/static/media/vn.e9330fb5.webp); }

.flag-icon-vn.flag-icon-squared {
  background-image: url(/static/media/vn.5dc02d92.webp); }

.flag-icon-vu {
  background-image: url(/static/media/vu.f0c32698.webp); }

.flag-icon-vu.flag-icon-squared {
  background-image: url(/static/media/vu.5e84b696.webp); }

.flag-icon-wf {
  background-image: url(/static/media/wf.6eb47c3c.webp); }

.flag-icon-wf.flag-icon-squared {
  background-image: url(/static/media/wf.d626b97b.webp); }

.flag-icon-ws {
  background-image: url(/static/media/ws.db00a32c.webp); }

.flag-icon-ws.flag-icon-squared {
  background-image: url(/static/media/ws.905c2ffe.webp); }

.flag-icon-ye {
  background-image: url(/static/media/ye.1f029acf.webp); }

.flag-icon-ye.flag-icon-squared {
  background-image: url(/static/media/ye.f026a25e.webp); }

.flag-icon-yt {
  background-image: url(/static/media/yt.6eb47c3c.webp); }

.flag-icon-yt.flag-icon-squared {
  background-image: url(/static/media/yt.2b05b4fe.webp); }

.flag-icon-za {
  background-image: url(/static/media/za.9817d55e.webp); }

.flag-icon-za.flag-icon-squared {
  background-image: url(/static/media/za.77123c7a.webp); }

.flag-icon-zm {
  background-image: url(/static/media/zm.32bf031f.webp); }

.flag-icon-zm.flag-icon-squared {
  background-image: url(/static/media/zm.7fbea6b2.webp); }

.flag-icon-zw {
  background-image: url(/static/media/zw.98521c21.webp); }

.flag-icon-zw.flag-icon-squared {
  background-image: url(/static/media/zw.4973c019.webp); }

.flag-icon-eu {
  background-image: url(/static/media/eu.d12cb7ef.webp); }

.flag-icon-eu.flag-icon-squared {
  background-image: url(/static/media/eu.17f1702f.webp); }

.flag-icon-gb-eng {
  background-image: url(/static/media/gb-eng.13007d95.webp); }

.flag-icon-gb-eng.flag-icon-squared {
  background-image: url(/static/media/gb-eng.7641f901.webp); }

.flag-icon-gb-sct {
  background-image: url(/static/media/gb-sct.7cdf6b88.webp); }

.flag-icon-gb-sct.flag-icon-squared {
  background-image: url(/static/media/gb-sct.7925dd86.webp); }

.flag-icon-gb-wls {
  background-image: url(/static/media/gb-wls.68fb3f02.webp); }

.flag-icon-gb-wls.flag-icon-squared {
  background-image: url(/static/media/gb-wls.e01b1fa3.webp); }

.flag-icon-un {
  background-image: url(/static/media/un.161527de.webp); }

.flag-icon-un.flag-icon-squared {
  background-image: url(/static/media/un.a2052b33.webp); }

.contact .document-pages-header {
  display: none; }

@media only screen and (max-width: 1024px) {
  .contact .document-pages-header {
    display: block !important;
    font-size: 20px;
    font-weight: bold;
    background-color: var(--dark-blue);
    text-align: center;
    padding: 20px 0 20px 0;
    text-transform: uppercase;
    margin-bottom: 20px; } }

.contact .form .ant-select-selection--single, .contact .form .ant-input {
  background-color: var(--form-input-background-color);
  border: transparent;
  color: #808897; }

.contact .form .ant-form-item .ant-form-item-label label {
  font-weight: 400 !important; }

.contact .form .ant-form-item-required::before {
  display: none; }

.contact .form .ant-form-item-required::after {
  display: inline-block;
  margin-left: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*'; }

.contact .sider-left,
.contact .sider-right {
  display: none !important; }

.contact .layout-inner {
  display: flex;
  justify-content: center; }
  .contact .layout-inner .content {
    max-width: 1024px !important; }
    .contact .layout-inner .content .container {
      display: flex;
      flex-direction: row; }
      @media only screen and (max-width: 1024px) {
        .contact .layout-inner .content .container {
          flex-direction: column; }
          .contact .layout-inner .content .container .left {
            margin-bottom: 30px; } }
      .contact .layout-inner .content .container .left {
        flex: 1 1;
        margin-right: 30px;
        width: 100%; }
      .contact .layout-inner .content .container .right {
        flex: 1 1;
        width: 100%; }
      .contact .layout-inner .content .container .right .google-map {
        height: 300px;
        width: 100%; }
      .contact .layout-inner .content .container .ant-layout-sider {
        border-radius: 4px;
        background-color: transparent;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 28px; }
      .contact .layout-inner .content .container .ant-layout-sider-children {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--dark-blue);
        border-radius: 5px;
        padding: 30px 20px 30px 20px; }
      .contact .layout-inner .content .container .title {
        font-size: 20px !important;
        font-weight: 400 !important;
        color: var(--white) !important;
        text-align: center;
        flex: 1 1;
        width: 285px;
        margin-bottom: 30px !important; }
      .contact .layout-inner .content .container .message {
        margin-top: 10px;
        color: var(--white); }
      .contact .layout-inner .content .container .message textarea {
        height: 155px !important;
        max-height: 155px !important; }
      .contact .layout-inner .content .container .captcha {
        margin-top: 20px;
        margin-bottom: 30px;
        overflow: visible;
        clear: both;
        width: 100% !important; }
      .contact .layout-inner .content .container .text .fu-title {
        font-size: 20px;
        font-weight: 600;
        opacity: 1 !important; }
      .contact .layout-inner .content .container .text:not(div) {
        width: 100%;
        font-size: 16px;
        opacity: 0.5 !important; }
    .contact .layout-inner .content .upload {
      background: #044da1; }
    .contact .layout-inner .content .anticon-paper-clip {
      display: none; }
    .contact .layout-inner .content .captcha > div > div {
      width: 100% !important; }
      .contact .layout-inner .content .captcha > div > div iframe {
        width: 100% !important; }
        .contact .layout-inner .content .captcha > div > div iframe * {
          width: 100% !important;
          border: solid 1px red; }

#rc-imageselect {
  transform: scale(0.1);
  -webkit-transform: scale(0.1);
  transform-origin: 0 0;
  -webkit-transform-origin: 0 0; }

.g-recaptcha {
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  -moz-transform-origin: 0;
  -ms-transform-origin: 0;
  -o-transform-origin: 0;
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transform-origin: 0 0;
  transform-origin: 0;
  filter: progid:DXImageTransform.Microsoft.Matrix(M11=1.1, M12=0, M21=0, M22=1.1, SizingMethod='auto expand'); }

.google-map {
  height: 240px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 30px; }

/********** Ant Menu **********/
.ant-menu {
  background: var(--light-black);
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  border: unset; }
  .ant-menu .ant-menu-item {
    padding: unset !important;
    margin: unset !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: unset !important;
    min-height: 40px !important;
    display: flex; }
    .ant-menu .ant-menu-item .title {
      text-transform: capitalize;
      flex: 1 1;
      opacity: 0.7; }
  .ant-menu .ant-menu-item-selected::after {
    border-right: 4px solid #e01c23; }
  .ant-menu .ant-menu-submenu-selected {
    border: solid 1px transparent; }
  .ant-menu .ant-menu-submenu .ant-menu-submenu-arrow::before, .ant-menu .ant-menu-submenu .ant-menu-submenu-arrow::after {
    width: 10px;
    -webkit-filter: brightness(1.8);
            filter: brightness(1.8); }
  .ant-menu .ant-menu-submenu .ant-menu-submenu-arrow::before {
    margin-left: 3px; }
  .ant-menu .ant-menu-submenu .title {
    width: 100%;
    display: flex;
    flex-direction: row; }
    .ant-menu .ant-menu-submenu .title .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center; }
      .ant-menu .ant-menu-submenu .title .icon img {
        -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
        width: 16px;
        height: 16px;
        margin-right: 10px; }
  .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
    opacity: 0.7;
    margin: unset !important;
    padding: unset !important;
    background-color: #172a40;
    padding-left: 10px !important; }
  .ant-menu .ant-menu-submenu.ant-menu-submenu-open .today {
    padding-left: 40px !important; }
  .ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
    background-color: #243a53;
    display: flex;
    flex-direction: row;
    align-items: center; }
  .ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-sub .ant-menu-item {
    opacity: 0.7;
    border-bottom: solid 1px var(--light-black);
    background-color: #243a53; }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-sub .ant-menu-item .nav-div {
      padding-left: 40px; }
  .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul {
    background-color: #243a53; }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul .ant-menu-sub.ant-menu-inline {
      overflow-y: auto !important;
      overflow-x: hidden !important;
      max-height: 50vh;
      scrollbar-width: none;
      -ms-overflow-style: none; }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0px var(--light-black);
      background-color: var(--light-black); }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul .ant-menu-sub.ant-menu-inline::-webkit-scrollbar {
      width: 0px;
      background-color: var(--light-black); }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: #243a53;
      border: solid 0px var(--light-black); }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul li {
      margin: unset !important;
      padding: unset !important; }
      .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul li .ant-menu-submenu-title {
        padding-left: 40px !important; }
      .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul li .ant-menu-submenu-open ul li .nav-div {
        width: 206px;
        padding-left: 50px !important;
        display: flex;
        flex-direction: row;
        justify-content: space-between; }
        .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul li .ant-menu-submenu-open ul li .nav-div .nav-text {
          flex: 1 1;
          margin-right: 10px; }
        .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul li .ant-menu-submenu-open ul li .nav-div .nav-badge {
          flex: 0.1 1; }
          .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul li .ant-menu-submenu-open ul li .nav-div .nav-badge sup {
            margin-left: -15px !important; }
  .ant-menu .nav-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 200ms;
    margin: 0.4rem 0;
    height: 20px;
    padding: 1rem 0.5rem;
    border: 1px solid transparent;
    border-radius: 5px; }
    .ant-menu .nav-div:hover {
      background-color: var(--light-black);
      cursor: pointer; }
    .ant-menu .nav-div .nav-text {
      flex: 4 1;
      width: 100%;
      white-space: nowrap;
      line-height: normal;
      overflow: hidden !important;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: lighter; }
    .ant-menu .nav-div .nav-badge {
      margin-left: auto; }
      .ant-menu .nav-div .nav-badge sup {
        background-color: transparent !important;
        padding: unset !important;
        margin: unset !important;
        box-shadow: unset;
        text-align: center;
        font-size: 14px;
        text-align: right; }
  .ant-menu .flag-icon {
    margin-right: 2px;
    border-radius: 4px;
    width: 100%;
    flex: 1 1; }
  .ant-menu .ant-menu-item-divider {
    margin: 30px auto 0px auto;
    opacity: 0.2;
    height: 2px; }
  .ant-menu .flat-item {
    opacity: 0.7;
    background-color: #172a40; }
    .ant-menu .flat-item .text {
      text-transform: capitalize; }
    .ant-menu .flat-item .icon i svg {
      width: 16px;
      height: 16px; }

/*----------------- Virtual Menu -----------------*/
.virtual-menu .ant-menu-submenu {
  background-color: var(--virtual-menu-background-color) !important;
  margin-bottom: 1px !important; }

.virtual-menu .ant-menu-item-selected {
  background-color: #243a53 !important; }

/*----------------- My Account Menu -----------------*/
.account-menu .title {
  display: flex;
  align-items: center; }
  .account-menu .title .icon {
    font-size: 20px; }

.account-menu .ant-menu-item:not(:last-child) {
  border-bottom: solid 1px #243a53; }

.account-menu .ant-menu-item-selected {
  background-color: #243a53 !important; }

.account-menu .ant-menu-item-selected::after {
  border-right: 4px solid blue; }

/******************** leagues menus ********************/
.sub-menu {
  width: 100%;
  background-color: var(--dark-blue);
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0 0 1rem 0; }

#sub-menu-items .sub-menu-divs {
  border-bottom: 1px solid #27334C;
  padding-top: 5px;
  padding-bottom: 5px; }

#menu-tree-bet-services #bet-services-statistics-container {
  border-bottom: 1px solid #27334C;
  padding-bottom: 20px;
  padding-top: 20px; }

#sub-menu-items > div:last-of-type {
  border: none !important; }

.menu-banner {
  font-size: 1.2rem;
  font-weight: 300;
  height: 2rem;
  padding: 1rem 0;
  margin: 0 0 0.3rem 0; }

.sub-menu {
  display: flex;
  flex-direction: column; }

.menu-item {
  height: 20px;
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: lighter;
  font-size: 16px; }
  .menu-item:hover {
    background-color: var(--light-black);
    cursor: pointer; }

@media only screen and (max-width: 950px) {
  #sub-menu-items .sub-menu-divs {
    border-bottom: 1px solid #3A4967; }
  #menu-tree-bet-services #bet-services-statistics-container {
    border-bottom: 1px solid #3A4967; } }

.modal-container {
  display: "flex";
  flex-direction: "column"; }
  .modal-container .modal-buttons {
    text-align: center; }
    .modal-container .modal-buttons .modal-button {
      max-width: -webkit-max-content;
      max-width: max-content;
      margin-left: 10px !important; }

.screen {
  flex: 1 1;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /*********************** Header Styles ************************/
  /*********************** Banner Styles ************************/
  /*********************** Body Styles ************************/
  /*********************** Sider ************************/
  /*********************** Title Strip ************************/
  /*********************** Footer Styles ************************/ }
  .screen #sport-categories-menu {
    padding-bottom: 5px; }
    .screen #sport-categories-menu .ant-tabs-tab-next-icon {
      left: -10% !important; }
    .screen #sport-categories-menu .ant-tabs-bar {
      border: none !important; }
    .screen #sport-categories-menu .ant-tabs-nav-scroll {
      display: flex;
      border-bottom: 3px solid rgba(174, 180, 191, 0.25) !important;
      border-bottom-left-radius: 5px !important;
      border-bottom-right-radius: 5px !important; }
    .screen #sport-categories-menu .ant-tabs-tab-prev-icon-target, .screen #sport-categories-menu .ant-tabs-tab-next-icon-target {
      font-size: 18px !important;
      opacity: 0.9; }
    .screen #sport-categories-menu .ant-tabs-nav-wrap {
      margin: 0 !important; }
    .screen #sport-categories-menu .ant-tabs-nav .ant-tabs-tab {
      margin: 0 0px 0 0  !important;
      padding-right: 4%; }
  .screen .sticky-header {
    z-index: 2; }
  .screen .ant-tabs-tab-prev.ant-tabs-tab-arrow-show, .screen .ant-tabs-tab-next.ant-tabs-tab-arrow-show {
    z-index: 0 !important; }
  .screen .header {
    width: 100%;
    flex: 1 1;
    line-height: unset;
    padding: unset;
    height: unset;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: -webkit-sticky;
    position: sticky;
    top: 0; }
    .screen .header .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 1;
      width: 100%; }
      .screen .header .container .connection {
        border-radius: 50px;
        width: 10px;
        height: 10px;
        margin-left: 20px; }
      @media only screen and (max-width: 700px) {
        .screen .header .container .connection {
          margin-left: 10px; }
        .screen .header .container .mode-switcher {
          padding: 0.4rem; }
        .screen .header .container #mode-switcher-text {
          display: none; } }
      .screen .header .container .top {
        padding: 0 6vw 0 6vw;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: var(--light-black); }
        .screen .header .container .top .size {
          max-width: 1760px;
          width: 100%;
          flex: 1 1;
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 50px; }
          .screen .header .container .top .size .link {
            margin-left: 16px;
            font-size: 14px;
            font-weight: bold;
            cursor: pointer; }
          .screen .header .container .top .size .time {
            opacity: var(--high-to-low-opacity);
            font-size: 14px;
            margin-left: 10px;
            color: gray !important; }
      .screen .header .container .top-menu {
        width: 100%;
        min-height: 50px;
        align-items: center;
        padding: 0 6vw 0 6vw;
        background-color: var(--dark-blue); }
        .screen .header .container .top-menu .size {
          max-width: 1760px;
          width: 100%;
          flex: 1 1;
          display: flex;
          align-items: center;
          justify-content: space-between; }
        .screen .header .container .top-menu .title-wrapper {
          text-transform: uppercase;
          display: flex;
          background-color: var(--header-menu-back-ground-color);
          width: 34vw;
          height: 40px;
          padding: 0.7rem 3rem;
          border-radius: 100px;
          justify-content: space-between; }
          .screen .header .container .top-menu .title-wrapper .title {
            color: var(--header-menu-items);
            font-weight: bolder;
            font-size: 1.1vw; }
            .screen .header .container .top-menu .title-wrapper .title:hover {
              color: var(--white) !important; }
          .screen .header .container .top-menu .title-wrapper .selected {
            color: var(--header-menu-selected-item); }
        .screen .header .container .top-menu .title {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal; }
          .screen .header .container .top-menu .title img {
            -webkit-filter: brightness(0) invert(1);
                    filter: brightness(0) invert(1); }
        .screen .header .container .top-menu .selected {
          color: #0e7eff; }
          .screen .header .container .top-menu .selected img {
            -webkit-filter: blue;
                    filter: blue; }
        .screen .header .container .top-menu .title:hover {
          cursor: pointer;
          color: #0e7eff; }
        .screen .header .container .top-menu .title img {
          margin-right: 5px; }
        .screen .header .container .top-menu .logo {
          cursor: pointer;
          margin-right: 3%; }
      .screen .header .container .login .account-details {
        height: auto;
        min-height: 45px;
        margin-right: 20px !important;
        border-radius: 25px;
        background-color: var(--white-to-blue);
        border: unset;
        font-size: 16px; }
        .screen .header .container .login .account-details span {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          color: var(--black-to-white) !important; }
      .screen .header .container .logedin .top-nav-account {
        display: flex;
        align-items: center; }
      .screen .header .container .login {
        display: flex;
        justify-content: flex-end;
        margin-top: 5px; }
        .screen .header .container .login input[type="text"],
        .screen .header .container .login input[type="password"] {
          height: 40px;
          width: 200px;
          font-size: 14px; }
        .screen .header .container .login .ant-form-inline .ant-form-item {
          margin: unset;
          margin-left: 15px; }
        .screen .header .container .login .ant-form-explain {
          display: none; }
        .screen .header .container .login .balance {
          margin-right: 20px;
          font-size: 18px;
          font-weight: bold; }
          .screen .header .container .login .balance .default-currency .ant-select-selection {
            height: 40px;
            display: flex;
            align-items: center;
            background: #044da1;
            border: none; }
        .screen .header .container .login .balance-bounce {
          -webkit-animation-name: bounce;
                  animation-name: bounce;
          -webkit-animation-duration: 1s;
                  animation-duration: 1s;
          -webkit-animation-iteration-count: 1;
                  animation-iteration-count: 1; }

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px); }
  60% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-8px);
            transform: translateY(-8px); }
  60% {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px); } }
        .screen .header .container .login .buttons {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-evenly;
          padding-bottom: 5px; }
          .screen .header .container .login .buttons button {
            min-width: 100px;
            font-weight: bold;
            font-family: 'Oswald', sans-serif !important;
            border: 1px solid var(--white-to-gray);
            font-size: 14px;
            height: 40px; }
          .screen .header .container .login .buttons button.clear {
            background-color: transparent;
            border: solid 1px var(--white) !important; }
            .screen .header .container .login .buttons button.clear span {
              color: var(--white) !important; }
      .screen .header .container .logedin {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto; }
        .screen .header .container .logedin .deposit {
          margin-right: 20px; }
        .screen .header .container .logedin .balance {
          margin-right: 20px;
          font-size: 18px;
          font-weight: bold; }
          .screen .header .container .logedin .balance .default-currency .ant-select-selection {
            height: 40px;
            display: flex;
            align-items: center;
            background: #044da1;
            border: none; }
        .screen .header .container .logedin .details {
          cursor: pointer; }
          .screen .header .container .logedin .details .drop {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center; }
            .screen .header .container .logedin .details .drop .icon {
              height: 36px;
              width: 36px;
              background-color: #044da1;
              border: solid 1px #006be7;
              border-radius: 30px;
              margin-right: 10px;
              font-size: 12px;
              font-weight: bold;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center; }
              .screen .header .container .logedin .details .drop .icon .first-letter {
                text-transform: uppercase;
                width: 9px;
                visibility: hidden; }
              .screen .header .container .logedin .details .drop .icon .first-letter:first-letter {
                visibility: visible; }
  .screen .banner {
    line-height: unset;
    padding: unset;
    height: unset;
    margin-top: 4px; }
    .screen .banner .container .content {
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
      .screen .banner .container .content .size {
        position: absolute;
        justify-content: center;
        align-items: center;
        text-align: center; }
      .screen .banner .container .content .text {
        font-size: 40px;
        font-weight: 900;
        text-shadow: 0 3px 0 #5b5b5b; }
      .screen .banner .container .content img {
        width: 100%;
        height: 100%; }
  .screen .body {
    display: flex;
    flex-direction: row;
    flex: 1 1;
    width: 100%;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 100% calc(100vh - 340px);
    padding-bottom: 30px; }
    .screen .body .size {
      margin-bottom: 30px; }
      .screen .body .size .content {
        width: 100%; }
        .screen .body .size .content .container .title {
          color: #182438;
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 40px; }
        .screen .body .size .content .container .mobile-title {
          font-size: 20px;
          font-weight: bold;
          background-color: var(--dark-blue);
          text-align: center;
          padding: 20px  0 20px 0;
          text-transform: uppercase; }
  @media only screen and (min-width: 1024px) {
    .screen .bg-00 {
      background-color: var(--light-black); }
    .screen .bg-22 {
      background-color: var(--light-black); }
    .screen .bg-51 {
      background-color: var(--light-black); }
    .screen .bg-11 {
      background-color: var(--light-black); }
    .screen .bg-61 {
      background-color: var(--light-black); }
    .screen .bg-616 {
      background-color: var(--light-black); }
    .screen .bg-71 {
      background-color: var(--light-black); }
    .screen .bg-62 {
      background-color: var(--light-black); }
    .screen .bg-82 {
      background-color: var(--light-black); }
    .screen .bg-63 {
      background-color: var(--light-black); }
    .screen .bg-64 {
      background-color: var(--light-black); }
    .screen .bg-65 {
      background-color: var(--light-black); }
    .screen .bg-66 {
      background-color: var(--light-black); }
    .screen .bg-612 {
      background-color: var(--light-black); }
    .screen .bg-6117 {
      background-color: var(--light-black); }
    .screen .bg-621 {
      background-color: var(--light-black); } }
  .screen .sider-left {
    background-color: var(--light-black);
    border-radius: 4px;
    margin-right: 20px; }
  .screen .sider-right {
    background-color: transparent;
    border-radius: 4px;
    margin-left: 20px; }
  .screen .strip {
    height: 71px;
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: var(--light-black);
    object-fit: contain;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    background-color: var(--dark-blue); }
    .screen .strip .title {
      font-size: 22px;
      font-weight: bold;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-left: 20px; }
      .screen .strip .title .live {
        margin-top: 3px;
        margin-left: 15px;
        color: #e01c23;
        border: solid 1px #e01c23;
        padding: 0 10px 0 10px;
        font-size: 18px; }
      .screen .strip .title img {
        margin-right: 15px;
        -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
        width: 36px;
        height: 36px; }
    .screen .strip .images {
      display: block;
      overflow: auto;
      margin-left: auto;
      display: flex;
      overflow: hidden; }
    .screen .strip .pic {
      margin-bottom: 1px;
      border-radius: 4px; }
    .screen .strip .mask {
      margin-left: -1px;
      position: absolute;
      border: unset;
      align-self: flex-end; }
    @media only screen and (max-width: 1024px) {
      .screen .strip {
        display: none; }
        .screen .strip .form {
          display: none !important; } }
  .screen .footer {
    width: 100%;
    background-color: var(--light-black);
    display: flex;
    flex-direction: column;
    align-items: center; }
    .screen .footer .container {
      padding: 20px 0 20px 0;
      width: 100%;
      padding: 0 6vw 0 6vw; }
      .screen .footer .container .content .size {
        width: 100%;
        flex: 1 1;
        display: flex; }
      .screen .footer .container .content .child {
        font-size: 16px; }
      .screen .footer .container .content .child:nth-child(1) {
        flex: 0.05 1; }
      .screen .footer .container .content .child:nth-child(2) {
        margin-right: 20px;
        flex: 0.2 1;
        line-height: 2.2; }
      .screen .footer .container .content .child:nth-child(3) {
        flex: 1 1; }
      .screen .footer .container .content .footer-description, .screen .footer .container .content .disclamer .text {
        opacity: 0.7; }
      .screen .footer .container .content .footer-description {
        margin-top: 2.35rem; }

.currency-message-popover {
  position: fixed; }
  .currency-message-popover .ant-popover-message-title {
    color: rgba(0, 0, 0, 0.65); }
  .currency-message-popover .ant-popover-buttons .ant-btn {
    font-size: 14px;
    border-radius: 4px;
    height: 24px;
    width: auto;
    font-weight: unset;
    margin-left: 8px !important; }
  .currency-message-popover .ant-popover-buttons button:first-child {
    border: 1px solid #d9d9d9 !important; }
    .currency-message-popover .ant-popover-buttons button:first-child span {
      color: rgba(0, 0, 0, 0.65) !important; }

.logged-in-popover .ant-popover-content .ant-popover-inner .ant-popover-title {
  font-weight: bold; }

.forgot,
.register {
  line-height: normal;
  font-size: 15px;
  cursor: pointer;
  margin-top: 5px;
  color: var(--white) !important; }

.login-confirm-popover .ant-popover-message-title {
  color: rgba(0, 0, 0, 0.65); }

.login-confirm-popover .ant-popover-buttons .ant-btn {
  font-size: 14px;
  border-radius: 4px;
  height: 24px;
  width: auto;
  font-weight: unset;
  margin-left: 8px !important; }

.login-confirm-popover .ant-popover-buttons button:first-child {
  border: 1px solid #d9d9d9 !important; }
  .login-confirm-popover .ant-popover-buttons button:first-child span {
    color: rgba(0, 0, 0, 0.65) !important; }

.balance {
  margin-right: 5px;
  font-size: 14px; }
  .balance .default-currency .ant-select-selection {
    height: 40px;
    display: flex;
    align-items: center;
    background: #044da1;
    border: none; }
  .balance a {
    color: black;
    height: 30px;
    display: flex;
    align-items: center;
    border: none; }
  .balance button {
    border-radius: 2rem;
    font-weight: unset;
    border: 1px solid lightgray;
    margin: 10px 0 !important;
    border-color: lightgray !important;
    justify-content: center;
    align-items: center; }
  .balance .btn-selected-balance {
    background-color: rgba(10, 187, 135, 0.1);
    border-color: rgba(10, 187, 135, 0.1) !important; }
    .balance .btn-selected-balance a {
      color: #0abb87; }
  .balance .eye-icon {
    color: grey !important;
    cursor: pointer;
    font-size: 17px;
    margin-bottom: 0.5em; }
  .balance .eye-icon:hover {
    color: #0e7eff !important; }

.ant-popover-inner-content .details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  border-top: 1px solid #e8e8e8; }
  .ant-popover-inner-content .details button {
    padding: 5px;
    margin: 5px 0 !important;
    width: 180px; }

.ant-popover-inner-content .details a:first-child {
  padding-top: 10px !important; }

@-moz-document url-prefix() {
  .screen .header .container .logedin .details .drop .icon .first-letter {
    overflow: hidden;
    visibility: visible; } }

.general-modal.ant-modal {
  width: -webkit-max-content !important;
  width: max-content !important; }

.mode-switcher {
  background-color: var(--switcher-button);
  padding: 0.2rem 1rem;
  border-radius: 20px;
  color: var(--white-to-gray) !important; }
  .mode-switcher:hover {
    background-color: var(--buttons-hover);
    cursor: pointer; }
  .mode-switcher img {
    margin: 0 0.5rem; }
  .mode-switcher #light-mode-text {
    color: gray !important; }
  .mode-switcher span {
    color: var(--white-to-gray) !important;
    opacity: var(--high-to-low-opacity); }

@media only screen and (max-width: 1380px) {
  .screen .row-of-bet-details-buttons {
    justify-content: center; } }

@media only screen and (max-width: 600px) {
  .screen .footer .container .content .child {
    font-size: 14px !important; } }

@media only screen and (max-width: 600px) {
  .screen .header .container .login .account-details {
    width: 45vw;
    margin-right: 0px !important; }
    .screen .header .container .login .account-details #my-account-username {
      font-size: 2.2vw !important; }
    .screen .header .container .login .account-details #my-account-balance {
      font-size: 3vw !important; }
    .screen .header .container .login .account-details span {
      display: inline-block; }
    .screen .header .container .login .account-details .account-details-headline {
      display: flex;
      justify-content: space-around;
      align-items: center; } }

@media only screen {
  .screen {
    width: 100%; } }
  @media only screen and (max-width: 500px) {
    .screen .header .container .top-menu .title {
      font-size: 3.2vw !important; }
    .screen .header .mobile .login .buttons button {
      width: 18vw !important;
      height: 8vw; }
      .screen .header .mobile .login .buttons button span {
        display: flex;
        justify-content: center;
        align-self: center;
        font-size: 0.7rem !important; } }
  @media only screen and (max-width: 360px) {
    .screen .header #winners-logo {
      width: 100px !important; }
    .screen .header .mobile .login .buttons button {
      width: 22vw !important;
      height: 10vw; }
      .screen .header .mobile .login .buttons button span {
        font-size: 0.6rem !important; } }

@media only screen {
    .screen .header .mobile .mobile-header-top {
      display: flex;
      justify-content: space-between; }
    .screen .header .mobile .mobile-header-title-wrapper {
      display: flex;
      justify-content: space-between;
      text-transform: uppercase;
      background-color: var(--light-black);
      padding: 1rem 2rem;
      border-radius: 100px;
      color: var(--white) !important; }
    .screen .header .mobile #menu-icon-container {
      padding: 10px 14px 10px 14px;
      border-radius: 20px;
      background-color: var(--dark-blue);
      margin-top: 10px; }
      .screen .header .mobile #menu-icon-container .menu {
        -webkit-filter: brightness(0.75);
                filter: brightness(0.75); } }
  @media only screen and (max-width: 600px) {
    .screen .header .mobile .search-box-mobile-view {
      width: 30px; } }
  @media only screen and (max-width: 400px) {
    .screen .header .mobile #time-mobile-view {
      font-size: 8px; } }

@media only screen {
    .screen .header .mobile .search-box-mobile-view-icon {
      width: 130px; }
    .screen .header .mobile .search-box-top-menu:focus {
      outline: none !important;
      border-color: var(--white); }
    .screen .header .mobile .search-box-top-menu .ant-input-suffix svg {
      color: #2e3c4f !important; }
    .screen .header .mobile .search-box-top-menu {
      width: 200px; }
      .screen .header .mobile .search-box-top-menu .ant-input {
        border: none;
        color: #172a40;
        font-size: 14px;
        font-weight: bold;
        width: 20px; }
    .screen .header .mobile .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      padding: 15px;
      height: 70px !important; }
      .screen .header .mobile .top .menu {
        width: 50px;
        margin-top: 5px;
        cursor: pointer; }
        .screen .header .mobile .top .menu .icon {
          font-size: 25px;
          opacity: 0.5;
          color: #044da1; }
      .screen .header .mobile .top .logo {
        margin: 0 auto 0 auto; }
        .screen .header .mobile .top .logo img {
          height: 40px; }
      .screen .header .mobile .top .empty {
        width: 50px; }
        .screen .header .mobile .top .empty div {
          margin-left: auto; }
    .screen .header .mobile .top-menu .scroll::-webkit-scrollbar {
      display: none; }
    .screen .header .mobile .top-menu .scroll {
      overflow-y: hidden; }
    .screen .header .mobile .top-menu .light-on {
      color: #3E5167 !important; }
    .screen .header .mobile .top-menu .light-on:hover {
      color: var(--white) !important; }
    .screen .header .mobile .top-menu .title img {
      -webkit-filter: invert(70%) sepia(7%) saturate(3072%) hue-rotate(178deg) brightness(92%) contrast(90%);
              filter: invert(70%) sepia(7%) saturate(3072%) hue-rotate(178deg) brightness(92%) contrast(90%); }
    .screen .header .mobile .top-menu .selected {
      color: var(--white) !important; }
      .screen .header .mobile .top-menu .selected img {
        -webkit-filter: invert(17%) sepia(90%) saturate(2356%) hue-rotate(206deg) brightness(96%) contrast(96%);
                filter: invert(17%) sepia(90%) saturate(2356%) hue-rotate(206deg) brightness(96%) contrast(96%); }
    .screen .header .mobile .top-menu .title:hover {
      cursor: pointer;
      color: #0e7eff; }
    .screen .header .mobile .top-menu .title img {
      margin-right: 5px; }
    .screen .header .mobile .top-menu .logo {
      cursor: pointer;
      margin-right: 3%; }
    .screen .header .mobile .top-menu .gt {
      opacity: 0.5; }
    .screen .header .mobile .top-menu .scroll {
      overflow-y: hidden;
      overflow-x: auto;
      height: 100%;
      display: flex;
      align-items: center;
      margin-right: 10px; }
      .screen .header .mobile .top-menu .scroll .title {
        white-space: nowrap;
        font-size: 14px; }
        .screen .header .mobile .top-menu .scroll .title img {
          height: 18px; }
    .screen .header .mobile .bet-slip-icon {
      height: unset !important;
      background-color: var(--light-black); }
      .screen .header .mobile .bet-slip-icon .ant-affix {
        left: calc((100vw - 50px) / 2) !important;
        top: unset !important;
        bottom: 50px;
        width: 50px !important;
        height: 50px;
        cursor: pointer;
        background-color: #2C8AF7;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 30px;
        box-shadow: 5px 5px 5px #00000070; }
        .screen .header .mobile .bet-slip-icon .ant-affix i svg {
          color: #182438;
          width: 25px;
          height: 25px; }
        .screen .header .mobile .bet-slip-icon .ant-affix .badge {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 100%;
          margin-top: -20px;
          margin-left: 15px;
          position: absolute;
          width: 25px !important;
          height: 25px;
          background-color: #E01B22  !important;
          color: var(--white); }
    .screen .banner {
      margin-bottom: 1px; }
      .screen .banner .mobile .content {
        height: 80px; }
        .screen .banner .mobile .content .text {
          font-size: 20px; }
    .screen .body {
      padding-bottom: unset; }
      .screen .body .size {
        width: 100%;
        min-height: unset;
        padding: 0 6vw 0 6vw; }
        .screen .body .size .sider-left,
        .screen .body .size .sider-right {
          display: none; }
        .screen .body .size .content {
          padding: 0px 10px 0px 10px;
          overflow: hidden; }
    .screen .footer {
      padding: unset; }
      .screen .footer .mobile .content .size {
        min-width: unset;
        flex-direction: column;
        align-items: unset; }
      .screen .footer .size {
        min-width: unset !important; } }

@media only screen and (min-width: 950px) {
  .screen .header .top .menu {
    display: none; }
  .screen .body {
    padding-bottom: unset; }
    .screen .body .size {
      min-height: unset; }
      .screen .body .size .sider-left {
        display: unset;
        margin-right: 0px;
        margin-left: 10px; }
      .screen .body .size .sport-types {
        display: none !important; } }

@media only screen and (min-width: 320px) {
  .screen .header .mobile .top-menu .scroll::-webkit-scrollbar {
    display: none; }
  .screen .header .mobile .top-menu .scroll {
    overflow-y: hidden; }
    .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-bar {
      margin: 0;
      border-bottom: none; }
    .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-ink-bar {
      display: none !important; }
    .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-nav .ant-tabs-tab {
      margin: 0; }
      .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-nav .ant-tabs-tab div {
        font-size: 12px; }
      .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-nav .ant-tabs-tab img {
        width: 12px; }
    .screen .header .mobile .top-menu .scroll .mobile-menu-tabs span {
      color: #044da1 !important; }
    .screen .header .mobile .top-menu .scroll .title {
      margin-right: calc(100% / 20);
      margin-left: 1.5%; } }

@media only screen and (min-width: 425px) {
  .screen .header .mobile .top .logo {
    margin: 0 0 0 35%; }
  .screen .header .mobile .top-menu .scroll::-webkit-scrollbar {
    display: none; }
  .screen .header .mobile .top-menu .scroll {
    overflow-y: hidden; }
    .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-bar {
      margin: 0; }
    .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-ink-bar {
      display: none !important; }
    .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-nav .ant-tabs-tab {
      margin: 0; }
      .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-nav .ant-tabs-tab div {
        font-size: 12px; }
      .screen .header .mobile .top-menu .scroll .mobile-menu-tabs .ant-tabs-nav .ant-tabs-tab img {
        width: 12px; }
    .screen .header .mobile .top-menu .scroll .mobile-menu-tabs span {
      color: #044da1 !important; }
    .screen .header .mobile .top-menu .scroll .title {
      margin-right: calc(100% / 20); } }

@media only screen and (min-width: 680px) {
  .screen .header .mobile .top-menu .gt {
    display: none; }
  .screen .header .mobile .top-menu .scroll {
    width: 100%;
    justify-content: center; }
    .screen .header .mobile .top-menu .scroll .title {
      margin-right: calc(100% / 20); } }

@media only screen and (min-width: 1000px) {
  .screen .header .mobile,
  .screen .banner .mobile,
  .screen .footer .mobile {
    display: none; }
  .screen .header .desktop,
  .screen .banner .desktop,
  .screen .footer .desktop {
    display: unset !important; }
  .screen .body .size .sider-right {
    display: unset;
    margin-right: 10px;
    margin-left: 0px;
    flex: 0 0 230px !important;
    max-width: 230px !important;
    min-width: 230px !important;
    width: 230px !important;
    top: 1.5rem; }
  .screen .body .size .mobile-title {
    display: none; }
  .screen .logedin .search-box {
    display: none; } }

@media only screen and (max-width: 1110px) {
  .screen .header .container .login .buttons,
  .screen .banner .container .login .buttons,
  .screen .footer .container .login .buttons {
    padding: 0; }
    .screen .header .container .login .buttons button,
    .screen .banner .container .login .buttons button,
    .screen .footer .container .login .buttons button {
      margin: 10 !important;
      min-width: 0; }
  .screen .header .top-menu .size .logo,
  .screen .banner .top-menu .size .logo,
  .screen .footer .top-menu .size .logo {
    margin-right: 0; } }

@media only screen and (max-width: 1000px) {
  .screen .header .container .top-menu .title {
    font-size: 2.5vw; }
  .screen .header .mobile,
  .screen .banner .mobile,
  .screen .footer .mobile {
    display: unset; }
    .screen .header .mobile .top-menu,
    .screen .banner .mobile .top-menu,
    .screen .footer .mobile .top-menu {
      min-height: unset !important;
      padding-bottom: 10px; }
    .screen .header .mobile .search-box,
    .screen .banner .mobile .search-box,
    .screen .footer .mobile .search-box {
      width: 100%;
      padding: 0;
      margin-top: 10px; }
      .screen .header .mobile .search-box input,
      .screen .banner .mobile .search-box input,
      .screen .footer .mobile .search-box input {
        width: 100% !important; }
    .screen .header .mobile .ant-input-affix-wrapper .ant-input:not(:last-child),
    .screen .banner .mobile .ant-input-affix-wrapper .ant-input:not(:last-child),
    .screen .footer .mobile .ant-input-affix-wrapper .ant-input:not(:last-child) {
      padding-right: 0; }
    .screen .header .mobile .search-box-mobile-view,
    .screen .banner .mobile .search-box-mobile-view,
    .screen .footer .mobile .search-box-mobile-view {
      margin-right: 20px; }
    .screen .header .mobile .logedin,
    .screen .banner .mobile .logedin,
    .screen .footer .mobile .logedin {
      display: flex;
      margin-left: unset;
      flex-direction: column-reverse;
      align-items: unset; }
    .screen .header .mobile .top-nav-account,
    .screen .banner .mobile .top-nav-account,
    .screen .footer .mobile .top-nav-account {
      align-items: center;
      justify-content: flex-start;
      display: flex; }
    .screen .header .mobile .login,
    .screen .banner .mobile .login,
    .screen .footer .mobile .login {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      box-sizing: content-box !important; }
      .screen .header .mobile .login .buttons,
      .screen .banner .mobile .login .buttons,
      .screen .footer .mobile .login .buttons {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly; }
        .screen .header .mobile .login .buttons button,
        .screen .banner .mobile .login .buttons button,
        .screen .footer .mobile .login .buttons button {
          max-width: 120px;
          width: 5rem;
          padding: 5px; }
          .screen .header .mobile .login .buttons button span,
          .screen .banner .mobile .login .buttons button span,
          .screen .footer .mobile .login .buttons button span {
            font-size: 0.8rem; }
        .screen .header .mobile .login .buttons button.clear,
        .screen .banner .mobile .login .buttons button.clear,
        .screen .footer .mobile .login .buttons button.clear {
          background-color: transparent;
          border: solid 1px #044da1 !important; }
          .screen .header .mobile .login .buttons button.clear span,
          .screen .banner .mobile .login .buttons button.clear span,
          .screen .footer .mobile .login .buttons button.clear span {
            color: #044da1 !important; }
  .screen .header .desktop,
  .screen .banner .desktop,
  .screen .footer .desktop {
    display: none !important; }
  .screen .footer .child:nth-child(3) {
    margin-top: 20px; } }

@media only screen and (min-width: 1024px) and (max-width: 1249px) {
  .screen .header .logedin .search-box {
    display: none; }
  .screen .header .login input[type="text"],
  .screen .header .login input[type="password"] {
    width: 80px !important; }
  .screen .header .login .ant-form-inline .ant-form-item {
    margin-left: 5px !important;
    margin-right: unset !important;
    width: 80px; }
  .screen .header .login .submit {
    width: 60px !important; } }

@media only screen and (min-width: 1300px) {
  .screen .body .size .sider-right {
    flex: 0 0 295px !important;
    max-width: 295px !important;
    min-width: 295px !important;
    width: 295px !important;
    top: 0rem; }
  .screen .header .logedin .search-box {
    display: none; } }

@media only screen and (max-width: 480px) {
  .account .ant-menu-inline .ant-menu-item {
    font-size: 12px; }
  body .account .layout-inner .content .container .deposit-main .deposit-title {
    font-size: 13px !important; }
  .screen .header .mobile .empty {
    width: 0 !important; }
  .screen .header .mobile .top .logo {
    margin: 0 0 0 35%; }
  .ant-modal.register .ant-steps {
    display: flex;
    margin: 0; }
    .ant-modal.register .ant-steps .ant-steps-item {
      min-height: 20px;
      align-items: initial !important; }
      .ant-modal.register .ant-steps .ant-steps-item .ant-steps-item-title {
        display: none; }
  .ant-modal.register .ant-steps > div > div > div {
    font-size: 12px;
    padding: 0; }
  .ant-modal.register .step .strip .content .text {
    font-size: 14px !important; }
  .size .content {
    padding: 0 !important; }
  .account .strip {
    display: inline !important; }
    .account .strip .title {
      font-size: 20px;
      padding-bottom: 10px;
      margin-left: 0; }
  .account .ant-calendar-range-picker-separator {
    color: var(--light-black) !important;
    padding-top: 5px; }
  .account .ant-input {
    max-height: 30px; }
  .account .ant-table-tbody .ant-table-row {
    height: 35px !important; }
  .account .layout-inner .content .container .ant-pagination li {
    height: 20px !important;
    line-height: 16px !important;
    padding: 0 !important; }
    .account .layout-inner .content .container .ant-pagination li a {
      font-size: 9px !important; }
    .account .layout-inner .content .container .ant-pagination li .table {
      max-width: 300px !important; }
  .account .bets .ant-table-fixed-right {
    z-index: 1; }
  .account .bets .ant-table-expand-icon-th,
  .account .bets .ant-table-row-expand-icon-cell {
    min-width: 20px !important;
    max-width: 20px !important;
    padding: 0 !important; }
    .account .bets .ant-table-expand-icon-th .ant-table-row-expand-icon,
    .account .bets .ant-table-row-expand-icon-cell .ant-table-row-expand-icon {
      width: 13px !important;
      height: 13px !important;
      line-height: 10px !important; }
  .account .bets .status-type {
    width: 50px !important;
    text-align: center; }
  .account .bets .ant-calendar-picker {
    padding-bottom: 5px;
    margin-left: 0px !important; }
  .account .bets .ant-select {
    width: 180px;
    padding: 0; }
    .account .bets .ant-select .ant-select-selection {
      height: 28px !important;
      margin-top: 0 !important; }
      .account .bets .ant-select .ant-select-selection .ant-select-selection__rendered {
        margin-top: 0 !important;
        line-height: 25px !important; }
  .account .bets .form-container .select,
  .account .voucher .form-container .select {
    margin-right: 0 !important;
    padding: 0;
    max-height: 30px;
    border: none !important; }
    .account .bets .form-container .select .ant-select-selection,
    .account .voucher .form-container .select .ant-select-selection {
      max-height: 30px;
      border: 1px solidvar(--black-to-white) !important; }
  .account .bets .filters-container,
  .account .voucher .filters-container {
    min-width: 350px !important; }
    .account .bets .filters-container .title,
    .account .voucher .filters-container .title {
      max-width: 40px !important;
      font-size: 10px !important; }
  .account .bets .form-container,
  .account .voucher .form-container {
    min-width: 300px !important; }
    .account .bets .form-container .select-dark,
    .account .voucher .form-container .select-dark {
      width: 150px !important;
      max-height: 30px; }
  .account .bets .ant-table-thead > tr > th,
  .account .voucher .ant-table-thead > tr > th {
    font-size: 10px !important;
    padding: 0px 5px !important;
    height: 31px; }
  .account .bets .ant-table-tbody > tr > td,
  .account .voucher .ant-table-tbody > tr > td {
    padding: 0px 5px !important;
    font-size: 8px !important;
    max-height: 20px !important; }
  .account .deposit-mtn .mtn-container .ant-select,
  .account .deposit-mtn .ant-input,
  .account .deposit-mtn .ant-input-number,
  .account .deposit-mtn .ant-input-number-input {
    max-height: 40px;
    color: var(--black-to-white); }
  .account .deposit,
  .account .withdrawals {
    text-align: center; }
    .account .deposit .ant-form-item-label,
    .account .withdrawals .ant-form-item-label {
      text-align: center; }
  .account .personal {
    width: 300px !important; }
    .account .personal .ant-btn > .anticon + span {
      display: none !important; }
    .account .personal .success-check {
      font-size: 8px !important;
      width: 20px;
      background: #2C8AF7 !important; }
    .account .personal .text {
      color: var(--black-to-white);
      font-size: 10px; }
    .account .personal .ant-form-item {
      display: flex;
      justify-content: space-between; }
    .account .personal .ant-form-item-label {
      width: 80px; }
    .account .personal .ant-form-item-control-wrapper {
      width: 180px; }
    .account .personal .ant-input-group {
      width: 180px; }
    .account .personal .ant-form-item-label > label {
      font-size: 11px !important; }
    .account .personal .ant-btn {
      height: 28px !important;
      font-size: 10px !important;
      margin-left: 0px;
      padding: 0 !important; }
    .account .personal .ant-select {
      width: 180px;
      padding: 0; }
      .account .personal .ant-select .ant-select-selection {
        height: 28px !important;
        margin-top: 0 !important; }
        .account .personal .ant-select .ant-select-selection .ant-select-selection__rendered {
          margin-top: 0 !important;
          line-height: 25px !important; }
  .add-phone-modal .ant-modal-content .ant-modal-body {
    padding: 10px; }
    .add-phone-modal .ant-modal-content .ant-modal-body .prefix-input {
      width: 50px; }
    .add-phone-modal .ant-modal-content .ant-modal-body .seperator-input {
      width: 55px; }
    .add-phone-modal .ant-modal-content .ant-modal-body .number-input {
      width: 125px; }
  .add-phone-modal .ant-btn {
    width: 100% !important; } }

@media only screen and (max-width: 950px) {
  body #side-menu {
    padding: 20px 2px 20px 2px; }
  body .sec-serach-continer {
    /* position: relative; */
    display: flex;
    justify-content: center; }
  body .sec-search-bar {
    width: 98%; }
  body .sec-search-icon {
    display: none; }
  body .events .row .anticon svg {
    -webkit-filter: brightness(100);
            filter: brightness(100); }
  body .main-image {
    margin: 0 !important; }
  body .slot-machines {
    min-height: 800px; }
    body .slot-machines .slots-game .game-symbols {
      display: none; }
  body .voucher-modal .voucher-details .details {
    display: block !important; }
  body .account .layout-inner .content .container .deposit-main .deposit-icon {
    height: 100%;
    width: 30%; }
  body .account .layout-inner .content .container .deposit-main .deposit-title {
    font-size: 15px;
    margin-left: 5%; }
  body .account .layout-inner .content .container .deposit-main .menu {
    display: block;
    margin-left: 20px; }
  .app {
    display: flex;
    flex-direction: column;
    background-color: var(--light-black); }
  .slot-machines .items .gutter-box {
    margin: 8px 0 !important; }
  .slot-machines .items .gutter-box:hover {
    box-shadow: none; }
  .ant-popover-inner-content .login button,
  .account button,
  .details button,
  .logedin button {
    background: #054da1 !important;
    background-color: #054da1 !important;
    color: var(--white); }
    .ant-popover-inner-content .login button span,
    .account button span,
    .details button span,
    .logedin button span {
      color: var(--white) !important; }
  .register .ant-modal-body .ant-btn-primary {
    background: #054da1 !important; }
  .back {
    color: var(--white) !important; }
    .back span {
      color: var(--white) !important; }
  button,
  span {
    color: var(--white) !important; }
  .sport-types .swipe {
    background-color: var(--white) !important; }
    .sport-types .swipe .sport-type {
      cursor: pointer; }
      .sport-types .swipe .sport-type .selected .icon {
        -webkit-filter: brightness(0) invert(1) !important;
                filter: brightness(0) invert(1) !important; }
      .sport-types .swipe .sport-type .box {
        opacity: 0.7; }
        .sport-types .swipe .sport-type .box .icon {
          margin-bottom: 5px; }
          .sport-types .swipe .sport-type .box .icon img {
            -webkit-filter: invert(45%) sepia(20%) saturate(392%) hue-rotate(166deg) brightness(86%) contrast(91%) !important;
                    filter: invert(45%) sepia(20%) saturate(392%) hue-rotate(166deg) brightness(86%) contrast(91%) !important;
            width: 25px;
            height: 25px; }
        .sport-types .swipe .sport-type .box .title {
          color: gray !important; }
      .sport-types .swipe .sport-type .box:hover {
        color: #40a9ff; }
      .sport-types .swipe .sport-type .box.selected {
        background-color: #060a4a !important;
        font-weight: 500;
        opacity: 1; }
        .sport-types .swipe .sport-type .box.selected .title {
          color: var(--white) !important; }
  .search-popover .light-on {
    color: #0e2842 !important; }
  .search-popover .ant-popover-inner .ant-popover-title {
    color: #0e2842 !important; }
  /********** Ant Menu **********/
  .ant-menu {
    background: var(--white) !important;
    border: none !important;
    margin-bottom: 20px;
    font-size: 14px;
    font-weight: bold;
    border: unset; }
    .ant-menu .ant-menu-submenu .title .icon img {
      -webkit-filter: brightness(0) invert(1);
              filter: brightness(0) invert(1);
      width: 16px;
      height: 16px;
      margin-right: 10px; }
    .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
      opacity: 1;
      margin: unset !important;
      padding: unset !important;
      background-color: #044188 !important;
      padding-left: 10px !important; }
      .ant-menu .ant-menu-submenu .ant-menu-submenu-title .nav-country {
        color: #e9f3ff !important; }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-submenu-title {
      color: var(--white); }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-sub .ant-menu-item {
      opacity: 0.7;
      border-bottom: solid 1px var(--light-black) !important; }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open ul {
      background-color: #054da1 !important; }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0px var(--white);
      background-color: var(--white); }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-sub.ant-menu-inline::-webkit-scrollbar {
      width: 0px;
      background-color: var(--white); }
    .ant-menu .ant-menu-submenu.ant-menu-submenu-open .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-thumb {
      border-radius: 0px;
      background-color: #054da1;
      border: solid 0px var(--white); }
  .sub-menu-mobile {
    background-color: #033570 !important;
    display: flex; }
    .sub-menu-mobile .ant-menu-submenu-title {
      min-height: 40px; }
      .sub-menu-mobile .ant-menu-submenu-title .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%; }
        .sub-menu-mobile .ant-menu-submenu-title .title .text {
          line-height: 20px; }
        .sub-menu-mobile .ant-menu-submenu-title .title .anticon {
          height: 15px; }
    .sub-menu-mobile .ant-menu {
      margin: 0; }
    .sub-menu-mobile .ant-menu-item {
      border-bottom: solid 1px #033570 !important;
      background: #044188 !important;
      padding: 0 10px !important;
      font-size: 9px; }
      .sub-menu-mobile .ant-menu-item .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px; }
        .sub-menu-mobile .ant-menu-item .title .text {
          line-height: 20px; }
    .sub-menu-mobile .ant-menu-submenu::after,
    .sub-menu-mobile .ant-menu-submenu::before {
      content: none; }
  .flat-item {
    opacity: 0.7;
    background-color: #172a40; }
  /*----------------- Virtual Menu -----------------*/
  .virtual-menu .ant-menu-submenu {
    background-color: #172a40 !important;
    margin-bottom: 1px !important; }
  .virtual-menu .ant-menu-item-selected {
    background-color: #054da1 !important; }
  /*----------------- My Account Menu -----------------*/
  .account-menu .ant-menu-item-selected {
    background-color: #054da1 !important; }
  .account-menu-mobile {
    width: 100%;
    display: flex;
    position: absolute;
    bottom: 0;
    margin: 0;
    min-height: 40px; }
    .account-menu-mobile .ant-menu-submenu .ant-menu-submenu-title {
      padding-left: 0 !important; }
    .account-menu-mobile .buttons-container {
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 5px 0;
      width: 100%; }
      .account-menu-mobile .buttons-container div {
        opacity: 0.7;
        line-height: 15px; }
    .account-menu-mobile .sub-menu-mobile .ul .ant-menu-item {
      border-bottom: solid 1px #033570 !important;
      margin: unset !important;
      background: #044188 !important;
      padding: 0 10px !important; }
    .account-menu-mobile li {
      width: inherit;
      opacity: 1;
      border-bottom: solid 1px #033570 !important;
      margin: unset !important;
      background-color: #044188 !important;
      font-size: 9px; }
      .account-menu-mobile li .title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 5px; }
        .account-menu-mobile li .title .text {
          line-height: 20px; }
        .account-menu-mobile li .title .anticon {
          margin: 0; }
  .bet-slip .success span {
    color: var(--white) !important; }
  .bet-slip .ant-tabs-top-bar .ant-tabs-tab {
    opacity: 0.5;
    color: var(--white) !important; }
  .bet-slip .ant-tabs-top-bar .ant-tabs-tab-active {
    opacity: unset; }
  .bet-slip .ant-tabs-top-bar .ant-tabs-ink-bar {
    background-color: var(--white); }
  .bet-slip .bet-code {
    border-bottom: solid 1px var(--light-black);
    background-color: var(--white); }
    .bet-slip .bet-code .text,
    .bet-slip .bet-code .code,
    .bet-slip .bet-code .close {
      color: #182438; }
  .bet-slip .tabs-betslip .ant-tabs-top-bar {
    background-color: var(--white);
    border-bottom: solid 1px #182438; }
    .bet-slip .tabs-betslip .ant-tabs-top-bar .ant-tabs-tab {
      color: var(--light-black);
      opacity: 0.5; }
    .bet-slip .tabs-betslip .ant-tabs-top-bar .ant-tabs-ink-bar {
      background-color: #0e7eff; }
  .bet-slip .load-code .top {
    background-color: #054da1 !important; }
  .bet-slip .load-code .content .fields .button {
    flex: 0.5 1; }
    .bet-slip .load-code .content .fields .button .load-betcode span {
      color: var(--white) !important; }
  .bet-slip .content .events {
    background-color: unset;
    color: #182438 !important; }
    .bet-slip .content .events .error-message .ant-btn::before {
      background: none !important;
      transition: none !important; }
    .bet-slip .content .events .error-message .clear-bet-interval {
      color: var(--white); }
    .bet-slip .content .events .row-amount .currency {
      color: #182438; }
    .bet-slip .content .events .row-total-possible div {
      color: var(--black-to-white); }
    .bet-slip .content .events .row {
      padding: unset;
      color: #182438; }
      .bet-slip .content .events .row .icon img {
        -webkit-filter: var(--white);
                filter: var(--white);
        width: 18px;
        height: 18px; }
      .bet-slip .content .events .row .text {
        color: #182438; }
      .bet-slip .content .events .row .teams {
        color: #182438; }
        .bet-slip .content .events .row .teams .live {
          color: var(--white); }
      .bet-slip .content .events .row .team {
        color: #182438; }
      .bet-slip .content .events .row .odd-name {
        color: #182438; }
      .bet-slip .content .events .row .odd-value {
        color: #182438; }
      .bet-slip .content .events .row .close {
        color: #182438; }
      .bet-slip .content .events .row .possible {
        color: #182438; }
    .bet-slip .content .events .placebet .row .placebet-btn span {
      color: var(--white) !important; }
    .bet-slip .content .events .placebet .row .placebet-btn .ant-btn::before {
      background: none !important;
      transition: none !important; }
    .bet-slip .content .events .placebet .row .placebet-btn .clear-bet-interval {
      color: var(--white); }
    .bet-slip .content .events .placebet .stake .text {
      color: #182438; }
    .bet-slip .content .events .placebet .stake .total {
      color: #182438; }
    .bet-slip .content .events .placebet .total-odds {
      color: #182438; }
    .bet-slip .content .events .placebet .total-odds-value {
      color: #182438; }
    .bet-slip .content .events .placebet .total-possible {
      color: #182438; }
    .bet-slip .content .events .placebet .total-possible-value {
      color: #182438; }
    .bet-slip .content .events .placebet .terms {
      color: #182438; }
      .bet-slip .content .events .placebet .terms a {
        color: #182438; }
    .bet-slip .content .events .placebet .amount .currency {
      color: #182438; }
  .bet-slip .notice {
    background-color: var(--white) 8dc; }
  .drawer-details {
    max-width: 230px; }
  .mobile-voucher-details .ant-tabs-nav-container {
    border: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    font-size: 11px;
    background-color: #044da1; }
    .mobile-voucher-details .ant-tabs-nav-container div {
      color: var(--white) !important;
      font-weight: bold;
      display: flex;
      justify-content: center; }
  .mobile-voucher-details div {
    color: var(--black-to-white); }
  .mobile-voucher-details .details {
    display: block; }
  .mobile-voucher-details .title {
    display: flex;
    justify-content: space-between;
    padding: 0 5px; }
  .mobile-voucher-details .value {
    padding: 0 5px; }
  .mobile-voucher-details .qr-code {
    display: flex;
    justify-content: center;
    padding: 20px 0; }
  .mobile-table-details .ant-tabs-bar {
    margin: 0; }
  .mobile-table-details .ant-tabs-nav-container {
    border: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    width: 100%;
    font-size: 11px;
    background-color: #044da1; }
    .mobile-table-details .ant-tabs-nav-container div {
      color: var(--white) !important;
      font-weight: bold;
      display: flex;
      justify-content: center; }
  .mobile-table-details div {
    color: var(--black-to-white); }
  .mobile-table-details .bet-lines .row {
    padding: 5px 10px 0 10px;
    display: flex;
    justify-content: space-between;
    font-weight: bold; }
    .mobile-table-details .bet-lines .row .possible-winnings {
      font-size: 14px;
      color: var(--black-to-white); }
    .mobile-table-details .bet-lines .row .winnings-odds {
      font-size: 12px;
      color: var(--black-to-white); }
    .mobile-table-details .bet-lines .row .text {
      font-size: 10px; }
  .mobile-table-details .bet-lines .row:last-child {
    justify-content: center; }
  .mobile-table-details .bet-lines .status {
    width: 230px;
    min-height: 25px;
    border-radius: 5px;
    margin: 5px 0;
    font-size: 12px;
    text-align: center;
    padding: 3px; }
  .mobile-table-details .events {
    border-radius: unset;
    background-color: unset;
    padding-bottom: unset;
    color: var(--white) !important; }
    .mobile-table-details .events .event {
      padding: 5px 10px 5px 10px;
      border-bottom: solid 1px var(--light-black);
      display: flex;
      flex-direction: column;
      align-items: start; }
      .mobile-table-details .events .event div {
        padding: 1px;
        display: flex;
        flex-direction: column;
        justify-content: space-between; }
      .mobile-table-details .events .event .row {
        display: flex;
        justify-content: start;
        flex-direction: row;
        width: 100%;
        align-items: center;
        height: 15px; }
        .mobile-table-details .events .event .row .status {
          width: 14px;
          border-radius: 50%;
          height: 14px;
          padding: 0px; }
        .mobile-table-details .events .event .row .icon {
          width: 13px; }
        .mobile-table-details .events .event .row .cell:first-child {
          width: 15px;
          min-width: 20px; }
        .mobile-table-details .events .event .row .cell:nth-child(2) {
          width: 170px; }
      .mobile-table-details .events .event .header {
        font-weight: bold;
        font-size: 10px; }
      .mobile-table-details .events .event .text {
        font-size: 9px; }
  .settings-popover .ant-popover-title {
    color: #252525 !important; }
  .settings-popover .betslip-settings-container .setting div {
    color: var(--black-to-white); }
  .settings-popover .betslip-settings-container .currency-setting {
    font-weight: bold;
    color: #252525 !important; }
    .settings-popover .betslip-settings-container .currency-setting .default-currency {
      width: 87.2px; }
    .settings-popover .betslip-settings-container .currency-setting svg {
      font-weight: bold;
      color: #252525 !important; }
  .settings-popover .betslip-settings-container .default-bet-amount {
    width: 87.2px !important;
    height: 30px !important;
    border-radius: 4px;
    border: solid 1px #dee2e8;
    margin: 0 0 0 10px;
    font-weight: bold;
    font-size: 16px;
    color: #252525; }
  .settings-popover .betslip-settings-container .ant-switch-checked {
    background-color: #0b995c !important; }
  .settings-popover .betslip-settings-container .betslip-switch {
    width: 60px;
    height: 30px;
    border-radius: 15px; }
  .settings-popover .betslip-settings-container .ant-switch::after {
    width: 20px;
    height: 20px;
    top: 4px;
    margin: 0 0 0 5px; }
  .settings-popover .betslip-settings-container .ant-switch-checked::after {
    width: 20px;
    height: 20px;
    top: 4px;
    left: 48px; }
  .settings-popover .tabs-top > .ant-tabs-top-bar .ant-tabs-tab,
  .settings-popover .tabs-top > .ant-tabs-top-bar .ant-tabs-ink-bar {
    margin: 0;
    width: 80px !important; }
  .settings-popover .tabs-top > .ant-tabs-top-bar .ant-tabs-tab:last-child {
    margin-left: 30px; }
  .settings-popover .content .events .event {
    padding: 10px; }
  .settings-popover .content .events .placebet {
    padding: 10px; }
  .mobile-bet-slip .bet-slip {
    display: flex; }
    .mobile-bet-slip .bet-slip .content .events .placebet .amount-up-down .input {
      width: 50px !important; }
    .mobile-bet-slip .bet-slip .content .events .placebet .total-possible-value {
      font-size: 14px; }
    .mobile-bet-slip .bet-slip .tabs-top > .ant-tabs-top-bar {
      border-top-right-radius: 0;
      border-top-left-radius: 0; }
    .mobile-bet-slip .bet-slip .load-code .top {
      border: none;
      border-top-right-radius: 0;
      border-top-left-radius: 0; }
  .top-bets {
    padding: 0 3.2vw 0 3.2vw; }
    .top-bets .ant-carousel .card .event:nth-child(1) {
      background-color: #054da1;
      background-image: none !important; }
    .top-bets .top .container .bt {
      width: 35px;
      height: 35px;
      border-radius: 4px;
      background-color: #32425c;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;
      cursor: pointer; }
  .fixture .layout-inner .content .container {
    background-color: transparent !important; }
    .fixture .layout-inner .content .container .left .title,
    .fixture .layout-inner .content .container .right .title {
      color: #182e49 !important; }
    .fixture .layout-inner .content .container .list .box {
      background-color: #054da1 !important; }
  .screen .body .size .content {
    padding: 0 !important; }
  .tabs-events .ant-tabs-bar,
  .ant-collapse-header {
    background-color: #060a4a !important; }
  .mtop-10 {
    margin-top: -1px; }
  .sport .buttons-ods button.selected {
    border-top: solid 2px gray !important;
    border-bottom: solid 2px gray !important;
    background-color: gray !important; }
  .sport .content.ant-layout-content > div {
    color: var(--black-to-white) !important; }
  .sport .buttons-ods button {
    opacity: 0.5;
    border-radius: 0px;
    border-top: solid 2px var(--light-black);
    border-bottom: solid 2px var(--light-black);
    background-color: var(--light-black);
    font-size: 14px; }
    .sport .buttons-ods button .container .value,
    .sport .buttons-ods button .name {
      color: var(--white); }
  .sport .buttons-ods button.selected {
    opacity: 1;
    border-top: solid 2px var(--dark-blue-to-dark-gray) !important;
    border-bottom: solid 2px var(--dark-blue-to-dark-gray) !important;
    background-color: var(--dark-blue-to-dark-gray) !important; }
  .sport .upcoming .title {
    color: #054da1 !important; }
  .sport span {
    color: var(--white) !important; }
  .sport .events {
    background-color: var(--light-black) !important; }
    .sport .events .header div {
      color: var(--white) !important; }
  .sport .row .col .bet-types i {
    color: #182e49 !important; }
  .sport .row .col .plus .icon {
    color: var(--white) !important; }
  .sport .row .col .event .details .teams .team {
    color: var(--white) !important; }
  .sport .row .bet-num,
  .sport .row .date,
  .sport .row .league .text,
  .sport .row .timer,
  .sport .row .league {
    color: var(--white) !important; }
  .sport .dd .event .bg-1 {
    background-image: none !important; }
  .sport .dd .event .container div,
  .sport .dd .tabs-events .ant-tabs-bar {
    background-color: #054da1; }
  .sport .dd .groups {
    background-color: var(--single-match-pages-background); }
  .sport .dd .tabs-events .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-ink-bar {
    background-color: var(--white); }
  .document .layout-inner .content .container p span {
    color: var(--white) !important; }
  .screen .footer {
    background-color: var(--light-black); }
  .screen .banner {
    margin-bottom: 0px; } }

.ant-tabs-nav-container {
  margin-bottom: -3px; }

@media only screen and (max-width: 800px) {
  .ant-tabs-nav-container img {
    height: auto;
    width: 100%; } }

@media only screen and (max-width: 1000px) {
  .screen header {
    position: -webkit-sticky;
    position: sticky; }
  .screen .body .main-image {
    padding: 0 6vw 0 6vw; }
  .screen .body #bits-filter {
    width: auto;
    padding: 0 6vw 0 6vw; }
  .screen .body #bits-filter-rows {
    padding: 0 6vw 0 6vw; }
  .screen .body .size {
    padding: 0; } }

.sport #mobile-top-bets-details-container {
  display: none; }

.sport .mobile-bets-filter-container {
  display: none; }

.sport .mobile-bet-filter-options {
  display: inline-block;
  text-align: center;
  border-radius: 25px;
  margin-left: 5px;
  background-color: var(--dark-blue);
  padding: 5px 10px;
  opacity: 0.5;
  cursor: pointer; }

@media only screen and (max-width: 600px) {
  .sport #top-bets-details-container {
    display: none; }
  .sport #mobile-top-bets-details-container {
    display: block !important; }
    .sport #mobile-top-bets-details-container .top-bets-header-details {
      text-align: center;
      opacity: 0.5; }
    .sport #mobile-top-bets-details-container .top-bets-match-details {
      padding: 20px 20px 5px 20px;
      box-sizing: border-box; }
      .sport #mobile-top-bets-details-container .top-bets-match-details .top-bets-match-teams {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px; }
  .sport .mobile-bet-filter-options {
    font-size: 14px; }
  .sport .mobile-bets-filter-container {
    display: block; }
  .sport .bets-filter-container {
    display: none; }
  .sport .buttons-ods button {
    width: 12vw; }
  .sport .row .col .event .details .teams .team {
    font-size: 14px !important; } }

@media only screen and (max-width: 500px) {
  .sport .mobile-bet-filter-options {
    font-size: 11px; } }

@media only screen and (max-width: 400px) {
  .sport .mobile-bet-filter-options {
    font-size: 8px; } }

@media only screen and (max-width: 300px) {
  .sport .mobile-bet-filter-options {
    font-size: 5px; } }

.sport .events .teams-position {
  display: inline;
  border-radius: 15%;
  background-color: var(--dark-blue-to-dark-gray);
  padding: 0 5px;
  color: var(--black-to-white);
  height: 20px;
  margin-right: 5px;
  margin-bottom: 5px;
  min-width: 20px;
  text-align: center; }

.sport .events .mobile-bet-rows-container {
  display: none; }

@media only screen and (max-width: 600px) {
  .sport .events .bet-type-0 {
    width: 45vw !important;
    max-width: 45vw !important;
    min-width: 45vw !important; }
  .sport .events #bet-rows-container {
    display: none; }
  .sport .events .mobile-bet-rows-container {
    display: block;
    padding: 5px 15px; }
    .sport .events .mobile-bet-rows-container .bet-rows-parent {
      border-bottom: 1px solid #2F384B;
      padding-top: 5px;
      padding-bottom: 5px; }
  .sport .events .event-row-parent-container:last-of-type .mobile-bet-rows-container .bet-rows-parent {
    border: none; } }

.sport {
  /*-----------------  events styles -----------------*/
  /*----------------- Sport styles -----------------*/
  /*----------------- Sport Top Bets styles -----------------*/
  /*----------------- Sport rows header styles -----------------*/
  /*----------------- Sport Skelaton -----------------*/
  /*----------------- Sport rows styles -----------------*/
  /*----------------- Sport menu styles -----------------*/
  /*----------------- Today styles -----------------*/ }
  .sport .events {
    border-radius: 4px;
    background-color: var(--light-black); }
  .sport .top-bets {
    margin-bottom: 30px; }
    .sport .top-bets .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 17px; }
      .sport .top-bets .top .title {
        height: 24px;
        font-size: 20px;
        font-weight: 300;
        margin-right: 15px;
        margin-left: 10px; }
      .sport .top-bets .top .container {
        display: flex;
        flex-direction: row; }
        .sport .top-bets .top .container .bt {
          width: 35px;
          height: 35px;
          border-radius: 4px;
          background-color: #32425c;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 25px;
          cursor: pointer; }
        .sport .top-bets .top .container .bt:last-child {
          margin-left: 10px;
          -webkit-transform: rotate(-180deg);
                  transform: rotate(-180deg); }
    .sport .top-bets .ant-carousel {
      margin-bottom: 20px; }
      .sport .top-bets .ant-carousel .card {
        display: flex !important;
        flex-direction: row; }
        .sport .top-bets .ant-carousel .card .event {
          cursor: pointer;
          flex: 1 1;
          border-radius: 4px; }
          .sport .top-bets .ant-carousel .card .event .container {
            width: 100%; }
            .sport .top-bets .ant-carousel .card .event .container .bet-line-num {
              opacity: 0.8;
              font-size: 18px;
              text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
              font-size: 20px; }
            @media only screen and (max-width: 1500px) {
              .sport .top-bets .ant-carousel .card .event .container .bet-line-num {
                opacity: 0.8;
                font-size: 18px;
                text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
                font-size: 15px; } }
            .sport .top-bets .ant-carousel .card .event .container .buttons-ods button .container {
              justify-content: space-between; }
              .sport .top-bets .ant-carousel .card .event .container .buttons-ods button .container .name {
                opacity: 0.5;
                text-align: left; }
              .sport .top-bets .ant-carousel .card .event .container .buttons-ods button .container .lock {
                margin-right: 10px; }
          .sport .top-bets .ant-carousel .card .event .row-1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 12px;
            justify-content: space-between; }
          .sport .top-bets .ant-carousel .card .event .row-2,
          .sport .top-bets .ant-carousel .card .event .row-3 {
            display: flex;
            flex-direction: row;
            justify-content: center; }
          .sport .top-bets .ant-carousel .card .event .row-2 {
            white-space: nowrap;
            justify-content: space-around;
            font-size: 18px; }
            .sport .top-bets .ant-carousel .card .event .row-2 .team {
              text-align: center;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
              color: var(--white); }
            .sport .top-bets .ant-carousel .card .event .row-2 .vs {
              width: 30%;
              display: flex;
              justify-content: center; }
          .sport .top-bets .ant-carousel .card .event .league {
            opacity: 0.8;
            font-size: 18px;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: -webkit-max-content;
            max-width: max-content;
            white-space: nowrap;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5); }
          .sport .top-bets .ant-carousel .card .event .date {
            text-align: right;
            opacity: 0.8;
            font-size: 18px;
            text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5); }
          @media only screen and (max-width: 1500px) {
            .sport .top-bets .ant-carousel .card .event .row-2 {
              margin-bottom: 12px;
              white-space: nowrap;
              justify-content: space-around;
              text-align: center; }
              .sport .top-bets .ant-carousel .card .event .row-2 .team {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
                color: var(--white); }
              .sport .top-bets .ant-carousel .card .event .row-2 .vs {
                width: 30%;
                display: flex;
                justify-content: center; }
            .sport .top-bets .ant-carousel .card .event .league {
              opacity: 0.8;
              font-size: 15px;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: -webkit-max-content;
              max-width: max-content;
              white-space: nowrap;
              text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
              width: 250px; }
            .sport .top-bets .ant-carousel .card .event .date {
              text-align: right;
              opacity: 0.8;
              font-size: 15px;
              text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5); } }
        .sport .top-bets .ant-carousel .card .event:not(:last-child) {
          margin-right: 16px; }
        .sport .top-bets .ant-carousel .card .event:nth-child(1) {
          background-color: var(--strong-blue);
          background-position: 0px 0px;
          background-size: 100% 100%;
          background-repeat: no-repeat; }
        .sport .top-bets .ant-carousel .card .event:nth-child(2) {
          background-image: url(/static/media/2.5394019c.webp);
          background-position: 0px 0px;
          background-size: 100% 100%;
          background-repeat: no-repeat; }
        .sport .top-bets .ant-carousel .card .event:nth-child(3) {
          background-image: url(/static/media/1.f719790e.webp);
          background-position: 0px 0px;
          background-size: 100% 100%;
          background-repeat: no-repeat; }
  .sport .header {
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 8px;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: solid 2px #182e49; }
    .sport .header .col {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .sport .header .col .bet-types {
        min-width: 150px;
        display: flex;
        align-items: center;
        justify-content: center; }
        .sport .header .col .bet-types .selectlist {
          width: 100%;
          max-width: 150px; }
        .sport .header .col .bet-types .odds {
          height: 40px;
          flex: 1 1;
          flex-direction: column;
          justify-content: space-between;
          width: 8.8vw; }
          .sport .header .col .bet-types .odds div {
            display: flex;
            flex-direction: row;
            width: 8.8vw; }
            .sport .header .col .bet-types .odds div div {
              width: 8.8vw;
              margin-right: 1.5vw; }
          .sport .header .col .bet-types .odds .odds-desc {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            font-size: 12px;
            font-weight: bold;
            text-transform: uppercase; }
        .sport .header .col .bet-types .title {
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          width: 100%;
          opacity: 0.6;
          font-size: 12px;
          font-weight: lighter;
          text-align: center;
          font-weight: 400;
          color: var(--white); }
        .sport .header .col .bet-types .select {
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          align-self: center;
          width: 100%; }
  .sport .skeleton {
    display: flex;
    flex-direction: column; }
    .sport .skeleton .row {
      height: 110px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .sport .skeleton .row .col {
        opacity: 0.5;
        float: left;
        height: 100%;
        flex: 1 1;
        background-color: var(--light-black);
        border-radius: 4px; }
      .sport .skeleton .row .col:not(:last-child) {
        margin-right: 10px; }
      .sport .skeleton .row .loader {
        position: absolute;
        float: left;
        height: 65px;
        width: calc(100% - 20px);
        flex: 1 1;
        -webkit-animation: ant-skeleton-loading 2s ease infinite;
                animation: ant-skeleton-loading 2s ease infinite;
        -webkit-animation-name: ant-skeleton-loading;
                animation-name: ant-skeleton-loading;
        -webkit-animation-duration: 2s;
                animation-duration: 2s;
        -webkit-animation-timing-function: ease;
                animation-timing-function: ease;
        -webkit-animation-delay: 0s;
                animation-delay: 0s;
        -webkit-animation-iteration-count: infinite;
                animation-iteration-count: infinite;
        -webkit-animation-direction: normal;
                animation-direction: normal;
        -webkit-animation-fill-mode: none;
                animation-fill-mode: none;
        -webkit-animation-play-state: running;
                animation-play-state: running;
        background: linear-gradient(90deg, transparent 25%, #32425c 37%, transparent 43%);
        background-size: 400% 300%; }
  .sport .row {
    overflow: visible;
    cursor: pointer; }
    .sport .row .disabled {
      background-color: var(--white);
      opacity: 0.3;
      width: 100%;
      height: 100px;
      float: left;
      margin-top: -95px; }
    .sport .row .notice * {
      color: #182438; }
      .sport .row .notice * a {
        text-decoration: underline; }
    .sport .row .row-0,
    .sport .row .row-1,
    .sport .row .row-2 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-top: 5px; }
    .sport .row .row-2 {
      margin-top: 5px; }
    .sport .row .disabled {
      display: flex;
      flex-direction: row;
      pointer-events: none;
      opacity: 0.6; }
    .sport .row .bet-num {
      opacity: 0.5;
      font-size: 15px; }
    .sport .row .date {
      white-space: nowrap;
      font-size: 14px;
      opacity: 0.5; }
    .sport .row .timer {
      opacity: 0.5;
      font-size: 12px;
      font-weight: bold;
      margin-left: 10px; }
    .sport .row .league {
      font-size: 12px;
      font-weight: bold;
      margin-left: 10px;
      max-width: calc(100vw - 170px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .sport .row .league .text {
        opacity: 0.5; }
    .sport .row .season {
      font-size: 12px;
      font-weight: bold;
      margin-right: 10px; }
    .sport .row .live {
      font-size: 12px;
      font-weight: bold;
      color: #e01c23;
      margin-right: 10px;
      text-transform: uppercase; }
    .sport .row .col {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .sport .row .col .event {
        width: 100%;
        display: flex;
        flex: 1 1; }
        .sport .row .col .event .details {
          width: 100%;
          padding-right: 20px; }
          .sport .row .col .event .details .teams {
            display: flex;
            flex-direction: column;
            justify-content: space-between; }
            .sport .row .col .event .details .teams .team {
              font-size: 16px;
              color: var(--white);
              width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap; }
        .sport .row .col .event .score {
          display: flex;
          flex-direction: column;
          align-items: flex-end; }
          .sport .row .col .event .score .event-score {
            color: #0e7eff !important; }
          .sport .row .col .event .score .game-scores {
            position: relative;
            display: flex;
            letter-spacing: 10px;
            align-items: center;
            font-size: 16px;
            margin-left: -5px; }
            .sport .row .col .event .score .game-scores .game-result {
              color: var(--white) !important; }
            .sport .row .col .event .score .game-scores .match::after {
              content: " ";
              position: absolute;
              top: 0.2em;
              background: var(--white);
              width: 1px;
              height: 40px; }
            .sport .row .col .event .score .game-scores .match-lost {
              color: var(--white) !important;
              opacity: 0.7; }
            .sport .row .col .event .score .game-scores .match-won {
              color: #044da1 !important;
              opacity: 0.8; }
            .sport .row .col .event .score .game-scores .game-current {
              color: #0e7eff !important;
              letter-spacing: 0px;
              margin: 0 10px; }
            .sport .row .col .event .score .game-scores .current-turn {
              width: 6px;
              height: 6px;
              background-color: #ebff13;
              border-radius: 50%; }
      .sport .row .col .stats {
        -webkit-filter: brightness(0) invert(5);
                filter: brightness(0) invert(5); }
      .sport .row .col .plus {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: transparent; }
        .sport .row .col .plus .icon {
          font-size: 14px;
          font-weight: bold; }
      .sport .row .col .bet-types {
        flex: 1 1;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: center; }
        .sport .row .col .bet-types .container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center; }
          .sport .row .col .bet-types .container i {
            font-size: 1.4rem;
            opacity: 0.5; }
  .sport .buttons.cols-1 button {
    width: 100%; }
  .sport .buttons.cols-2 button {
    width: 50%; }
  .sport .buttons.cols-3 button {
    width: 33.3%; }
  .sport .buttons.cols-4 button {
    width: 25%; }
  .sport .buttons-ods:first-child button:first-child {
    border-top-left-radius: 6px; }
  .sport .buttons-ods:first-child button:last-child {
    border-top-right-radius: 6px; }
  .sport .buttons-ods:last-child button:first-child {
    border-bottom-left-radius: 6px; }
  .sport .buttons-ods:last-child button:last-child {
    border-bottom-right-radius: 6px; }
  .sport .buttons-ods {
    flex: 1 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 3px !important; }
    .sport .buttons-ods button {
      opacity: var(--sport-odd-buttons-opacity);
      border-radius: 0px;
      border-top: solid 2px var(--sport-event-buttons-background-color);
      border-bottom: solid 2px var(--sport-event-buttons-background-color);
      background-color: var(--sport-event-buttons-background-color);
      font-size: 14px; }
    .sport .buttons-ods button:hover {
      border-top: solid 2px #044da1;
      border-bottom: solid 2px #044da1;
      background-color: #044da1; }
    @media (hover: none) {
      .sport .buttons-ods button:hover {
        padding: unset;
        border-radius: 0px;
        border-top: solid 2px var(--light-black);
        border-bottom: solid 2px var(--light-black);
        background-color: var(--light-black);
        font-size: 14px;
        font-weight: bold; } }
    .sport .buttons-ods button.selected {
      opacity: 1;
      border-top: solid 2px var(--dark-blue-to-dark-gray);
      border-bottom: solid 2px var(--dark-blue-to-dark-gray);
      background-color: var(--dark-blue-to-dark-gray); }
      .sport .buttons-ods button.selected div div {
        color: white !important; }
    .sport .buttons-ods button:not(:last-child) {
      margin-right: 3px !important; }
    .sport .buttons-ods .up {
      border-top: solid 2px #2C8AF7;
      -webkit-animation: blink-border-top normal 0.5s 4 ease-in-out forwards;
              animation: blink-border-top normal 0.5s 4 ease-in-out forwards; }
    .sport .buttons-ods .down {
      border-bottom: solid 2px #e82f2f;
      -webkit-animation: blink-border-bottom normal 0.5s 4 ease-in-out forwards;
              animation: blink-border-bottom normal 0.5s 4 ease-in-out forwards; }

@-webkit-keyframes blink-border-top {
  0% {
    border-top: solid 2px transparent; }
  50% {
    border-top: solid 2px #2C8AF7; }
  100% {
    border-top: solid 2px transparent; } }

@keyframes blink-border-top {
  0% {
    border-top: solid 2px transparent; }
  50% {
    border-top: solid 2px #2C8AF7; }
  100% {
    border-top: solid 2px transparent; } }

@-webkit-keyframes blink-border-bottom {
  0% {
    border-bottom: solid 2px transparent; }
  50% {
    border-bottom: solid 2px #e82f2f; }
  100% {
    border-bottom: solid 2px transparent; } }

@keyframes blink-border-bottom {
  0% {
    border-bottom: solid 2px transparent; }
  50% {
    border-bottom: solid 2px #e82f2f; }
  100% {
    border-bottom: solid 2px transparent; } }
  .sport .upcoming .title {
    font-weight: bold;
    font-size: 18px;
    opacity: 0.8;
    margin: 20px 0 10px 10px;
    text-transform: uppercase; }
  .sport .search {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    height: 40px;
    background-color: var(--blue); }
  .sport .today {
    display: flex;
    flex-direction: column; }

.center {
  display: block;
  margin-left: auto;
  margin-right: auto; }

@media only screen and (max-width: 1550px) {
  .sport .top-bets .ant-carousel .card .event .row-2 .team {
    font-size: 16px !important; } }

@media only screen and (max-width: 1400px) {
  .sport .top-bets .ant-carousel .card .event .row-2 .team {
    font-size: 14px !important; } }

@media only screen and (max-width: 600px) {
  .sport .events .bet-type-1,
  .sport .events .bet-type-2,
  .sport .events .bet-type-3,
  .sport .events .bet-type-4 {
    width: 17vw !important; }
  .sport .header .col .bet-types .odds {
    width: 17vw !important; }
    .sport .header .col .bet-types .odds div {
      width: 17vw !important; }
      .sport .header .col .bet-types .odds div div {
        width: 17vw !important; }
  .sport .bets-filter-container:last-child div {
    width: 82vw; } }

@media all {
  .sport .top-bets {
    margin-bottom: unset; }
    .sport .top-bets .top {
      display: flex;
      justify-content: space-between; }
    .sport .top-bets .carousel-1,
    .sport .top-bets .carousel-2,
    .sport .top-bets .carousel-3 {
      display: none; }
    .sport .top-bets .ant-carousel .card .event:not(:last-child) {
      margin-right: 10px; }
    .sport .top-bets .ant-carousel .card .event .container .buttons-ods button .container {
      padding: 7px; }
    .sport .top-bets .ant-carousel .card .event .row-2 .team {
      font-size: 20px; }
  .sport .top-bets[min-width~="300px"] .carousel-1 {
    display: block; }
  .sport .top-bets[min-width~="500px"] .carousel-1,
  .sport .top-bets[min-width~="500px"] .carousel-3 {
    display: none; }
  .sport .top-bets[min-width~="500px"] .carousel-2 {
    display: block; }
  .sport .top-bets[min-width~="890px"] .carousel-1,
  .sport .top-bets[min-width~="890px"] .carousel-2 {
    display: none; }
  .sport .top-bets[min-width~="890px"] .carousel-3 {
    display: block; }
  .sport .events .header {
    padding: 0.6vw;
    padding-right: 2.6vw; }
  .sport .events .row {
    background-color: var(--dark-blue); }
  .sport .events .bet-type-0 {
    max-width: 220px;
    min-width: 220px;
    margin-right: auto;
    flex-basis: auto; }
    .sport .events .bet-type-0 .details {
      width: 100%; }
      .sport .events .bet-type-0 .details .country {
        display: flex;
        align-items: center; }
      .sport .events .bet-type-0 .details .title {
        font-size: 18px;
        color: var(--white);
        max-width: 100%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis; }
  .sport .events .bet-type-1,
  .sport .events .bet-type-2,
  .sport .events .bet-type-3,
  .sport .events .bet-type-4 {
    width: 8.8vw; }
  .sport .events .bet-type-5,
  .sport .events .bet-type-6 {
    display: flex; }
    .sport .events .bet-type-5 .plus,
    .sport .events .bet-type-6 .plus {
      padding: 3px;
      height: auto;
      width: auto;
      max-width: 35px; }
  .sport .events .bet-types {
    width: 7.8vw; }
    .sport .events .bet-types .container .buttons-ods {
      display: flex; }
  .sport .events[min-width~="403px"] .bet-types {
    min-width: 7.8vw; }
  .sport .events[min-width~="403px"] .bet-type-5,
  .sport .events[min-width~="403px"] .bet-type-6 {
    display: flex; }
  .sport .events[min-width~="530px"] .bet-type-2 {
    display: flex; }
  .sport .events[min-width~="690px"] .bet-type-3 {
    display: flex; }
  .sport .events[min-width~="850px"] .bet-type-4 {
    display: flex; } }

@media only screen and (min-width: 425px) {
  .sport .top-bets .ant-carousel .card .event {
    width: 45%; } }

@media only screen and (max-width: 1024px) {
  .sport .events .bet-types {
    width: 7.8vw; }
    .sport .events .bet-types .container .buttons-ods {
      display: flex; }
      .sport .events .bet-types .container .buttons-ods button {
        min-height: 50px; }
  .sport .event .score .game-result {
    letter-spacing: 0px;
    padding: 0 5px; } }

.sport-types::-webkit-scrollbar {
  display: none; }

.sport-types .swipe {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  background-color: #172a40; }
  .sport-types .swipe .sport-type {
    cursor: pointer; }
    .sport-types .swipe .sport-type .box {
      padding: 12px 20px 10px 15px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      align-content: space-between;
      opacity: 0.7; }
      .sport-types .swipe .sport-type .box .icon {
        margin-bottom: 5px; }
        .sport-types .swipe .sport-type .box .icon img {
          -webkit-filter: brightness(0) invert(1);
                  filter: brightness(0) invert(1);
          width: 25px;
          height: 25px; }
      .sport-types .swipe .sport-type .box .title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        font-weight: bold; }
    .sport-types .swipe .sport-type .box:hover {
      color: #40a9ff; }
    .sport-types .swipe .sport-type .box.selected {
      background-color: #243a53;
      font-weight: 500;
      opacity: 1; }
  .sport-types .swipe .sport-type:first-child {
    /* margin-left: 0; */ }
  .sport-types .swipe .sport-type:last-child .divider {
    /* margin-right: 0; */
    border: none; }
  .sport-types .swipe .paddle {
    width: 1em;
    height: auto;
    cursor: pointer;
    margin-left: 1rem !important;
    margin-right: 1rem !important;
    border: none !important;
    background-color: transparent !important;
    border-radius: 0 !important;
    opacity: 1; }
  .sport-types .swipe .left-paddle {
    left: 0; }
  .sport-types .swipe .right-paddle {
    right: 0; }

.bet-slip .teams .bet-slip-teams-container {
  flex-wrap: wrap; }

.bet-slip .load-code-mobile-view {
  display: none; }

.bet-slip .default-currency {
  top: 0.1rem;
  left: 13rem;
  z-index: 1; }

.bet-slip .ant-select-selection {
  background-color: transparent;
  border: none; }

@media only screen and (max-width: 1300px) {
  .bet-slip .default-currency {
    left: 9rem; } }

@media only screen and (max-width: 1000px) {
  .bet-slip .default-currency {
    left: 60vw !important; } }

@media only screen and (max-width: 600px) {
  .bet-slip .default-currency {
    left: 53vw !important; } }

@media only screen and (max-width: 360px) {
  .bet-slip .default-currency {
    left: 48vw !important; } }

.bet-slip .ant-tabs-ink-bar .ant-tabs-ink-bar-animated {
  width: 0px; }

.bet-slip .sport-images {
  background-color: var(--betslip-sport-images);
  padding: 0 15px 0 15px; }

.bet-slip .ant-tabs-ink-bar {
  height: 2px; }

.bet-slip .ant-tabs-nav .ant-tabs-tab {
  margin: 0px;
  width: 5.9rem; }

@media only screen and (max-width: 1300px) {
  .bet-slip .ant-tabs-nav .ant-tabs-tab {
    width: 4.5rem; } }

@media only screen and (max-width: 600px) {
  .bet-slip .load-code-mobile-view {
    padding: 10px 15px !important; }
  .bet-slip .content .events .placebet {
    padding: 10px 15px !important; }
  .bet-slip .content .events .event {
    padding: 10px 15px !important; }
  .bet-slip .load-code {
    display: none; }
  .bet-slip .load-code-mobile-view {
    display: block;
    margin-top: 20px; }
    .bet-slip .load-code-mobile-view #input-code-mobile-view {
      width: 43vw;
      display: inline-block; }
    .bet-slip .load-code-mobile-view #submit-code-mobile-view {
      width: 21vw;
      display: inline-block; }
    .bet-slip .load-code-mobile-view .ant-btn {
      height: 40px; }
    .bet-slip .load-code-mobile-view fieldset {
      padding: 20px 10px;
      border: 2px solid #354361;
      border-radius: 5px; }
    .bet-slip .load-code-mobile-view legend {
      font-size: 14px;
      width: auto;
      margin-left: 5px; }
  .bet-slip .tabs-betslip .ant-tabs-top-bar .ant-tabs-tab {
    width: 25vw; } }

@media only screen and (max-width: 450px) {
  .bet-slip .load-code-mobile-view #input-code-mobile-view {
    width: 39vw;
    display: inline-block; }
  .bet-slip .load-code-mobile-view #submit-code-mobile-view {
    width: 22vw;
    display: inline-block; } }

@media only screen and (max-width: 380px) {
  .bet-slip .load-code-mobile-view #input-code-mobile-view {
    width: 36vw;
    display: inline-block; }
  .bet-slip .load-code-mobile-view #submit-code-mobile-view {
    width: 19vw;
    display: inline-block; } }

@media only screen and (max-width: 360px) {
  .bet-slip .load-code-mobile-view #input-code-mobile-view {
    width: 34vw;
    display: inline-block; }
  .bet-slip .load-code-mobile-view #submit-code-mobile-view {
    width: 21vw;
    display: inline-block; } }

.bet-slip .bit-slip-header {
  padding: 20px 0px 15px 0px;
  margin-bottom: 0px !important;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  width: 115px;
  color: var(--white);
  position: absolute;
  z-index: 1; }

.bet-slip #default-currency-container {
  position: absolute; }
  .bet-slip #default-currency-container .ant-select-arrow .ant-select-arrow-icon svg {
    display: none; }

.bet-slip .ant-select-selection--single {
  position: absolute !important; }

.bet-slip .events .bet-slip-row-container .event:not(:last-child) {
  border-bottom: solid 1px #3A4864; }

.bet-slip #split-bitslip {
  border-bottom: solid 1px #3A4864;
  opacity: 0.25; }

.bet-slip .ant-tabs-bar {
  border-bottom: none !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px; }

.bet-slip {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  border-radius: 4px; }
  .bet-slip .tabs-top > .ant-tabs-bar {
    margin: unset; }
  .bet-slip .tabs-top > .ant-tabs-top-bar {
    border: none;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    height: 60px;
    width: 100%;
    background-color: var(--blue); }
    .bet-slip .tabs-top > .ant-tabs-top-bar .ant-tabs-tab {
      padding: 20px 0px 15px 0px;
      margin-bottom: 0px !important;
      width: 115px;
      text-align: center;
      font-size: 16px;
      opacity: 0.5;
      color: var(--white);
      text-transform: uppercase; }
    .bet-slip .tabs-top > .ant-tabs-top-bar .ant-tabs-tab-active {
      display: none;
      opacity: unset; }
    .bet-slip .tabs-top > .ant-tabs-top-bar .ant-tabs-ink-bar {
      background-color: var(--white); }
    .bet-slip .tabs-top > .ant-tabs-top-bar .ant-tabs-nav-scroll {
      display: flex;
      flex-direction: row; }
  .bet-slip .delete-all {
    color: var(--white-to-red);
    padding: 10px 15px 0 15px;
    display: flex;
    flex-direction: row-reverse;
    cursor: pointer; }
  .bet-slip .bet-code {
    border-bottom: solid 1px var(--light-black);
    background-color: var(--white);
    padding: 10px;
    text-align: center; }
    .bet-slip .bet-code .text,
    .bet-slip .bet-code .code,
    .bet-slip .bet-code .close {
      color: #182438; }
    .bet-slip .bet-code .text {
      font-size: 14px; }
    .bet-slip .bet-code .code {
      font-size: 22px;
      font-weight: bold; }
    .bet-slip .bet-code .close {
      position: absolute;
      right: 0;
      margin-right: 10px;
      width: 30px;
      height: 30px;
      text-align: right;
      cursor: pointer; }
  .bet-slip .tabs-betslip .ant-tabs-bar {
    margin: unset; }
  .bet-slip .tabs-betslip .ant-tabs-top-bar {
    border: none;
    height: 40px;
    border-bottom: solid 1px var(--light-black); }
    .bet-slip .tabs-betslip .ant-tabs-top-bar .ant-tabs-tab {
      padding: 12px 0 7px 0;
      margin-bottom: 0px !important;
      text-align: center;
      font-size: 14px;
      color: var(--white);
      opacity: 0.5; }
    .bet-slip .tabs-betslip .ant-tabs-top-bar .ant-tabs-tab-active {
      opacity: unset; }
    .bet-slip .tabs-betslip .ant-tabs-top-bar .ant-tabs-ink-bar {
      background-color: var(--white); }
    .bet-slip .tabs-betslip .ant-tabs-top-bar .ant-tabs-nav-scroll {
      display: flex;
      flex-direction: row;
      background-color: var(--dark-blue-to-white);
      color: var(--white); }
  .bet-slip .load-code {
    margin-top: 30px; }
    .bet-slip .load-code .top {
      border: none;
      border-top-right-radius: 20px;
      border-top-left-radius: 20px;
      height: 30px;
      font-size: 16px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start; }
    .bet-slip .load-code .content {
      padding: 15px !important;
      background-color: var(--dark-blue-to-white);
      border-radius: 5px !important; }
      .bet-slip .load-code .content .fields {
        display: flex;
        align-items: center; }
        .bet-slip .load-code .content .fields .code {
          flex: 1 1; }
          .bet-slip .load-code .content .fields .code input {
            height: 35px !important;
            font-size: 12px; }
        .bet-slip .load-code .content .fields .button {
          margin-left: 15px;
          flex: 0.5 1; }
          .bet-slip .load-code .content .fields .button button {
            height: 35px; }
      .bet-slip .load-code .content .notice {
        margin-top: 20px; }
  .bet-slip .multiple {
    border: solid 1px red; }
  .bet-slip .content {
    padding: unset !important;
    width: 100% !important;
    background-color: var(--dark-blue-to-white); }
    .bet-slip .content .events {
      font-size: 13px;
      border-radius: unset;
      background-color: unset;
      padding-bottom: unset;
      color: var(--white) !important; }
      .bet-slip .content .events .bonus-btn-container {
        display: flex;
        flex-direction: column;
        align-items: end;
        height: 25px; }
        .bet-slip .content .events .bonus-btn-container .bonus-btn {
          width: 100px;
          font-size: 13px;
          margin: 0 5px !important; }
      .bet-slip .content .events .event {
        padding: 10px 15px 10px 15px; }
      .bet-slip .content .events .error-message {
        padding: 20px 20px 20px 20px !important; }
        .bet-slip .content .events .error-message .success-bet {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%; }
          .bet-slip .content .events .error-message .success-bet div {
            padding: 10px; }
          .bet-slip .content .events .error-message .success-bet .ant-btn::before {
            background: none !important;
            transition: none !important; }
          .bet-slip .content .events .error-message .success-bet .anticon-loading {
            margin-left: -17px !important;
            font-size: 22px;
            padding-right: 15px; }
          .bet-slip .content .events .error-message .success-bet .clear-bets-btn {
            width: 140px;
            height: 40px;
            border-radius: 5px;
            background-color: #0b995c;
            display: flex;
            align-items: center; }
          .bet-slip .content .events .error-message .success-bet .clear-bet-interval {
            color: var(--white);
            font-size: 12px;
            left: 10px;
            position: absolute; }
      .bet-slip .content .events .message,
      .bet-slip .content .events .error-message {
        font-weight: bold; }
        .bet-slip .content .events .message .success,
        .bet-slip .content .events .error-message .success {
          background-color: unset;
          justify-content: center;
          font-size: 20px; }
      .bet-slip .content .events .row-amount {
        border: none;
        display: flex;
        justify-content: flex-end;
        align-items: center; }
        .bet-slip .content .events .row-amount .currency {
          color: #182438;
          font-size: 14px;
          font-weight: bold;
          padding: 5px; }
      .bet-slip .content .events .row-total-possible {
        display: flex;
        justify-content: flex-end; }
        .bet-slip .content .events .row-total-possible div {
          padding: 5px; }
      .bet-slip .content .events .row {
        padding: unset;
        color: var(--white);
        font-size: 14px;
        border: none;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center; }
        .bet-slip .content .events .row .icon {
          min-width: 25px;
          margin-right: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center; }
          .bet-slip .content .events .row .icon img {
            -webkit-filter: var(--black-to-white);
                    filter: var(--black-to-white);
            width: 18px;
            height: 18px; }
        .bet-slip .content .events .row .sport-icon {
          width: 23px; }
        .bet-slip .content .events .row .text {
          width: 100%;
          display: flex;
          color: var(--white);
          font-size: 12px;
          text-align: center;
          font-weight: 300; }
        .bet-slip .content .events .row .odds {
          font-size: 14px; }
        .bet-slip .content .events .row .teams {
          color: var(--white);
          margin-right: auto;
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 13px; }
          .bet-slip .content .events .row .teams .live {
            background-color: #e82f2f;
            color: var(--white);
            font-size: 10px;
            font-weight: bold;
            padding: 0px 1px 0px 1px; }
        .bet-slip .content .events .row .team {
          color: var(--white);
          font-weight: bold;
          font-family: "Roboto Black"; }
        .bet-slip .content .events .row .odd-name {
          color: var(--white); }
        .bet-slip .content .events .row .odd-value {
          color: var(--white);
          font-weight: bold;
          margin-left: auto;
          font-family: "Roboto Black"; }
        .bet-slip .content .events .row .odd-value.up {
          border-top: solid 2px #2C8AF7;
          -webkit-animation: blink-border-top-betslip normal 0.5s 4 ease-in-out forwards;
                  animation: blink-border-top-betslip normal 0.5s 4 ease-in-out forwards; }
        .bet-slip .content .events .row .odd-value.down {
          border-bottom: solid 2px #e82f2f;
          -webkit-animation: blink-border-bottom-betslip normal 0.5s 4 ease-in-out forwards;
                  animation: blink-border-bottom-betslip normal 0.5s 4 ease-in-out forwards; }

@-webkit-keyframes blink-border-top-betslip {
  0% {
    border-top: solid 2px #2C8AF7; }
  50% {
    border-top: solid 2px transparent; }
  100% {
    border-top: solid 2px #2C8AF7; } }

@keyframes blink-border-top-betslip {
  0% {
    border-top: solid 2px #2C8AF7; }
  50% {
    border-top: solid 2px transparent; }
  100% {
    border-top: solid 2px #2C8AF7; } }

@-webkit-keyframes blink-border-bottom-betslip {
  0% {
    border-bottom: solid 2px #e82f2f; }
  50% {
    border-bottom: solid 2px transparent; }
  100% {
    border-bottom: solid 2px #e82f2f; } }

@keyframes blink-border-bottom-betslip {
  0% {
    border-bottom: solid 2px #e82f2f; }
  50% {
    border-bottom: solid 2px transparent; }
  100% {
    border-bottom: solid 2px #e82f2f; } }
        .bet-slip .content .events .row .close {
          color: var(--white);
          font-weight: bold;
          cursor: pointer; }
          .bet-slip .content .events .row .close i svg {
            width: 20px;
            height: 20px; }
        .bet-slip .content .events .row .possible {
          margin-left: auto;
          color: var(--white);
          font-weight: bold; }
        .bet-slip .content .events .row .buttons {
          width: 100%;
          flex-direction: column; }
          .bet-slip .content .events .row .buttons button:not(:last-child) {
            margin-bottom: 10px !important; }
      .bet-slip .content .events .row:not(:last-child) {
        margin-bottom: 1px; }
      .bet-slip .content .events .placebet {
        padding: 20px 15px 5px 15px; }
        .bet-slip .content .events .placebet .row {
          margin: 0px 0px 5px 0px !important; }
          .bet-slip .content .events .placebet .row .placebet-btn {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            justify-content: center; }
            .bet-slip .content .events .placebet .row .placebet-btn div {
              padding: 10px; }
            .bet-slip .content .events .placebet .row .placebet-btn .ant-btn::before {
              background: none !important;
              transition: none !important; }
            .bet-slip .content .events .placebet .row .placebet-btn .anticon-loading {
              font-size: 30px;
              position: absolute;
              left: 55px;
              top: 5px; }
            .bet-slip .content .events .placebet .row .placebet-btn .clear-bets-btn {
              width: 140px;
              height: 40px;
              border-radius: 5px;
              display: flex;
              align-items: center; }
            .bet-slip .content .events .placebet .row .placebet-btn .clear-bet-interval {
              color: var(--white);
              font-size: 16px;
              left: 40px;
              position: absolute; }
        .bet-slip .content .events .placebet .stake {
          display: flex;
          align-self: center; }
          .bet-slip .content .events .placebet .stake .text {
            color: var(--white); }
          .bet-slip .content .events .placebet .stake .total {
            color: var(--white); }
        .bet-slip .content .events .placebet .total-odds {
          color: var(--white); }
        .bet-slip .content .events .placebet .total-odds-value {
          color: var(--white); }
        .bet-slip .content .events .placebet .total-possible {
          color: var(--white);
          display: flex;
          align-self: center; }
        .bet-slip .content .events .placebet .total-possible-value {
          color: var(--white);
          font-family: "Roboto Black";
          font-weight: bold;
          font-size: 20px;
          text-align: end; }
        .bet-slip .content .events .placebet .terms {
          margin-top: 7px;
          margin-bottom: 12px;
          font-size: 12px;
          color: var(--white); }
          .bet-slip .content .events .placebet .terms a {
            color: var(--white);
            font-weight: bold;
            text-decoration: underline; }
        .bet-slip .content .events .placebet .amount {
          display: flex;
          align-content: center;
          justify-content: center; }
          .bet-slip .content .events .placebet .amount .anticon svg {
            display: inline-block;
            width: 8px;
            -webkit-filter: brightness(0);
                    filter: brightness(0);
            opacity: var(--high-to-very-low-opacity); }
          .bet-slip .content .events .placebet .amount .currency {
            margin-left: 10px;
            display: flex;
            align-items: center;
            color: var(--white);
            font-weight: bold; }
  .bet-slip .notice {
    padding: 10px;
    margin-bottom: 10px;
    width: 100%; }
    .bet-slip .notice .alert:not(:last-child) {
      margin-bottom: 10px; }
  .bet-slip .images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center; }

.betslip-settings ant-tabs-bar {
  margin-top: 1rem !important; }

.betslip-settings i {
  position: absolute;
  z-index: 1;
  right: 5px;
  top: 20px;
  font-size: 25px; }

.settings-popover {
  width: 230px; }
  .settings-popover .ant-popover-content {
    position: -webkit-sticky;
    position: sticky;
    top: 210px; }
  .settings-popover .ant-popover-arrow {
    right: 8px !important; }
  .settings-popover .ant-popover-title {
    font-weight: bold;
    height: 43px;
    align-items: center;
    display: flex;
    color: #252525; }
  .settings-popover .betslip-settings-container .setting {
    display: flex;
    justify-content: space-between;
    padding: 10px 0px; }
    .settings-popover .betslip-settings-container .setting div {
      font-size: 14px;
      color: var(--black-to-white); }
  .settings-popover .betslip-settings-container .currency-setting {
    font-weight: bold;
    color: #252525; }
    .settings-popover .betslip-settings-container .currency-setting .default-currency {
      width: 87.2px; }
    .settings-popover .betslip-settings-container .currency-setting svg {
      font-weight: bold;
      color: #252525; }
  .settings-popover .betslip-settings-container .default-bet-amount {
    width: 87.2px !important;
    height: 30px !important;
    border-radius: 4px;
    border: solid 1px #dee2e8;
    margin: 0 0 0 10px;
    font-weight: bold;
    font-size: 16px;
    color: #252525; }
  .settings-popover .betslip-settings-container .ant-switch-checked {
    background-color: #0b995c !important; }
  .settings-popover .betslip-settings-container .betslip-switch {
    width: 60px;
    height: 30px;
    border-radius: 15px; }
  .settings-popover .betslip-settings-container .ant-switch::after {
    width: 20px;
    height: 20px;
    top: 4px;
    margin: 0 0 0 5px; }
  .settings-popover .betslip-settings-container .ant-switch-checked::after {
    width: 20px;
    height: 20px;
    top: 4px;
    left: 48px; }

@media only screen and (max-width: 1300px) {
  .bet-slip .ant-tabs-nav-wrap {
    margin-bottom: 0px; }
  .bet-slip .tabs-top > .ant-tabs-top-bar .ant-tabs-tab,
  .bet-slip .tabs-top > .ant-tabs-top-bar .ant-tabs-ink-bar {
    margin: 0;
    width: 80px !important; }
  .bet-slip .tabs-top > .ant-tabs-top-bar .ant-tabs-tab:last-child {
    margin-left: 30px; }
  .bet-slip .content .events .event {
    padding: 10px; }
  .bet-slip .content .events .placebet {
    padding: 10px; }
  .mobile-bet-slip .ant-drawer-close {
    left: -25px;
    top: -15px;
    background: #044da1;
    width: 26px;
    border-radius: 50%;
    height: 26px; }
    .mobile-bet-slip .ant-drawer-close svg {
      margin: 0 0 14px 0; }
  .mobile-bet-slip .bet-slip .content .events .placebet .amount-up-down .input {
    width: 50px !important; }
  .mobile-bet-slip .bet-slip .content .events .placebet .total-possible-value {
    font-size: 20px; }
  .mobile-bet-slip .bet-slip .tabs-top > .ant-tabs-top-bar {
    border-top-right-radius: 0;
    border-top-left-radius: 0; }
  .mobile-bet-slip .bet-slip .load-code .top {
    border: none;
    border-top-right-radius: 0;
    border-top-left-radius: 0; } }

.ant-modal.register {
  width: 900px !important; }
  @media only screen and (max-width: 1024px) {
    .ant-modal.register {
      width: 100%; }
      .ant-modal.register .content {
        padding: 10px; }
        .ant-modal.register .content .prefix-input {
          width: 50px; }
        .ant-modal.register .content .seperator-input {
          width: 55px; }
        .ant-modal.register .content .number-input {
          width: 125px; } }
  .ant-modal.register .ant-modal-body {
    padding: 20px 0px 40px 0px; }
    .ant-modal.register .ant-modal-body .ant-btn-primary {
      background: #044da1; }
  .ant-modal.register .ant-steps {
    padding: 15px 20px 15px 20px; }
    .ant-modal.register .ant-steps .ant-steps-item {
      display: flex;
      flex-direction: row;
      align-items: center; }
      .ant-modal.register .ant-steps .ant-steps-item .ant-steps-item-icon {
        min-width: 20px;
        max-width: 20px;
        height: 20px;
        border: unset;
        background-color: #d0d9e4; }
        .ant-modal.register .ant-steps .ant-steps-item .ant-steps-item-icon .ant-steps-icon {
          color: transparent !important;
          width: 20px; }
      .ant-modal.register .ant-steps .ant-steps-item .ant-steps-item-content .ant-steps-item-title {
        color: #182438;
        font-weight: bold;
        line-height: unset;
        white-space: normal; }
      .ant-modal.register .ant-steps .ant-steps-item .ant-steps-item-content .ant-steps-item-title:after {
        height: unset; }
      .ant-modal.register .ant-steps .ant-steps-item .ant-steps-item-content .ant-steps-item-description {
        font-size: 12px;
        font-weight: bold; }
    .ant-modal.register .ant-steps .ant-steps-item-wait .ant-steps-item-title,
    .ant-modal.register .ant-steps .ant-steps-item-wait .ant-steps-item-description {
      opacity: 0.5; }
    .ant-modal.register .ant-steps .ant-steps-item-finish .ant-steps-item-icon {
      background-color: #2C8AF7; }
    .ant-modal.register .ant-steps .ant-steps-item-finish .ant-steps-item-content .ant-steps-item-title {
      color: #2C8AF7; }
    .ant-modal.register .ant-steps .ant-steps-item-finish .ant-steps-item-content .ant-steps-item-description {
      color: #2C8AF7; }
  .ant-modal.register .step {
    display: flex;
    flex-direction: column;
    justify-content: center; }
    .ant-modal.register .step .strip {
      width: 100%;
      height: 110px;
      background-color: #f0f4f9;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px; }
      .ant-modal.register .step .strip .content .text {
        text-align: center;
        max-width: 590px;
        color: #182438;
        font-size: 16px;
        font-weight: bold; }
    .ant-modal.register .step > .content {
      display: flex;
      justify-content: center; }
      .ant-modal.register .step > .content .form {
        max-width: 375px; }
        .ant-modal.register .step > .content .form input {
          border: solid 1px var(--light-black); }
        .ant-modal.register .step > .content .form .ant-checkbox-wrapper {
          line-height: normal; }
        .ant-modal.register .step > .content .form .ant-form-item-children label span {
          color: #182438 !important;
          font-size: 12px; }
        .ant-modal.register .step > .content .form .ant-form-item .ant-form-item-label label {
          color: #182438; }
        .ant-modal.register .step > .content .form .ant-row {
          margin-bottom: 5px; }
        .ant-modal.register .step > .content .form .terms {
          height: 80px; }
          .ant-modal.register .step > .content .form .terms a {
            color: #182438;
            text-decoration: underline; }
        .ant-modal.register .step > .content .form .ant-select-selection {
          border: solid 1px var(--light-black); }
          .ant-modal.register .step > .content .form .ant-select-selection .ant-select-selection__rendered {
            margin-top: 4px; }
          .ant-modal.register .step > .content .form .ant-select-selection .ant-select-selection-selected-value {
            color: #182438; }
        .ant-modal.register .step > .content .form #Step1_day {
          width: 20% !important; }
        .ant-modal.register .step > .content .form #Step1_month {
          width: 50% !important; }
        .ant-modal.register .step > .content .form #Step1_year {
          width: 30% !important; }
        .ant-modal.register .step > .content .form .next {
          margin-top: 20px !important; }
        .ant-modal.register .step > .content .form .btn_none_bg {
          margin-top: 20px !important; }
          .ant-modal.register .step > .content .form .btn_none_bg span {
            color: var(--black-to-white) !important;
            font-size: 16px !important;
            font-weight: bold;
            cursor: pointer; }
  .ant-modal.register .ant-modal-close {
    margin-top: -10px;
    margin-right: -10px; }
    .ant-modal.register .ant-modal-close i {
      color: var(--light-black); }

.ant-message-notice-content {
  background: var(--black-to-white);
  font-size: 20px; }

.form {
  width: 100%; }
  .form .validation-meter div {
    color: black; }
  .form .validation-meter .alert .icon .inner {
    display: flex;
    align-items: center; }
  .form .validation-meter .alert .success {
    background-color: lightgreen; }
  .form .validation-meter .alert svg {
    width: 12px; }
  .form .ant-input {
    color: var(--white); }
  .form .ant-select-arrow i svg {
    color: var(--white) !important; }
  .form .ant-input-disabled {
    background-color: lightgray !important; }
  .form .ant-select-disabled .ant-select-selection {
    background-color: lightgray; }
  .form .ant-form-item .ant-form-item-label {
    text-align: unset; }
    .form .ant-form-item .ant-form-item-label label {
      color: var(--white);
      font-size: 14px;
      font-weight: bold; }
  .form .ant-form-item .ant-input-group-addon {
    padding: unset;
    border: unset; }
    .form .ant-form-item .ant-input-group-addon div {
      color: red; }
    .form .ant-form-item .ant-input-group-addon .ant-btn {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset; }
  .form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single {
    height: 40px; }
    .form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered {
      margin-top: 4px; }
      .form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
        color: #182438; }
  .form .ant-form-item .ant-select-arrow .ant-select-arrow-icon svg {
    color: #c1c1c1; }
  .form .ant-form-item-with-help {
    margin-bottom: 10px !important; }
  .form .captcha {
    border: solid 0px red; }

.form-white .ant-form-item .ant-form-item-label label {
  color: #182438; }

.phone-number-item .ant-form-item-control {
  line-height: 0; }

.phone-number-item .seperator-input {
  width: 55px;
  pointer-events: 'none';
  background-color: var(--white);
  font-size: 40px;
  border-left: 0 !important;
  border-right: 0 !important;
  text-align: center;
  cursor: auto; }

.phone-number-item .prefix-input {
  border-right: 0 !important;
  width: 70px;
  text-align: center; }

.phone-number-item .number-input {
  width: 140px;
  border-left: 0 !important;
  text-align: center;
  background-color: white !important; }

.phone-number-item .country-select {
  width: 110px !important; }
  .phone-number-item .country-select .ant-select-selection {
    height: 40px !important; }

.phone-number-item .ant-input {
  -webkit-box-shadow: 0 0 0 0 white !important; }

.phone-number-view .ant-input-disabled {
  background-color: lightgray; }

.phone-number-view .ant-input-group-wrapper .ant-input-group-addon {
  padding: 0 15px; }

.tabs-events .ant-tabs-bar {
  margin: unset;
  background-color: var(--light-black);
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px; }
  .tabs-events .ant-tabs-bar .ant-tabs-nav-scroll {
    display: flex;
    flex-direction: row;
    align-items: center; }
    .tabs-events .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab {
      font-size: 16px;
      font-weight: bold;
      opacity: 0.7;
      color: var(--white);
      text-transform: uppercase; }
    .tabs-events .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-nav {
      margin: auto; }
    .tabs-events .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-tab-active {
      opacity: unset; }
    .tabs-events .ant-tabs-bar .ant-tabs-nav-scroll .ant-tabs-ink-bar {
      background-color: #044da1; }

.document .sider-left,
.document .sider-right {
  display: none !important; }

.document .layout-inner {
  display: flex;
  justify-content: center; }
  .document .layout-inner .content {
    font-size: 17px; }
    .document .layout-inner .content .email_validate {
      display: flex;
      flex-direction: row-reverse;
      align-items: baseline;
      color: var(--white); }
    .document .layout-inner .content .container {
      border-radius: 4px;
      padding: 20px 0 20px 0; }
      .document .layout-inner .content .container h3 {
        display: none; }
      .document .layout-inner .content .container h5 {
        color: var(--white);
        font-size: 18px;
        font-size: 18px !important;
        opacity: 1 !important; }
        .document .layout-inner .content .container h5 u {
          text-decoration: none; }
      .document .layout-inner .content .container p {
        opacity: 0.8; }
        .document .layout-inner .content .container p strong u {
          color: var(--white);
          font-size: 17px;
          font-weight: bold;
          opacity: 1 !important; }
      .document .layout-inner .content .container p:nth-of-type(2) {
        font-size: 18px !important;
        opacity: 1 !important;
        font-weight: 400; }

@media only screen and (max-width: 600px) {
  .document {
    position: relative;
    bottom: 50px; }
    .document .layout-inner .content .container {
      padding: 20px 6vw 0 6vw; } }

.fixture .sider-left,
.fixture .sider-right {
  display: none !important; }

.fixture .layout-inner {
  display: flex;
  justify-content: center; }
  .fixture .layout-inner .content {
    max-width: 1024px; }
    .fixture .layout-inner .content .container {
      border-radius: 4px;
      background-color: var(--light-black);
      padding: 20px;
      display: flex; }
      .fixture .layout-inner .content .container .left,
      .fixture .layout-inner .content .container .right {
        width: 100%; }
        .fixture .layout-inner .content .container .left > .title,
        .fixture .layout-inner .content .container .right > .title {
          font-size: 20px !important;
          font-weight: bold;
          margin-bottom: 15px !important;
          color: var(--white); }
      .fixture .layout-inner .content .container .right {
        margin-left: 50px; }
      .fixture .layout-inner .content .container .list .col1 {
        margin-right: 15px; }
      .fixture .layout-inner .content .container .list .box {
        cursor: pointer;
        background-color: #044da1;
        width: 100%;
        max-width: 100%;
        min-height: 60px;
        padding: 0 12px 0 12px;
        margin-bottom: 10px;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        align-items: center; }
        .fixture .layout-inner .content .container .list .box .icon {
          font-size: 35px;
          line-height: 0; }
        .fixture .layout-inner .content .container .list .box .title {
          font-size: 16px !important;
          font-weight: bold;
          color: var(--white) !important;
          margin-bottom: unset; }
        .fixture .layout-inner .content .container .list .box .text {
          opacity: 0.6;
          font-size: 14px;
          font-weight: bold; }
  @media only screen and (max-width: 650px) {
    .fixture .layout-inner .content .container {
      flex-direction: column;
      align-items: center;
      justify-items: center; }
      .fixture .layout-inner .content .container .right {
        margin-top: 30px;
        margin-left: unset; } }

.account {
  border: black; }
  .account .sider-right {
    margin-right: 0px !important; }
  .account .content .container .title {
    font-size: 18px !important; }
  .account .ant-empty-description {
    color: var(--white); }
  .account #my-account-mobile-table {
    display: none; }
    .account #my-account-mobile-table thead {
      display: none !important; }
  .account .my-account-headline-menu-items {
    display: inline-block;
    margin-left: 10px;
    padding: 10px;
    background-color: var(--dark-blue);
    margin-bottom: 10px;
    border-radius: 20px; }
  .account #my-account-headline-menu {
    display: none; }
  @media only screen and (max-width: 1700px) {
    .account #my-account-left-sider-container {
      display: none; }
    .account #my-account-headline-menu {
      display: block;
      background-color: transparent; }
      .account #my-account-headline-menu .account-menu .ant-menu-item-selected {
        background-color: #2C8AF7 !important;
        border-radius: 5px; }
      .account #my-account-headline-menu .ant-layout-sider .ant-layout-sider-children {
        background-color: transparent !important; }
      .account #my-account-headline-menu .ant-menu {
        display: inline-block; }
      .account #my-account-headline-menu .account-menu .ant-menu-item {
        display: inline-block;
        width: auto;
        border: none;
        background-color: var(--dark-blue);
        border-radius: 5px;
        border: none;
        padding: 0 10px !important;
        margin-bottom: 10px !important; }
      .account #my-account-headline-menu .account-menu .ant-menu-item:not(:first-child) {
        margin-left: 10px !important; }
      .account #my-account-headline-menu .ant-menu .ant-menu-item .title {
        display: inline-block; }
      .account #my-account-headline-menu #my-account-left-menu-container {
        padding: 0; }
    .account .my-account-headeline {
      display: none; } }
  .account #my-account-left-menu-container {
    padding: 20px 10px 80px 10px; }
  .account .account-menu .ant-menu-item:not(:last-child) {
    padding: 5px 0 !important; }
  .account .account-menu .ant-menu-item-selected {
    background-color: var(--light-black) !important;
    border-radius: 5px; }
  .account .ant-menu {
    font-weight: 400 !important; }
    .account .ant-menu .ant-menu-item .title {
      opacity: 1; }
  .account .bets .ant-tabs-bar {
    border-bottom: 2px solid var(--light-black) !important; }
  .account .bets .ant-tabs-nav-scroll {
    padding-top: 5px; }
    .account .bets .ant-tabs-nav-scroll .ant-tabs-ink-bar {
      background-color: var(--white); }
    .account .bets .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab {
      opacity: 0.6;
      width: 27.5vw;
      text-align: center;
      font-size: 16px;
      color: var(--white) !important; }
    .account .bets .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab-active {
      opacity: 1 !important; }
  .account .my-account-headeline {
    padding: 15px 0 0 0;
    font-size: 18px; }
  .account .ant-layout .ant-layout-sider .ant-layout-sider-children {
    background-color: var(--dark-blue);
    height: auto;
    border-radius: 5px; }
  .account .my-account-menu-Icons {
    padding-right: 10px; }
  .account .alert {
    color: var(--light-black); }
  .account .ant-select {
    border-radius: 4px; }
    .account .ant-select .ant-select-selection {
      margin-top: 0px !important; }
  .account .ant-calendar-range-picker-input {
    width: 40%; }
  .account .ant-input-group-addon {
    background-color: #f5f5f5;
    border: 1px solid black !important;
    border-right: none !important; }
  .account .layout-inner {
    display: flex;
    justify-content: center; }
    .account .layout-inner .content {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .account .layout-inner .content .content-right {
        margin-left: 10px; }
      .account .layout-inner .content .container {
        min-width: 57.47vw;
        min-height: 415px;
        border-radius: 4px;
        background-color: var(--dark-blue);
        display: flex; }
        .account .layout-inner .content .container .bets,
        .account .layout-inner .content .container .voucher {
          display: flex;
          justify-content: center;
          width: 100%; }
          .account .layout-inner .content .container .bets .match-text,
          .account .layout-inner .content .container .voucher .match-text {
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 120px;
            overflow: hidden; }
            .account .layout-inner .content .container .bets .match-text span,
            .account .layout-inner .content .container .voucher .match-text span {
              color: black !important; }
          .account .layout-inner .content .container .bets .ant-table-fixed-right,
          .account .layout-inner .content .container .voucher .ant-table-fixed-right {
            z-index: 1; }
          .account .layout-inner .content .container .bets .message-alert,
          .account .layout-inner .content .container .voucher .message-alert {
            margin: 30px 10px; }
            .account .layout-inner .content .container .bets .message-alert span,
            .account .layout-inner .content .container .voucher .message-alert span {
              color: var(--black-to-white) !important; }
          .account .layout-inner .content .container .bets .table .ant-table-tbody td,
          .account .layout-inner .content .container .voucher .table .ant-table-tbody td {
            color: black;
            text-align: center; }
          .account .layout-inner .content .container .bets .ant-table-expanded-row > td,
          .account .layout-inner .content .container .voucher .ant-table-expanded-row > td {
            color: var(--white) !important;
            padding-top: 0px !important; }
          .account .layout-inner .content .container .bets .ant-table-thead > tr > th,
          .account .layout-inner .content .container .voucher .ant-table-thead > tr > th {
            font-weight: 400 !important;
            background-color: var(--dark-blue);
            opacity: 1 !important;
            border-bottom: 2px solid #616A7C !important;
            text-align: center;
            font-size: 14px; }
            .account .layout-inner .content .container .bets .ant-table-thead > tr > th div,
            .account .layout-inner .content .container .voucher .ant-table-thead > tr > th div {
              color: black; }
          .account .layout-inner .content .container .bets .ant-table-tbody > tr > td,
          .account .layout-inner .content .container .voucher .ant-table-tbody > tr > td {
            background-color: var(--dark-blue) !important;
            color: var(--white) !important; }
          .account .layout-inner .content .container .bets .ant-pagination .ant-pagination-prev, .account .layout-inner .content .container .bets .ant-pagination .ant-pagination-next, .account .layout-inner .content .container .bets .ant-pagination .ant-pagination-jump-prev, .account .layout-inner .content .container .bets .ant-pagination .ant-pagination-jump-next,
          .account .layout-inner .content .container .voucher .ant-pagination .ant-pagination-prev,
          .account .layout-inner .content .container .voucher .ant-pagination .ant-pagination-next,
          .account .layout-inner .content .container .voucher .ant-pagination .ant-pagination-jump-prev,
          .account .layout-inner .content .container .voucher .ant-pagination .ant-pagination-jump-next {
            min-width: 62px !important; }
          .account .layout-inner .content .container .bets .ant-pagination li,
          .account .layout-inner .content .container .voucher .ant-pagination li {
            border: solid 0px;
            height: 40px;
            line-height: 40px;
            color: var(--black-to-white) !important;
            background-color: transparent;
            padding: 0 10px 0 10px;
            border: 1px solid #7A8291; }
            .account .layout-inner .content .container .bets .ant-pagination li span, .account .layout-inner .content .container .bets .ant-pagination li a,
            .account .layout-inner .content .container .voucher .ant-pagination li span,
            .account .layout-inner .content .container .voucher .ant-pagination li a {
              font-size: 14px;
              font-weight: bold;
              opacity: 1;
              background-color: transparent !important;
              border: unset !important; }
            .account .layout-inner .content .container .bets .ant-pagination li a,
            .account .layout-inner .content .container .voucher .ant-pagination li a {
              -webkit-filter: var(--convert-brightness);
                      filter: var(--convert-brightness); }
          .account .layout-inner .content .container .bets .form-container,
          .account .layout-inner .content .container .voucher .form-container {
            min-width: 750px; }
            .account .layout-inner .content .container .bets .form-container .filters-container,
            .account .layout-inner .content .container .voucher .form-container .filters-container {
              display: flex;
              align-items: center; }
            .account .layout-inner .content .container .bets .form-container .title,
            .account .layout-inner .content .container .voucher .form-container .title {
              color: black;
              max-width: 50px;
              font-size: 13px;
              margin: 0px; }
            .account .layout-inner .content .container .bets .form-container .range-picker,
            .account .layout-inner .content .container .voucher .form-container .range-picker {
              margin-left: 10px;
              margin-right: 20px; }
            .account .layout-inner .content .container .bets .form-container .select,
            .account .layout-inner .content .container .voucher .form-container .select {
              margin-left: 10px;
              margin-right: 20px; }
              .account .layout-inner .content .container .bets .form-container .select div, .account .layout-inner .content .container .bets .form-container .select span,
              .account .layout-inner .content .container .voucher .form-container .select div,
              .account .layout-inner .content .container .voucher .form-container .select span {
                color: black !important; }
            .account .layout-inner .content .container .bets .form-container .select-dark,
            .account .layout-inner .content .container .voucher .form-container .select-dark {
              width: 170px; }
        .account .layout-inner .content .container .mtn-container {
          max-width: 400px !important; }
        .account .layout-inner .content .container .deposit-main {
          margin-top: 7vh;
          margin-left: 1.5vw; }
          .account .layout-inner .content .container .deposit-main .deposit-title {
            font-size: 18px;
            color: var(--white);
            font-weight: 300; }
          .account .layout-inner .content .container .deposit-main .deposit-input {
            border: 1px solid black; }
          .account .layout-inner .content .container .deposit-main .menu {
            display: flex;
            flex-flow: wrap; }
          .account .layout-inner .content .container .deposit-main .menu > a:last-of-type {
            margin-left: 15px; }
          .account .layout-inner .content .container .deposit-main .deposit-icon {
            margin: 20px 0% 0 0;
            height: 140px;
            width: 145px;
            border-radius: 5px; }
        .account .layout-inner .content .container .deposit, .account .layout-inner .content .container .deposit-mtn,
        .account .layout-inner .content .container .withdrawals {
          margin: auto;
          width: 500px;
          padding: 30px 0; }
          .account .layout-inner .content .container .deposit .ant-input-number-input, .account .layout-inner .content .container .deposit-mtn .ant-input-number-input,
          .account .layout-inner .content .container .withdrawals .ant-input-number-input {
            height: 38px; }
          .account .layout-inner .content .container .deposit .ant-select-selection__rendered, .account .layout-inner .content .container .deposit-mtn .ant-select-selection__rendered,
          .account .layout-inner .content .container .withdrawals .ant-select-selection__rendered {
            line-height: 38px; }
          .account .layout-inner .content .container .deposit .withdraw-button-container, .account .layout-inner .content .container .deposit-mtn .withdraw-button-container,
          .account .layout-inner .content .container .withdrawals .withdraw-button-container {
            width: 70% !important;
            margin: auto !important; }
          .account .layout-inner .content .container .deposit .ant-btn, .account .layout-inner .content .container .deposit-mtn .ant-btn,
          .account .layout-inner .content .container .withdrawals .ant-btn {
            background-color: #2C8AF7 !important; }
          .account .layout-inner .content .container .deposit .ant-input, .account .layout-inner .content .container .deposit-mtn .ant-input,
          .account .layout-inner .content .container .withdrawals .ant-input {
            background-color: var(--form-input-background-color);
            border: none;
            color: var(--white); }
          .account .layout-inner .content .container .deposit .title, .account .layout-inner .content .container .deposit-mtn .title,
          .account .layout-inner .content .container .withdrawals .title {
            color: var(--white);
            text-align: center;
            font-weight: 500; }
          .account .layout-inner .content .container .deposit .form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value, .account .layout-inner .content .container .deposit-mtn .form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value,
          .account .layout-inner .content .container .withdrawals .form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
            color: var(--white); }
          .account .layout-inner .content .container .deposit .ant-select-selection, .account .layout-inner .content .container .deposit-mtn .ant-select-selection,
          .account .layout-inner .content .container .withdrawals .ant-select-selection {
            background-color: var(--form-input-background-color);
            border: none; }
          .account .layout-inner .content .container .deposit .form .ant-input-disabled, .account .layout-inner .content .container .deposit-mtn .form .ant-input-disabled,
          .account .layout-inner .content .container .withdrawals .form .ant-input-disabled {
            background-color: var(--form-input-background-color) !important; }
          .account .layout-inner .content .container .deposit .form .ant-input, .account .layout-inner .content .container .deposit-mtn .form .ant-input,
          .account .layout-inner .content .container .withdrawals .form .ant-input {
            color: var(--white);
            background-color: var(--form-input-background-color);
            border: none !important; }
          .account .layout-inner .content .container .deposit .ant-form-item-required::before, .account .layout-inner .content .container .deposit-mtn .ant-form-item-required::before,
          .account .layout-inner .content .container .withdrawals .ant-form-item-required::before {
            display: none; }
          .account .layout-inner .content .container .deposit .text, .account .layout-inner .content .container .deposit-mtn .text,
          .account .layout-inner .content .container .withdrawals .text {
            text-align: center;
            font-size: 16px; }
          .account .layout-inner .content .container .deposit .ant-input-number-input, .account .layout-inner .content .container .deposit-mtn .ant-input-number-input,
          .account .layout-inner .content .container .withdrawals .ant-input-number-input {
            color: var(--white); }
          .account .layout-inner .content .container .deposit .ant-input-number, .account .layout-inner .content .container .deposit-mtn .ant-input-number,
          .account .layout-inner .content .container .withdrawals .ant-input-number {
            background-color: var(--form-input-background-color);
            border: none; }
          .account .layout-inner .content .container .deposit .ant-input-group > [class*='col-'], .account .layout-inner .content .container .deposit-mtn .ant-input-group > [class*='col-'],
          .account .layout-inner .content .container .withdrawals .ant-input-group > [class*='col-'] {
            width: 25%; }
            .account .layout-inner .content .container .deposit .ant-input-group > [class*='col-'] input, .account .layout-inner .content .container .deposit-mtn .ant-input-group > [class*='col-'] input,
            .account .layout-inner .content .container .withdrawals .ant-input-group > [class*='col-'] input {
              text-align: center; }
          .account .layout-inner .content .container .deposit .balance, .account .layout-inner .content .container .deposit-mtn .balance,
          .account .layout-inner .content .container .withdrawals .balance {
            font-size: 14px;
            margin-bottom: 60px;
            text-align: center; }
            .account .layout-inner .content .container .deposit .balance span, .account .layout-inner .content .container .deposit-mtn .balance span,
            .account .layout-inner .content .container .withdrawals .balance span {
              font-weight: bold; }
          .account .layout-inner .content .container .deposit .withdraw-confirmation-statement, .account .layout-inner .content .container .deposit-mtn .withdraw-confirmation-statement,
          .account .layout-inner .content .container .withdrawals .withdraw-confirmation-statement {
            text-align: center;
            padding-bottom: 15px; }
            .account .layout-inner .content .container .deposit .withdraw-confirmation-statement span, .account .layout-inner .content .container .deposit-mtn .withdraw-confirmation-statement span,
            .account .layout-inner .content .container .withdrawals .withdraw-confirmation-statement span {
              color: #26A405 !important;
              font-size: 17px; }
          .account .layout-inner .content .container .deposit .withdraw-another-amount-button, .account .layout-inner .content .container .deposit-mtn .withdraw-another-amount-button,
          .account .layout-inner .content .container .withdrawals .withdraw-another-amount-button {
            margin-top: 60px; }
          .account .layout-inner .content .container .deposit .qr-code, .account .layout-inner .content .container .deposit-mtn .qr-code,
          .account .layout-inner .content .container .withdrawals .qr-code {
            justify-content: center !important; }
          .account .layout-inner .content .container .deposit .voucher, .account .layout-inner .content .container .deposit-mtn .voucher,
          .account .layout-inner .content .container .withdrawals .voucher {
            font-size: 17px;
            margin-top: 10px;
            display: flex;
            justify-content: center; }
            .account .layout-inner .content .container .deposit .voucher .number, .account .layout-inner .content .container .deposit-mtn .voucher .number,
            .account .layout-inner .content .container .withdrawals .voucher .number {
              font-weight: 600; }
        .account .layout-inner .content .container .personal {
          width: 600px; }
          .account .layout-inner .content .container .personal .ant-btn {
            height: 38px; }
        .account .layout-inner .content .container .success-check {
          background: #2C8AF7 !important; }
        .account .layout-inner .content .container .change-password {
          width: 100%;
          display: flex;
          justify-content: center; }
          .account .layout-inner .content .container .change-password .alert {
            background-color: var(--dark-blue) !important; }
            .account .layout-inner .content .container .change-password .alert .message {
              color: var(--white); }
          .account .layout-inner .content .container .change-password .form-container {
            padding: 30px 0 30px 0;
            width: 90%; }
            .account .layout-inner .content .container .change-password .form-container .show-password-icon {
              position: absolute;
              right: 3%;
              top: 10px; }
            .account .layout-inner .content .container .change-password .form-container .ant-input {
              background-color: var(--form-input-background-color) !important;
              color: var(--white) !important;
              border: none;
              padding: 20px;
              font-size: 15px; }
            .account .layout-inner .content .container .change-password .form-container .info {
              background-color: #169ef4 !important; }
            .account .layout-inner .content .container .change-password .form-container .change-password-buttons-container {
              width: 30% !important;
              margin: auto !important; }
            .account .layout-inner .content .container .change-password .form-container .ant-form-item-required::before {
              display: none; }
            .account .layout-inner .content .container .change-password .form-container .ant-form-item-required::after {
              display: inline-block;
              margin-right: 4px;
              color: #f5222d;
              font-size: 14px;
              font-family: SimSun, sans-serif;
              line-height: 1;
              content: '*'; }
            .account .layout-inner .content .container .change-password .form-container .forget-password-labels-containers {
              width: 48%;
              display: inline-block; }
            .account .layout-inner .content .container .change-password .form-container .forget-password-second-section {
              display: flex;
              justify-content: space-between; }
          .account .layout-inner .content .container .change-password .validation-meter {
            width: 300px;
            margin: auto; }
            .account .layout-inner .content .container .change-password .validation-meter .alert {
              margin: 0;
              padding: 0; }
              .account .layout-inner .content .container .change-password .validation-meter .alert .icon .inner {
                display: flex;
                align-items: center; }
              .account .layout-inner .content .container .change-password .validation-meter .alert .success {
                background-color: lightgreen; }
              .account .layout-inner .content .container .change-password .validation-meter .alert svg {
                width: 10px; }
        .account .layout-inner .content .container .buttons {
          margin-top: 30px; }
          .account .layout-inner .content .container .buttons button:last-child {
            margin-top: 20px !important; }
        .account .layout-inner .content .container .alert {
          border-radius: 4px;
          padding: 10px;
          margin-top: 20px;
          background-color: var(--light-black); }
        .account .layout-inner .content .container .table {
          margin-top: 30px;
          padding: 20px; }
          .account .layout-inner .content .container .table .status-type {
            background: lightgray;
            width: 100px; }
        .account .layout-inner .content .container .ant-form-item-label label {
          color: var(--white);
          font-weight: 500; }
        .account .layout-inner .content .container .ant-table-placeholder {
          background-color: transparent;
          border: unset; }

.voucher-modal {
  width: 70% !important;
  height: 60% !important;
  top: 2% !important;
  padding: 0 !important; }
  .voucher-modal .ant-modal-content {
    width: 100% !important;
    height: 100% !important; }
  .voucher-modal .ant-modal-body {
    padding: 20px;
    width: 100%;
    height: 100%; }
    .voucher-modal .ant-modal-body .message {
      color: black !important; }
    .voucher-modal .ant-modal-body .voucher-details {
      text-align: center;
      padding: 20px; }
      .voucher-modal .ant-modal-body .voucher-details .ant-modal-body {
        padding: 20px;
        width: 100%;
        height: 100%; }
      .voucher-modal .ant-modal-body .voucher-details .qr-code {
        padding-top: 20px; }
      .voucher-modal .ant-modal-body .voucher-details .table-wrapper {
        height: 100%; }
      .voucher-modal .ant-modal-body .voucher-details div {
        color: black;
        text-align: center; }
    .voucher-modal .ant-modal-body .voucher-details {
      height: 100%; }
      .voucher-modal .ant-modal-body .voucher-details h3 {
        font-weight: bold !important;
        padding: 10px;
        margin: 0; }
      .voucher-modal .ant-modal-body .voucher-details .details {
        padding-bottom: 25px;
        display: flex;
        flex-wrap: wrap;
        width: 100%; }
        .voucher-modal .ant-modal-body .voucher-details .details div {
          width: 50%;
          display: flex;
          align-items: center;
          align-content: center; }
  .voucher-modal .voucher-container {
    display: flex;
    flex-direction: column;
    padding: 10px 5px;
    text-align: center; }
    .voucher-modal .voucher-container .alert {
      justify-content: center; }
      .voucher-modal .voucher-container .alert .message {
        font-size: 25px; }

.bet-details-modal {
  width: 76% !important;
  height: 80% !important;
  top: 3% !important;
  right: 1%;
  padding: 0 !important; }
  .bet-details-modal .ant-modal-content {
    background-color: var(--dark-blue);
    width: 100% !important;
    height: 100% !important; }
  .bet-details-modal .ant-modal-body {
    padding: 0;
    width: 100%;
    height: 100%; }
    .bet-details-modal .ant-modal-body .ant-table-thead > tr > th, .bet-details-modal .ant-modal-body .ant-table-tbody > tr > td {
      color: var(--white) !important; }
    .bet-details-modal .ant-modal-body .ant-table-tbody > tr > td {
      border-bottom: 2px solid #26334B !important;
      text-align: center; }
    .bet-details-modal .ant-modal-body .ant-table-thead > tr > th {
      border-bottom: 2px solid #26334B !important; }
    .bet-details-modal .ant-modal-body .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: unset !important; }
    .bet-details-modal .ant-modal-body .modal-description-container {
      display: flex;
      justify-content: space-between;
      font-size: 16px; }
      .bet-details-modal .ant-modal-body .modal-description-container .modal-desciption-divs {
        padding: 10px; }
    .bet-details-modal .ant-modal-body .modal-left-description, .bet-details-modal .ant-modal-body .modal-right-description {
      display: inline-block; }
    .bet-details-modal .ant-modal-body .btn-wrapper {
      display: flex;
      width: 100%;
      justify-content: center;
      padding: 20px; }
      .bet-details-modal .ant-modal-body .btn-wrapper .ant-btn {
        margin-right: 20px !important;
        flex: none;
        width: 150px; }
      .bet-details-modal .ant-modal-body .btn-wrapper .btn-cancel {
        background: grey; }
    .bet-details-modal .ant-modal-body .circle {
      width: 32px !important;
      padding: 0 !important;
      font-size: 16px !important;
      border-radius: 50% !important;
      height: 32px !important;
      margin: 0 20px !important; }
    .bet-details-modal .ant-modal-body .kiosk-menu {
      position: absolute;
      padding: 20px; }
      .bet-details-modal .ant-modal-body .kiosk-menu .btn-open-checkin,
      .bet-details-modal .ant-modal-body .kiosk-menu .btn-open-chashin {
        margin-top: 15px !important;
        display: block;
        width: 40px; }
    .bet-details-modal .ant-modal-body .kiosk-info {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      justify-content: center;
      align-items: center;
      padding-bottom: 60px; }
      .bet-details-modal .ant-modal-body .kiosk-info img {
        max-width: 50%;
        max-height: 75%; }
      .bet-details-modal .ant-modal-body .kiosk-info .ant-input-lg {
        font-size: 14px; }
    .bet-details-modal .ant-modal-body .btn-kiosk {
      width: auto;
      flex: unset;
      margin-right: 10px;
      margin-bottom: 10px; }
    .bet-details-modal .ant-modal-body .title-kiosk {
      margin-top: 30px; }
    .bet-details-modal .ant-modal-body .message {
      color: black !important; }
    .bet-details-modal .ant-modal-body .bet-details {
      padding: 20px; }
      .bet-details-modal .ant-modal-body .bet-details .ant-pagination li {
        background-color: #4ba5e6; }
        .bet-details-modal .ant-modal-body .bet-details .ant-pagination li a {
          background-color: #4ba5e6 !important; }
      .bet-details-modal .ant-modal-body .bet-details .ant-pagination .ant-pagination-item-active li {
        background-color: #4ba5e682; }
        .bet-details-modal .ant-modal-body .bet-details .ant-pagination .ant-pagination-item-active li a {
          background-color: #4ba5e682 !important; }
      .bet-details-modal .ant-modal-body .bet-details .table-wrapper {
        height: 100%; }
      .bet-details-modal .ant-modal-body .bet-details div {
        color: black; }
      .bet-details-modal .ant-modal-body .bet-details th {
        font-weight: bold;
        background: #4ba5e6; }
      .bet-details-modal .ant-modal-body .bet-details .ant-table-row {
        background: aliceblue;
        text-align: center; }
    .bet-details-modal .ant-modal-body .bet-details {
      height: 100%; }
      .bet-details-modal .ant-modal-body .bet-details h3 {
        color: var(--white);
        font-weight: 300;
        font-size: 14px;
        padding: 10px;
        margin: 0; }
      .bet-details-modal .ant-modal-body .bet-details .ant-table-thead > tr > th,
      .bet-details-modal .ant-modal-body .bet-details .ant-table-tbody > tr > td {
        padding: 5px;
        word-break: break-all; }
      .bet-details-modal .ant-modal-body .bet-details .details {
        display: flex;
        flex-wrap: wrap;
        width: 100%; }
        .bet-details-modal .ant-modal-body .bet-details .details div {
          color: var(--white);
          width: 50%;
          display: flex;
          align-items: center;
          align-content: center; }
  .bet-details-modal .bet-details-container {
    display: flex;
    flex-direction: column;
    padding: 50px 20px;
    background-color: var(--dark-blue); }
    .bet-details-modal .bet-details-container #modal-details-table-mobile-view {
      display: none; }
    .bet-details-modal .bet-details-container .ant-table-thead > tr > th {
      background-color: transparent;
      text-align: center; }
    .bet-details-modal .bet-details-container h1 {
      color: var(--white);
      text-transform: uppercase;
      font-size: 18px;
      text-align: center;
      padding: 10px 0; }
    .bet-details-modal .bet-details-container .alert {
      justify-content: center; }
      .bet-details-modal .bet-details-container .alert .message {
        font-size: 25px; }
    .bet-details-modal .bet-details-container .status {
      font-size: 20px;
      display: flex;
      flex-direction: column;
      text-align: center;
      padding: 10px; }
    .bet-details-modal .bet-details-container .details-table {
      padding: 10px 0; }
      .bet-details-modal .bet-details-container .details-table .ant-pagination li {
        background-color: transparent;
        border: 1px solid var(--white); }
        .bet-details-modal .bet-details-container .details-table .ant-pagination li a {
          opacity: 1;
          background-color: transparent;
          height: 0px; }
      .bet-details-modal .bet-details-container .details-table .ant-table table {
        font-size: 13px; }
    .bet-details-modal .bet-details-container .form-title {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      color: black;
      text-align: center; }
    .bet-details-modal .bet-details-container .form-details {
      margin-bottom: 1rem; }
    .bet-details-modal .bet-details-container .form-message {
      margin-bottom: 1.5rem;
      margin-top: 0.5rem; }
    .bet-details-modal .bet-details-container .table-winning-row {
      background-color: lightgreen;
      color: black; }
    .bet-details-modal .bet-details-container .table-losing-row {
      background-color: lightcoral;
      color: black; }
    .bet-details-modal .bet-details-container .table-row-refund {
      color: red; }
    .bet-details-modal .bet-details-container .table-no-result-row {
      color: black; }
    .bet-details-modal .bet-details-container .winnings-form-text-value {
      color: #169ef4; }
    .bet-details-modal .bet-details-container .winnings-form-text {
      color: black; }
  .bet-details-modal .column-header div span {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500; }

.add-second-phone {
  display: flex;
  flex-direction: column;
  text-align: center; }
  .add-second-phone * {
    color: black; }
  .add-second-phone .ant-btn {
    width: 375px; }

@media only screen and (max-width: 1100px) {
  .bet-details-modal .bet-details-container .status {
    font-size: 13px;
    padding: 15px; }
  .bet-details-modal .ant-modal-body {
    padding: 0; }
    .bet-details-modal .ant-modal-body .ant-table-tbody > tr > td {
      border-bottom: none !important;
      text-align: start; }
    .bet-details-modal .ant-modal-body .mobile-modal-row-details {
      padding: 10px 0;
      border: none;
      background-image: linear-gradient(to right, #202D46 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 30px 3px;
      background-repeat: repeat-x; }
    .bet-details-modal .ant-modal-body .bet-details {
      padding: 0; }
    .bet-details-modal .ant-modal-body .bet-details-container #modal-details-table-desktop-view {
      display: none; }
    .bet-details-modal .ant-modal-body .bet-details-container #modal-details-table-mobile-view {
      display: block; }
      .bet-details-modal .ant-modal-body .bet-details-container #modal-details-table-mobile-view thead {
        display: none; }
    .bet-details-modal .ant-modal-body .bet-details-container .modal-left-description, .bet-details-modal .ant-modal-body .bet-details-container .modal-right-description {
      display: block; }
      .bet-details-modal .ant-modal-body .bet-details-container .modal-left-description div span, .bet-details-modal .ant-modal-body .bet-details-container .modal-right-description div span {
        font-size: 13px; }
    .bet-details-modal .ant-modal-body .bet-details-container .ant-table-pagination.ant-pagination {
      float: none;
      display: flex;
      justify-content: center; }
    .bet-details-modal .ant-modal-body .modal-description-container {
      display: block; }
      .bet-details-modal .ant-modal-body .modal-description-container .modal-desciption-divs {
        padding: 0; } }

@media only screen and (max-width: 1000px) {
  #my-account-body-container {
    padding: 0 6vw 0 6vw !important; }
  .account .ant-table-pagination.ant-pagination {
    float: none !important;
    display: flex !important;
    justify-content: center !important; }
  .account .bets .ant-tabs-nav-scroll .ant-tabs-nav .ant-tabs-tab {
    width: 38vw; } }

@media only screen and (max-width: 1000px) {
  .account #my-account-headline-menu #my-account-left-menu-container {
    height: 90px;
    overflow: hidden; }
    .account #my-account-headline-menu #my-account-left-menu-container .ant-menu {
      font-weight: 400 !important;
      height: 90px;
      width: 86vw;
      overflow: scroll;
      -webkit-overflow-scrolling: touch;
      white-space: nowrap;
      overflow-y: hidden;
      scroll-behavior: smooth; } }

@media only screen and (max-width: 900px) {
  .account .layout-inner .content .container .bets .table .ant-table-tbody td {
    text-align: start; }
  .account .layout-inner .content .container .deposit-mtn, .account .layout-inner .content .container .withdrawals, .account .layout-inner .content .container .deposit {
    width: 73vw; }
  .account .layout-inner .content .container .table {
    width: 90vw;
    padding: 0; }
    .account .layout-inner .content .container .table .mobile-table-row-data {
      margin-left: 8vw; }
  .account #my-account-mobile-table {
    display: block; }
    .account #my-account-mobile-table .ant-table-tbody > tr > td {
      border: none;
      background-image: linear-gradient(to right, #202D46 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 32px 3px;
      background-repeat: repeat-x; }
  .account #my-account-desktop-table {
    display: none; } }

@media only screen and (max-width: 500px) {
  .account .layout-inner .content .container .table {
    margin-top: 0; } }

@media only screen and (max-width: 480px) {
  .account .layout-inner .content .container .change-password .validation-meter {
    width: auto; }
  .account .layout-inner .content .container .change-password .form-container .change-password-buttons-container {
    width: 75% !important; }
  .account .layout-inner .content .container .change-password .form-container .forget-password-labels-containers {
    width: 100%;
    display: block; }
  .account .layout-inner .content .container .change-password .form-container .forget-password-second-section {
    display: block; }
  .account .layout-inner .content .container {
    min-height: 43vh; }
    .account .layout-inner .content .container .mtn-container {
      padding: 15px; }
      .account .layout-inner .content .container .mtn-container .ant-btn {
        font-size: 12px;
        font-weight: bold; }
    .account .layout-inner .content .container .deposit-mtn .title {
      font-size: 16px; }
    .account .layout-inner .content .container .withdrawals .withdraw-confirmation-statement {
      text-align: start; }
      .account .layout-inner .content .container .withdrawals .withdraw-confirmation-statement span {
        font-size: 15px; }
    .account .layout-inner .content .container .withdrawals .voucher span {
      font-size: 12px; }
    .account .layout-inner .content .container .withdrawals .voucher .number {
      font-size: 12px; }
    .account .layout-inner .content .container .withdrawals .ant-btn {
      font-size: 12px;
      font-weight: bold; }
    .account .layout-inner .content .container .withdrawals .balance {
      font-size: 12px; } }

@media only screen and (max-width: 380px) {
  .account .layout-inner .content .container .withdrawals .withdraw-confirmation-statement {
    text-align: start; }
    .account .layout-inner .content .container .withdrawals .withdraw-confirmation-statement span {
      font-size: 12px; }
  .account .layout-inner .content .container .approve-for-withdraw-container .ant-btn {
    font-size: 10px; } }

@media only screen and (max-width: 300px) {
  .account .layout-inner .content .container .approve-for-withdraw-container .ant-btn {
    font-size: 8px; } }

.today-bets .header .sort .title {
  cursor: pointer; }
  .today-bets .header .sort .title i svg {
    height: 13px; }

.today-bets .header .sort .sort-by {
  display: flex;
  align-items: center; }
  .today-bets .header .sort .sort-by .text {
    opacity: 0.5;
    font-size: 14px;
    font-weight: bold;
    line-height: normal;
    padding-bottom: 4px; }
  .today-bets .header .sort .sort-by .selectlist {
    width: 100px;
    font-size: 14px;
    font-weight: bold; }
    .today-bets .header .sort .sort-by .selectlist .ant-select-selection {
      opacity: unset;
      text-transform: unset; }

/*********************** Ant Layout ************************/
.ant-layout {
  background-color: transparent !important; }
  .ant-layout .ant-layout-sider .ant-layout-sider-children {
    width: 100%; }

/*********************** Carousel *****************************/
.ant-carousel {
  padding: 15px 10px; }

/*********************** Button Styles ************************/
.ant-btn {
  margin: unset !important;
  flex: 1 1;
  width: 100%;
  border: none;
  border-radius: 50px;
  height: 50px;
  font-size: 16px; }

/*********************** Ant Table ************************/
.table .ant-table-content {
  width: 100%; }

.table .ant-table-fixed {
  background: var(--white); }

.table .ant-table-expanded-row {
  min-height: 116px; }

.table .ant-table-row {
  height: 53px !important; }

.table .ant-table-header {
  background: var(--white); }

.table .ant-table-thead tr > th {
  opacity: 0.5;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  background-color: transparent;
  border-bottom: 2px solid var(--light-black); }

.table .ant-table-tbody {
  background: var(--white); }
  .table .ant-table-tbody tr > td {
    border-bottom: 2px solid #182e49; }
  .table .ant-table-tbody .ant-table-row-expand-icon {
    background-color: transparent;
    color: var(--black-to-white) !important; }
  .table .ant-table-tbody tr.ant-table-expanded-row {
    background: var(--white); }

.table .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.table .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.table .ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background-color: transparent;
  background-color: initial; }

/*********************** Ant Pagination ************************/
.ant-pagination li {
  border: solid 0px;
  height: 40px;
  line-height: 40px;
  background-color: #172a40;
  padding: 0 10px 0 10px; }
  .ant-pagination li a {
    font-size: 14px;
    font-weight: bold;
    opacity: 0.5;
    color: var(--white) !important;
    background-color: #172a40 !important;
    border: unset !important; }

/*********************** Ant Range Picker ************************/
.range-picker * {
  color: var(--white); }

/*********************** Ant Select ************************/
.select-dark * {
  color: var(--white); }

/*********************** Ant Dropdown ************************/
.ant-dropdown.dark * {
  color: var(--white); }

/*********************** Back to top Styles ************************/
#components-back-top-demo-custom .ant-back-top {
  bottom: 100px; }

.back-top {
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 4px;
  background-color: #044da1;
  color: var(--white);
  text-align: center;
  font-size: 20px; }

/*********************** Tooltip Styles ************************/
.ant-tooltip.error {
  background-color: unset; }
  .ant-tooltip.error .ant-tooltip-inner {
    background-color: red; }
  .ant-tooltip.error .ant-tooltip-arrow {
    border-top-color: red;
    border-bottom-color: red; }

.ant-tooltip.error.ant-tooltip-placement-bottomRight {
  margin-top: 22px; }

/*********************** Drawer Mobile ************************/
.ant-drawer.mobile {
  background-color: none !important; }
  .ant-drawer.mobile .ant-drawer-mask {
    opacity: 0; }
  .ant-drawer.mobile .menu-banner, .ant-drawer.mobile .ant-drawer-body, .ant-drawer.mobile .sub-menu, .ant-drawer.mobile .ant-menu-submenu-title {
    background-color: var(--dark-blue-to-white) !important; }
  .ant-drawer.mobile .menu-banner {
    text-transform: uppercase;
    margin: 0 !important;
    color: #7E879B;
    padding-left: 10px;
    font-size: 18px;
    font-weight: 500; }
  .ant-drawer.mobile .ant-drawer-content-wrapper {
    width: 75vw !important;
    background-color: var(--dark-blue-to-white) !important;
    max-width: 75vw !important; }
    .ant-drawer.mobile .ant-drawer-content-wrapper .ant-drawer-content {
      background-color: var(--light-black); }
      .ant-drawer.mobile .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body {
        background-color: var(--dark-blue-to-white) !important; }
        .ant-drawer.mobile .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body {
          padding: unset; }
          .ant-drawer.mobile .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .ant-menu {
            margin: unset; }

.ant-drawer.mobile.mobile-bet-slip .ant-drawer-content-wrapper .ant-drawer-content .ant-drawer-wrapper-body .ant-drawer-body .ant-menu {
  margin: unset; }

/*********************** Collaps Mobile ************************/
.ant-collapse.collaps {
  background: unset;
  border: unset; }
  .ant-collapse.collaps .ant-collapse-item {
    border: unset;
    margin-bottom: 1px; }
    .ant-collapse.collaps .ant-collapse-item i {
      left: 10px !important; }
    .ant-collapse.collaps .ant-collapse-item .ant-collapse-header {
      padding-left: 30px;
      display: flex;
      align-items: center;
      color: var(--white);
      font-size: 12px;
      font-weight: bold;
      background-color: #182e49;
      height: 30px;
      line-height: unset; }
    .ant-collapse.collaps .ant-collapse-item .ant-collapse-content {
      background: unset;
      border: unset; }
      .ant-collapse.collaps .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
        padding: 0px; }
        .ant-collapse.collaps .ant-collapse-item .ant-collapse-content .ant-collapse-content-box .row:last-child {
          border-bottom: unset; }

@media only screen and (max-width: 1024px) {
  /*********************** Popover Mobile ************************/
  .ant-popover {
    margin-right: 10px; }
    .ant-popover .ant-popover-title {
      font-size: 16px;
      font-weight: bold;
      color: #0e2842; }
    .ant-popover .ant-form-item-with-help {
      margin-bottom: unset; }
    .ant-popover .ant-popover-inner-content {
      padding: 20px; }
      .ant-popover .ant-popover-inner-content .login {
        max-width: 260px; }
        .ant-popover .ant-popover-inner-content .login .search-box {
          display: none; }
        .ant-popover .ant-popover-inner-content .login .ant-row {
          width: 100%; }
          .ant-popover .ant-popover-inner-content .login .ant-row div {
            font-size: 14px;
            font-weight: bold;
            color: #0e2842;
            line-height: unset;
            margin-bottom: 5px; }
          .ant-popover .ant-popover-inner-content .login .ant-row .ant-form-item-control-wrapper {
            width: 100%; }
            .ant-popover .ant-popover-inner-content .login .ant-row .ant-form-item-control-wrapper input {
              border: solid 1px #a0acb9;
              background-color: unset; }
            .ant-popover .ant-popover-inner-content .login .ant-row .ant-form-item-control-wrapper .ant-form-explain {
              display: none; } }

.tabs {
  margin-top: 17px;
  width: 100%; }

.ant-tabs-ink-bar {
  height: 3px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  bottom: 0px; }

.blueBorder .ant-tabs-ink-bar {
  background-color: #2c8af7; }

.orangeBorder .ant-tabs-ink-bar {
  background-color: #f4a932; }

.greenBorder .ant-tabs-ink-bar {
  background-color: #2ea867; }

.redBorder .ant-tabs-ink-bar {
  background-color: #d95458; }

.greyBlueBorder .ant-tabs-ink-bar {
  background-color: #5b88bd; }

.purpleBorder .ant-tabs-ink-bar {
  background-color: #9457cf; }

.ant-tabs-bar {
  border-bottom: 4px solid #333e4d !important;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px; }

.ant-tabs-nav-scroll {
  display: flex; }

.tab {
  height: 35px; }

.ant-menu,
.ant-menu-submenu-title {
  background-color: transparent !important; }

.ant-menu-submenu-title {
  font-size: 16px;
  font-family: "Poppins";
  opacity: 1 !important;
  font-weight: lighter;
  transition: all 200ms;
  background-color: transparent !important;
  border: 1px solid transparent;
  border-radius: 5px; }

.ant-menu-submenu-title:hover {
  color: var(--white) !important;
  background-color: var(--light-black) !important;
  cursor: pointer; }

.sport {
  /*********************** VFL ************************/
  /*********************** VFLM ************************/
  /*********************** VBL ************************/
  /*********************** VDR ************************/
  /*********************** VHR ************************/
  /*********************** EPL ************************/ }
  .sport .virtual-video {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .sport .virtual-video .frame-video {
      overflow-x: hidden;
      width: 100%; }
    .sport .virtual-video .skeleton {
      height: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .sport .virtual-video .skeleton .loading .ant-spin-dot {
        font-size: 40px !important;
        width: 40px;
        height: 40px; }
      .sport .virtual-video .skeleton .loading .text {
        font-size: 25px; }
  .sport .virtual-11[min-width~="752px"] .frame-video {
    max-width: 760px;
    height: 520px; }
  .sport .virtual-11[max-width~="752px"] .frame-video {
    display: none !important; }
  .sport .virtual-11-mobile[min-width~="685px"] .frame-video {
    max-width: 685px;
    height: 410px; }
  .sport .virtual-11-mobile[max-width~="685px"] .frame-video {
    display: none !important; }
  .sport .virtual-71[min-width~="1140px"] .frame-video {
    max-width: 1140px;
    height: 610px; }
  .sport .virtual-71[max-width~="1140px"] .frame-video {
    max-width: 604px;
    height: 575px; }
  .sport .virtual-71-mobile[min-width~="320px"] .frame-video {
    max-width: 540px;
    height: 520px; }
  .sport .virtual-71-mobile[max-width~="320px"] .frame-video {
    display: none !important; }
  .sport .virtual-82[min-width~="765px"] .frame-video {
    max-width: 765px;
    height: 670px; }
  .sport .virtual-82[max-width~="762px"] .frame-video {
    display: none !important; }
  .sport .virtual-82-mobile[min-width~="688px"] .frame-video {
    max-width: 688px;
    height: 604px; }
  .sport .virtual-82-mobile[max-width~="688px"] .frame-video {
    display: none !important; }
  .sport .virtual-33[min-width~="762px"] .frame-video {
    max-width: 762px;
    height: 356px; }
  .sport .virtual-33[max-width~="762px"] .frame-video {
    display: none !important; }
  .sport .virtual-33-mobile[min-width~="688px"] .frame-video {
    max-width: 688px;
    height: 325px; }
  .sport .virtual-33-mobile[max-width~="688px"] .frame-video {
    display: none !important; }
  .sport .virtual-22[min-width~="762px"] .frame-video {
    max-width: 762px;
    height: 356px; }
  .sport .virtual-22[max-width~="762px"] .frame-video {
    display: none !important; }
  .sport .virtual-22-mobile[min-width~="688px"] .frame-video {
    max-width: 688px;
    height: 325px; }
  .sport .virtual-22-mobile[max-width~="688px"] .frame-video {
    display: none !important; }
  .sport .virtual-91[min-width~="1140px"] .frame-video {
    max-width: 1140px;
    height: 610px; }
  .sport .virtual-91[max-width~="1140px"] .frame-video {
    max-width: 604px;
    height: 575px; }
  .sport .virtual-91-mobile[min-width~="320px"] .frame-video {
    max-width: 540px;
    height: 520px; }
  .sport .virtual-91-mobile[max-width~="320px"] .frame-video {
    display: none !important; }

@media only screen and (max-width: 500px) {
  .sport .virtual-video .frame-video {
    height: 105vw !important; } }

.ant-input-affix-wrapper .ant-input:not(:last-child) {
  padding-right: 7rem; }

.search-box .ant-input {
  background-color: var(--dark-blue);
  color: var(--white);
  font-size: 12px;
  padding-left: 40px;
  border: none;
  border-radius: 20px;
  height: 30px !important; }

@media only screen and (min-width: 600px) {
  .search-box .ant-input {
    padding-left: 20px !important; }
  .search-box .ant-input-affix-wrapper .ant-input-suffix {
    position: absolute;
    left: 90% !important; } }

.search-box .ant-input-suffix svg {
  width: 19px;
  height: 19.7px;
  opacity: 0.5;
  color: var(--white-to-gray); }

.search-box .ant-input-affix-wrapper .ant-input-suffix {
  right: 0;
  left: 10px;
  width: 20px; }

.search-box .ant-spin-nested-loading div .ant-spin-spinning .ant-spin-dot {
  left: 80%; }

.search-popover .ant-popover-title {
  font-weight: bold;
  height: 43px;
  align-items: center;
  display: flex; }

.search-popover .ant-popover-inner-content {
  padding: 12px; }

.search-list {
  max-height: 600px;
  height: auto;
  width: 230px;
  cursor: pointer;
  overflow-y: scroll;
  line-height: normal; }
  .search-list h3 {
    font-weight: bold;
    line-height: 1; }

.search-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #F5F5F5; }

.search-list::-webkit-scrollbar-thumb {
  background-color: var(--blue); }

.search-list::-webkit-scrollbar {
  width: 6px;
  background-color: #F5F5F5; }

.search-box-top-menu .ant-input {
  background-color: transparent;
  border: none;
  color: var(--white);
  font-size: 14px;
  font-weight: bold;
  width: 20px; }

.search-box-top-menu .ant-input-suffix svg {
  width: 19px;
  height: 19.7px;
  opacity: 0.5;
  color: var(--white); }

.search-popover-hidden {
  position: absolute;
  z-index: -1; }

/***************** Secondary Search bar ***************/
.sec-search-bar {
  background-color: var(--light-blue-to-off-white);
  border: 5px solid transparent;
  border-radius: 5px;
  width: 100%;
  margin: 0.6rem 0;
  padding: 0.25rem 1.5rem 0.25rem 0.2rem; }

.sec-serach-continer {
  position: relative; }

.sec-search-icon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%); }

.slot-machines {
  /*-----------------  events styles -----------------*/ }
  .slot-machines .scroll-slot-categories span {
    color: var(--white) !important; }
  .slot-machines .back {
    font-weight: bold;
    margin: 0 0 10px 0 !important; }
  .slot-machines .items {
    border-radius: 4px;
    padding-bottom: 10px; }
    .slot-machines .items .gutter-example .ant-row > div {
      background: transparent;
      border: 0; }
    .slot-machines .items .gutter-box {
      margin: 20px 0;
      cursor: pointer; }
    .slot-machines .items .gutter-box:hover {
      opacity: 0.9;
      filter: alpha(opacity=90);
      /* For IE8 and earlier */
      box-shadow: 10px 10px 5pxvar --black-to-white; }
    .slot-machines .items .slot-image {
      max-width: 100%;
      max-height: 100%; }
  .slot-machines .slots-game {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .slot-machines .slots-game .game-symbols {
      display: flex;
      width: 100%;
      justify-content: space-between; }
      .slot-machines .slots-game .game-symbols .symbol {
        padding: 20px 20px 0 0;
        width: 100%; }
        .slot-machines .slots-game .game-symbols .symbol img {
          width: 100%;
          max-width: 170px;
          max-height: 170px; }
        .slot-machines .slots-game .game-symbols .symbol .symbol-text {
          text-align: left;
          font-size: 16px;
          font-weight: bold; }
          .slot-machines .slots-game .game-symbols .symbol .symbol-text p {
            margin: 0 0px 1px 25% !important; }
            .slot-machines .slots-game .game-symbols .symbol .symbol-text p span:first-child {
              color: gold !important; }
      .slot-machines .slots-game .game-symbols .symbol:last-child {
        padding: 20px 0 0 0; }
    .slot-machines .slots-game .frame-video {
      width: 100%; }
    .slot-machines .slots-game .skeleton {
      height: 300px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center; }
      .slot-machines .slots-game .skeleton .loading .ant-spin-dot {
        font-size: 40px !important;
        width: 40px;
        height: 40px; }
      .slot-machines .slots-game .skeleton .loading .text {
        font-size: 25px; }
  .slot-machines .slots-video .frame-video {
    height: 60vh; }

.modal-not-finish-text {
  font-size: 30px; }

#bits-filter-button-container {
  display: flex;
  flex-direction: row;
  align-self: center;
  min-width: 12.9vw;
  padding-right: 1.5vw; }

.bets-filter-buttons {
  background-color: var(--blue-to-gray);
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  width: 5em;
  font-weight: 600;
  border: transparent;
  margin-left: 10px; }

.bets-filter-buttons:active {
  background-color: #2C8AF7;
  color: var(--white); }

.bets-filter-container {
  display: flex;
  flex-wrap: wrap; }

@media only screen and (max-width: 1790px) {
  .bets-filter-container {
    justify-content: center; } }

#bits-filter-rows {
  margin-top: 40px; }

#bet-rows-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 25px 0 25px; }

.event-row-button-reset {
  opacity: 50%;
  border-radius: 0px;
  border-top: solid 2px var(--light-black);
  border-bottom: solid 2px var(--light-black);
  background-color: var(--light-black);
  font-size: 14px; }

.row-of-bet-details-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.event-row-parent-container:last-child #bet-rows-container .events-row-container {
  border: none; }

.bet-info {
  display: flex; }

.buttons-inside-event-row {
  display: flex; }

.bet-rows-icon-container {
  background-color: var(--light-black);
  margin-right: 3px;
  padding: 9px 4px 9px 4px;
  border-radius: 5px;
  font-weight: bolder; }

.events-row-container {
  padding: 20px 0 20px 0;
  border-bottom: solid 2px #182e49; }

.bit-slip-rows {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

#first-bet-slip-row {
  padding-bottom: 15px; }

.bet-slip-buttons {
  flex: 1 1;
  width: 100%;
  border: none;
  border-radius: 50px;
  height: 50px;
  font-size: 13px;
  font-weight: bold;
  margin-top: 10px; }

@media only screen and (max-width: 1900px) {
  /*********************** event rows width 1750px ************************/
  .row-of-bet-details-buttons {
    flex-wrap: wrap;
    flex-direction: row;
    height: 100%; } }

@media only screen and (max-width: 1480px) {
  /*********************** event rows width 1750px ************************/
  .row-of-bet-details-buttons {
    flex-wrap: wrap;
    flex-direction: row;
    height: 100%; } }

#my-bets-container {
  overflow-y: scroll;
  max-height: 60vh;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  position: fixed;
  bottom: 0;
  width: 295px;
  z-index: 3;
  background-color: #044DA1;
  right: 6.5vw; }
  #my-bets-container div, #my-bets-container span {
    color: white; }
  #my-bets-container .my-bets-rows-arrow {
    display: flex;
    justify-content: end;
    position: relative;
    bottom: 30px; }
  #my-bets-container .my-bets-headline {
    display: flex;
    justify-content: space-between;
    background-color: #044DA1;
    padding: 10px; }
  #my-bets-container .fixed-my-bets-headline {
    display: flex;
    position: fixed;
    justify-content: space-between;
    background-color: #044DA1;
    padding: 10px;
    width: 285px;
    z-index: 3; }
  #my-bets-container .list-group-container {
    margin-bottom: 10px;
    margin-top: 50px; }
  #my-bets-container .my-bets-tap-pane {
    width: 50%; }
  #my-bets-container .ant-tabs-bar {
    width: 100%; }
  #my-bets-container .ant-tabs-nav-wrap {
    display: flex;
    justify-content: space-around; }
  #my-bets-container .ant-tabs-nav .ant-tabs-tab {
    margin: 0; }
  #my-bets-container .my-bets-single-row {
    text-align: center;
    background-color: #45577C; }
  #my-bets-container .my-bets-rows-container {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #FAF9F6; }
  #my-bets-container .lower-opacity-container {
    opacity: 0.5; }
  #my-bets-container .active, #my-bets-container .my-bets-rows-container:hover {
    background-color: #45577C; }
  #my-bets-container .my-bets-details-container {
    padding: 10px;
    display: none;
    overflow: hidden; }
  #my-bets-container .my-bets-rows-container h3 {
    overflow: hidden;
    text-align: center;
    color: #f8f8f8; }
  #my-bets-container .my-bets-rows-container h3:before,
  #my-bets-container .my-bets-rows-container h3:after {
    background-color: #346FB3;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%; }
  #my-bets-container .my-bets-rows-container h3:before {
    right: 1em;
    margin-left: -50%; }
  #my-bets-container .my-bets-rows-container h3:after {
    left: 1em;
    margin-right: -50%; }
  #my-bets-container .my-bets-rows-container .my-bets-row-1 {
    display: flex; }
  #my-bets-container .my-bets-rows-container .my-bets-formId-container {
    font-size: 14px;
    width: 10rem;
    align-self: center;
    text-align: center; }
  #my-bets-container .my-bets-header {
    text-transform: uppercase;
    font-size: 16px; }
  #my-bets-container .my-bets-tabs {
    border-top: 1px solid #6C6F71; }
  #my-bets-container a {
    text-decoration: none;
    color: inherit; }
  #my-bets-container nav {
    max-width: 800px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #0c0c0c; }
  #my-bets-container nav a {
    flex-grow: 1;
    text-align: center;
    padding: 1em;
    position: relative; }
  #my-bets-container nav a::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    -webkit-transform: scaleX(0);
            transform: scaleX(0);
    background: #D0DAE6;
    transition: 0.7s -webkit-transform cubic-bezier(0.06, 0.9, 0.28, 1);
    transition: 0.7s transform cubic-bezier(0.06, 0.9, 0.28, 1);
    transition: 0.7s transform cubic-bezier(0.06, 0.9, 0.28, 1), 0.7s -webkit-transform cubic-bezier(0.06, 0.9, 0.28, 1); }
  #my-bets-container nav a:hover::after {
    -webkit-transform: scaleX(1);
            transform: scaleX(1); }
  #my-bets-container .lower-opacity-container {
    opacity: 0.5; }
  #my-bets-container .my-bets-container-header-arrows {
    cursor: pointer; }
  #my-bets-container #my-bets-total-odds-container {
    display: flex;
    justify-content: space-between;
    padding: 25px 10px 25px 10px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    background-color: #233355; }
  #my-bets-container .bolded-element {
    font-weight: bold; }

@media only screen and (max-width: 1280px) {
  #my-bets-container .my-bets-rows-container .my-bets-row-1 {
    display: flex;
    width: auto;
    flex-wrap: wrap;
    justify-content: center; } }

@media only screen and (max-width: 1400px) {
  #my-bets-container, .fixed-my-bets-headline {
    right: 6.65vw; } }

@media only screen and (max-width: 1300px) {
  #my-bets-container, .fixed-my-bets-headline {
    width: 230px !important;
    right: 6.78vw; } }

@media only screen and (max-width: 1000px) {
  #my-bets-container {
    display: none; } }

::-webkit-scrollbar {
  width: 0px;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */ }

#transactions-container {
  width: 100%; }
  #transactions-container .ant-table-tbody > tr > td {
    padding: 0;
    padding-top: 10px; }
  #transactions-container .form-container {
    width: 100%;
    padding-top: 60px; }

.voucher-details-expanded {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: var(--light-black) !important;
  width: 100%;
  padding: 20px; }
  .voucher-details-expanded .voucher-content-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start; }
    .voucher-details-expanded .voucher-content-details .issued-date {
      color: var(--white);
      font-size: 13px;
      margin-bottom: 10px; }
      .voucher-details-expanded .voucher-content-details .issued-date span:nth-of-type(1) {
        -webkit-margin-end: 10px;
                margin-inline-end: 10px; }
    .voucher-details-expanded .voucher-content-details .expiry-date {
      color: var(--white);
      font-size: 13px;
      margin-bottom: 10px; }
      .voucher-details-expanded .voucher-content-details .expiry-date span:nth-of-type(1) {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; }
    .voucher-details-expanded .voucher-content-details .voucher-number {
      color: var(--white);
      font-size: 13px;
      margin-bottom: 10px; }
      .voucher-details-expanded .voucher-content-details .voucher-number span:nth-of-type(1) {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; }
      .voucher-details-expanded .voucher-content-details .voucher-number span:nth-of-type(2) {
        font-weight: bold; }
    .voucher-details-expanded .voucher-content-details .voucher-status {
      color: var(--white);
      font-size: 13px;
      margin-bottom: 10px; }
      .voucher-details-expanded .voucher-content-details .voucher-status span:nth-of-type(1) {
        -webkit-margin-end: 5px;
                margin-inline-end: 5px; }
      .voucher-details-expanded .voucher-content-details .voucher-status span:nth-of-type(2) {
        color: #FF9D00 !important;
        font-weight: bold; }
  .voucher-details-expanded .qr-code-top-border {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 135px;
    height: 135px;
    background: linear-gradient(to right, var(--white) 2px, transparent 2px) 0 0, linear-gradient(to right, var(--white) 2px, transparent 2px) 0 100%, linear-gradient(to left, var(--white) 2px, transparent 2px) 100% 0, linear-gradient(to left, var(--white) 2px, transparent 2px) 100% 100%, linear-gradient(to bottom, var(--white) 2px, transparent 2px) 0 0, linear-gradient(to bottom, var(--white) 2px, transparent 2px) 100% 0, linear-gradient(to top, var(--white) 2px, transparent 2px) 0 100%, linear-gradient(to top, var(--white) 2px, transparent 2px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 20px 20px; }

.account #my-account-transaction-mobile-table {
  display: none; }
  .account #my-account-transaction-mobile-table thead {
    display: none !important; }

@media only screen and (max-width: 900px) {
  .account .layout-inner .content .container .bets .table .ant-table-tbody td {
    text-align: start; }
  .account .layout-inner .content .container .deposit-mtn, .account .layout-inner .content .container .withdrawals, .account .layout-inner .content .container .deposit {
    width: 73vw; }
  .account .layout-inner .content .container .table {
    width: 90vw;
    padding: 0; }
    .account .layout-inner .content .container .table .mobile-table-row-data {
      margin-left: 8vw; }
  .account #my-account-transaction-mobile-table {
    display: block; }
    .account #my-account-transaction-mobile-table .ant-table-tbody > tr > td {
      border: none;
      background-image: linear-gradient(to right, #202D46 33%, rgba(255, 255, 255, 0) 0%);
      background-position: bottom;
      background-size: 32px 3px;
      background-repeat: repeat-x;
      text-align: start; }
  .account #my-account-desktop-table {
    display: none; } }

@media only screen and (max-width: 1000px) {
  .voucher-details-expanded {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--light-black) !important;
    width: 95%; }
  .voucher-content-details {
    margin-bottom: 33px; }
  .qr-code-top-border {
    margin-bottom: 26px; } }

@media only screen and (max-width: 900px) {
  .voucher-details-expanded {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px; }
  .voucher-content-details {
    margin-bottom: 33px; }
  .qr-code-top-border {
    margin-bottom: 26px; } }

@media only screen and (max-width: 500px) {
  #transactions-container .form-container {
    padding-top: 20px; } }

@media only screen and (max-width: 320px) {
  .account .voucher .ant-table-tbody > tr > td {
    font-size: 7px !important; } }

.personal-form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 30px; }
  .personal-form .ant-form-item .ant-form-item-label label {
    font-size: 13px !important; }
  .personal-form .ant-input-lg {
    font-size: 15px !important; }
  .personal-form .ant-input {
    font-size: 15px; }
  .personal-form .ant-select {
    font-size: 15px; }
  .personal-form .ant-form-item-control {
    line-height: 0; }
  .personal-form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered {
    margin-top: 0 !important; }
  .personal-form .form-container {
    width: 100%; }
  .personal-form .ant-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  .personal-form .validation-meter div {
    color: black; }
  .personal-form .validation-meter .alert .icon .inner {
    display: flex;
    align-items: center; }
  .personal-form .validation-meter .alert .success {
    background-color: lightgreen; }
  .personal-form .validation-meter .alert svg {
    width: 12px; }
  .personal-form .ant-input {
    color: #FFFFFF;
    background-color: #3B475F;
    height: 47px;
    border: none;
    height: 47px !important; }
  .personal-form .ant-select-arrow i svg {
    color: #000 !important; }
  .personal-form .ant-input-disabled {
    color: rgba(255, 255, 255, 0.44);
    background-color: #3B475F !important; }
  .personal-form .ant-select-selection {
    border: none; }
  .personal-form .ant-select-disabled .ant-select-selection {
    background-color: #3B475F; }
  .personal-form .ant-form-item {
    display: flex;
    flex-direction: column;
    width: 40%;
    -webkit-margin-start: 5%;
            margin-inline-start: 5%; }
    .personal-form .ant-form-item .ant-form-item-label {
      text-align: unset;
      width: 100%; }
      .personal-form .ant-form-item .ant-form-item-label label {
        color: #fff !important;
        font-size: 14px;
        font-weight: bold; }
      .personal-form .ant-form-item .ant-form-item-label .ant-form-item-required::before {
        content: ''; }
      .personal-form .ant-form-item .ant-form-item-label .ant-form-item-required::after {
        display: inline-block;
        margin-right: 4px;
        color: #f5222d;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*'; }
    .personal-form .ant-form-item .ant-input-group-addon {
      padding: unset;
      border: unset; }
      .personal-form .ant-form-item .ant-input-group-addon div {
        color: red; }
      .personal-form .ant-form-item .ant-input-group-addon .ant-btn {
        border-top-left-radius: unset;
        border-bottom-left-radius: unset; }
    .personal-form .ant-form-item .ant-form-item-control-wrapper {
      width: 85%; }
      .personal-form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single {
        height: 47px;
        background-color: #3B475F; }
        .personal-form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered {
          margin-top: 4px;
          height: 100%; }
          .personal-form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection__placeholder {
            color: rgba(255, 255, 255, 0.44); }
          .personal-form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value {
            color: white;
            height: 100%;
            display: flex !important;
            opacity: 1;
            justify-content: center;
            align-items: center;
            flex-direction: column; }
            .personal-form .ant-form-item .ant-form-item-control-wrapper .ant-select .ant-select-selection--single .ant-select-selection__rendered .ant-select-selection-selected-value span {
              color: rgba(255, 255, 255, 0.44); }
    .personal-form .ant-form-item .ant-select-arrow .ant-select-arrow-icon svg {
      color: #c1c1c1; }
  .personal-form .ant-form-item-with-help {
    margin-bottom: 10px !important; }
  .personal-form .captcha {
    border: solid 0px red; }
  .personal-form .phone-number-item .ant-form-item-control {
    line-height: 0; }
  .personal-form .phone-number-item .seperator-input {
    width: 55px;
    pointer-events: 'none';
    background-color: #fff;
    font-size: 40px;
    border-left: 0 !important;
    border-right: 0 !important;
    text-align: center;
    cursor: auto; }
  .personal-form .phone-number-item .prefix-input {
    border-right: 0 !important;
    width: 70px;
    text-align: center; }
  .personal-form .phone-number-item .number-input {
    width: 140px;
    border-left: 0 !important;
    text-align: center;
    background-color: #fff !important; }
  .personal-form .phone-number-item .country-select {
    width: 110px !important; }
    .personal-form .phone-number-item .country-select .ant-select-selection {
      height: 47px !important; }
  .personal-form .phone-number-item .ant-input {
    -webkit-box-shadow: 0 0 0 0 white !important; }
  .personal-form .phone-number-view .ant-input-disabled {
    color: rgba(255, 255, 255, 0.44); }
  .personal-form .phone-number-view .ant-input-group-wrapper .ant-input-group-addon {
    padding: 0 15px;
    background-color: #3B475F;
    border: none !important; }

.ant-select-dropdown {
  background-color: #3B475F !important;
  align-items: flex-start; }
  .ant-select-dropdown .ant-select-dropdown-menu-item {
    display: flex;
    color: white; }
  .ant-select-dropdown .ant-select-dropdown-menu-item-selected {
    color: rgba(0, 0, 0, 0.44); }
    .ant-select-dropdown .ant-select-dropdown-menu-item-selected div div:nth-child(2) {
      color: #000; }

.form-white .ant-form-item .ant-form-item-label label {
  color: #182438; }

@media only screen and (max-width: 1100px) {
  .ant-form {
    flex-direction: column; }
    .ant-form .ant-form-item {
      width: 100%; }
    .ant-form .ant-input {
      color: #FFFFFF;
      background-color: #3B475F;
      height: 47px;
      border: none;
      height: 47px !important; }
  .ant-form-item-control-wrapper {
    width: 100%; }
  .phone-number-view .ant-input-disabled {
    color: rgba(255, 255, 255, 0.44); }
  .phone-number-view .ant-input-group-wrapper .ant-input-group-addon {
    padding: 0 5px; } }

@media only screen and (max-width: 1000px) {
  .ant-form {
    flex-direction: column; }
    .ant-form .ant-form-item {
      width: 100%; }
    .ant-form .ant-input {
      color: #FFFFFF;
      background-color: #3B475F;
      height: 47px;
      border: none;
      height: 47px !important; }
  .ant-form-item-control-wrapper {
    width: 100%; }
  .ant-input-group-wrapper .ant-input-group-addon {
    padding: 0 5px !important; }
    .ant-input-group-wrapper .ant-input-group-addon div {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; } }

@media only screen and (max-width: 900px) {
  .ant-form {
    flex-direction: column; }
    .ant-form .ant-form-item {
      width: 100%; }
    .ant-form .ant-input {
      color: #FFFFFF;
      background-color: #3B475F;
      height: 47px;
      border: none;
      height: 47px !important; }
  .ant-form-item-control-wrapper {
    width: 100%; }
  .ant-input-group-wrapper .ant-input-group-addon {
    padding: 0 5px; }
    .ant-input-group-wrapper .ant-input-group-addon div {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; } }

@media only screen and (max-width: 480px) {
  .ant-form {
    flex-direction: column; }
    .ant-form .ant-form-item {
      width: 100%; }
    .ant-form .ant-input {
      color: #FFFFFF;
      background-color: #3B475F;
      height: 47px !important;
      border: none;
      max-height: 47px !important; }
      .ant-form .ant-input .ant-select {
        max-height: 47px;
        font-size: 11px;
        padding: 0; }
  .ant-form-item-control-wrapper {
    width: 100%; }
  .ant-input-group-wrapper .ant-input-group-addon {
    padding: 0 5px; }
    .ant-input-group-wrapper .ant-input-group-addon div {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; } }

@media only screen and (max-width: 380px) {
  .ant-form {
    flex-direction: column; }
    .ant-form .ant-form-item {
      max-width: 100%; }
    .ant-form .ant-input {
      color: #FFFFFF;
      background-color: #3B475F;
      height: 47px;
      border: none;
      height: 47px !important; }
  .ant-form-item-control-wrapper {
    width: 100%; }
  .ant-input-group-wrapper .ant-input-group-addon {
    padding: 0 5px; }
    .ant-input-group-wrapper .ant-input-group-addon div {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; } }

@media only screen and (max-width: 300px) {
  .ant-form {
    flex-direction: column; }
    .ant-form .ant-form-item {
      width: 100%; }
    .ant-form .ant-input {
      color: #FFFFFF;
      background-color: #3B475F;
      height: 47px;
      border: none;
      height: 47px !important; }
  .ant-form-item-control-wrapper {
    width: 100%; }
  .ant-input-group-wrapper .ant-input-group-addon {
    padding: 0 5px; }
    .ant-input-group-wrapper .ant-input-group-addon div {
      -webkit-margin-end: 10px;
              margin-inline-end: 10px; } }

#login-and-regisration-container {
  min-height: 85vh; }
  #login-and-regisration-container .ant-btn span {
    color: white !important; }
  #login-and-regisration-container .ant-steps-horizontal.ant-steps-label-horizontal > .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: none; }
  #login-and-regisration-container .forget-password-parent-container #OTP-vertification-parent-container .ant-form .ant-form-item {
    width: 100% !important; }
  #login-and-regisration-container .ant-col-xs-24 {
    float: none;
    width: 100%; }
  #login-and-regisration-container .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: none;
    color: var(--forget-password-headline);
    position: relative;
    top: 70px;
    right: 70%;
    font-size: 17px;
    display: block !important;
    white-space: nowrap; }
  #login-and-regisration-container .forget-password-steps {
    display: flex;
    width: 25%;
    padding-top: 10px;
    margin: auto; }
  #login-and-regisration-container .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: white !important; }
  #login-and-regisration-container .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon {
    display: inline !important; }
  #login-and-regisration-container .ant-steps-item-finish .ant-steps-item-icon {
    background-color: #2C8AF7;
    color: white !important; }
  #login-and-regisration-container .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--forget-password-steps); }
  #login-and-regisration-container .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after {
    background-color: var(--forget-password-steps); }
  #login-and-regisration-container .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon {
    color: #979CAA !important;
    display: inline !important; }
  #login-and-regisration-container .ant-steps-item-wait .ant-steps-item-icon {
    background-color: var(--forget-password-steps);
    border: transparent; }
  #login-and-regisration-container .ant-steps-item-title::after {
    left: -7px;
    top: 25px;
    width: 160px;
    height: 3px; }
  #login-and-regisration-container .ant-steps-item-icon {
    height: 50px;
    width: 50px;
    font-size: 20px;
    line-height: 47px; }
  #login-and-regisration-container .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    margin-right: 0; }
  #login-and-regisration-container .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    max-width: none; }
  #login-and-regisration-container .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--forget-password-headline); }
  #login-and-regisration-container .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: var(--forget-password-headline);
    position: relative;
    top: 70px;
    right: 70%;
    font-size: 17px;
    display: block !important;
    white-space: nowrap; }
  #login-and-regisration-container .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
    color: var(--forget-password-headline); }
  #login-and-regisration-container .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    color: #979CAA;
    position: relative;
    top: 70px;
    right: 75%;
    font-size: 17px;
    padding-bottom: 70px;
    display: block !important; }
  #login-and-regisration-container .form .ant-input {
    color: var(--login-registartion-inputs-font-color);
    background-color: var(--login-registartion-inputs);
    border: none; }
  #login-and-regisration-container .login-forget-password-container {
    text-align: center;
    padding-top: 15px; }
    #login-and-regisration-container .login-forget-password-container span {
      border-bottom: 1px solid var(--forget-password-headline);
      color: var(--forget-password-headline) !important; }
  #login-and-regisration-container .ant-form label::after {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*'; }
  #login-and-regisration-container .show-password-icon {
    position: absolute;
    right: 2%;
    top: 27%; }
  #login-and-regisration-container .login-form-container {
    background-color: var(--login-regitration-header);
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
    padding: 50px 110px;
    margin-top: 100px; }
    #login-and-regisration-container .login-form-container .form-inputs-container {
      width: 40%; }
    #login-and-regisration-container .login-form-container .login-form-headline {
      text-align: center;
      font-size: 20px;
      font-weight: 600;
      padding-bottom: 30px;
      color: var(--forget-password-headline) !important; }
    #login-and-regisration-container .login-form-container .ant-input {
      background-color: var(--login-registartion-inputs) !important;
      border: none;
      color: var(--login-registartion-inputs-font-color); }
    #login-and-regisration-container .login-form-container .ant-form-inline .ant-form-item > .ant-form-item-label {
      display: block; }
    #login-and-regisration-container .login-form-container .ant-form-item-label {
      text-align: start; }
    #login-and-regisration-container .login-form-container .ant-form label {
      font-size: 13px; }
    #login-and-regisration-container .login-form-container .ant-form-item-label > label {
      color: var(--white); }
    #login-and-regisration-container .login-form-container .login-password-container {
      position: relative; }
    #login-and-regisration-container .login-form-container .login-form-items-container {
      display: flex;
      justify-content: space-between; }
    #login-and-regisration-container .login-form-container .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper {
      width: 100%; }
    #login-and-regisration-container .login-form-container .login-form-button-container {
      display: flex;
      justify-content: center;
      padding-top: 120px; }
      #login-and-regisration-container .login-form-container .login-form-button-container .ant-form-item {
        width: auto !important; }
    #login-and-regisration-container .login-form-container .ant-btn {
      width: 20vw;
      background-color: #2C8AF7; }
  #login-and-regisration-container .forget-password-headline {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    padding-bottom: 30px;
    color: var(--forget-password-headline) !important; }
  #login-and-regisration-container .steps-content {
    background-color: var(--login-regitration-header);
    padding: 50px 100px;
    margin-top: 50px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px; }
    #login-and-regisration-container .steps-content .OTP-fields {
      display: flex;
      justify-content: center;
      white-space: nowrap; }
      #login-and-regisration-container .steps-content .OTP-fields .confirmation-input {
        margin-right: 5px;
        height: 92px !important;
        width: 76px !important;
        padding: 0 !important;
        font-size: 15px;
        text-align: center;
        font-weight: 400;
        border: none;
        background-color: var(--login-registartion-inputs);
        color: var(--login-registartion-inputs-font-color); }
    #login-and-regisration-container .steps-content .forget-password-confirmation-input-label-container {
      display: flex;
      justify-content: space-between; }
      #login-and-regisration-container .steps-content .forget-password-confirmation-input-label-container .ant-form-item {
        width: 38% !important; }
      #login-and-regisration-container .steps-content .forget-password-confirmation-input-label-container label {
        font-weight: 400; }
      #login-and-regisration-container .steps-content .forget-password-confirmation-input-label-container .ant-form-item-required::before {
        display: none; }
    #login-and-regisration-container .steps-content .forget-password-input-label-container {
      display: flex;
      justify-content: space-between; }
      #login-and-regisration-container .steps-content .forget-password-input-label-container .ant-form-item-required::before {
        display: none; }
      #login-and-regisration-container .steps-content .forget-password-input-label-container .ant-form-item {
        width: 40%; }
      #login-and-regisration-container .steps-content .forget-password-input-label-container label {
        font-weight: 400; }
    #login-and-regisration-container .steps-content .step1-parent-container .ant-input {
      width: 100%; }
    #login-and-regisration-container .steps-content .step1-button-container, #login-and-regisration-container .steps-content .step2-button-container {
      margin-top: 100px;
      display: flex;
      justify-content: end; }
      #login-and-regisration-container .steps-content .step1-button-container .ant-btn, #login-and-regisration-container .steps-content .step2-button-container .ant-btn {
        width: 150px;
        flex: none; }
  #login-and-regisration-container .ant-steps {
    margin-top: 20px; }
    #login-and-regisration-container .ant-steps .anticon svg {
      font-size: 20px;
      color: white;
      content: "1"; }
    #login-and-regisration-container .ant-steps .ant-steps-item {
      overflow: visible; }

#login-and-registration-header .top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 6vw 0 6vw;
  background-color: var(--login-regitration-header); }
  #login-and-registration-header .top-menu img {
    cursor: pointer; }

#login-and-registration-header .header-text {
  font-size: 18px; }
  #login-and-registration-header .header-text div {
    display: inline-block;
    border-bottom: 2px solid var(--forget-password-headline);
    margin-left: 5px;
    cursor: pointer;
    color: var(--forget-password-headline) !important; }
  #login-and-registration-header .header-text span {
    opacity: 0.5; }

#login-and-registration-header .back-to-login {
  font-size: 18px;
  color: var(--forget-password-headline) !important;
  font-weight: 600;
  cursor: pointer; }
  #login-and-registration-header .back-to-login img {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

#register-user-container .ant-btn span {
  color: white !important; }

#register-user-container .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: white !important; }

#register-user-container .ant-select-selection--single {
  background-color: var(--login-registartion-inputs);
  border: none; }

#register-user-container form :not(.ant-input-group-wrapper) > .ant-input-group {
  display: flex; }

#register-user-container .phone-number-input-group .ant-input {
  width: 100% !important;
  text-align: start; }

#register-user-container .phone-number-input-group .ant-select-arrow svg {
  color: white; }

#register-user-container .ant-select-selection-selected-value {
  margin-top: 5px; }

#register-user-container .register-buttons-container {
  display: flex;
  justify-content: end; }
  #register-user-container .register-buttons-container .ant-btn {
    width: 150px;
    flex: none; }

#register-user-container .register-headline {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding-bottom: 30px;
  color: var(--forget-password-headline) !important; }

#register-user-container .register-form-container {
  background-color: var(--login-regitration-header); }
  #register-user-container .register-form-container .ant-select-disabled .ant-select-selection, #register-user-container .register-form-container .ant-select-selection, #register-user-container .register-form-container .ant-input-group-addon {
    background-color: var(--login-registartion-inputs);
    border: none; }
  #register-user-container .register-form-container .register-form-sections {
    display: flex;
    justify-content: space-between; }
  #register-user-container .register-form-container .ant-form-item {
    display: inline-block;
    width: 40%; }
  #register-user-container .register-form-container .validation-meter {
    padding: 20px;
    margin-bottom: 10px;
    background-color: var(--login-regitartion-validiation-meter); }
    #register-user-container .register-form-container .validation-meter .validation-meter-headline {
      opacity: 0.44; }
    #register-user-container .register-form-container .validation-meter div {
      padding: 3px 0; }
  #register-user-container .register-form-container #register-check-box label::after {
    display: none; }
  #register-user-container .register-form-container .ant-form-item-required::before {
    display: none; }
  #register-user-container .register-form-container .form-inputs-container {
    width: 40%; }
  #register-user-container .register-form-container .login-form-headline {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 30px;
    color: var(--forget-password-headline) !important; }
  #register-user-container .register-form-container .ant-input {
    background-color: var(--login-registartion-inputs) !important;
    border: none;
    color: var(--login-registartion-inputs-font-color); }
  #register-user-container .register-form-container .ant-form-inline .ant-form-item > .ant-form-item-label {
    display: block; }
  #register-user-container .register-form-container .ant-form-item-label {
    text-align: start; }
  #register-user-container .register-form-container .ant-form label {
    font-size: 13px; }
  #register-user-container .register-form-container .ant-form-item-label > label {
    color: var(--white); }
  #register-user-container .register-form-container .login-password-container {
    position: relative; }
  #register-user-container .register-form-container .login-form-items-container {
    display: flex;
    justify-content: space-between; }
  #register-user-container .register-form-container .ant-form-inline .ant-form-item > .ant-form-item-control-wrapper {
    width: 100%; }
  #register-user-container .register-form-container .login-form-button-container {
    display: flex;
    justify-content: center;
    padding-top: 120px; }
  #register-user-container .register-form-container .ant-btn {
    width: 20vw;
    background-color: #2C8AF7; }

#register-user-container .form .ant-input {
  color: var(--login-registartion-inputs-font-color);
  background-color: var(--login-registartion-inputs);
  border: none; }

#register-user-container .steps-content {
  margin-top: 10px; }

#register-user-container .strip {
  position: relative;
  text-transform: none;
  display: flex !important;
  background-color: var(--login-regitartion-strip);
  padding: 80px 0;
  margin-top: 50px;
  text-align: center;
  font-size: 17px; }
  #register-user-container .strip .register-close-icon {
    position: absolute;
    right: 10px;
    top: 10px; }
  #register-user-container .strip div {
    color: var(--forget-password-headline) !important; }
  #register-user-container .strip .content {
    padding: 0 20%; }

@media only screen and (max-width: 1400px) {
  #login-and-regisration-container .forget-password-steps {
    width: 35%; } }

@media only screen and (max-width: 1200px) {
  #login-and-regisration-container .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    display: none !important; }
  #login-and-regisration-container .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description {
    display: none !important; }
  .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
    display: none !important; }
  #login-and-regisration-container .forget-password-steps {
    margin: 0;
    margin-top: 30px; }
  #login-and-regisration-container .ant-steps-item-container {
    width: 41vw;
    overflow: hidden; }
  #login-and-regisration-container .ant-steps-item-title::after {
    width: 1000px; } }

@media only screen and (max-width: 1100px) {
  .phone-number-item .country-select .ant-select-selection {
    height: 47px !important; }
  #login-and-registration-header .top-menu .logo img {
    height: 70px !important;
    width: 150px !important; }
  #login-and-registration-header .header-text div {
    font-size: 12px; }
  #login-and-registration-header .header-text span {
    display: none; } }

@media only screen and (max-width: 1000px) {
  #login-and-regisration-container {
    padding: 0 6vw 0 6vw; }
  #login-and-regisration-container .steps-content .forget-password-input-label-container {
    width: 100%; }
  #login-and-registration-header .back-to-login {
    font-size: 12px; } }

@media only screen and (max-width: 900px) {
  #login-and-regisration-container .steps-content .OTP-fields .confirmation-input {
    height: 52px !important;
    width: 44px !important; } }

@media only screen and (max-width: 700px) {
  .step1-parent-container .forget-password-input-label-container {
    display: block !important; }
    .step1-parent-container .forget-password-input-label-container .ant-form-item {
      width: 100% !important; }
  #register-user-container .register-form-container .register-form-sections {
    display: block; }
  #register-user-container .register-form-container .ant-form-item {
    width: 100%; }
  #register-user-container .strip {
    text-align: start;
    padding: 80px 20px;
    font-size: 13px; }
  #register-user-container .register-headline {
    font-size: 17px; }
  #register-user-container .ant-checkbox-wrapper {
    font-size: 11px !important; }
  #register-user-container .register-buttons-container {
    justify-content: center !important; }
  #login-and-regisration-container .login-form-container .login-form-items-container {
    display: block; }
  #login-and-regisration-container .steps-content .step1-button-container, #login-and-regisration-container .steps-content .step2-button-container {
    margin-top: 30px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center; }
    #login-and-regisration-container .steps-content .step1-button-container .ant-btn, #login-and-regisration-container .steps-content .step2-button-container .ant-btn {
      width: 40%; }
  #login-and-regisration-container .login-form-container .form-inputs-container {
    width: 100%;
    padding-bottom: 20px; }
  #login-and-regisration-container .login-form-container {
    margin-top: 50px; }
  #login-and-regisration-container .login-form-container .login-form-button-container {
    padding-top: 30px; }
  #login-and-regisration-container .login-form-container {
    padding: 30px 20px; }
  #login-and-regisration-container .steps-content {
    padding: 30px 20px; }
  #login-and-regisration-container .login-form-container .ant-btn {
    width: 40vw; }
  #login-and-regisration-container .steps-content .step1-button-container {
    justify-content: center; }
  #login-and-regisration-container .steps-content .step1-button-container .ant-btn {
    width: 40%; }
  #login-and-regisration-container .steps-content .step1-button-container {
    margin-top: 40px;
    margin-bottom: 20px; }
  #login-and-regisration-container .ant-steps-item-container {
    width: 40vw; } }

@media only screen and (max-width: 550px) {
  #login-and-regisration-container .ant-steps-item-container {
    width: 39vw; } }

@media only screen and (max-width: 500px) {
  #login-and-regisration-container .steps-content .forget-password-confirmation-input-label-container {
    display: block; }
  #login-and-regisration-container .steps-content .forget-password-confirmation-input-label-container .ant-form-item {
    width: 100% !important; }
  #login-and-regisration-container .steps-content .OTP-fields .confirmation-input {
    font-size: 18px; }
  #login-and-regisration-container .login-form-container .login-form-headline {
    font-size: 17px; }
  #login-and-regisration-container .ant-input {
    font-size: 15px; }
  #login-and-regisration-container .login-form-container .ant-btn {
    font-size: 13px; }
  #login-and-regisration-container .login-forget-password-container span {
    font-size: 13px; }
  #login-and-regisration-container .ant-steps-item-title::after {
    display: block !important; }
  #login-and-regisration-container .ant-steps-horizontal.ant-steps-label-horizontal .ant-steps-item-icon {
    margin-right: 0 !important; }
  #login-and-regisration-container .forget-password-headline {
    font-size: 17px; }
  #login-and-regisration-container .form .ant-form-item .ant-form-item-label label {
    font-size: 13px; }
  #login-and-regisration-container .ant-btn {
    font-size: 13px; } }

@media only screen and (max-width: 450px) {
  #login-and-regisration-container .ant-steps-item-container {
    width: 37vw; } }

@media only screen and (max-width: 400px) {
  #login-and-registration-header .back-to-login {
    font-size: 9px; }
  #login-and-regisration-container .steps-content .OTP-fields .confirmation-input {
    height: 42px !important;
    width: 34px !important; } }

@media only screen and (max-width: 330px) {
  #login-and-regisration-container .ant-steps-item-container {
    width: 36vw; }
  #login-and-regisration-container .steps-content .OTP-fields .confirmation-input {
    height: 37px !important;
    width: 29px !important; } }

