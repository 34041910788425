/********** Ant Menu **********/
.ant-menu {
  background: var(--light-black);

  margin-bottom: 20px;
  font-size: 14px;
  font-weight: bold;
  border: unset;

  .ant-menu-item {
    padding: unset !important;
    margin: unset !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    height: unset !important;
    min-height: 40px !important;
    display: flex;

    .title {
      text-transform: capitalize;
      flex: 1 1;
      opacity: 0.7;
    }
  }

  .ant-menu-item-selected::after {
    border-right: 4px solid #e01c23;
  }

  .ant-menu-submenu-selected {
    border: solid 1px transparent;
  }

  .ant-menu-submenu {
    .ant-menu-submenu-arrow::before , .ant-menu-submenu-arrow::after{

      width: 10px;
      filter: brightness(1.8);
    }
    .ant-menu-submenu-arrow::before {
      margin-left: 3px;
    }
    .title {
      width: 100%;
      display: flex;
      flex-direction: row;

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          filter: brightness(0) invert(1);
          width: 16px;
          height: 16px;
          margin-right: 10px;
        }
      }
    }

    .ant-menu-submenu-title {
      opacity: 0.7;
      // border-bottom: solid 1px #29354D;
      margin: unset !important;
      padding: unset !important;
      background-color: #172a40;
      padding-left: 10px !important;
    }
  }

  .ant-menu-submenu.ant-menu-submenu-open {
    .today {
      padding-left: 40px !important;
    }

    .ant-menu-submenu-title {
      background-color: #243a53;
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .ant-menu-sub {
      .ant-menu-item {
        opacity: 0.7;
        border-bottom: solid 1px var(--light-black);
        background-color: #243a53;

        .nav-div {
          padding-left: 40px;
        }
      }
    }

    ul {
      background-color: #243a53;

      .ant-menu-sub.ant-menu-inline {
        overflow-y: auto !important;
        overflow-x: hidden !important;
        max-height: 50vh;
        scrollbar-width: none;
        -ms-overflow-style: none;
      }

      .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 0px var(--light-black);
        background-color: var(--light-black);
      }

      .ant-menu-sub.ant-menu-inline::-webkit-scrollbar {
        width: 0px;
        background-color: var(--light-black);
      }

      .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: #243a53;
        border: solid 0px var(--light-black);
      }

      //----------------- OROGINAL CODE - DO NOT DELETE THIS !!!
      // .ant-menu-sub.ant-menu-inline {
      // 	overflow-y: auto !important;
      // 	overflow-x: hidden !important;
      // 	max-height: 500px;
      // }

      // .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-track {
      // 	-webkit-box-shadow: inset 0 0 6px var(--light-black);
      // 	background-color: var(--light-black);
      // }

      // .ant-menu-sub.ant-menu-inline::-webkit-scrollbar {
      // 	width: 13px;
      // 	background-color: var(--light-black);
      // }

      // .ant-menu-sub.ant-menu-inline::-webkit-scrollbar-thumb {
      // 	border-radius: 8px;
      // 	background-color: #243a53;
      // 	border: solid 3px var(--light-black);
      // }
      //----------------- OROGINAL CODE - DO NOT DELETE THIS !!!

      li {
        margin: unset !important;
        padding: unset !important;

        .ant-menu-submenu-title {
          padding-left: 40px !important;
        }

        .ant-menu-submenu-open {
          ul {
            li {
              .nav-div {
                width: 206px;
                padding-left: 50px !important;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                .nav-text {
                  flex: 1;
                  margin-right: 10px;
                }

                .nav-badge {
                  flex: 0.1;

                  sup {
                    margin-left: -15px !important;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .nav-div {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: all 200ms;
    margin: 0.4rem 0;
    height: 20px;
    padding: 1rem 0.5rem;
    border: 1px solid transparent;
    border-radius: 5px;
    &:hover {
      background-color: var(--light-black);
      cursor: pointer;
    }

    .nav-text {
      flex: 4;
      width: 100%;
      white-space: nowrap;
      line-height: normal;
      overflow: hidden !important;
      text-overflow: ellipsis;
      font-size: 14px;
      font-weight: lighter;
    }

    .nav-badge {
      margin-left: auto;

      sup {
        background-color: transparent !important;
        padding: unset !important;
        margin: unset !important;
        box-shadow: unset;
        text-align: center;
        font-size: 14px;
        text-align: right;
      }
    }
  }

  .flag-icon {
    margin-right: 2px;
    border-radius: 4px;
    width: 100%;
    flex: 1;
  }

  .ant-menu-item-divider {
    //width: 95%;
    margin: 30px auto 0px auto;
    opacity: 0.2;
    height: 2px;
  }

  .flat-item {
    opacity: 0.7;
    background-color: #172a40;

    .text {
      text-transform: capitalize;
    }

    .icon {
      i svg {
        width: 16px;
        height: 16px;
      }
    }
  }
}

/*----------------- Virtual Menu -----------------*/
.virtual-menu {
  .ant-menu-submenu {
    background-color: var(--virtual-menu-background-color) !important;
    margin-bottom: 1px !important;
  }

  .ant-menu-item-selected {
    background-color: #243a53 !important;
  }
}

/*----------------- My Account Menu -----------------*/
.account-menu {
  .title {
    display: flex;
    align-items: center;

    .icon {
      font-size: 20px;
    }
  }

  .ant-menu-item:not(:last-child) {
    border-bottom: solid 1px #243a53;
  }

  .ant-menu-item-selected {
    background-color: #243a53 !important;
  }

  .ant-menu-item-selected::after {
    border-right: 4px solid blue;
  }
}

/******************** leagues menus ********************/
.sub-menu {
  width: 100%;
  background-color: var(--dark-blue);
  border-radius: 5px;
  padding: 0.5rem;
  margin: 0 0 1rem 0;
}
#sub-menu-items{
  .sub-menu-divs{
    border-bottom:1px solid #27334C ;
    padding-top:5px ;
    padding-bottom:5px;
  }
}
#menu-tree-bet-services{
  #bet-services-statistics-container{
    border-bottom:1px solid #27334C;
    padding-bottom:20px ;
    padding-top:20px;
  }
}

  

#sub-menu-items> div:last-of-type{
  border:none !important;



}

.menu-banner {
  font-size: 1.2rem;
  font-weight: 300;
  height: 2rem;
  padding: 1rem 0;
  margin: 0 0 0.3rem 0;
}

.sub-menu {
  display: flex;
  flex-direction: column;
}

.menu-item {
  height: 20px;
  display: flex;
  align-items: center;
  padding: 1rem 0.5rem;
  border: 1px solid transparent;
  border-radius: 5px;
  font-weight: lighter;
  font-size: 16px;
  &:hover {
    background-color: var(--light-black);
    cursor: pointer;
  }
}
@media only screen and (max-width: 950px){
  #sub-menu-items{
    .sub-menu-divs{
      border-bottom:1px solid #3A4967 ;
    }
  }
  #menu-tree-bet-services{
    #bet-services-statistics-container{
      border-bottom:1px solid #3A4967;
    }
  }
}