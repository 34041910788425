.dd {
  .event {
    .row-1 {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-radius: 4px;
      background-color: var(--light-black);
      padding: 10px;
      margin-bottom: 10px;

      .col-1 {
        flex: 1;
      }

      .col-2 {
        display: flex;
        flex-direction: row;
      }

      .league {
        opacity: 0.7;
        font-size: 14px;
        font-weight: bold;
      }

      .date {
        text-align: right;
        opacity: 0.7;
        font-size: 14px;
        font-weight: bold;
        align-self: flex-end;
        display: flex;
        flex-direction: row;
        justify-items: flex-end;
        align-items: flex-end;

        .timer,
        .event-id {
          margin-left: 20px;
        }
      }

      @media only screen and (max-width: 768px) {
        flex-direction: column;
        padding: 10px 20px 10px 20px;
        margin-left: -10px;
        margin-right: -10px;
        margin-bottom: 2px;

        .league {
          margin-bottom: 5px;
        }
      }
    }

    .row-2 {
      overflow: auto;
      margin-bottom: 30px;
      display: flex;
      flex-direction: row;
      background-size: 100% 100%;
      height: 125px;
      border-radius: 4px;

      .back {
        padding-left: 15px;
        float: left;
        position: absolute;
        margin-top: 10px;
        margin-left: 10px;
        font-weight: bold;
        background-image: url("../../resources/images/lt.webp");
        background-repeat: no-repeat;
        background-position: 0 3px;
        cursor: pointer;
      }

      .teams {
        margin: auto auto auto auto;
        width: 100%;

        .names,
        .score {
          text-shadow: 0 0 50px var(--black-to-white);
          font-weight: bold;
          font-size: 40px;
        }

        .names {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        }

        .score {
          text-align: center;
        }
      }

      @media only screen and (max-width: 768px) {
        height: 70px;

        .teams {
          .names,
          .score {
            font-size: 20px;
          }
        }
      }
    }

    .bg-1 {
      background-image: url("../../resources/drilldown/drill1.webp");

      @media only screen and (max-width: 768px) {
        margin-left: -10px;
        margin-right: -10px;
        border-radius: unset;
        background-image: unset;
        background-color: var(--light-black);
      }
    }
  }

  .groups {
    padding: 10px;
    border-radius: 4px;
    background-color: var(--single-match-pages-background);

    .group {
      //margin-top: 20px;
      display: flex;
      flex-direction: column;

      .title {
        font-weight: bold;
        font-size: 18px;
        //opacity: 0.8;
        margin-bottom: 5px;
        margin-top: 10px;
        text-transform: capitalize;
        color: #7aa5c8;
      }

      .group-title {
        font-weight: bold;
        font-size: 14px;
        opacity: 0.8;
        margin-bottom: 5px;
        margin-top: 10px;
        text-transform: capitalize;
      }

      .buttons-ods.match {
        .name {
          opacity: unset !important;
        }
      }

      .buttons-ods {
        margin-bottom: 7px;

        button {
          height: 100%;
          min-height: 40px;
          flex: 1 1;

          .container {
            flex: 1 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .name {
              width: 100%;
              padding: 5px 10px 5px 10px;
              text-align: left;
              font-weight: bold;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .value {
              padding: 5px 10px 5px 10px;
              text-align: center;
            }

            .lock {
              margin-right: 10px;
            }
          }
        }

        .empty {
          flex: 1 1;
        }
      }

      @media only screen and (max-width: 768px) {
        .buttons-ods {
          flex-direction: row;

          button {
            //min-height: 60px !important;

            .container {
              flex: 1 1;
              display: flex;
              flex-direction: column !important;
              justify-content: space-between;
              align-items: center;

              .name {
                width: 100%;
                padding: 5px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}
